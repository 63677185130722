import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ApiService} from '../api.service';
import {Router, ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {DatePipe} from '@angular/common';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {NgxSpinnerService} from 'ngx-spinner';
import {ValidateAccessService} from "../validate-access.service";
import { NgxDateRangePickerOptions } from 'ngx-daterangepicker';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

import moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'app-gastos-medicos-menores-p2',
  templateUrl: './gastos-medicos-menores-p2.component.html',
  styleUrls: ['./gastos-medicos-menores-p2.component.css']
})
export class GastosMedicosMenoresP2Component implements OnInit {

  planForm: FormGroup; // Formulario

  idPlan: number;
  idProduct: number;

  planes: any;
  aseguradoras: any;
  aseguradorasSeleccionadas: any = [];
  aseguradorasSeleccionadasName: any = [];
  coverages: any = {};
  coveragesSeleccionados: any = {};
  coveragesOtrosSeleccionados: any = {};
  poblation: any = {};
  planesSeleccionados: any = {};
  loadPlanes: any = {};
  plan: any;
  tarifas: any = {};

  constructor(private api: ApiService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private toastr: ToastrService,
              private shared: SharedService,
              private datePipe: DatePipe,
              public fb: FormBuilder,
              private modalService: BsModalService,
              private spinner: NgxSpinnerService,
              private formBuilder: FormBuilder,
              private validateAccess: ValidateAccessService) {
  }
  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.idPlan = params.idPlan;
      this.idProduct = +params.idProduct;

      this.getPlanInfo();
    });
  }
  /* -----------------------------------------------------------------------------------------------------
  ----------------------------- F U N C I O N E S G E N E R A L E S ---------------------------------------
  ----------------------------------------------------------------------------------------------------- */
  goToBack() {
    this.shared.gSearchId = 0;
    this.router.navigate(['/admin/executive']);
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  getPlanInfo(): void {
    const params = {
      idProduct: +this.idProduct,
      flexPlanId: +this.idPlan
    };

    this.api.getInfoConfigurationP2(params).subscribe(response => {
      this.plan = response;
      this.getInsurancePlanByIdProduct();
    },
    error => {
      console.log(error);
    });
  }

  getInsurancePlanByIdProduct(): void {
    this.api.getInsurancePlanByIdProduct({ idProduct: this.idProduct }).subscribe(response => {
      this.planes = response;
      console.log(this.planes);
      this.aseguradoras = _.chain(this.planes).groupBy('insuranceName').value();

      Object.keys(this.aseguradoras).forEach(key => {
        this.aseguradorasSeleccionadas[key] = false;
        this.loadPlanes[key] = false;
      });

      //Hablar con gaby para que regrese el insuranceName desde el mismo servicio
      this.plan.listCategoriesPlan.forEach(cat => {
        const plan = this.getPlan(cat.planName);
        cat.insuranceName = plan.insuranceName;
      });

      const aseguradorasFromPlan = _.chain(this.plan.listCategoriesPlan).groupBy('insuranceName').value();
      Object.keys(aseguradorasFromPlan).forEach(async (key, index) => {
        const as = aseguradorasFromPlan[key];
        this.aseguradorasSeleccionadas[key] = true
        await this.delay(1000);
        this.getCoveragesByIdProduct(key, index, as);
      });
    },
    error => {
      console.log(error);
    });
  }

  getPlanRates(plan: any): void {
    const planList = this.getPlanFormPlan(plan.planName);
    const params = {
      flexPlanId : +this.idPlan,
      flexPlanPlanesId: planList.idFlexPlanPlanes,
      insuranceId: plan.idInsurance
    };

    this.api.getPlanRatesConfigurationP2(params).subscribe(response => {
      console.log(response);
      this.tarifas[plan.planName] = response;
    },
    error => {
      console.log(error);
    });
  }

  getCoveragesByIdProduct(name: string, index: number, as: any = null): void {
    this.loadPlanes[name] = false;
    this.aseguradorasSeleccionadasName[index] = name;
    if (this.aseguradorasSeleccionadas[name]) {
      const plan = this.aseguradoras[name];
      const params = {
        idPlan: plan[0].idInsurancePlan,
        idProduct: this.idProduct
      };
      this.api.getCoveragesByIdProductOb(params).subscribe(response => {
        this.coverages[name] = response;

        this.aseguradoras[name].forEach(plan => {
          const ob = { [plan.planName] : [] };
          this.coveragesSeleccionados[name] = {...this.coveragesSeleccionados[name], ...ob};
          const obCov = { [plan.planName] : [] };
          this.coveragesOtrosSeleccionados[name] = {...this.coveragesOtrosSeleccionados[name], ...obCov};
          let ob2;
          if (as) {
            const ind = _.findIndex(as, { planName : plan.planName });
            if (ind >= 0) {
              ob2 = { [plan.planName] : true };
              this.planesSeleccionados[name] = {...this.planesSeleccionados[name], ...ob2};

              this.coverages[name].forEach((value, ind) => {
                if (as) {
                  const indPlan = _.findIndex(as, { planName : plan.planName });
                  if (indPlan >= 0) {
                    const ind2 = _.findIndex(as[indPlan].listCoverages, { coverageName : value.coverageName });
                    if (ind2 >= 0) {
                      const indCovOptions = _.findIndex(value.optionsValues, { value: as[indPlan].listCoverages[ind2].value })
                      this.coveragesSeleccionados[name][plan.planName][ind] = indCovOptions < 0 ? 'OTRO' : as[indPlan].listCoverages[ind2].value;
                      this.coveragesOtrosSeleccionados[name][plan.planName][ind] = as[indPlan].listCoverages[ind2].value;
                    } else {
                      value.optionsValues.forEach(val => {
                        if (val.defaultValue) {
                          this.coveragesSeleccionados[name][plan.planName][ind] = val.value;
                          this.coveragesOtrosSeleccionados[name][plan.planName][ind] = val.value;
                        }
                      });
                    }
                  } else {
                    value.optionsValues.forEach(val => {
                      if (val.defaultValue) {
                        this.coveragesSeleccionados[name][plan.planName][ind] = val.value;
                        this.coveragesOtrosSeleccionados[name][plan.planName][ind] = val.value;
                      }
                    });
                  }
                } else {
                  value.optionsValues.forEach(val => {
                    if (val.defaultValue) {
                      this.coveragesSeleccionados[name][plan.planName][ind] = val.value;
                      this.coveragesOtrosSeleccionados[name][plan.planName][ind] = val.value;
                    }
                  });
                }
              });

              if (this.planesSeleccionados[name][plan.planName]) {
                this.getPlanRates(plan);
              }

            }
          }

        });

        this.loadPlanes[name] = true;
      },
      error => {
        console.log(error);
      });
    } else {
    }
  }

  getPlan(name: string): any {
    let res = {};

    this.planes.forEach(plan => {
      if (plan.planName === name) {
        res = plan;
      }
    });

    return res;
  }

  getPlanFormPlan(name: string): any {
    let res = {};

    this.plan.listCategoriesPlan.forEach(plan => {
      if (plan.planName === name) {
        res = plan;
      }
    });

    return res;
  }

  configurar(params: string): void {
    this.api.updateRatesConfigurationP2(params).subscribe(response => {
      this.toastr.success('Tarifa actualizada correctamente');
    },
    error => {
      console.log(error);
    });
  }

}
