import {Component, OnInit, EventEmitter, Input, Output, ChangeDetectorRef} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService } from '../../api.service';
import { ToastrService } from 'ngx-toastr';
import {number, string} from '@amcharts/amcharts4/core';

@Component({
  selector: 'app-flex-type-poblation',
  templateUrl: './flex-type-poblation.component.html',
  styleUrls: ['./flex-type-poblation.component.css']
})
export class FlexTypePoblationComponent implements OnInit {

  constructor(private api: ApiService,
              private toastr: ToastrService,
              private cdRef: ChangeDetectorRef) {

  }

  get subranchId(): number {
    return this._subranchId;
  }
  @Input()
  set subranchId(value: number) {
    this._subranchId = value;
  }

  get planInitEdit(): any {
    return this._planInitEdit;
  }

  @Input()
  set planInitEdit(value: any) {
    this._planInitEdit = value;
    this.updateSelectedDependentOption();
  }

  private _subranchId: number;

  @Input() hiddenTipoPlan: boolean = false;
  @Input() hiddenReduccion: boolean = false;

  typePopulationPolicyId: number;
  typePlanProductId: number;
  planReduction = false;
  planInit: any = {};
  @Output() planInitEmmiter: EventEmitter<object> = new EventEmitter<object>();

  addSpouse = false;
  addChildren = false;
  addMother = false;
  addFather = false;
  numberOfChildren = 0;
  typePopulationOptions: any = [];
  planProductOptions: any = [];
  private _planInitEdit: any = {
    typePopulationPolicyId: 0,
    typePlanProductId: 0,
    planReduction: false,
    addSpouse: false,
    addChildren: false,
    addMother: false,
    addFather: false,
    numberOfChildren: 0,
  };

  selectedDependentOption: string;

  ngOnInit(): void {
    this.loadTypePopulationOptions();
    this.loadPlanProductOptions();
  }

  loadTypePopulationOptions(): void {
    this.api.getPopulationType().then(
      (response: any) => {
        this.typePopulationOptions = response;
      },
      error => {
        this.toastr.error('Ocurrió un problema al cargar el catálogo de Roles', 'Notificación');
      }
    );
  }

  loadPlanProductOptions(): void {
    this.api.getMobilityPlans().then(
      (response: any) => {
        this.planProductOptions = response;
      },
      error => {
        this.toastr.error('Ocurrió un problema al cargar el catálogo de Roles', 'Notificación');
      }
    );
  }

  onTypePlanProductIdChange(value: number): void {
    this._planInitEdit.typePlanProductId = value;
    this.onSubmit();
  }

  onTypePopulationChange(value: number): void {
    this._planInitEdit.typePopulationPolicyId = value;
    this.resetOptions();

    if (value === 2 || value === 3) {
      this._planInitEdit.addChildren = true;
      this._planInitEdit.numberOfChildren = 1;
    }

    switch (value) {
      case 1:
        this._planInitEdit.addSpouse = true;
        break;
      case 2:
        this._planInitEdit.addMother = true;
        this._planInitEdit.addFather = true;
        break;
      case 3:
        this._planInitEdit.addSpouse = true;
        this._planInitEdit.addMother = true;
        this._planInitEdit.addFather = true;
        break;
      default:
        break;
    }

    this.onSubmit();
  }

  resetOptions(): void {
    this._planInitEdit.addSpouse = false;
    this._planInitEdit.addChildren = false;
    this._planInitEdit.addMother = false;
    this._planInitEdit.addFather = false;
  }

  onSubmit(): void {
    /*this.planInit = {
      subranchId: this._subranchId,
      typePopulationPolicyId: Number(this.typePopulationPolicyId),
      typePlanProductId: Number(this.typePlanProductId),
      planReduction: this.planReduction,
      addSpouse: this.addSpouse,
      addChildren: this.addChildren,
      addMother: this.addMother,
      addFather: this.addFather,
      numberOfChildren: Number(this.numberOfChildren),
    };*/
    this.planInitEmmiter.emit(this._planInitEdit);
    console.log(this._planInitEdit);
  }

  setDependents(option: string): void {
    this._planInitEdit.addSpouse = false;
    this._planInitEdit.addChildren = false;
    this._planInitEdit.addMother = false;
    this._planInitEdit.addFather = false;

    switch (option) {
      case 'spouseAndChildren':
        this._planInitEdit.addSpouse = true;
        this._planInitEdit.addChildren = true;
        this._planInitEdit.numberOfChildren = 1;
        break;
      case 'children':
        this._planInitEdit.addChildren = true;
        this._planInitEdit.numberOfChildren = 1;
        break;
      case 'spouseChildrenAndParents':
        this._planInitEdit.addSpouse = true;
        this._planInitEdit.addChildren = true;
        this._planInitEdit.addMother = true;
        this._planInitEdit.addFather = true;
        this._planInitEdit.numberOfChildren = 1;
        break;
      case 'parents':
        this._planInitEdit.addMother = true;
        this._planInitEdit.addFather = true;
        this._planInitEdit.numberOfChildren = 0;
        break;
    }

    this.onSubmit();
  }

  private updateSelectedDependentOption(): void {
    if (this._planInitEdit.addSpouse && this._planInitEdit.addChildren && this._planInitEdit.addMother && this._planInitEdit.addFather) {
      this.selectedDependentOption = 'spouseChildrenAndParents';
    } else if (this._planInitEdit.addSpouse && this._planInitEdit.addChildren) {
      this.selectedDependentOption = 'spouseAndChildren';
    } else if (this._planInitEdit.addChildren) {
      this.selectedDependentOption = 'children';
    } else if (this._planInitEdit.addMother && this._planInitEdit.addFather) {
      this.selectedDependentOption = 'parents';
    } else {
      this.selectedDependentOption = '';
    }
  }

}
