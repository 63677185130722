import {Component, OnInit, TemplateRef, ViewChild, ChangeDetectorRef} from '@angular/core';
import {ApiService} from '../api.service';
import {Router, ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {DatePipe} from '@angular/common';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {NgxSpinnerService} from 'ngx-spinner';
import {ValidateAccessService} from "../validate-access.service";
import { NgxDateRangePickerOptions } from 'ngx-daterangepicker';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { ConfirmComponent } from '../dialogs/confirm/confirm.component';

import moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'app-gastos-medicos-menores',
  templateUrl: './gastos-medicos-menores.component.html',
  styleUrls: ['./gastos-medicos-menores.component.css']
})
export class GastosMedicosMenoresComponent implements OnInit {
// variables pantalla consulta siniestros
  planForm: FormGroup; // Formulario

  idPlan: number;
  idProduct: number;

  planes: any;
  aseguradoras: any;
  aseguradorasSeleccionadas: any = [];
  aseguradorasSeleccionadasName: any = [];
  coverages: any = {};
  coveragesSeleccionados: any = {};
  coveragesOtrosSeleccionados: any = {};
  poblation: any = {};
  planesSeleccionados: any = {};
  loadPlanes: any = {};
  plan: any;

  previousValueSelected: any;

  constructor(private api: ApiService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private toastr: ToastrService,
              private shared: SharedService,
              private datePipe: DatePipe,
              public fb: FormBuilder,
              private modalService: BsModalService,
              private spinner: NgxSpinnerService,
              private formBuilder: FormBuilder,
              private cdr: ChangeDetectorRef,
              private validateAccess: ValidateAccessService) {
  }
  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.idPlan = params.idPlan;
      this.idProduct = +params.idProduct;

      this.getPlanInfo();
    });

    this.createForm();
  }
  /* -----------------------------------------------------------------------------------------------------
  ----------------------------- F U N C I O N E S G E N E R A L E S ---------------------------------------
  ----------------------------------------------------------------------------------------------------- */
  goToBack() {
    this.shared.gSearchId = 0;
    this.router.navigate(['/admin/executive']);
  }

  getPlanInfo(): void {
    const params = {
      idProduct: +this.idProduct,
      flexPlanId: +this.idPlan
    };

    this.api.getInfoConfigurationP1(params).subscribe(response => {
      this.plan = response;
      this.getInsurancePlanByIdProduct();
    },
    error => {
      console.log(error);
    });
  }

  getInsurancePlanByIdProduct(): void {
    this.api.getInsurancePlanByIdProduct({ idProduct: this.idProduct }).subscribe(response => {
      this.planes = response;
      this.aseguradoras = _.chain(this.planes).groupBy('insuranceName').value();

      Object.keys(this.aseguradoras).forEach(key => {
        this.aseguradorasSeleccionadas[key] = false;
        this.loadPlanes[key] = false;
      });

      //Hablar con gaby para que regrese el insuranceName desde el mismo servicio
      if (this.plan.listCategoriesPlan) {
        this.plan.listCategoriesPlan.forEach(cat => {
          const plan = this.getPlan(cat.planName);
          cat.insuranceName = plan.insuranceName;
        });
      }

      const aseguradorasFromPlan = _.chain(this.plan.listCategoriesPlan).groupBy('insuranceName').value();
      Object.keys(aseguradorasFromPlan).forEach((key, index) => {
        const as = aseguradorasFromPlan[key];
        this.aseguradorasSeleccionadas[key] = true
        this.getCoveragesByIdProduct(key, index, as);
      });
    },
    error => {
      console.log(error);
    });
  }

  getCoveragesByIdProduct(name: string, index: number, as: any = null): void {
    this.loadPlanes[name] = false;
    this.aseguradorasSeleccionadasName[index] = name;
    if (this.aseguradorasSeleccionadas[name]) {
      const plan = this.aseguradoras[name];
      const params = {
        idPlan: plan[0].idInsurancePlan,
        idProduct: this.idProduct
      };
      this.api.getCoveragesByIdProductOb(params).subscribe(response => {
        this.coverages[name] = response;

        this.aseguradoras[name].forEach(plan => {
          const ob = { [plan.planName] : [] };
          this.coveragesSeleccionados[name] = {...this.coveragesSeleccionados[name], ...ob};
          const obCov = { [plan.planName] : [] };
          this.coveragesOtrosSeleccionados[name] = {...this.coveragesOtrosSeleccionados[name], ...obCov};
          let ob2;
          if (as) {
            const ind = _.findIndex(as, { planName : plan.planName });
            if (ind >= 0) {
              ob2 = { [plan.planName] : true };
            } else {
              ob2 = { [plan.planName] : false };
            }
          } else {
            ob2 = { [plan.planName] : false };
          }
          this.planesSeleccionados[name] = {...this.planesSeleccionados[name], ...ob2};

          this.coverages[name].forEach((value, ind) => {
            if (as) {
              const indPlan = _.findIndex(as, { planName : plan.planName });
              if (indPlan >= 0) {
                const ind2 = _.findIndex(as[indPlan].listCoverages, { coverageName : value.coverageName });
                if (ind2 >= 0) {
                  const indCovOptions = _.findIndex(value.optionsValues, { value: as[indPlan].listCoverages[ind2].value })
                  this.coveragesSeleccionados[name][plan.planName][ind] = indCovOptions < 0 ? 'OTRO' : as[indPlan].listCoverages[ind2].value;
                  this.coveragesOtrosSeleccionados[name][plan.planName][ind] = as[indPlan].listCoverages[ind2].value;
                } else {
                  value.optionsValues.forEach(val => {
                    if (val.defaultValue) {
                      this.coveragesSeleccionados[name][plan.planName][ind] = val.value;
                      this.coveragesOtrosSeleccionados[name][plan.planName][ind] = val.value;
                    }
                  });
                }
              } else {
                value.optionsValues.forEach(val => {
                  if (val.defaultValue) {
                    this.coveragesSeleccionados[name][plan.planName][ind] = val.value;
                    this.coveragesOtrosSeleccionados[name][plan.planName][ind] = val.value;
                  }
                });
              }
            } else {
              value.optionsValues.forEach(val => {
                if (val.defaultValue) {
                  this.coveragesSeleccionados[name][plan.planName][ind] = val.value;
                  this.coveragesOtrosSeleccionados[name][plan.planName][ind] = val.value;
                }
              });
            }
          });

        });

        this.loadPlanes[name] = true;
      },
      error => {
        console.log(error);
      });
    } else {
    }
  }

  createForm(): void {
    this.planForm = this.formBuilder.group({
    });
  }

  getPlanInit(event) {
    this.poblation = event;
  }

  getPlan(name: string): any {
    let res = {};

    this.planes.forEach(plan => {
      if (plan.planName === name) {
        res = plan;
      }
    });

    return res;
  }

  getPlanFormPlan(name: string): any {
    let res = {};

    if (this.plan.listCategoriesPlan) {
      this.plan.listCategoriesPlan.forEach(plan => {
        if (plan.planName === name) {
          res = plan;
        }
      });
    }

    return res;
  }

  deletePlan(plan: any, name: any): void {
    if (this.plan) {
      const res = this.planesSeleccionados[name][plan.planName]
      if (!res) {
        const categorie = this.getPlanFormPlan(plan.planName);
        const params = {
          flexPlanId: categorie.planFlexId,
          flexPlanCategoriesId: categorie.idFlexPlanCategories,
          flexPlanPlanesId: categorie.idFlexPlanPlanes
        };
        this.api.deletePlanConfigurationP1(params).subscribe(response => {
        },
        error => {
          console.log(error);
        });
      }
    }
  }

  savePlan(): void {
    const listCategoriesPlan = [];
    Object.keys(this.planesSeleccionados).forEach(key => {
      Object.keys(this.planesSeleccionados[key]).forEach(key2 => {
        const planSeleccionado = this.planesSeleccionados[key][key2];

        if (planSeleccionado) {
          const plan = this.getPlan(key2);
          const tmp = {
            'idFlexPlanCategories': null,
            'planFlexId': this.idPlan,
            'flexibleProductId': this.idProduct,
            'insurancePlanId': plan.idInsurancePlan,
            'categoryName': null,
            'idFlexPlanPlanes': null,
            'planName': plan.planName,
            'flexiblePlan': null,
            'status': null,
            'listCoverages': []
          };
          this.coverages[key].forEach((cov, indexCov) => {
            const listCoverages = {
              'idFlexPlanCoverages': null,
              'flexPlanPlanesId': null,
              'coverageSurexsId': cov.idCoverage,
              'origen': null,
              'coverageName': cov.coverageName,
              'idCoverageOption': null,
              'value': this.coveragesSeleccionados[key][key2][indexCov] === 'OTRO' ? this.coveragesOtrosSeleccionados[key][key2][indexCov] : this.coveragesSeleccionados[key][key2][indexCov],
              'unitMeasurementId': 9
            };

            tmp.listCoverages.push(listCoverages);
          });
          listCategoriesPlan.push(tmp);
        }
      });
    });
    
    const params = {
        'typePopulationPolicyId': +this.poblation.typePopulationPolicyId,
        'addSpouse': this.poblation.addSpouse,
        'listCategoriesPlan': listCategoriesPlan,
        'addChildren': 0,
        'planFlexId': +this.idPlan,
        'flexPlanMinorsId': null,
        'addMother': this.poblation.addMother,
        'addFather': this.poblation.addFather
    };

    console.log(this.poblation);
    console.log(params);
    this.api.insertUpdateConfigurationP1(params).subscribe(response => {
      this.toastr.success('Configuración guardada correctamente');
    },
    error => {
      console.log(error);
    });
  }

  checkCambioCobertura(plan: any, index: number, name: string, event: any): void {
    const planOb = this.getPlanFormPlan(plan.value.planName);
    const cov = this.coverages[name][index];
    if (planOb.status === 2) {
      const modalRef = this.modalService.show(ConfirmComponent, { });
      modalRef.content.textConfirm = 'El plan ya tiene tarifas agregadas, si se modifica se eliminaran estas tarifas, estas de acuerdo?';

      modalRef.content.onClose.subscribe(data => {
          modalRef.hide();
          if (data.res) {
            planOb.status = 1;
            this.coveragesSeleccionados[name][plan.value.planName][index] = event;
            this.coveragesOtrosSeleccionados[name][plan.value.planName][index] = '';
          } else {
            const ind2 = _.findIndex(planOb.listCoverages, { coverageName : cov.coverageName });
            this.coveragesSeleccionados[name][plan.value.planName][index] = planOb.listCoverages[ind2].value; 
            this.coveragesOtrosSeleccionados[name][plan.value.planName][index] = ''; 
          }
      });
    } else {
      this.coveragesSeleccionados[name][plan.value.planName][index] = event;
      this.coveragesOtrosSeleccionados[name][plan.value.planName][index] = '';
    }
  }

  getSlip(): void {
    const params = {
      flexPlanId: +this.idPlan
    };
    this.api.getSlipMenores(params).subscribe((response: any) => {
      const binaryImg = atob(response.slip);
      const length = binaryImg.length;
      const arrayBuffer = new ArrayBuffer(length);
      const uintArray = new Uint8Array(arrayBuffer);

      for (let i = 0; i < length; i++) {
          uintArray[i] = binaryImg.charCodeAt(i);
      }
      const blob = new Blob([uintArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const fileURL = window.URL.createObjectURL(blob);
      window.open(fileURL);
    },
    error => {
      console.log(error);
    });
  }

}
