import {Component, OnInit, TemplateRef} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ApiService} from '../api.service';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {ActivatedRoute, Router} from '@angular/router';
import {RealTimeAPI} from "rocket.chat.realtime.api.rxjs";
import {Dates} from "../plans-carga-poblacion/dates.model";

@Component({
  selector: 'app-flex-life-quote-view',
  templateUrl: './flex-life-quote-view.component.html',
  styleUrls: ['./flex-life-quote-view.component.css']
})
export class FlexLifeQuoteViewComponent implements OnInit {
  typeOptions: any = [];
  typeOptionsQuotes: any = [];
  typeOptionsGF: any = [];
  typeOptionsGFPremium: any = [];
  dataSendLife: any = {};
  // tslint:disable-next-line:variable-name
  _planFlexId: number;
  planProductOptions: Array<{ key: number, value: string }> = [];
  planUnitOptions: Array<{ key: number, value: string }> = [];
  typeSumInsured: number;
  catalogForm = this.fb.group({
    catalogs: ['groups', [Validators.required]]
  });
  catalogForm2 = this.fb.group({
    catalogs2: ['groups1', [Validators.required]]
  });
  numCategory: number;
  planPlanes: any = [];
  planPlanesFlex: any = [];
  planFlexCoverages: any = [];
  coverageList: any = [];
  modalCoverage: BsModalRef;
  modalRef: BsModalRef;
  key = 'idCoverage';
  display = 'coverageName';
  format = { add: 'Agregar', all: 'Todo', none: 'ninguno' };
  nextP1: number;
  messageProduct: string;
  planAddCar: number;
  quotes: any = {};
  funeralExpenses: number;

  catInsuranceCompany: any = [];
  isPoblation: boolean;
  dates: Dates;
  constructor(private fb: FormBuilder,
              private api: ApiService,
              private toastr: ToastrService,
              private spinner: NgxSpinnerService,
              private modalService: BsModalService,
              private activatedRoute: ActivatedRoute,
              private router: Router) { }

  ngOnInit() {
    this.dates = {
      edadMaximaAnios: 50,
      edadMaximaMeses: 11,
      edadMinimaAnios: 5,
      edadMinimaMeses: 12,
      edadMaximaRenovacionAnios: 50,
      edadMaximaRenovacionMeses: 11,
      edadMinimaRenovacionAnios: 5,
      edadMinimaRenovacionMeses: 12,
    };
    this.isPoblation = false;
    this.typeOptions = [
      { id: true, optionSelect: 'POR CUOTA'},
      { id: false, optionSelect: 'POR PRIMA'}
    ];
    this.typeOptionsQuotes = [
      { id: false, optionSelect: 'QUOTA POR PROMEDIO'},
      { id: true, optionSelect: 'QUOTA POR COBERTURA'}
    ];
    this.typeOptionsGF = [
      { id: true, optionSelect: 'POR QUOTA'},
      { id: false, optionSelect: 'POR PRIMA NETA'}
    ];
    this.typeOptionsGFPremium = [
      { id: true, optionSelect: 'PRIMA NETA POR PERSONA'},
      { id: false, optionSelect: 'POR PRIMA NETA TOTAL'}
    ];
    // this._planFlexId = 5;

    this.activatedRoute.params.subscribe(params => {
      this._planFlexId = +params.idPlan;
      console.log('planId');
      console.log(this._planFlexId);
      this.loadUnitOptions();
      this.invokeFunctionGetConfigurationProduct();
      this.invokeServiceGetInsuranceCompanies();
      this.invokeFunctionLoadPoblation();
    });
  }
  invokeServiceGetInsuranceCompanies() {
    /*this.api.getInsuranceCompanies().then((data: any) => {
      this.catInsuranceCompany = data;
    }, error => {
    });*/
    const params = {
      idProduct: 3
    };
    this.api.getInsuranceList(params).subscribe(response => {
        this.catInsuranceCompany = response;
        console.log(this.catInsuranceCompany);
      },
      error => {
        console.log(error);
      });
  }
  loadUnitOptions() {
    this.api.getUnitMeasurement()
      .then(
        (response: any) => {
          this.planUnitOptions = response;
        }, error => {
          this.toastr.error('Ocurrió un problema, contacte al dministrador.', 'Notificación');
        }
      );
  }
  getUnitOptions(): any[] {
    return this.planUnitOptions;
  }

  invokeFunctionGetConfigurationProduct() {
    this.spinner.show('sp');
    const objRequest = {
      idProduct: 3,
      idPlan: this._planFlexId
    };
    this.api.getConfigurationProductLifeP2(objRequest)
      .then(
        (response: any) => {
          this.dataSendLife = response;
          this.planPlanes = this.dataSendLife.planesCurrentLife;
          this.planPlanesFlex = this.dataSendLife.planesFlexLife;
          console.log(this.dataSendLife);
          console.log('refresca carga del plan para ir por las edades');
          this.spinner.hide('sp');
        }, error => {
          this.toastr.error('Ocurrió un problema, contacte al dministrador.', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }

  invokeFunctionConfigurationQuotes(template: TemplateRef<any>, plan: any, position: number) {
    if (position > 0 && this.planPlanes.length > 0 && this.planPlanes[0].insuranceCompanyId === 0) {
      this.toastr.info('DEBE INGRESAR LAS TARIFAS DEL PLAN 1 POR FAVOR', 'NOTIFICACIÓN');
      return;
    } else if (position > 0 && this.planPlanesFlex[0].insuranceCompanyId === 0) {
      this.toastr.info('DEBE INGRESAR LAS TARIFAS DEL PLAN 1 POR FAVOR', 'NOTIFICACIÓN');
      return;
    }
    this.quotes = plan;
    console.log(this.quotes);
    this.funeralExpenses = 0;
    this.quotes.coverageLifeModelList.forEach(item => {
        if (item.coverageName === 'GASTOS FUNERARIOS') {
            this.funeralExpenses = 1;
        }
    });
    console.log(this.catInsuranceCompany);
    console.log(this.quotes.insuranceCompanyId);
    this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false, ignoreBackdropClick: true, backdrop: false});
  }


  onTypeChange(value: any) {
    if (value) {
      this.quotes.flexPlanPlanesLifeQuotaModel.totalNetPremium = 0;
      this.quotes.flexPlanPlanesLifeQuotaModel.totalSumInsured = 0;
    }
  }
  onTypeChangeQuote(value: any) {
    if (value) {
      this.quotes.flexPlanPlanesLifeQuotaModel.averageQuota = 0;
    }
  }

  onTypeChangeQuoteGF(value: any) {
    if (!value) {
      this.quotes.flexPlanPlanesLifeQuotaModel.funeralExpensesQuotaPremiumTotal = 0;
    }
  }
  onTypeChangeQuoteGFPremium(value: any) {
    if (value) {
      this.quotes.flexPlanPlanesLifeQuotaModel.funeralExpensesPremiumTotal = 0;
    } else {
      this.quotes.flexPlanPlanesLifeQuotaModel.funeralExpensesPremiumIndividual = 0;
    }
  }

  invokeFunctionSaveQuotes() {
    console.log(this.quotes);
    this.spinner.show('sp');
    this.api.saveConfigurationQuoteByPlan(this.quotes)
      .then(
        (response: any) => {
          this.spinner.hide('sp');
          if (response.flexPlanLifeId === 0) {
            this.toastr.error('Ocurrió un problema, contacte al dministrador.', 'Notificación');
          } else {
            this.toastr.success('La quotas fueron guardadas de manera correcta', 'Notificación');
            this.quotes = {};
          }
          this.modalRef.hide();
          this.invokeFunctionGetConfigurationProduct();
        }, error => {
          this.toastr.error('Ocurrió un problema, contacte al dministrador.', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  layoutCargado(e) {
    console.log('respuesta población inicial');
    console.log(e);
    // this.isPoblation = e;
  }
  invokeFunctionBackDetailPlan() {
    this.router.navigate(['/admin/plans-detalle/', this._planFlexId]);
  }
  invokeFunctionLoadPoblation() {
    console.log(this.isPoblation);
    this.isPoblation = false;
    const objRequest = {
      idPlan: this._planFlexId,
      idProduct: 3
    };

    this.api.getLoadTariffQuotesByPlanProduct(objRequest)
      .then(
        (response: any) => {
          if (response.LoadTariffQuotes === 0) {
            this.isPoblation = true;
          } else {
            this.toastr.info('TIENE QUE CONFIGURAR TODAS LAS TARIFAS PARA PODER CARGAR LA POBLACIÓN', 'NOTIFICACIÓN');
            this.isPoblation = false;
          }
        }, error => {
          this.toastr.error('Ocurrió un problema, contacte al dministrador.', 'Notificación');
        }
      );

  }
}
