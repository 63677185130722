import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

import {
    Category,
    FlexVisionModel,
    UpdateCategory,
    UpdateCoverage,
    UpdatePlan
} from './flex-vision-p1-model-component';

@Component({
    selector: 'app-flex-vision-p1-view',
    templateUrl: './flex-vision-p1-view.component.html',
    styleUrls: ['./flex-vision-p1-view.component.css']
})

export class FlexVisionP1ViewComponent implements OnInit {
    idPlanFlex;
    planInit: any = {};
    planInformation: any = {};
    flagStatus2 = false;
    requestBody: FlexVisionModel = {
        addChildren: 0,
        addFather: false,
        addMother: false,
        addSpouse: false,
        flex: false,
        idPlanFlexId: 0,
        idTypePlanProduct: 0,
        idTypePopulation: 0,
        reduction: false
    };
    planFlexForm: FormGroup;
    planPlanes: FormArray;
    planFlexPlanes: FormArray;
    categoriesCount = 0;
    planFlexCount = 0;
    specialCoverageList = [];
    dataSourcesCoverages = [];
    selectedPlanIndex = 0;
    planUnitOptions = [];
    poblationType: any = {}; // Almacena el valor ingresado en el modal
    modalRefAddCoveragePlan: BsModalRef;
    modalRef: BsModalRef;
    key = 'idCoverage';
    display = 'coverageName';
    format = {add: 'AGREGAR', remove: 'QUITAR', all: 'TODO'};
    selectedOption = '';
    submitFlag = false;
    options = [
        {value: 'SI', label: 'SI'},
        {value: 'NO', label: 'NO'},
    ];
    @ViewChild('messageAlert', {static: true}) myTemplate: TemplateRef<any>;

    constructor(private fb: FormBuilder,
                private api: ApiService,
                private activatedRoute: ActivatedRoute,
                private toastr: ToastrService,
                private spinner: NgxSpinnerService,
                private modalService: BsModalService,
                private router: Router) {
        this.planFlexForm = this.fb.group({
            categoriesCount: [0],
            planPlanes: this.fb.array([]),
            planFlexPlanes: this.fb.array([])
        });
        this.activatedRoute.params.subscribe(params => {
            this.idPlanFlex = params.idPlan;
        });
        this.planPlanes = this.planFlexForm.get('planPlanes') as FormArray;
        this.planFlexPlanes = this.planFlexForm.get('planFlexPlanes') as FormArray;
    }

    ngOnInit(): void {
        this.loadUnitOptions();
        this.loadCoveragesByIdProduct();
    }

    orderByOrder(arr) {
        // Recorremos cada objeto del arreglo
        arr.forEach(item => {
            // Verificamos si tiene la propiedad 'optionsValues' y si es un arreglo
            if (Array.isArray(item.optionsValues)) {
                // Ordenamos 'optionsValues' por el campo 'order'
                item.optionsValues.sort((a, b) => a.order - b.order);
            }
        });
        return arr;
    }

    getUnitOptions(): any[] {
        return this.planUnitOptions;
    }

    getUnitById(id: number): string | undefined {
        console.log('id', id);
        console.log('this.planUnitOptions', this.planUnitOptions);
        const unidad = this.planUnitOptions.find(u => String(u.id) === String(id));
        console.log('unidad', unidad);
        return unidad ? unidad.code : undefined;
    }

    onCategoriesCountChange(): void {
        const categoriesCountControl = this.planFlexForm.get('categoriesCount');
        this.categoriesCount = categoriesCountControl ? categoriesCountControl.value : 0;
        this.updatePlanPlanes(this.categoriesCount);
    }

    updatePlanPlanes(count: number) {
        this.planPlanes.clear();
        console.log(this.planFlexForm);
        for (let i = 0; i < count; i++) {
            this.planPlanes.push(this.createPlan(i + 1));
            console.log(this.planPlanes);
        }
        console.log(this.planFlexForm);
    }

    createPlan(idPlan: number): FormGroup {
        return this.fb.group({
            idPlan: [''],
            idPlanFlexId: [''],
            plan_name: ['', [Validators.required, Validators.min(1)]],
            flexible_plan: [false],
            quote: [''],
            premium_individual: [''],
            average_premium: [''],
            net_premium_individual: [''],
            net_premium_group: [''],
            policy_rights: [''],
            surcharges: [''],
            creation_date: [''],
            update_date: [''],
            creation_user_id: [''],
            update_user_id: [''],
            iva: [''],
            iva_frontier: [''],
            configuration_type: [''],
            planCategory: this.fb.group({
                idCategory: [''],
                // category_name: [''],
                category_name: [null, [Validators.required]],
                flexible_product_id: [''],
                creation_date: [''],
                update_date: [''],
                creation_user_id: [''],
                update_user_id: ['']
            }),
            planCoverages: this.fb.array(this.createCoverages(idPlan, this.specialCoverageList))
        });
    }

    createPlanFlex(): any {
        this.planFlexCount += 1;
        this.planFlexPlanes.push(
            this.fb.group({
                idPlan: [this.planFlexCount],
                idPlanFlexId: [''],
                plan_name: ['', [Validators.required, Validators.min(1)]],
                flexible_plan: [true],
                quote: [''],
                premium_individual: [''],
                average_premium: [''],
                net_premium_individual: [''],
                net_premium_group: [''],
                policy_rights: [''],
                surcharges: [''],
                creation_date: [''],
                update_date: [''],
                creation_user_id: [''],
                update_user_id: [''],
                iva: [''],
                iva_frontier: [''],
                configuration_type: [''],
                planCategory: this.fb.group({
                    idCategory: [''],
                    category_name: ['Flex'],
                    flexible_product_id: [''],
                    creation_date: [''],
                    update_date: [''],
                    creation_user_id: [''],
                    update_user_id: ['']
                }),
                planCoverages: this.fb.array(this.createCoverages(this.planFlexCount, this.specialCoverageList))
            })
        );
        console.log(this.planPlanes);
    }

    createCoverages(idPlan: number, specialCoverageList = []): any {
        const coverageList = [];
        if (this.specialCoverageList.length > 0) {
            specialCoverageList.forEach(coverage => {
                let value89 = '';
                if (coverage.idCoverage === 27) {
                    value89 = 'AMPARADO';
                }
                coverageList.push(
                    this.fb.group({
                        id_plan: idPlan,
                        product_id: 6, // Este campo se llenará después de crear el plan_coverage
                        coverage_type: coverage.coverageType,
                        coverage_name: coverage.coverageName,
                        open_value: coverage.openValue,
                        multiple_value: coverage.multipleValue,
                        mandatory: coverage.mandatory,
                        optionsValues: this.fb.array(this.createOptionValues(coverage.optionsValues)),
                        id_coverage: coverage.idCoverage,
                        unit_measurement: this.fb.array(this.createUnitsMeasurement(coverage.unitsMeasurement)),
                        idSurexs: [''],
                        nameCoverage: [''],
                        // value: [''],
                        value: [value89, [Validators.required]],
                        idMeasurementUnit: [2],
                        status: null,
                        otherValue: [''],
                        showOtherField: [''],
                        update_date: null,
                        creation_user_id: null,
                        update_user_id: null
                    })
                );
            });
        }
        console.log('COV-', coverageList);
        return coverageList;
    }

    createOptionValues(optionValues: any): FormGroup[] {
        // Validar que optionValues sea un array
        if (!Array.isArray(optionValues)) {
            throw new Error('El parámetro optionValues debe ser un array.');
        }

        const optionValueList: FormGroup[] = [];

        optionValues.forEach(optionValue => {
            // Validar cada elemento en el array
            if (typeof optionValue !== 'object' || optionValue === null) {
                throw new Error('Cada elemento de optionValues debe ser un objeto.');
            }
            const {unitMeasurement_en, unitMeasurementId, unitMeasurement, value} = optionValue;
            if (typeof unitMeasurement_en !== 'string') {
                throw new Error('La propiedad unitMeasurement_en debe ser una cadena de texto.');
            }
            if (typeof unitMeasurementId !== 'string') {
                throw new Error('La propiedad unitMeasurementId debe ser una cadena de texto.');
            }
            if (typeof unitMeasurement !== 'string') {
                throw new Error('La propiedad unitMeasurement debe ser una cadena de texto.');
            }
            if (typeof value !== 'string') {
                throw new Error('La propiedad value debe ser una cadena de texto.');
            }
            // Si las validaciones pasan, agregar el FormGroup a la lista
            optionValueList.push(
                this.fb.group({
                    unitMeasurement_en: [unitMeasurement_en],
                    unitMeasurementId: [unitMeasurementId],
                    unitMeasurement: [unitMeasurement],
                    value: [value]
                })
            );
        });
        return optionValueList;
    }

    createUnitsMeasurement(unitsMeasurement: any): FormGroup[] {
        // Validar que optionValues sea un array
        if (!Array.isArray(unitsMeasurement)) {
            throw new Error('El parámetro optionValues debe ser un array.');
        }

        const unitsMeasurementList: FormGroup[] = [];

        unitsMeasurement.forEach(unit => {
            // Validar cada elemento en el array
            if (typeof unit !== 'object' || unit === null) {
                throw new Error('Cada elemento de optionValues debe ser un objeto.');
            }
            const {code, code_en, id} = unit;
            if (typeof code !== 'string') {
                throw new Error('La propiedad code debe ser una cadena de texto.');
            }
            if (typeof code_en !== 'string') {
                throw new Error('La propiedad code_en debe ser una cadena de texto.');
            }
            if (typeof id !== 'number' || !Number.isInteger(id)) {
                throw new Error('La propiedad id debe ser un numero.');
            }
            // Si las validaciones pasan, agregar el FormGroup a la lista
            unitsMeasurementList.push(
                this.fb.group({
                    code_en: [code_en],
                    code: [code],
                    id: [id]
                })
            );
        });
        return unitsMeasurementList;
    }

    onCoverageChange(event: any, planIndex: number, index: number): void {
        const planCoverages = this.planPlanes.controls[planIndex].get('planCoverages') as FormArray;
        const coverage = planCoverages.at(index) as FormGroup;  // Usamos at(index) para obtener el control
        console.log('plan-', planCoverages);
        console.log('cov-', coverage);

        // Si la cobertura tiene un valor "Otro", mostramos el campo adicional
        if (coverage.get('id_coverage').value === 31) {
            const selectedValue = event.target.value;
            if (selectedValue === 'OTRO') {
                coverage.get('showOtherField').setValue(true);  // Mostramos el campo "Otro"
            } else {
                coverage.get('showOtherField').setValue(false); // Ocultamos el campo "Otro"
            }
        }
    }

    onCoverageFlexChange(event: any, planIndex: number, index: number): void {
        const planCoverages = this.planFlexPlanes.controls[planIndex].get('planCoverages') as FormArray;
        const coverage = planCoverages.at(index) as FormGroup;  // Usamos at(index) para obtener el control
        console.log('plan-', planCoverages);
        console.log('cov-', coverage);

        // Si la cobertura tiene un valor "Otro", mostramos el campo adicional
        if (coverage.get('id_coverage').value === 31) {
            const selectedValue = event.target.value;
            if (selectedValue === 'OTRO') {
                coverage.get('showOtherField').setValue(true);  // Mostramos el campo "Otro"
            } else {
                coverage.get('showOtherField').setValue(false); // Ocultamos el campo "Otro"
            }
        }
    }

    // INICIO SERVICIOS
    loadCoveragesByIdProduct(): void {
        const data = {
            idProduct: 6,
            idPlan: null
        };
        this.spinner.show('sp');
        this.api.getCoveragesByIdProduct(data)
            .then(
                (response: any) => {
                    this.specialCoverageList = response;
                    console.log('cov-', response);
                    this.getPlanInformation();
                    this.spinner.hide('sp');
                }, error => {
                    this.toastr.error('Ocurrió un problema al cargar los coverages', 'Notificación');
                    this.spinner.hide('sp');
                }
            );
    }

    loadUnitOptions() {
        this.api.getUnitMeasurement()
            .then(
                (response: any) => {
                    this.planUnitOptions = response;
                    console.log('UNIT-', this.planUnitOptions);
                }, error => {
                    this.toastr.error('Ocurrió un problema al cargar el catálogo de Medidas', 'Notificación');
                }
            );
    }

    getPlanInformation(): void {
        const data = {
            idPlanFlex: Number(this.idPlanFlex)
        };
        console.log(this.idPlanFlex);
        this.spinner.show('sp');
        this.api.getPlanVisionInformation(data)
            .then(
                (response: any) => {
                    this.planInformation = response;
                    console.log('INFORMATION-', this.planInformation);
                    this.setPlanInfo(this.planInformation);
                    this.spinner.hide('sp');
                }, error => {
                    this.toastr.show('No se encontro información', 'Notificación');
                    this.spinner.hide('sp');
                }
            );
    }

    deleteQuotesFromPlan(): void {
        const data = {
            idPlan: Number(this.idPlanFlex)
        };
        console.log(this.idPlanFlex);
        this.spinner.show('sp');
        this.api.deleteQuotesFromPlanVision(data)
            .then(
                (response: any) => {
                    this.spinner.hide('sp');
                }, error => {
                    this.toastr.error('No se pudo eliminar las tarifas', 'Notificación');
                    this.spinner.hide('sp');
                }
            );
    }

    // FIN SERVICIOS
    removeCoverage(planIndex: number, coverageIndex: number, planParam: any) {
        // Obtener el FormArray de planCoverages del plan correspondiente
        console.log('event-', planParam);
        const planPlanes = this.planFlexForm.get('planPlanes') as FormArray;
        const plan = planPlanes.at(planIndex) as FormGroup;
        const planCoverages = plan.get('planCoverages') as FormArray;

        // Verificar que el FormArray planCoverages exista antes de eliminar
        if (planCoverages) {
            const arl = planCoverages.at(coverageIndex) as FormGroup;
            console.log(arl.value);
            if (!this.isEmpty(this.planInformation)) {
                const idCategory = planParam.value.planCategory.idCategory;
                const categorySelected = this.planInformation.categories.find(categoryf => categoryf.idCategory === idCategory);
                if (categorySelected !== undefined) {
                    console.log(categorySelected);
                    const planSelected = categorySelected.plans.find(planf => planf.idPlan === planParam.value.idPlan);
                    console.log(planSelected);
                    const coverageSelected = planSelected.coverages.find(covf => covf.idSurexs === arl.value.id_coverage);
                    // if (coverageSelected !== undefined) {
                    // this.deleteCoverageById(coverageSelected.idCoverage);
                    // }
                    planCoverages.removeAt(coverageIndex);
                }
            } else {
                planCoverages.removeAt(coverageIndex);
            }
        } else {
            console.error('FormArray planCoverages no existe');
        }
    }

    removeFlexCoverage(planIndex: number, coverageIndex: number, planParam: any) {
        // Obtener el FormArray de planCoverages del plan correspondiente
        const planPlanes = this.planFlexForm.get('planFlexPlanes') as FormArray;
        const plan = planPlanes.at(planIndex) as FormGroup;
        const planCoverages = plan.get('planCoverages') as FormArray;
        console.log('planCoverages-', planCoverages);
        // Verificar que el FormArray planCoverages exista antes de eliminar
        if (planCoverages) {
            const arl = planCoverages.at(coverageIndex) as FormGroup;
            console.log(arl.value);
            if (!this.isEmpty(this.planInformation)) {
                const idCategory = planParam.value.planCategory.idCategory;
                const categorySelected = this.planInformation.categories.find(categoryf => categoryf.idCategory === idCategory);
                if (categorySelected !== undefined) {
                    console.log(categorySelected);
                    const planSelected = categorySelected.plans.find(planf => planf.idPlan === planParam.value.idPlan);
                    console.log(planSelected);
                    const coverageSelected = planSelected.coverages.find(covf => covf.idSurexs === arl.value.id_coverage);
                    // if (coverageSelected !== undefined) {
                    // this.deleteCoverageById(coverageSelected.idCoverage);
                    // }
                    planCoverages.removeAt(coverageIndex);
                }
            } else {
                planCoverages.removeAt(coverageIndex);
            }
        } else {
            console.error('FormArray planCoverages no existe');
        }
    }

    getPlanInit(event) {
        this.planInit = event;
        console.log('plan init', this.planInit);
        this.requestBody.addChildren = this.planInit.numberOfChildren && this.planInit.numberOfChildren !== '' ? this.planInit.numberOfChildren : 0;
        this.requestBody.addFather = this.planInit.addFather && this.planInit.addFather !== '' ? this.planInit.addFather : false;
        this.requestBody.addMother = this.planInit.addMother && this.planInit.addMother !== '' ? this.planInit.addMother : false;
        this.requestBody.addSpouse = this.planInit.addSpouse && this.planInit.addSpouse !== '' ? this.planInit.addSpouse : false;
        this.requestBody.reduction = this.planInit.planReduction && this.planInit.planReduction !== '' ? this.planInit.planReduction : false;
        this.requestBody.idTypePlanProduct = this.planInit.typePlanProductId;
        this.requestBody.idTypePopulation = this.planInit.typePopulationPolicyId;
        this.toastr.success('SE HA GUARDADO CON ÉXITO', 'NOTIFICACION');
    }

    setPlanInfo(planInformation: any) {
        console.log('setPlanInfo', planInformation.idPlanFlexId);
        if (planInformation.idPlanFlexId !== undefined) {
            console.log(planInformation.categories.length);
            this.selectedOption = 'SI';
            this.submitFlag = true;
            console.log('TEST-', this.planFlexForm.controls.categoriesCount);
            this.categoriesCount = this.planFlexForm.controls.categoriesCount.value;
            this.planFlexForm.controls.categoriesCount.setValue(planInformation.categories.length);
            planInformation.categories.forEach(category => {
                category.plans.forEach(plan => {
                    if (plan.status === 2) {
                        this.flagStatus2 = true;
                    }
                    const coverages = this.createCoverages(plan.idPlan, this.specialCoverageList);
                    if (category.nameCategory.includes('flex') || category.nameCategory.includes('Flex')) {
                        this.planFlexPlanes.push(
                            this.fb.group({
                                    idPlan: [plan.idPlan],
                                    idPlanFlexId: [planInformation.idPlanFlexId],
                                    plan_name: [plan.namePlan, [Validators.required]],
                                    flexible_plan: [plan.flexiblePlan],
                                    quote: [plan.quote],
                                    premium_individual: [plan.premiumIndividual],
                                    average_premium: [plan.averagPremium],
                                    net_premium_individual: [plan.netPremiumIndividual],
                                    policy_rights: [plan.policyRights],
                                    surcharges: [plan.surcharges],
                                    creation_date: [''],
                                    update_date: [''],
                                    creation_user_id: [''],
                                    update_user_id: [''],
                                    iva: [''],
                                    iva_frontier: [''],
                                    configuration_type: [''],
                                    planCategory: this.fb.group({
                                        idCategory: [category.idCategory],
                                        // category_name: [''],
                                        category_name: [category.nameCategory, [Validators.required]],
                                        flexible_product_id: [category.idFlexibleProduct],
                                        creation_date: [''],
                                        update_date: [''],
                                        creation_user_id: [''],
                                        update_user_id: ['']
                                    }),
                                    planCoverages: this.fb.array(coverages)  // FormArray para almacenar las coberturas
                                }
                            )
                        );
                    } else {
                        this.planPlanes.push(
                            this.fb.group({
                                    idPlan: [plan.idPlan],
                                    idPlanFlexId: [planInformation.idPlanFlexId],
                                    plan_name: [plan.namePlan, [Validators.required]],
                                    flexible_plan: [plan.flexiblePlan],
                                    quote: [plan.quote],
                                    premium_individual: [plan.premiumIndividual],
                                    average_premium: [plan.averagPremium],
                                    net_premium_individual: [plan.netPremiumIndividual],
                                    policy_rights: [plan.policyRights],
                                    surcharges: [plan.surcharges],
                                    creation_date: [''],
                                    update_date: [''],
                                    creation_user_id: [''],
                                    update_user_id: [''],
                                    iva: [''],
                                    iva_frontier: [''],
                                    configuration_type: [''],
                                    planCategory: this.fb.group({
                                        idCategory: [category.idCategory],
                                        category_name: [category.nameCategory],
                                        flexible_product_id: [category.idFlexibleProduct],
                                        creation_date: [''],
                                        update_date: [''],
                                        creation_user_id: [''],
                                        update_user_id: ['']
                                    }),
                                    planCoverages: this.fb.array(coverages)  // FormArray para almacenar las coberturas
                                }
                            )
                        );
                    }

                    this.planPlanes.controls.forEach(control => {
                        // @ts-ignore
                        control.get('planCoverages').controls.forEach(coverage => {
                            plan.coverages.forEach(cov => {
                                // @ts-ignore
                                if (coverage.controls.id_coverage.value === cov.idSurexs && control.controls.idPlan.value === plan.idPlan) {
                                    coverage.controls.value.setValue(cov.value);
                                    coverage.controls.idMeasurementUnit.setValue(cov.idMeasurementUnit);
                                }
                                if (coverage.controls.id_coverage.value === 31) {
                                    const filteredCoverage = this.specialCoverageList.filter(item => item.idCoverage === coverage.controls.id_coverage.value);
                                    const firstCoverage = filteredCoverage.length > 0 ? filteredCoverage[0] : null;
                                    if (firstCoverage !== null) {
                                        const containsValue = firstCoverage.optionsValues.filter(option => option.value === coverage.controls.value.value);
                                        const contain = containsValue.length > 0 ? containsValue[0] : null;
                                        if (contain === null) {
                                            coverage.controls.value.setValue('OTRO');
                                            coverage.controls.otherValue.setValue(cov.value);
                                        }
                                    }
                                }
                            });
                        });
                    });

                    this.planFlexPlanes.controls.forEach(control => {
                        // @ts-ignore
                        control.get('planCoverages').controls.forEach(coverage => {
                            plan.coverages.forEach(cov => {
                                // @ts-ignore
                                if (coverage.controls.id_coverage.value === cov.idSurexs && control.controls.idPlan.value === plan.idPlan) {
                                    coverage.controls.value.setValue(cov.value);
                                    coverage.controls.idMeasurementUnit.setValue(cov.idMeasurementUnit);
                                }
                                if (coverage.controls.id_coverage.value === 31) {
                                    const filteredCoverage = this.specialCoverageList.filter(item => item.idCoverage === coverage.controls.id_coverage.value);
                                    const firstCoverage = filteredCoverage.length > 0 ? filteredCoverage[0] : null;
                                    if (firstCoverage !== null) {
                                        const containsValue = firstCoverage.optionsValues.filter(option => option.value === coverage.controls.value.value);
                                        const contain = containsValue.length > 0 ? containsValue[0] : null;
                                        if (contain === null) {
                                            coverage.controls.value.setValue('OTRO');
                                            coverage.controls.otherValue.setValue(cov.value);
                                        }
                                    }
                                }
                            });
                        });
                    });
                });
            });
            this.poblationType = {
                typePopulationPolicyId: this.planInformation.idTypePopulation,
                typePlanProductId: this.planInformation.idTypePlanProduct,
                planReduction: this.planInformation.reduction,
                addSpouse: this.planInformation.addSpouse,
                addChildren: this.planInformation.addChildren,
                addMother: this.planInformation.addMother,
                addFather: this.planInformation.addFather
            };
            this.planInit.numberOfChildren = this.planInformation.addChildren;
            this.planInit.addFather = this.planInformation.addFather;
            this.planInit.addMother = this.planInformation.addMother;
            this.planInit.addSpouse = this.planInformation.addSpouse;
            this.planInit.planReduction = this.planInformation.reduction;
            this.planInit.typePlanProductId = this.planInformation.idTypePlanProduct;
            this.planInit.typePopulationPolicyId = this.planInformation.idTypePopulation;
        }
        if (this.flagStatus2 === true) {
            this.modalRef = this.modalService.show(this.myTemplate, {
                class: 'modal-md',
                keyboard: false,
                backdrop: 'static'
            });
        }
    }

    onSubmit(): void {
        const dto = this.planFlexForm.value;
        console.log(dto);
        if (this.planInformation.idPlanFlexId !== undefined) {
            this.updatePlanInformation();
        } else {
            this.savePlanInformation();
        }
        // console.log(this.planPlanes.controls[0].get('planCoverages').controls[2].get('optionsValues').controls);
        // console.log(this.planPlanes.controls[0].get('categories').controls[0].get('plans').controls[0].get('coverages').value);
    }

    validatePopulationType() {
        // Verificamos que 'this.planInit' no sea undefined o null
        if (!this.planInit) {
            console.log('Error: planInit es undefined o null');
            return false;
        }

        const fields = [
            'typePlanProductId',
            'typePopulationPolicyId'
        ];
        // Validamos cada propiedad
        for (const field of fields) {
            if (this.planInit[field] === undefined || this.planInit[field] === null) {
                console.log(`Error: ${field} es undefined o null`);
                this.toastr.error(`Falta llenar los campos de tipo de población.`);
                return false;  // Si encontramos un valor no válido, retornamos false
            }
        }
        // Si todas las propiedades son válidas
        console.log('Todos los campos son válidos');
        return true;
    }

    getPlanAtIndex(planIndex: number): FormGroup {
        const planPlanes = this.planFlexForm.get('planPlanes') as FormArray;
        return planPlanes.at(planIndex) as FormGroup;
    }

    getFlexPlanAtIndex(planIndex: number): FormGroup {
        const planPlanes = this.planFlexForm.get('planFlexPlanes') as FormArray;
        return planPlanes.at(planIndex) as FormGroup;
    }

    openModalCoverage(templateCoverage: TemplateRef<any>, planIndex: number) {
        this.dataSourcesCoverages = [];
        this.selectedPlanIndex = planIndex; // Guardamos el índice del plan seleccionado

        // Obtenemos las coberturas actuales del plan y sus IDs
        const plan = this.getPlanAtIndex(planIndex);
        const planCoverages = plan.get('planCoverages') as FormArray;
        const planCoverageIds = planCoverages.value.map((coverage: any) => coverage.id_coverage); // IDs de coberturas actuales
        // Cargar las coberturas seleccionadas basadas en las que ya están en el plan
        this.dataSourcesCoverages = this.specialCoverageList.filter(coverage => planCoverageIds.includes(coverage.idCoverage));

        // tslint:disable-next-line:max-line-length
        this.modalRefAddCoveragePlan = this.modalService.show(templateCoverage,
            {class: 'modal-lg', keyboard: false, ignoreBackdropClick: true, backdrop: false});
    }

    openFlexModalCoverage(templateCoverage: TemplateRef<any>, planIndex: number) {
        this.dataSourcesCoverages = [];
        this.selectedPlanIndex = planIndex; // Guardamos el índice del plan seleccionado

        // Obtenemos las coberturas actuales del plan y sus IDs
        const plan = this.getFlexPlanAtIndex(planIndex);
        const planCoverages = plan.get('planCoverages') as FormArray;
        const planCoverageIds = planCoverages.value.map((coverage: any) => coverage.id_coverage); // IDs de coberturas actuales
        // Cargar las coberturas seleccionadas basadas en las que ya están en el plan
        this.dataSourcesCoverages = this.specialCoverageList.filter(coverage => planCoverageIds.includes(coverage.idCoverage));

        // tslint:disable-next-line:max-line-length
        this.modalRefAddCoveragePlan = this.modalService.show(templateCoverage,
            {class: 'modal-lg', keyboard: false, ignoreBackdropClick: true, backdrop: false});
    }

    closeModalCoverage() {
        this.modalRefAddCoveragePlan.hide();
    }

    closeModalAlert() {
        this.planInformation.categories.forEach(category => {
            category.plans.forEach(plan => {
                plan.policyRights = 0;
                plan.netPremiumIndividual = 0;
                plan.surcharges = 0;
                plan.status = 1;
            });
        });
        this.deleteQuotesFromPlan();
        this.modalRef.hide();
    }

    onCoverageByPlanChange(event: any) {
        const plan = this.getPlanAtIndex(this.selectedPlanIndex);
        const planCoverages = plan.get('planCoverages') as FormArray;

        // Iteramos sobre las coberturas seleccionadas en el dual-list
        event.forEach((coverage: any) => {
            // Verificar si la cobertura ya existe en planCoverages
            // console.log(coverage);
            // console.log(planCoverages.controls[0].controls);
            const existingCoverage = planCoverages.controls.some((ctrl: any) => ctrl.get('id_coverage').value === coverage.idCoverage);
            console.log(existingCoverage);
            // Si no existe, la agregamos
            if (!existingCoverage) {
                planCoverages.push(
                    this.fb.group({
                        id_plan: plan.get('id_plan'),
                        product_id: 6, // Este campo se llenará después de crear el plan_coverage
                        coverage_type: coverage.coverageType,
                        coverage_name: coverage.coverageName,
                        open_value: coverage.openValue,
                        multiple_value: coverage.multipleValue,
                        mandatory: coverage.mandatory,
                        optionsValues: this.fb.array(this.createOptionValues(coverage.optionsValues)),
                        id_coverage: coverage.idCoverage,
                        unit_measurement: this.fb.array(this.createUnitsMeasurement(coverage.unitsMeasurement)),
                        idSurexs: [''],
                        nameCoverage: [''],
                        value: [''],
                        idMeasurementUnit: ['2'],
                        status: null,
                        update_date: null,
                        creation_user_id: null,
                        update_user_id: null
                    })
                );
            }
        });

        // Actualizamos el array `dataSourcesCoverages` para reflejar las coberturas seleccionadas
        this.dataSourcesCoverages = event;
    }

    onFlexCoverageByPlanChange(event: any) {
        const plan = this.getFlexPlanAtIndex(this.selectedPlanIndex);
        const planCoverages = plan.get('planCoverages') as FormArray;

        // Iteramos sobre las coberturas seleccionadas en el dual-list
        event.forEach((coverage: any) => {
            // Verificar si la cobertura ya existe en planCoverages
            // console.log(coverage);
            // console.log(planCoverages.controls[0].controls);
            const existingCoverage = planCoverages.controls.some((ctrl: any) => ctrl.get('id_coverage').value === coverage.idCoverage);
            console.log(existingCoverage);
            // Si no existe, la agregamos
            if (!existingCoverage) {
                planCoverages.push(
                    this.fb.group({
                        id_plan: plan.get('id_plan'),
                        product_id: 6, // Este campo se llenará después de crear el plan_coverage
                        coverage_type: coverage.coverageType,
                        coverage_name: coverage.coverageName,
                        open_value: coverage.openValue,
                        multiple_value: coverage.multipleValue,
                        mandatory: coverage.mandatory,
                        optionsValues: this.fb.array(this.createOptionValues(coverage.optionsValues)),
                        id_coverage: coverage.idCoverage,
                        unit_measurement: this.fb.array(this.createUnitsMeasurement(coverage.unitsMeasurement)),
                        idSurexs: [''],
                        nameCoverage: [''],
                        value: [''],
                        idMeasurementUnit: ['2'],
                        status: null,
                        update_date: null,
                        creation_user_id: null,
                        update_user_id: null
                    })
                );
            }
        });

        // Actualizamos el array `dataSourcesCoverages` para reflejar las coberturas seleccionadas
        this.dataSourcesCoverages = event;
    }

    removePlan(idCategory: any, planIndex: number): void {
        console.log(planIndex);
        const planPlanes = this.planFlexForm.get('planPlanes') as FormArray;
        // if (this.planInformation.idPlanVision) {
        // this.deleteCategoryByIdVision(idCategory);
        // }
        if (planPlanes && planPlanes.length > planIndex) {
            planPlanes.removeAt(planIndex);
        }
        this.planFlexForm.get('categoriesCount').setValue(this.planFlexForm.get('categoriesCount').value - 1);
    }

    removeFlexPlan(idCategory: any, planIndex: number): void {
        console.log(planIndex);
        const planPlanes = this.planFlexForm.get('planFlexPlanes') as FormArray;
        // if (this.planInformation.idPlanVision) {
        // this.deleteCategoryByIdVision(idCategory);
        // }
        if (planPlanes && planPlanes.length > planIndex) {
            planPlanes.removeAt(planIndex);
        }
    }

    savePlanInformation(): void {
        const dto = this.planFlexForm.value;
        const categories: Category[] = [];
        const flexCategories: Category[] = [];
        this.requestBody.idPlanFlexId = Number(this.idPlanFlex);
        this.requestBody.flex = true;
        console.log(this.planPlanes.value);
        if (this.validatePopulationType()) {
            dto.planPlanes.forEach(plan => {
                categories.push(
                    {
                        nameCategory: plan.planCategory.category_name,
                        plans: this.createNewPlan(plan)
                    });
                console.log('categories ', categories);
                this.requestBody.categories = categories;
                this.requestBody.idTypePopulation = Number(this.requestBody.idTypePopulation);
            });
            //// flex flow
            console.log(this.planFlexPlanes.value);
            dto.planFlexPlanes.forEach(plan => {
                flexCategories.push(
                    {
                        nameCategory: plan.planCategory.category_name,
                        plans: this.createNewPlan(plan)
                    });
                console.log('categories ', flexCategories);
                this.requestBody.categories = categories.concat(flexCategories);
                this.requestBody.idTypePopulation = Number(this.requestBody.idTypePopulation);
            });
            console.log('REQ-', this.requestBody);
            this.api.saveVisionPlanInformation(this.requestBody).subscribe((response: any) => {
                    console.log(response);
                    this.toastr.success('SE HA GUARDADO CON ÉXITO', 'NOTIFICACION');
                    this.getSlip();
                },
                error => {
                    console.log(error);
                    this.toastr.error('HUBO UN ERROR AL GUARDAR INTENTELO MAS TARDE');
                });
        }
    }

    updatePlanInformation(): void {
        const dto = this.planFlexForm.value;
        console.log('dto-', dto);
        console.log('planInformation-', this.planInformation);
        console.log('planInformation L-', this.planInformation.categories.length);
        let requestModel = {};
        const categories: UpdateCategory[] = [];
        const flexCategories: UpdateCategory[] = [];
        let plans: UpdatePlan[] = [];
        let coverages: UpdateCoverage[] = [];
        let flexPlans: UpdatePlan[] = [];
        let flexCoverages: UpdateCoverage[] = [];
        dto.planPlanes.forEach(plan => {
            console.log(plan);
            if (this.planInformation.categories.length > 0) {
                console.log('defined');
                const categorySelected = this.planInformation.categories.find(categoryf => categoryf.idCategory === plan.planCategory.idCategory);
                if (categorySelected !== undefined) {
                    const planSelected = categorySelected.plans.find(planf => planf.idPlan === plan.idPlan);
                    if (planSelected !== undefined) {
                        console.log(planSelected);
                        plans = [];
                        coverages = [];
                        plan.planCoverages.forEach(coverage => {
                            console.log('COV', coverage);
                            const coverageSelected = planSelected.coverages.find(covf => covf.idSurexs === coverage.id_coverage);
                            // console.log('SEL',coverageSelected);
                            let valueIdMeasurementUnit = 0;
                            if (coverage.idCoverage === 27 || coverage.idCoverage === 30) {
                              valueIdMeasurementUnit = 9;
                            } else {
                              valueIdMeasurementUnit = Number(coverage.idMeasurementUnit);
                            }
                            if (coverageSelected !== undefined) {
                                coverages.push({
                                    flexible: coverageSelected.flexible,
                                    measurementDescriptionEn: '', // coverage.optionsValues[0].unitMeasurement_en,
                                    endoso: coverageSelected.endoso,
                                    nameCoverage: coverageSelected.nameCoverage,
                                    factorQuote: coverageSelected.factorQuote,
                                    idSurexs: coverageSelected.idSurexs,
                                    measurementDescription: '', // coverage.optionsValues[0].unitMeasurement,
                                    origen: coverageSelected.origen,
                                    idMeasurementUnit: coverage.value === 'OTRO' ? 2 : valueIdMeasurementUnit,
                                    value: coverage.value === 'OTRO' ? String(coverage.otherValue) : String(coverage.value),
                                    idCoverage: coverageSelected.idCoverage
                                });
                                // console.log('PUSH', coverages);
                            } else {
                                coverages.push({
                                  idSurexs: coverage.id_coverage,
                                  nameCoverage: coverage.coverage_name,
                                  value: coverage.value === 'OTRO' ? String(coverage.otherValue) : String(coverage.value),
                                  idMeasurementUnit: coverage.value === 'OTRO' ? 2 : valueIdMeasurementUnit
                                });
                            }
                        });

                        plans.push({
                            namePlan: plan.plan_name,
                            coverages,
                            idCategory: plan.planCategory.idCategory,
                            idPlan: plan.idPlan,
                            premiumIndividual: plan.premium_individual,
                            policyRights: plan.policy_rights,
                            quote: plan.quote,
                            averagPremium: plan.average_premium,
                            netPremiumIndividual: plan.net_premium_individual,
                            surcharges: plan.surcharges,
                            flexiblePlan: plan.flexible_plan,
                            status: planSelected.status
                        });

                        categories.push({
                            idPlanFlex: plan.idPlanFlexId,
                            plans,
                            nameCategory: plan.planCategory.category_name,
                            idFlexibleProduct: plan.planCategory.flexible_product_id,
                            idCategory: plan.planCategory.idCategory
                        });
                    } else {
                        categories.push({
                            plans: this.createNewPlan(plan),
                            nameCategory: plan.planCategory.category_name
                        });
                    }
                } else {
                    categories.push({
                        plans: this.createNewPlan(plan),
                        nameCategory: plan.planCategory.category_name
                    });
                }
            } else {
                categories.push({
                    plans: this.createNewPlan(plan),
                    nameCategory: plan.planCategory.category_name
                });
            }
        });
        // flex flow
        dto.planFlexPlanes.forEach(plan => {
            console.log(plan);
            const categorySelected = this.planInformation.categories.find(categoryf => categoryf.idCategory === plan.planCategory.idCategory);
            if (categorySelected !== undefined) {
                const planSelected = categorySelected.plans.find(planf => planf.idPlan === plan.idPlan);
                if (planSelected !== undefined) {
                    console.log(planSelected);
                    flexPlans = [];
                    flexCoverages = [];
                    plan.planCoverages.forEach(coverage => {
                        // console.log('COV',coverage);
                        const coverageSelected = planSelected.coverages.find(covf => covf.idSurexs === coverage.id_coverage);
                        let valueIdMeasurementUnit = 0;
                        if (coverage.idCoverage === 27 || coverage.idCoverage === 30) {
                          valueIdMeasurementUnit = 9;
                        } else {
                          valueIdMeasurementUnit = Number(coverage.idMeasurementUnit);
                        }
                        // console.log('SEL',coverageSelected);
                        if (coverageSelected !== undefined) {
                            flexCoverages.push({
                                flexible: coverageSelected.flexible,
                                measurementDescriptionEn: '', // coverage.optionsValues[0].unitMeasurement_en,
                                endoso: coverageSelected.endoso,
                                nameCoverage: coverageSelected.nameCoverage,
                                factorQuote: coverageSelected.factorQuote,
                                idSurexs: coverageSelected.idSurexs,
                                measurementDescription: '', // coverage.optionsValues[0].unitMeasurement,
                                origen: coverageSelected.origen,
                                idMeasurementUnit: coverage.value === 'OTRO' ? 2 : valueIdMeasurementUnit,
                                value: coverage.value === 'OTRO' ? String(coverage.otherValue) : String(coverage.value),
                                idCoverage: coverageSelected.idCoverage
                            });
                            // console.log('PUSH', coverages);
                        } else {
                            flexCoverages.push({
                              idSurexs: coverage.id_coverage,
                              nameCoverage: coverage.coverage_name,
                              value: coverage.value === 'OTRO' ? String(coverage.otherValue) : String(coverage.value),
                              idMeasurementUnit: coverage.value === 'OTRO' ? 2 : valueIdMeasurementUnit,
                            });
                        }
                    });

                    flexPlans.push({
                        namePlan: plan.plan_name,
                        coverages: flexCoverages,
                        idCategory: plan.planCategory.idCategory,
                        idPlan: plan.idPlan,
                        premiumIndividual: plan.premium_individual,
                        policyRights: plan.policy_rights,
                        quote: plan.quote,
                        averagPremium: plan.average_premium,
                        netPremiumIndividual: plan.net_premium_individual,
                        surcharges: plan.surcharges,
                        flexiblePlan: plan.flexible_plan,
                        status: planSelected.status
                    });

                    flexCategories.push({
                        idPlanFlex: plan.idPlanFlexId,
                        plans: flexPlans,
                        nameCategory: plan.planCategory.category_name,
                        idFlexibleProduct: plan.planCategory.flexible_product_id,
                        idCategory: plan.planCategory.idCategory
                    });
                } else {
                    flexCategories.push({
                        nameCategory: plan.planCategory.category_name,
                        plans: this.createNewPlan(plan)
                    });
                }
            } else {
                flexCategories.push({
                    nameCategory: plan.planCategory.category_name,
                    plans: this.createNewPlan(plan)
                });
            }
        });
        requestModel = {
            addChildren: this.planInit.numberOfChildren,
            addFather: this.planInit.addFather,
            addMother: this.planInit.addMother,
            addSpouse: this.planInit.addSpouse,
            categories: categories.concat(flexCategories),
            configuration: this.planInformation.configuration,
            flex: this.planInformation.flex,
            idPlanVision: this.planInformation.idPlanVision,
            idPlanFlexId: this.planInformation.idPlanFlexId,
            idTypePlanProduct: this.planInit.typePlanProductId,
            idTypePopulation: this.planInit.typePopulationPolicyId,
            reduction: this.planInit.planReduction
        };

        console.log('REQ-', requestModel);
        console.log(this.planPlanes.value);
        // validar si viene vacio
        this.api.updateVisionPlanInformation(requestModel).subscribe((response: any) => {
                console.log(response);
                this.toastr.success('SE HA GUARDADO CON ÉXITO', 'NOTIFICACION');
            },
            error => {
                console.log(error);
                this.toastr.error('HUBO UN ERROR INTENTELO MAS TARDE');
            });
    }

    getSlip(): void {
        this.spinner.show('sp');
        console.log(this.idPlanFlex);
        this.api.getSlipVision(this.idPlanFlex)
            .then(
                (response: any) => {
                    console.log(response);
                    const base64Data = response.slip;
                    console.log('--', base64Data);
                    if (base64Data !== undefined) {
                        const binaryData = atob(base64Data);  // Decodificar Base64 a binario
                        const arrayBuffer = new ArrayBuffer(binaryData.length);
                        const uintArray = new Uint8Array(arrayBuffer);

                        for (let i = 0; i < binaryData.length; i++) {
                            uintArray[i] = binaryData.charCodeAt(i);
                        }

                        const blob = new Blob([uintArray], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                        const downloadLink = document.createElement('a');
                        downloadLink.href = window.URL.createObjectURL(blob);
                        downloadLink.setAttribute('download', 'SurexsVisionSlip.xlsx');
                        document.body.appendChild(downloadLink);
                        downloadLink.click();
                        downloadLink.remove();
                        this.invokeFunctionBackDetailPlan();
                        this.spinner.hide('sp');
                    } else {
                        this.spinner.hide('sp');
                        alert('la propiedad slip no esta definida en la respuesta.');
                    }
                }, (error) => {
                    this.spinner.hide('sp');
                });
    }

    deleteCategoryByIdVision(idCategory: number): void {
        const data = {
            idCategory
        };
        this.spinner.show('sp');
        this.api.deleteCategoryByIdVision(data)
            .then(
                (response: any) => {
                    this.toastr.success('SE ELIMINO CON ÉXITO', 'NOTIFICACION');
                    this.spinner.hide('sp');
                }, error => {
                    this.toastr.error('Ocurrió un problema', 'Notificación');
                    this.spinner.hide('sp');
                }
            );
    }

    addCoverage(plan: any, coverage: any): void {
        const data = {
            idPlan: plan.idPlan,
            flexiblePlan: plan.flexible_plan,
            coverages: coverage
        };
        this.spinner.show('sp');
        console.log('++>*', data);
        this.api.addCoverageToPlanVision(data)
            .then(
                (response: any) => {
                    console.log(response);
                    // this.toastr.success('SE ELIMINO CON ÉXITO', 'NOTIFICACION');
                    this.spinner.hide('sp');
                }, error => {
                    this.toastr.error(error, 'Notificación');
                    this.spinner.hide('sp');
                }
            );
    }

    deleteCoverageById(idCoverage: any): void {
        const data = {
            idCoverage
        };
        this.spinner.show('sp');
        console.log('-->*', data);
        this.api.deleteCoverageByIdVision(data)
            .then(
                (response: any) => {
                    console.log(response);
                    // this.toastr.success('SE ELIMINO CON ÉXITO', 'NOTIFICACION');
                    this.spinner.hide('sp');
                }, error => {
                    this.toastr.error('No se pudo eliminar', 'Notificación');
                    this.spinner.hide('sp');
                }
            );
    }

    isEmpty(obj: object): boolean {
        return Object.keys(obj).length === 0;
    }

    invokeFunctionBackDetailPlan() {
        this.router.navigate(['/admin/plans-detalle/', this.idPlanFlex]);
    }

    createNewPlan(plan: any): any {
        const plans = [];
        const coverages = [];

        plan.planCoverages.forEach(coverage => {
            console.log('COV', coverage);

            let valueIdMeasurementUnit = 0;
            if (coverage.idCoverage === 27 || coverage.idCoverage === 30) {
              valueIdMeasurementUnit = 9;
            } else {
              valueIdMeasurementUnit = Number(coverage.idMeasurementUnit);
            }

            coverages.push({
              nameCoverage: coverage.coverage_name,
              idSurexs: coverage.id_coverage,
              idMeasurementUnit: coverage.value === 'OTRO' ? 2 : valueIdMeasurementUnit,
              value: coverage.value === 'OTRO' ? String(coverage.otherValue) : String(coverage.value),
            });
            /*if (coverage.id_coverage === 31) {
                coverages.push({
                    nameCoverage: coverage.coverage_name,
                    idSurexs: coverage.id_coverage,
                    idMeasurementUnit: 2,
                    value: coverage.value === 'OTRO' ? String(coverage.otherValue) : String(coverage.value),
                });
            } else {
                coverages.push({
                    nameCoverage: coverage.coverage_name,
                    idSurexs: coverage.id_coverage,
                    idMeasurementUnit: coverage.value === 'OTRO' ? 2 : Number(coverage.idMeasurementUnit),
                    value: coverage.value === 'OTRO' ? String(coverage.otherValue) : String(coverage.value),
                });
            }*/
        });

        plans.push({
            namePlan: plan.plan_name,
            coverages,
            flexiblePlan: plan.flexible_plan
        });
        return plans;
    }
}
