import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, Validators} from '@angular/forms';
import {ApiService} from '../api.service';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {JSONParser} from '@amcharts/amcharts4/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-flex-life-view',
  templateUrl: './flex-life-view.component.html',
  styleUrls: ['./flex-life-view.component.css']
})
export class FlexLifeViewComponent implements OnInit {
  typeOptions: any = [];
  dataSendLife: any = {};
  _planFlexId: number;
  planProductOptions: Array<{ key: number, value: string }> = [];
  planUnitOptions: Array<{ key: number, value: string }> = [];
  typeSumInsured: number;
  catalogForm = this.fb.group({
    catalogs: ['groups', [Validators.required]]
  });
  catalogForm2 = this.fb.group({
    catalogs2: ['groups1', [Validators.required]]
  });

  catalogForm3 = this.fb.group({
    catalogs3: ['groups3', [Validators.required]]
  });
  numCategory: number;
  planPlanes: any = [];
  planPlanesFlex: any = [];
  planFlexCoverages: any = [];
  planFlexCoveragesAll: any = [];
  coverageList: any = [];
  modalCoverage: BsModalRef;
  modalRef: BsModalRef;
  key = 'idCoverage';
  display = 'coverageName';
  format = { add: 'Agregar', all: 'Todo', none: 'ninguno' };
  nextP1: number;
  messageProduct: string;
  planAddCar: number;
  positionArray: number;
  typeSumInsurance: boolean;
  @ViewChild('messageAlert', {static : true}) myTemplate: TemplateRef<any>;
  constructor(private fb: FormBuilder,
              private api: ApiService,
              private toastr: ToastrService,
              private spinner: NgxSpinnerService,
              private modalService: BsModalService,
              private activatedRoute: ActivatedRoute,
              private router: Router) { }

  ngOnInit() {
    this.planAddCar = 0;
    // this._planFlexId = 5;
    this.numCategory = 0;
    this.typeOptions = [
      { id: true, optionSelect: 'SI'},
      { id: false, optionSelect: 'NO'}
    ];
    this.activatedRoute.params.subscribe(params => {
      this._planFlexId = +params.idPlan;
      console.log('planId');
      console.log(this._planFlexId);
      this.loadPlanProductOptions();
      this.loadUnitOptions();
      this.invokeFunctionGetConfigurationProduct();
    });
  }
  invokeFunctionInitObjectLife() {
    this.dataSendLife = {
      flexPlanLifeId: 0,
      planFlexId: this._planFlexId,
      typePlanProductId: 3,
      planReduction: false,
      currentPolicy: false,
      sumInsuranceMonthsSalary: false,
      fixedSumInsured: false,
      salaryPlan: false,
      sumInsuredPlan: false,
      configurationId: 0
    };
    this.typeSumInsured = 0;
    this.typeSumInsurance = false;
  }
  onTypeChange(value: any) {
    this.dataSendLife.currentPolicy = value;
    console.log(this.dataSendLife.currentPolicy);
    this.dataSendLife.salaryPlan = false;
    this.dataSendLife.sumInsuredPlan = false;
    this.dataSendLife.sumInsuranceMonthsSalary = false;
    this.dataSendLife.fixedSumInsured = false;
    if (value) {
      this.typeSumInsured = 1;
    } else {
      this.typeSumInsured = 0;
    }
    console.log(this.typeSumInsured);
  }

  loadPlanProductOptions(): void {
    this.api.getMobilityPlans()
      .then(
        (response: any) => {
          this.planProductOptions = response;
          console.log(this.planProductOptions);
        }, error => {
          this.toastr.error('Ocurrió un problema al cargar el catálogo de Roles', 'Notificación');
        }
      );
  }
  invokeFunctionSetMoth(value) {
    console.log(value.catalogs);
    this.dataSendLife.sumInsuranceMonthsSalary = true;
    this.dataSendLife.fixedSumInsured = false;
  }
  invokeFunctionSetSumInsured(value) {
    console.log(value.catalogs);
    this.dataSendLife.sumInsuranceMonthsSalary = false;
    this.dataSendLife.fixedSumInsured = true;
  }
  invokeFunctionSetMoth1(value) {
    this.dataSendLife.salaryPlan = true;
    this.dataSendLife.sumInsuredPlan = false;
  }
  invokeFunctionSetSumInsured1(value) {
    this.dataSendLife.salaryPlan = false;
    this.dataSendLife.sumInsuredPlan = true;
  }
  invokeFunctionNext() {
    // validar que si no hay planes base no pueda seleccionar opción 1 y 2 de movilidad
    if (!this.dataSendLife.currentPolicy && (this.dataSendLife.typePlanProductId === 1 || this.dataSendLife.typePlanProductId === 2)) {
      // tslint:disable-next-line:max-line-length
      this.toastr.warning('COMO NO HAY PLANES BASE NO PUEDE SELECCIONAR ESTE TIPO DE MOVILIDAD', 'NOTIFICACIÓN');
      return false;
    }
    this.nextP1 = 1;
    this.planPlanesFlex = [];
    this.planPlanes = [];
    console.log(this.dataSendLife);
    if (!this.dataSendLife.currentPolicy) {
      this.invokeServiceAddPlanFlex();
    }
  }
  onTypeChangeProduct(value: any) {
    this.dataSendLife.typePlanProductId = value;
  }
  onCategoriesCountChange() {
    console.log(this.numCategory);
    if (this.numCategory === 0 || this.numCategory === undefined || this.numCategory === null) {
      this.toastr.warning('TIENE QUE INGRESAR EL NUMERO DE CATEGORÍAS A GENERAR, NO PUEDE SER 0', 'NOTIFICACIÓN');
      return;
    }
    this.invokeServiceGetPlanesCreate(this.numCategory, false);
  }

  invokeServiceGetPlanesCreate(num: number, planFlexible: boolean ) {
    this.spinner.show('sp');
    const objRequest = {
      flexiblePlan: planFlexible,
      numPlan: num,
      planFlexId: this._planFlexId
    };
    this.api.getPlanesCreateLife(objRequest)
      .then(
        (response: any) => {
          this.planPlanes = response;
          const initCoverages = response[0].coverageLifeModelList;
          this.planFlexCoverages = initCoverages;
          this.spinner.hide('sp');
        }, error => {
          this.toastr.error('Ocurrió un problema.', 'Notificación');
        }
      );
  }
  invokeServiceGetPlanesCreateOne() {
    this.spinner.show('sp');
    const objRequest = {
      flexiblePlan: false,
      numPlan: 1,
      planFlexId: this._planFlexId
    };
    this.api.getPlanesCreateLife(objRequest)
      .then(
        (response: any) => {
          this.planPlanes.push(response[0]);
          this.spinner.hide('sp');
        }, error => {
          this.toastr.error('Ocurrió un problema, contacte al dministrador.', 'Notificación');
        }
      );
  }
  removeCoverage(i: number, j: number, itemF: any) {
    this.spinner.show('sp');
    if (itemF.coverageName === 'GASTOS FUNERARIOS') {
      const sgf = this.planPlanes[i].coverageLifeModelList.filter(item => item.coverageName !== 'SUMA ASEGURADA GASTOS FUNERARIOS');
      const l1 = sgf.filter(item => item.idCoverage !== itemF.idCoverage);
      console.log(l1);
      this.planPlanes[i].coverageLifeModelList = l1;
    } else {
      // tslint:disable-next-line:max-line-length
      const filter =  this.planPlanes[i].coverageLifeModelList.filter(item => item.idCoverage !== itemF.idCoverage);
      console.log(filter);
      this.planPlanes[i].coverageLifeModelList = filter;
    }
    this.spinner.hide('sp');
    this.toastr.success('SE HA ELIMINADO DE MANERA CORRECTA', 'NOTIFICACIÓN');
  }
  removeCoverageFlex(i: number, j: number, itemF: any) {
    this.spinner.show('sp');
    if (itemF.coverageName === 'GASTOS FUNERARIOS') {
      const sgf = this.planPlanesFlex[i].coverageLifeModelList.filter(item => item.coverageName !== 'SUMA ASEGURADA GASTOS FUNERARIOS');
      const l1 = sgf.filter(item => item.idCoverage !== itemF.idCoverage);
      console.log(l1);
      this.planPlanesFlex[i].coverageLifeModelList = l1;
    } else {
      // tslint:disable-next-line:max-line-length
      const filter =  this.planPlanesFlex[i].coverageLifeModelList.filter(item => item.idCoverage !== itemF.idCoverage);
      console.log(filter);
      this.planPlanesFlex[i].coverageLifeModelList = filter;
    }
    this.spinner.hide('sp');
    this.toastr.success('SE HA ELIMINADO DE MANERA CORRECTA', 'NOTIFICACIÓN');
  }
  changeSelectionOption2(coverage: any, i: number, j: number, coverageName: string) {
    if (coverageName === 'GASTOS FUNERARIOS') {
      this.planPlanes[i].coverageLifeModelList[j].coverageValueModel.value = coverage.target.value;
      this.planPlanes[i].coverageLifeModelList[j].coverageValueModel.unitMeasurementId = 9;
    }
  }
  changeSelectionOption3(coverage: any, i: number, j: number, coverageName: string) {
    this.planPlanes[i].coverageLifeModelList[j].coverageValueModel.unitMeasurementId = Number(coverage.target.value);
  }

  changeSelectionOption4(coverage: any, i: number, j: number, coverageName: string) {
    if (coverageName === 'GASTOS FUNERARIOS') {
      this.planPlanesFlex[i].coverageLifeModelList[j].coverageValueModel.value = coverage.target.value;
      this.planPlanesFlex[i].coverageLifeModelList[j].coverageValueModel.unitMeasurementId = 9;
    }
  }
  changeSelectionOption5(coverage: any, i: number, j: number, coverageName: string) {
    this.planPlanesFlex[i].coverageLifeModelList[j].coverageValueModel.unitMeasurementId = Number(coverage.target.value);
  }
  getUnitOptions(): any[] {
    return this.planUnitOptions;
  }
  loadUnitOptions() {
    this.api.getUnitMeasurement()
      .then(
        (response: any) => {
          this.planUnitOptions = response;
        }, error => {
          this.toastr.error('Ocurrió un problema, contacte al dministrador.', 'Notificación');
        }
      );
  }

  invokeServiceSaveConfigurationLife() {
    this.spinner.show('sp');
    if (this.planPlanes.length === 0 && this.dataSendLife.currentPolicy) {
      this.toastr.warning('TIENE QUE DAR DE ALTA AL MENOS UN PLAN BASE, PARA CONTINUAR CON EL REGISTRO.', 'NOTIFICACIÓN');
      this.spinner.hide('sp');
      return;
    }
    if (this.planPlanesFlex.length === 0) {
      this.toastr.warning('TIENE QUE DAR DE ALTA AL MENOS UN PLAN FLEX, PARA CONTINUAR CON EL REGISTRO.', 'NOTIFICACIÓN');
      this.spinner.hide('sp');
      return;
    }
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.planPlanes.length; i++) {
      if (this.planPlanes[i].planeName === '' || this.planPlanes[i].categoryName === '') {
        this.toastr.warning('ES NECESARIO AGREGAR LOS NOMBRE DEL PLAN/ CATEGORIA', 'NOTIFICACIÓN');
        this.spinner.hide('sp');
        return;
      }
      for (let c: 0; c < this.planPlanes[i].coverageLifeModelList.length; c++) {
        if (this.planPlanes[i].coverageLifeModelList[c].coverageName === 'SUMA ASEGURADA'
          || this.planPlanes[i].coverageLifeModelList[c].coverageName === 'SUMA ASEGURADA GASTOS FUNERARIOS') {
          if (this.planPlanes[i].coverageLifeModelList[c].coverageValueModel.value === ''
          || this.planPlanes[i].coverageLifeModelList[c].coverageValueModel.value === null
          || this.planPlanes[i].coverageLifeModelList[c].coverageValueModel.unitMeasurementId === null) {
            this.toastr.warning('ES NECESARIO AGREGAR EL VALOR DE LAS SUMA ASEGURADA', 'NOTIFICACIÓN');
            this.spinner.hide('sp');
            return;
          }
        }
      }
    }

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.planPlanesFlex.length; i++) {
      if (this.planPlanesFlex[i].planeName === '' || this.planPlanesFlex[i].categoryName === '') {
        this.toastr.warning('ES NECESARIO AGREGAR LOS NOMBRE DEL PLAN/ CATEGORIA', 'NOTIFICACIÓN');
        this.spinner.hide('sp');
        return;
      }
      // tslint:disable-next-line:prefer-for-of
      for (let c = 0; c < this.planPlanesFlex[i].coverageLifeModelList.length; c++) {
        if (this.planPlanesFlex[i].coverageLifeModelList[c].coverageName === 'SUMA ASEGURADA'
          || this.planPlanesFlex[i].coverageLifeModelList[c].coverageName === 'SUMA ASEGURADA GASTOS FUNERARIOS') {
          if (this.planPlanesFlex[i].coverageLifeModelList[c].coverageValueModel.value === ''
            || this.planPlanesFlex[i].coverageLifeModelList[c].coverageValueModel.value === null
            || this.planPlanesFlex[i].coverageLifeModelList[c].coverageValueModel.unitMeasurementId === null) {
            this.toastr.warning('ES NECESARIO AGREGAR EL VALOR DE LAS SUMA ASEGURADA', 'NOTIFICACIÓN');
            this.spinner.hide('sp');
            return;
          }
        }
      }
    }
    this.dataSendLife.planesCurrentLife = this.planPlanes;
    this.dataSendLife.planesFlexLife = this.planPlanesFlex;
    console.log(this.dataSendLife);
    this.api.SaveConfigurationProductLife(this.dataSendLife)
      .then(
        (response: any) => {
          console.log(response);
          this.dataSendLife.flexPlanLifeId = response.flexPlanLifeId;
          if (this.dataSendLife.flexPlanLifeId === 0) {
            this.toastr.error('NO SE HA GUARDO LA INFORMACIÓN, POR FAVOR VERIFIQUE SU INFORMACIÓN', 'NOTIFICACIÓN');
            return;
          } else {
            this.toastr.success('SE HA GUARDADO CORRECTAMENTE LA INFORMACIÓN', 'NOTIFICACIÓN');
            this.spinner.hide('sp');
            this.invokeServiceGetSlip();
            setTimeout(() => {
              this.router.navigate(['/admin/plans-detalle/', this._planFlexId]);
            }, 500);
        }
        }, error => {
          this.toastr.error('Ocurrió un problema, contacte al dministrador.', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  invokeServiceAddPlanFlex() {
    this.invokeServiceGetPlanesFlexCreate(1, true);
  }

  invokeServiceGetPlanesFlexCreate(num: number, planFlexible: boolean ) {
    this.spinner.show('sp');
    const objRequest = {
      flexiblePlan: planFlexible,
      numPlan: num,
      planFlexId: this._planFlexId
    };
    this.api.getPlanesCreateLife(objRequest)
      .then(
        (response: any) => {
          console.log(response);
          const objResponse = response[0];
          objResponse.categoryName = objResponse.categoryName + (this.planPlanesFlex.length + 1);
          this.planPlanesFlex.push(objResponse);
          this.planFlexCoverages = objResponse.coverageLifeModelList;
          console.log(this.planPlanesFlex);
          this.spinner.hide('sp');
        }, error => {
          this.toastr.error('Ocurrió un problema, contacte al dministrador.', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }

  invokeFunctionDeletePlanInitial(position: number, plan: any) {
    console.log(plan);
    this.spinner.show('sp');
    if (plan.flexPlanCategoriesId === 0) {
      const newPlanes = this.planPlanes.filter(item => item.flexPlanPlanesId !== plan.flexPlanPlanesId);
      this.planPlanes = newPlanes;
      this.spinner.hide('sp');
      this.toastr.success('SE HA ELIMINADO DE MANERA CORRECTA', 'NOTIFICACIÓN');
    } else {
      // si el flexPlanCategoriesId > 0 entonces se tiene que mandar a borrar en la base de datos
      const objRequest = {
        idProduct: plan.flexPlanCategoriesId
      };
      this.api.deletePlanLifeById(objRequest)
        .then(
          (response: any) => {
            console.log(response);
            if (response.flexPlanLifeId === 1) {
              const newPlanes1 = this.planPlanes.filter(item => item.categoryName !== plan.categoryName);
              this.planPlanes = newPlanes1;
              this.spinner.hide('sp');
              this.toastr.success('SE HA ELIMINADO DE MANERA CORRECTA', 'NOTIFICACIÓN');
            } else if (response.flexPlanLifeId === 2) {
              this.spinner.hide('sp');
              this.toastr.info('NO SE PUEDE ELIMINAR EL PLAN DADO QUE YA FUE ASIGNADO A USUARIOS', 'NOTIFICACIÓN');
            } else {
              this.spinner.hide('sp');
              this.toastr.warning('HA OCURRIDO UN PROBLEMA CON EL SERVIDOR, CONTACTE AL ADMINISTRADOR', 'NOTIFICACIÓN');
            }
          }, error => {
            this.toastr.error('Ocurrió un problema', 'Notificación');
          }
        );
    }
  }
  invokeFunctionDeletePlanFlex(position: number, plan: any) {
    console.log(position);
    /*this.spinner.show('sp');
    const newPlanes = this.planPlanesFlex.filter(item => item.categoryName !== plan.categoryName);
    this.planPlanesFlex = newPlanes;
    this.spinner.hide('sp');
    this.toastr.success('SE HA ELIMINADO DE MANERA CORRECTA', 'NOTIFICACIÓN');*/

    this.spinner.show('sp');
    if (plan.flexPlanCategoriesId === 0) {
      const newPlanes = this.planPlanesFlex.filter(item => item.categoryName !== plan.categoryName);
      this.planPlanesFlex = newPlanes;
      this.spinner.hide('sp');
      this.toastr.success('SE HA ELIMINADO DE MANERA CORRECTA', 'NOTIFICACIÓN');
    } else {
      // si el flexPlanCategoriesId > 0 entonces se tiene que mandar a borrar en la base de datos
      const objRequest = {
        idProduct: plan.flexPlanCategoriesId
      };
      this.api.deletePlanLifeById(objRequest)
        .then(
          (response: any) => {
            console.log(response);
            if (response.flexPlanLifeId === 1) {
              const newPlanes1 = this.planPlanesFlex.filter(item => item.categoryName !== plan.categoryName);
              this.planPlanesFlex = newPlanes1;
              this.spinner.hide('sp');
              this.toastr.success('SE HA ELIMINADO DE MANERA CORRECTA', 'NOTIFICACIÓN');
            } else if (response.flexPlanLifeId === 2) {
              this.spinner.hide('sp');
              this.toastr.info('NO SE PUEDE ELIMINAR EL PLAN DADO QUE YA FUE ASIGNADO A USUARIOS', 'NOTIFICACIÓN');
            } else {
              this.spinner.hide('sp');
              this.toastr.warning('HA OCURRIDO UN PROBLEMA CON EL SERVIDOR, CONTACTE AL ADMINISTRADOR', 'NOTIFICACIÓN');
            }
          }, error => {
            this.toastr.error('Ocurrió un problema', 'Notificación');
          }
        );
    }
  }

  closeModal() {
    this.modalCoverage.hide();
  }
  addCoverage() {}

  invokeFunctionAddCoverage(template: TemplateRef<any>, coverages: any, position: number) {
    const objRequest = {
      flexiblePlan: false,
      numPlan: 1,
      planFlexId: this._planFlexId
    };
    this.api.getPlanesCreateLife(objRequest)
      .then(
        (response: any) => {
          const objResponse = response[0];
          this.planFlexCoverages = objResponse.coverageLifeModelList;
          console.log(coverages);
          this.positionArray = position;
          this.coverageList = coverages;
          console.log(this.planFlexCoverages);
          // tslint:disable-next-line:max-line-length
          this.modalCoverage = this.modalService.show(template, {class: 'modal-md', keyboard: false, ignoreBackdropClick: true, backdrop: false});
        }, error => {
          this.toastr.error('Ocurrió un problema', 'Notificación');
        }
      );
  }

  onCoverageByPlanChange(event: any) {
    console.log(event);
    this.planPlanes[this.positionArray].coverageLifeModelList = event;
    console.log(this.planPlanes);

    console.log(this.coverageList);
  }

  invokeServiceGetSlip() {
    this.spinner.show('sp');
    this.api.getSlipLife(this._planFlexId)
      .then(
        (response: any) => {
          if (response.slip === undefined) {
            this.toastr.error('OCURRIO UN PROBLEMA AL OBTENER EL SLIP.', 'NOTIFIACION');
            this.spinner.hide('sp');
            return;
          }
          const base64Data = response.slip;
          const binaryData = atob(base64Data);  // Decodificar Base64 a binario
          const arrayBuffer = new ArrayBuffer(binaryData.length);
          const uintArray = new Uint8Array(arrayBuffer);

          for (let i = 0; i < binaryData.length; i++) {
            uintArray[i] = binaryData.charCodeAt(i);
          }

          const blob = new Blob([uintArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(blob);
          downloadLink.setAttribute('download', 'SurexsSlip.xlsx');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
          this.spinner.hide('sp');
        }, (error) => {
          this.spinner.hide('sp');
        });
  }
  invokeFunctionGetConfigurationProduct() {
    this.spinner.show('sp');
    const objRequest = {
      idProduct: 3,
      idPlan: this._planFlexId
    };
    this.api.getConfigurationProductLife(objRequest)
      .then(
        (response: any) => {
          this.dataSendLife = response;
          if (this.dataSendLife.flexPlanLifeId > 0) {
            // editar configuración
            this.nextP1 = 1;
            if (this.dataSendLife.currentPolicy) {
              this.typeSumInsured = 1;
              this.typeSumInsurance = this.dataSendLife.sumInsuranceMonthsSalary === true ? true : false;
            } else {
              this.typeSumInsured = 0;
              this.typeSumInsurance = this.dataSendLife.salaryPlan === true ? true : false;
            }
            this.planPlanes = this.dataSendLife.planesCurrentLife;
            this.planPlanesFlex = this.dataSendLife.planesFlexLife;
            for (let p = 0; p < this.planPlanesFlex.length; p++) {
              if (this.planPlanesFlex[p].status === 3) {
                this.planAddCar = 1;
              }
            }
            console.log(this.planAddCar);
            switch (this.dataSendLife.configurationId) {
              case 2:
                this.messageProduct = 'YA SE HA GENERADO SLIP, SI REALIZA CAMBIOS DEBE DE GENERARLO DE NUEVO PARA SU COTIZACIÓN';
                this.modalRef = this.modalService.show(this.myTemplate, {
                  class: 'modal-md',
                  keyboard: false,
                  backdrop: 'static'
                });
                break;
              case 3:
                if (this.planAddCar === 0) {
                  // tslint:disable-next-line:max-line-length
                  this.messageProduct = 'YA SE ENCUENTRAN CONFIGURADAS LAS TARIFAS SI USTED HACE UN CAMBIO SE BORRARAN Y TENDRÁ QUE GENERAR SLIP PARA COTIZACIÓN Y VOLVER A INGRESAR TARIFAS';
                } else {
                  // tslint:disable-next-line:max-line-length
                  this.messageProduct = 'YA NO SE PUEDEN REALIZAR CAMBIOS, UN PLAN YA SE ENCUENTRA EN PROCESO DE COMPRA';
                }
                this.modalRef = this.modalService.show(this.myTemplate, {
                  class: 'modal-md',
                  keyboard: false,
                  backdrop: 'static'
                });
                break;
            }
            this.invokeFunctionGetCoveragesPlan();
          } else {
            // configuración nueva
            this.nextP1 = 0;
            this.invokeFunctionInitObjectLife();
          }
          this.spinner.hide('sp');
        }, error => {
          this.toastr.error('Ocurrió un problema.', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }




  invokeFunctionGetCoveragesPlan() {
    const objRequest = {
      flexiblePlan: false,
      numPlan: 1,
      planFlexId: this._planFlexId
    };
    this.api.getPlanesCreateLife(objRequest)
      .then(
        (response: any) => {
          const objResponse = response[0];
          this.planFlexCoverages = objResponse.coverageLifeModelList;
        }, error => {
          this.toastr.error('Ocurrió un problema', 'Notificación');
        }
      );
  }

  invokeServiceUpdateConfigurationLife() {
    this.spinner.show('sp');
    if (this.planPlanesFlex.length === 0) {
      this.toastr.warning('TIENE QUE DAR DE ALTA AL MENOS UN PLAN FLEX', 'NOTIFICACIÓN');
      this.spinner.hide('sp');
      return;
    }
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.planPlanes.length; i++) {
      if (this.planPlanes[i].planeName === '' || this.planPlanes[i].categoryName === '') {
        this.toastr.warning('ES NECESARIO AGREGAR LOS NOMBRE DEL PLAN/ CATEGORIA', 'NOTIFICACIÓN');
        this.spinner.hide('sp');
        return;
      }
      for (let c: 0; c < this.planPlanes[i].coverageLifeModelList.length; c++) {
        if (this.planPlanes[i].coverageLifeModelList[c].coverageName === 'SUMA ASEGURADA'
          || this.planPlanes[i].coverageLifeModelList[c].coverageName === 'SUMA ASEGURADA GASTOS FUNERARIOS') {
          if (this.planPlanes[i].coverageLifeModelList[c].coverageValueModel.value === ''
            || this.planPlanes[i].coverageLifeModelList[c].coverageValueModel.value === null
            || this.planPlanes[i].coverageLifeModelList[c].coverageValueModel.unitMeasurementId === null) {
            this.toastr.warning('ES NECESARIO AGREGAR EL VALOR DE LAS SUMA ASEGURADA', 'NOTIFICACIÓN');
            this.spinner.hide('sp');
            return;
          }
        }
      }
    }

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.planPlanesFlex.length; i++) {
      if (this.planPlanesFlex[i].planeName === '' || this.planPlanesFlex[i].categoryName === '') {
        this.toastr.warning('ES NECESARIO AGREGAR LOS NOMBRE DEL PLAN/ CATEGORIA', 'NOTIFICACIÓN');
        this.spinner.hide('sp');
        return;
      }
      // tslint:disable-next-line:prefer-for-of
      for (let c = 0; c < this.planPlanesFlex[i].coverageLifeModelList.length; c++) {
        if (this.planPlanesFlex[i].coverageLifeModelList[c].coverageName === 'SUMA ASEGURADA'
          || this.planPlanesFlex[i].coverageLifeModelList[c].coverageName === 'SUMA ASEGURADA GASTOS FUNERARIOS') {
          if (this.planPlanesFlex[i].coverageLifeModelList[c].coverageValueModel.value === ''
            || this.planPlanesFlex[i].coverageLifeModelList[c].coverageValueModel.value === null
            || this.planPlanesFlex[i].coverageLifeModelList[c].coverageValueModel.unitMeasurementId === null) {
            this.toastr.warning('ES NECESARIO AGREGAR EL VALOR DE LAS SUMA ASEGURADA', 'NOTIFICACIÓN');
            this.spinner.hide('sp');
            return;
          }
        }
      }
    }
    this.dataSendLife.planesCurrentLife = this.planPlanes;
    this.dataSendLife.planesFlexLife = this.planPlanesFlex;
    console.log(this.dataSendLife);
    this.api.updateConfigurationProductLife(this.dataSendLife)
      .then(
        (response: any) => {
          console.log(response);
          this.dataSendLife.flexPlanLifeId = response.flexPlanLifeId;
          this.toastr.success('SE HA GUARDADO CORRECTAMENTE LA INFORMACIÓN', 'NOTIFICACIÓN');
          this.spinner.hide('sp');
          this.router.navigate(['/admin/plans-detalle/', this._planFlexId]);
        }, error => {
          this.toastr.error('Ocurrió un problema, contacte al dministrador.', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  invokeFunctionBackDetailPlan() {
    this.router.navigate(['/admin/plans-detalle/', this._planFlexId]);
  }

  invokeFunctionSetNameCategory(i: number, name: string) {
    this.planPlanesFlex[i].categoryName = 'flex_category_' + name;
  }
  invokeFunctionSetCurrentPolicy(value) {
    this.dataSendLife.salaryPlan = false;
    this.dataSendLife.sumInsuredPlan = false;
    this.dataSendLife.sumInsuranceMonthsSalary = false;
    this.dataSendLife.fixedSumInsured = false;
    if (value) {
      this.typeSumInsured = 1;
    } else {
      this.typeSumInsured = 0;
    }
  }
}
