import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ApiService} from '../api.service';
import {ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-flex-excess-view',
  templateUrl: './flex-excess-view.component.html',
  styleUrls: ['./flex-excess-view.component.css']
})
export class FlexExcessViewComponent implements OnInit {

  planInit: any = {};
  planProductOptions: any = [];
  planFlexForm: FormGroup;
  planPlanesModels: FormArray;
  manualLoad = null;
  categoriesCount = 0;
  showSpecialCoverages = false;
  // Datos para el dual-list
  specialCoverageList = [];
  specialCoverageListSelectedByPlan = [];
  specialCoverageListSelected = [];
  key = 'idCoverage';
  display = 'coverageName';
  format = {add: 'Agregar', remove: 'Quitar', all: 'Todo', none: 'ninguno'};
  modalRefAddCoveragePlan: BsModalRef;
  idPlanFlex;
  selectedPlanIndex = 0; // Para saber qué plan está seleccionando el usuario
  dataSourcesCoverages = [];
  isEdition = false;
  messageProduct: string;
  modalRef: BsModalRef;
  planAddCar: number;
  @ViewChild('messageAlert', {static : true}) myTemplate: TemplateRef<any>;
  selectedOption: any = null;
  manualValue: string = ''; // Almacena el valor ingresado en el modal
  optionsValuesMap: { [key: string]: any[] } = {}; // Mapea valores personalizados por cobertura
  title: string;
  // Nuevas variables para almacenar la cobertura y el índice actual
  currentCoverageIndex: number;
  currentCoverage: AbstractControl;
  public onClose: Subject<string> = new Subject(); //
  constructor(private fb: FormBuilder,
              private api: ApiService,
              private activatedRoute: ActivatedRoute,
              private toastr: ToastrService,
              private spinner: NgxSpinnerService,
              private modalService: BsModalService) {

    this.activatedRoute.params.subscribe(params => {
      this.idPlanFlex = params.idPlan;
    });

    this.planFlexForm = this.fb.group({
      productPlanFlexConfiguration: [0],
      planPlanesModels: this.fb.array([])
    });

    this.planPlanesModels = this.planFlexForm.get('planPlanesModels') as FormArray;
  }

  ngOnInit(): void {
    this.loadUnitOptions();
    this.loadCoveragesByIdProduct();
    this.getPlanByIdPlanFlex();

  }

  onManualLoadChange(event, templateCoverage: TemplateRef<any>): void {
    if (event.target.value === 'false') {
      this.openModals(templateCoverage, 'md');
    }

  }

  onCategoriesCountChange(): void {
    this.updatePlanPlanes(this.categoriesCount);
    this.loadCoveragesByIdProduct();
  }

  updatePlanPlanes(count: number) {
    return new Promise((resolve, reject) => {
      try {
        this.planPlanesModels.clear();
        for (let i = 0; i < count; i++) {
          this.planPlanesModels.push(this.createPlan(false, i)); // Agrega planes según el count
        }
        resolve(); // Llama a resolve cuando termine
      } catch (error) {
        reject(error); // En caso de error
      }
    });
  }

  createPlan(isPlanFexlible: boolean, count): FormGroup {
    return this.fb.group({
      idFlexPlanPlanes: [null],  // Integer
      flexPlanCategoriesId: [null],  // Integer
      planName: ['PLAN ' + count, Validators.required],  // String
      flexiblePlan: [isPlanFexlible],  // Boolean
      quote: [0.0],  // Double
      premiumIndividual: [false],  // Double
      averagePremium: [0.0],  // Double
      netPremiumIndividual: [0.0],  // Double
      netPremiumGroup: [0.0],  // Double
      policyRights: [0.0],  // Double
      surcharges: [0.0],  // Double
      creationDate: [null],  // Date
      updateDate: [null],  // Date
      creationUserId: [null],  // Integer
      updateUserId: [null],  // Integer
      status: [1],  // Integer
      flexPlanCategoriesModel: this.fb.group({ // FormGroup para almacenar la categoria
        idFlexPlanCategories: [null],
        categoryName: ['', Validators.required],
        planFlexId: [this.idPlanFlex],
        flexibleProductId: [1],
        creationDate: [''],
        updateDate: [''],
        creationUserId: [''],
        updateUserId: ['']
      }),
      flexPlanCoveragesModels: this.fb.array([])  // FormArray para almacenar las coberturas
    });
  }

  onDestinationChange(selectedCoverages): void {
    this.specialCoverageListSelected = selectedCoverages;

    this.planPlanesModels.controls.forEach(planPlane => {
      const flexPlanCoveragesModels = planPlane.get('flexPlanCoveragesModels') as FormArray;

      this.addSelectedCoveragesToPlans(selectedCoverages);
    });
  }


  createEndorsement(coverageName: string, coverageId: number): FormGroup {
    return this.fb.group({
      idFlexPlanEndorsement: null,
      flexPlanCoverageId: null, // Este campo se llenará después de crear el planCoverage
      coverageName,
      value: [null, Validators.required],
      unitMeasurementId: [null, Validators.required],
      creationDate: null,
      updateDate: null,
      creationUserId: null,
      updateUserId: null
    });
  }

  removeCategory(planIndex: number, categoryIndex: number): void {
    const planCategories = this.planPlanesModels.at(planIndex).get('flexPlanCategoriesModel') as FormArray;
    planCategories.removeAt(categoryIndex);
  }

  loadCoveragesByIdProduct(): void {
    const data = {
      idProduct: 2,
      idPlan: null
    };
    this.spinner.show('sp');
    this.api.getCoveragesByIdProduct(data)
      .then(
        (response: any) => {
          this.specialCoverageList = response;
          if (!this.isEdition) { // Los filtros solo se harán si el plan es nuevo
            this.addSelectedCoveragesToPlans(response);
          }

          this.spinner.hide('sp');
        }, error => {
          this.toastr.error('Ocurrió un problema al cargar el catálogo de Roles', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }

  addSelectedCoveragesToPlans(selectedCoverages: any[]): void {
    this.planPlanesModels.controls.forEach(planPlane => {
      const flexPlanCoveragesModels = planPlane.get('flexPlanCoveragesModels') as FormArray;

      this.specialCoverageListSelected = selectedCoverages
        .map(coverage => ({
          idCoverage: coverage.idCoverage,
          coverageName: coverage.coverageName
        }));

      selectedCoverages.forEach(coverage => {
        // Verificar si la cobertura ya existe en flexPlanCoveragesModels
        const exists = flexPlanCoveragesModels.controls.some(ctrl => ctrl.get('coverageSurexsId').value === coverage.idCoverage);

        if (!exists) {
          flexPlanCoveragesModels.push(this.fb.group({
            idFlexPlanCoverages: null,
            flexPlanPlanesId: null,
            coverageSurexsId: coverage.idCoverage,
            origen: 1,
            coverageName: coverage.coverageName,
            value: [null, Validators.required],
            unitMeasurementId: [null, Validators.required],
            endoso: false,
            flexible: false,
            factorQuote: null,
            creationDate: null,
            updateDate: null,
            creationUserId: null,
            updateUserId: null,
            endorsements: this.fb.array([]),
            optionsValues: coverage.optionsValues && coverage.optionsValues.length > 0
              ? this.fb.array(coverage.optionsValues.map(option => this.fb.group({
              value: [option.value],
              unitMeasurementId: [option.unitMeasurementId],
              defaultValue: [option.defaultValue],
              openValue: [option.openValue],
              order: [option.order],
              unitMeasurement: [option.unitMeasurement]
            }))) : this.fb.array([])
          }));
        }
      });
    });
  }


  loadUnitOptions() {
    this.api.getUnitMeasurement()
      .then(
        (response: any) => {
          this.planProductOptions = response;
        }, error => {
          this.toastr.error('Ocurrió un problema al cargar el catálogo de Medidas', 'Notificación');
        }
      );
  }

  getUnitOptions(): any[] {
    return this.planProductOptions;
  }

  onSubmit(): void {
    const dto = this.planFlexForm.value;
    console.log(dto);
  }

  getPlanInit(event) {
    this.planInit = event;
    // Asignación de los valores al FormGroup
    this.planFlexForm.patchValue({
      typePopulationPolicyId: event.typePopulationPolicyId,  // Convertir a número (Long)
      planReduction: event.planReduction,  // Boolean
      addSpouse: event.addSpouse,  // Boolean
      addChildren: 2,  // Boolean
      addMother: event.addMother,  // Boolean
      addFather: event.addFather,  // Boolean
      typePlanProductId: 1
    });
  }

  removeCoverage(planIndex: number, coverageIndex: number) {
    // Obtener el FormArray de flexPlanCoveragesModels del plan correspondiente
    const planPlanesModels = this.planFlexForm.get('planPlanesModels') as FormArray;
    const plan = planPlanesModels.at(planIndex) as FormGroup;
    const flexPlanCoveragesModels = plan.get('flexPlanCoveragesModels') as FormArray;

    // Verificar que el FormArray flexPlanCoveragesModels exista antes de eliminar
    if (flexPlanCoveragesModels) {
      flexPlanCoveragesModels.removeAt(coverageIndex);
    } else {
      console.error('FormArray flexPlanCoveragesModels no existe');
    }
  }

  toggleSpecialCoverages() {
    this.showSpecialCoverages = !this.showSpecialCoverages;
  }


  openModalCoverage(templateCoverage: TemplateRef<any>, planIndex: number) {
    this.dataSourcesCoverages = [];
    this.selectedPlanIndex = planIndex; // Guardamos el índice del plan seleccionado

    // Obtenemos las coberturas actuales del plan y sus IDs
    const plan = this.getPlanAtIndex(planIndex);
    const flexPlanCoveragesModels = plan.get('flexPlanCoveragesModels') as FormArray;
    const planCoverageIds = flexPlanCoveragesModels.value.map((coverage: any) => coverage.coverageSurexsId); // IDs de coberturas actuales

    // Cargar las coberturas seleccionadas basadas en las que ya están en el plan
    this.dataSourcesCoverages = this.specialCoverageList.filter(coverage => planCoverageIds.includes(coverage.idCoverage));

    // tslint:disable-next-line:max-line-length
    this.modalRefAddCoveragePlan = this.modalService.show(templateCoverage,
      {class: 'modal-lg', keyboard: false, ignoreBackdropClick: true, backdrop: false});

  }

  openModals(templateCoverage: TemplateRef<any>, size: string) {
    this.modalRef = this.modalService.show(templateCoverage,
      {class: size, keyboard: false, ignoreBackdropClick: true, backdrop: false});
  }

  openModalCoverageByPlan(templateCoverage: TemplateRef<any>, planIndex: number) {
    this.dataSourcesCoverages = [];
    this.selectedPlanIndex = planIndex; // Guardamos el índice del plan seleccionado

    // Obtenemos las coberturas actuales del plan y sus IDs
    const plan = this.getPlanAtIndex(planIndex);
    const flexPlanCoveragesModels = plan.get('flexPlanCoveragesModels') as FormArray;
    this.specialCoverageListSelectedByPlan = flexPlanCoveragesModels.value
      .filter((coverage: any) => coverage.flexible === false) // Filtrar solo donde flexible es false
      .map((coverage: any) => ({
        coverageSurexsId: coverage.coverageSurexsId,
        coverageName: coverage.coverageName,
        endoso: coverage.endoso
      }));

    // Cargar las coberturas del plan que esten flexibilizadas
    this.dataSourcesCoverages = flexPlanCoveragesModels.value
      .filter((coverage: any) => coverage.flexible === true) // Filtrar solo donde flexible es false
      .map((coverage: any) => ({
        coverageSurexsId: coverage.coverageSurexsId,
        coverageName: coverage.coverageName,
        endoso: coverage.endoso
      }));

    this.modalRefAddCoveragePlan = this.modalService.show(templateCoverage,
      {class: 'modal-lg', keyboard: false, ignoreBackdropClick: true, backdrop: false});

  }

  getPlanAtIndex(planIndex: number): FormGroup {
    const planPlanesModels = this.planFlexForm.get('planPlanesModels') as FormArray;
    return planPlanesModels.at(planIndex) as FormGroup;
  }

  closeModalCoverage() {
    this.modalRefAddCoveragePlan.hide();
  }

  onCoverageByPlanChange(event: any) {
    const plan = this.getPlanAtIndex(this.selectedPlanIndex);
    const flexPlanCoveragesModels = plan.get('flexPlanCoveragesModels') as FormArray;

    // Iteramos sobre las coberturas seleccionadas en el dual-list
    event.forEach((coverage: any) => {
      // Verificar si la cobertura ya existe en flexPlanCoveragesModels
      const existingCoverage = flexPlanCoveragesModels.controls.some(
        (ctrl: any) => ctrl.get('coverageSurexsId').value === coverage.idCoverage);

      // Si no existe, la agregamos
      if (!existingCoverage) {
        if (coverage.coverageType === 1) {
          // Agregar cobertura de tipo 1
          flexPlanCoveragesModels.push(this.fb.group({
            idFlexPlanCoverages: null,
            flexPlanPlanesId: null,
            coverageSurexsId: coverage.idCoverage,
            origen: 1,
            coverageName: coverage.coverageName,
            value: [null, Validators.required],
            unitMeasurementId: [null, Validators.required],
            endoso: false,
            flexible: false,
            factorQuote: null,
            creationDate: null,
            updateDate: null,
            creationUserId: null,
            updateUserId: null,
            endorsements: this.fb.array([])
          }));
        } else if (coverage.coverageType === 2) {
          // Agregar cobertura de tipo 2 con endorsements
          flexPlanCoveragesModels.push(this.fb.group({
            idFlexPlanCoverages: null,
            flexPlanPlanesId: null,
            coverageSurexsId: coverage.idCoverage,
            origen: 1,
            coverageName: coverage.coverageName,
            value: ['-', Validators.required],
            unitMeasurementId: [10, Validators.required],
            endoso: true,
            flexible: false,
            factorQuote: null,
            creationDate: null,
            updateDate: null,
            creationUserId: null,
            updateUserId: null,
            endorsements: this.fb.array([
              this.createEndorsement('SUMA ASEGURADA', coverage.idCoverage),
              this.createEndorsement('DEDUCIBLE', coverage.idCoverage),
              this.createEndorsement('TOPE COASEGURO', coverage.idCoverage),
              this.createEndorsement('PERIODO DE ESPERA', coverage.idCoverage)
            ])
          }));
        }
      }
    });

    // Actualizamos el array `dataSourcesCoverages` para reflejar las coberturas seleccionadas
    this.dataSourcesCoverages = event;
  }

  onCoverageFlexByPlanChange(event: any) {
    const plan = this.getPlanAtIndex(this.selectedPlanIndex);
    const flexPlanCoveragesModels = plan.get('flexPlanCoveragesModels') as FormArray;

    // Iteramos sobre las coberturas seleccionadas en el dual-list
    event.forEach((coverage: any) => {
      // Verificar si la cobertura ya existe en flexPlanCoveragesModels
      const existingCoverage = flexPlanCoveragesModels.controls.some((ctrl: any) =>
        ctrl.get('coverageSurexsId').value === coverage.coverageSurexsId && ctrl.get('flexible').value === true);

      // Si no existe, la agregamos
      if (!existingCoverage) {
        if (!coverage.endoso) {
          // Agregar cobertura de tipo 1
          flexPlanCoveragesModels.push(this.fb.group({
            idFlexPlanCoverages: null,
            flexPlanPlanesId: null,
            coverageSurexsId: coverage.coverageSurexsId,
            origen: 1,
            coverageName: coverage.coverageName,
            value: [null, Validators.required],
            unitMeasurementId: [null, Validators.required],
            endoso: false,
            flexible: true,
            factorQuote: null,
            creationDate: null,
            updateDate: null,
            creationUserId: null,
            updateUserId: null,
            endorsements: this.fb.array([])
          }));
        } else if (coverage.endoso) {
          // Agregar cobertura de tipo 2 con endorsements
          flexPlanCoveragesModels.push(this.fb.group({
            idFlexPlanCoverages: null,
            flexPlanPlanesId: null,
            coverageSurexsId: coverage.coverageSurexsId,
            origen: 1,
            coverageName: coverage.coverageName,
            value: ['-', Validators.required],
            unitMeasurementId: [10, Validators.required],
            endoso: true,
            flexible: true,
            factorQuote: null,
            creationDate: null,
            updateDate: null,
            creationUserId: null,
            updateUserId: null,
            endorsements: this.fb.array([
              this.createEndorsement('SUMA ASEGURADA', coverage.idCoverage),
              this.createEndorsement('DEDUCIBLE', coverage.idCoverage),
              this.createEndorsement('TOPE COASEGURO', coverage.idCoverage),
              this.createEndorsement('PERIODO DE ESPERA', coverage.idCoverage)
            ])
          }));
        }
      }
    });

    // Actualizamos el array `dataSourcesCoverages` para reflejar las coberturas seleccionadas
    this.dataSourcesCoverages = event;
  }

  removePlan(planIndex: number) {
    const planPlanesModels = this.planFlexForm.get('planPlanesModels') as FormArray;

    if (planPlanesModels && planPlanesModels.length > planIndex) {
      planPlanesModels.removeAt(planIndex);
    }
  }

  // Método principal para clonar un plan
  clonePlan(planIndex: number): void {
    // Obtenemos el FormArray que contiene todos los planes
    const planPlanesModels = this.planFlexForm.get('planPlanesModels') as FormArray;

    // Obtenemos el plan en el índice especificado
    const planToClone = planPlanesModels.at(planIndex) as FormGroup;

    // Creamos un nuevo FormGroup basado en los valores del plan a clonar
    const clonedPlan = this.fb.group({
      idFlexPlanPlanes: [''],
      planName: [planToClone.get('planName').value, Validators.required],
      flexPlanCategoriesId: ['', Validators.required],
      flexiblePlan: true,
      quote: [planToClone.get('quote').value],
      premiumIndividual: [planToClone.get('premiumIndividual').value],
      averagePremium: [planToClone.get('averagePremium').value],
      netPremiumIndividual: [planToClone.get('netPremiumIndividual').value],
      netPremiumGroup: [planToClone.get('netPremiumGroup').value],
      policyRights: [planToClone.get('policyRights').value],
      surcharges: [planToClone.get('surcharges').value],
      creationDate: [planToClone.get('creationDate').value],
      updateDate: [planToClone.get('updateDate').value],
      creationUserId: [planToClone.get('creationUserId').value],
      updateUserId: [planToClone.get('updateUserId').value],
      status: [planToClone.get('status').value],
      flexPlanCategoriesModel: this.fb.group({
        idFlexPlanCategories: [planToClone.get('flexPlanCategoriesModel').get('idFlexPlanCategories').value],
        categoryName: [planToClone.get('flexPlanCategoriesModel').get('categoryName').value],
        planFlexId: [planToClone.get('flexPlanCategoriesModel').get('planFlexId').value],
        flexibleProductId: [planToClone.get('flexPlanCategoriesModel').get('flexibleProductId').value],
        creationDate: [planToClone.get('flexPlanCategoriesModel').get('creationDate').value],
        updateDate: [planToClone.get('flexPlanCategoriesModel').get('updateDate').value],
        creationUserId: [planToClone.get('flexPlanCategoriesModel').get('creationUserId').value],
        updateUserId: [planToClone.get('flexPlanCategoriesModel').get('updateUserId').value]
      }),
      // Solo agregamos las coberturas de `specialCoverageList` al plan clonado
      flexPlanCoveragesModels: this.addSpecialCoveragesWithRestrictions(planToClone),
    });

    // Añadimos el plan clonado al FormArray
    planPlanesModels.push(clonedPlan);
  }

// Método para agregar solo las coberturas de `specialCoverageList` y aplicar la lógica de restricciones
  addSpecialCoveragesWithRestrictions(planToClone: FormGroup): FormArray {
    const coverages = this.fb.array([]);

    // Obtenemos el FormArray de coberturas del plan original
    const planCoverages = planToClone.get('flexPlanCoveragesModels') as FormArray;

    // Encontramos las coberturas específicas "DEDUCIBLE" y "TABULADOR MEDICO" en el plan original
    const deductibleCoverage = planCoverages.controls.find((coverage: AbstractControl) =>
      coverage.get('coverageName').value === 'DEDUCIBLE'
    );
    const tabulatorCoverage = planCoverages.controls.find((coverage: AbstractControl) =>
      coverage.get('coverageName').value === 'TABULADOR MEDICO'
    );

    // Iteramos sobre cada cobertura en `specialCoverageList`
    this.specialCoverageList.forEach((specialCoverage) => {
      const specialCoverageGroup = this.fb.group({
        idFlexPlanCoverages: [null],
        flexPlanPlanesId: [null],
        coverageSurexsId: [specialCoverage.idCoverage],
        origen: [1],
        coverageName: [specialCoverage.coverageName],
        value: [null, Validators.required],
        unitMeasurementId: [null, Validators.required],
        endoso: [false],
        flexible: [false],
        factorQuote: [null],
        creationDate: [null],
        updateDate: [null],
        creationUserId: [null],
        updateUserId: [null],
        optionsValues: specialCoverage.optionsValues && specialCoverage.optionsValues.length > 0
          ? this.fb.array(specialCoverage.optionsValues.map(option => this.fb.group({
            value: [option.value],
            unitMeasurementId: [option.unitMeasurementId],
            defaultValue: [option.defaultValue],
            openValue: [option.openValue],
            order: [option.order],
            unitMeasurement: [option.unitMeasurement]
          }))) : this.fb.array([])
      });

      // Si la cobertura tiene endorsements y es 2 o 6, la deshabilitamos
      if ((specialCoverage.idCoverage === 2 || specialCoverage.idCoverage === 6) && this.hasEndorsementsInAnyPlan()) {
        specialCoverageGroup.disable();
      }

      // Solo si `hasEndorsementsInAnyPlan()` es verdadero, asignamos los valores a la cobertura correspondiente
      if (this.hasEndorsementsInAnyPlan()) {
        // Asignar `value` y `unitMeasurementId` a la cobertura "DEDUCIBLE" si corresponde
        if (deductibleCoverage && specialCoverage.coverageName === 'DEDUCIBLE') {
          specialCoverageGroup.patchValue({
            value: deductibleCoverage.get('value').value,
            unitMeasurementId: deductibleCoverage.get('unitMeasurementId').value
          });
        }
        // Asignar `value` y `unitMeasurementId` a la cobertura "TABULADOR" si corresponde
        if (tabulatorCoverage && specialCoverage.coverageName === 'TABULADOR') {
          specialCoverageGroup.patchValue({
            value: tabulatorCoverage.get('value').value,
            unitMeasurementId: tabulatorCoverage.get('unitMeasurementId').value
          });
        }
      }

      coverages.push(specialCoverageGroup);
    });

    return coverages;
  }


  createPlanFlex(): void {
    // Obtenemos el FormArray que contiene todos los planes
    const planPlanesModels = this.planFlexForm.get('planPlanesModels') as FormArray;

    // Añadimos el nuevo plan al FormArray
    planPlanesModels.push(this.createPlan(true, ''));
  }

  savePlanExcess(): void {
    this.spinner.show('sp');

    const formCopy = {
      ...this.planFlexForm.getRawValue(), // Usamos getRawValue para incluir valores deshabilitados
      planPlanesModels: this.planFlexForm.getRawValue().planPlanesModels.map(plan => ({
        ...plan,
        flexPlanCoveragesModels: plan.flexPlanCoveragesModels.map(coverage => {
          // Eliminamos el campo optionsValues
          const { optionsValues, ...coverageWithoutOptions } = coverage;
          return coverageWithoutOptions;
        })
      }))
    };

    if (!this.planFlexForm.valid) {
      this.planFlexForm.markAllAsTouched();
      this.spinner.hide('sp');
      return;
    }

// Aquí puedes enviar formCopy al backend
    console.log('Form ready for backend:', formCopy);


    if (!this.isEdition) {
      this.api.savePlanExcess(formCopy)
        .then(
          (response: any) => {
            this.planFlexForm.reset();
            this.populateFormWithResponseData(response);
            this.spinner.hide('sp');
            this.toastr.success('SU SOLICITUD SE HA REALIZADO DE FORMA EXITOSA', 'NOTIFICACIÓN');

          }, error => {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            this.spinner.hide('sp');
          }
        );
    } else {
      this.api.editPlanById(formCopy)
        .then(
          (response: any) => {
            this.planFlexForm.reset();
            this.populateFormWithResponseData(response, false);
            this.spinner.hide('sp');
            this.toastr.success('SU SOLICITUD SE HA REALIZADO DE FORMA EXITOSA', 'NOTIFICACIÓN');

          }, error => {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            this.spinner.hide('sp');
          }
        );
    }

  }

  getEndorsementLabel(index: number): string {
    const labels = ['SUMA ASEGURADA', 'DEDUCIBLE', 'COASEGURO', 'PERIODO DE ESPERA'];
    return labels[index] || 'Valor';
  }

  getPlanByIdPlanFlex(): void {
    this.spinner.show('sp');
    this.api.getPlanById(this.idPlanFlex)
      .then(
        (response: any) => {

          if ( response.planPlanesModels.length > 0 ) {
            this.isEdition = true;
          } else {
            this.isEdition = false;
          }

          this.populateFormWithResponseData(response, false);

          for (const plan of response.planPlanesModels) {
            if (plan.status === 3) {
              this.planAddCar = 1;
              return;
            }
          }

          switch (response.productPlanFlexConfiguration) {
            case 2:
              this.messageProduct = 'YA SE HA GENERADO SLIP, SI REALIZA CAMBIOS DEBE DE GENERARLO DE NUEVO PARA SU COTIZACIÓN';
              this.modalRef = this.modalService.show(this.myTemplate, {
                class: 'modal-md',
                keyboard: false,
                backdrop: 'static'
              });
              break;
            case 3:
              if (this.planAddCar === 0) {
                // tslint:disable-next-line:max-line-length
                this.messageProduct = 'YA SE ENCUENTRAN CONFIGURADAS LAS TARIFAS SI USTED HACE UN CAMBIO SE BORRARAN Y TENDRÁ QUE GENERAR SLIP PARA COTIZACIÓN Y VOLVER A INGRESAR TARIFAS';
              } else {
                // tslint:disable-next-line:max-line-length
                this.messageProduct = 'YA NO SE PUEDEN REALIZAR CAMBIOS, UN PLAN YA SE ENCUENTRA EN PROCESO DE COMPRA';
              }
              this.modalRef = this.modalService.show(this.myTemplate, {
                class: 'modal-md',
                keyboard: false,
                backdrop: 'static'
              });
              break;
          }

          if (this.isEdition) {

          }

          this.spinner.hide('sp');
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }

  populateFormWithResponseData(response: any, planBaseFromGMM?: boolean): void {

    this.planFlexForm.patchValue({
      productPlanFlexConfiguration: response.productPlanFlexConfiguration
    });


    // Limpiar el FormArray antes de agregar nuevos planes
    const planPlanesModelsArray = this.planFlexForm.get('planPlanesModels') as FormArray;
    planPlanesModelsArray.clear();

    // Iterar sobre los planes y agregarlos al FormArray
    response.planPlanesModels.forEach((plan: any, index: number) => {
      const planFormGroup = this.createPlan(plan.flexiblePlan, index + 1);

      // Asignar valores al plan
      planFormGroup.patchValue({
        idFlexPlanPlanes: plan.idFlexPlanPlanes,
        flexPlanCategoriesId: plan.flexPlanCategoriesId,
        planName: plan.planName,
        flexiblePlan: plan.flexiblePlan,
        quote: plan.quote,
        premiumIndividual: plan.premiumIndividual,
        averagePremium: plan.averagePremium,
        netPremiumIndividual: plan.netPremiumIndividual,
        netPremiumGroup: plan.netPremiumGroup,
        policyRights: plan.policyRights,
        surcharges: plan.surcharges,
        creationDate: plan.creationDate,
        updateDate: plan.updateDate,
        creationUserId: plan.creationUserId,
        updateUserId: plan.updateUserId,
      });

      // Asignar valores a la categoría del plan
      planFormGroup.get('flexPlanCategoriesModel').patchValue({
        idFlexPlanCategories: plan.flexPlanCategoriesModel.idFlexPlanCategories,
        categoryName: plan.flexPlanCategoriesModel.categoryName,
        planFlexId: plan.flexPlanCategoriesModel.planFlexId,
        flexibleProductId: plan.flexPlanCategoriesModel.flexibleProductId,
        creationDate: plan.flexPlanCategoriesModel.creationDate,
        updateDate: plan.flexPlanCategoriesModel.updateDate,
        creationUserId: plan.flexPlanCategoriesModel.creationUserId,
        updateUserId: plan.flexPlanCategoriesModel.updateUserId
      });

      // Asignar coberturas al plan
      const flexPlanCoveragesModelsArray = planFormGroup.get('flexPlanCoveragesModels') as FormArray;
      plan.flexPlanCoveragesModels.forEach((coverage: any) => {
        // Validar que existan endorsements antes de procesarlos
        let endorsementsArray: FormArray = new FormArray([]);
        if (coverage.endorsements && coverage.endorsements.length > 0) {
          endorsementsArray = this.fb.array(coverage.endorsements.map((endorsement: any) => this.fb.group({
            idFlexPlanEndorsement: endorsement.idFlexPlanEndorsement,
            flexPlanCoverageId: endorsement.flexPlanCoverageId,
            coverageName: endorsement.coverageName,
            value: [endorsement.value, Validators.required],
            unitMeasurementId: [endorsement.unitMeasurementId, Validators.required],
            creationDate: endorsement.creationDate,
            updateDate: endorsement.updateDate,
            creationUserId: endorsement.creationUserId,
            updateUserId: endorsement.updateUserId
          })));
        }

        const coverageGroup = this.fb.group({
          idFlexPlanCoverages: coverage.idFlexPlanCoverages,
          flexPlanPlanesId: coverage.flexPlanPlanesId,
          coverageSurexsId: coverage.coverageSurexsId,
          origen: coverage.origen,
          coverageName: coverage.coverageName,
          value: [coverage.value, Validators.required],
          unitMeasurementId: [coverage.unitMeasurementId, Validators.required],
          endoso: coverage.endoso,
          flexible: coverage.flexible,
          factorQuote: coverage.factorQuote,
          creationDate: coverage.creationDate,
          updateDate: coverage.updateDate,
          creationUserId: coverage.creationUserId,
          updateUserId: coverage.updateUserId,
          endorsements: endorsementsArray
        });

        // TODO QUITAR PORQUE TAMBIÉN LO PUEDE USAR PLANES BASE
        if (this.isEdition && plan.flexiblePlan) {
          const specialCoverage = this.specialCoverageList.find(sc => sc.coverageName === coverage.coverageName);

          // Si existe una coincidencia, agregar optionsValues si están definidos
          if (specialCoverage && specialCoverage.optionsValues && specialCoverage.optionsValues.length > 0) {
            const optionsValuesArray = this.fb.array(specialCoverage.optionsValues.map(option => this.fb.group({
              value: [option.value],
              unitMeasurementId: [option.unitMeasurementId],
              defaultValue: [option.defaultValue],
              openValue: [option.openValue],
              order: [option.order],
              unitMeasurement: [option.unitMeasurement]
            })));
            coverageGroup.addControl('optionsValues', optionsValuesArray);
          }
        }

        // Agregar cobertura al FormArray
        flexPlanCoveragesModelsArray.push(coverageGroup);

        // Deshabilitar cobertura si tiene endorsements y el plan es flexible
        if (!plan.flexiblePlan && endorsementsArray.length > 0) {
          coverageGroup.disable();
        }
      });

      // Deshabilitar el plan completo si es flexible y tiene al menos una cobertura con endorsements
      if (!plan.flexiblePlan && flexPlanCoveragesModelsArray.controls.some((coverage: FormGroup) => coverage.get('endorsements').value.length > 0)) {
        planFormGroup.disable();
      }

      // Agregar plan al FormArray de planes
      planPlanesModelsArray.push(planFormGroup);
    });

    console.log('Así se llenó' + this.planFlexForm.value);
  }


  addCoverage(indexPlan: number, flexPlanCoverages: FormGroup): void {
    const plan = this.getPlanAtIndex(indexPlan);
    console.log('el index ', indexPlan);
    console.log('el objeto ', flexPlanCoverages);
    const flexPlanCoveragesModels = plan.get('flexPlanCoveragesModels') as FormArray;

    flexPlanCoveragesModels.push(this.fb.group({
      idFlexPlanCoverages: null,
      flexPlanPlanesId: null,
      coverageSurexsId: flexPlanCoverages.get('coverageSurexsId').value,
      origen: 1,
      coverageName: flexPlanCoverages.get('coverageName').value,
      value: [null, Validators.required],
      unitMeasurementId: [null, Validators.required],
      endoso: false,
      flexible: true,
      factorQuote: null,
      creationDate: null,
      updateDate: null,
      creationUserId: null,
      updateUserId: null,
      endorsements: this.fb.array([])
    }));

  }

  getSlip(): void {
    this.spinner.show('sp');
    this.api.getSlipExcess(this.idPlanFlex)
      .then(
        (response: any) => {
          const base64Data = response.slip;
          const binaryData = atob(base64Data);  // Decodificar Base64 a binario
          const arrayBuffer = new ArrayBuffer(binaryData.length);
          const uintArray = new Uint8Array(arrayBuffer);

          for (let i = 0; i < binaryData.length; i++) {
            uintArray[i] = binaryData.charCodeAt(i);
          }

          const blob = new Blob([uintArray], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(blob);
          downloadLink.setAttribute('download', 'SurexsSlip.xlsx');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
          this.spinner.hide('sp');
        }, (error) => {
          this.spinner.hide('sp');
        });

  }

  getFilteredCoverages(plan: FormGroup): any[] {
    const coverages = plan.get('flexPlanCoveragesModels') as FormArray;
    return coverages.controls.filter(cov =>
      !cov.get('endoso').value && !cov.get('flexible').value
    );
  }

  get categoriesForDropdown(): Array<{ idFlexPlanCategories: number, categoryName: string }> {
    return this.getCategoriesForDropdown(this.planFlexForm.get('planPlanesModels') as FormArray);
  }

  getCategoriesForDropdown(plansArray: FormArray): Array<{ idFlexPlanCategories: number, categoryName: string }> {
    // Filtra los planes con flexiblePlan === false
    let filteredPlans = plansArray.controls
      .filter((planGroup: FormGroup) => {
        const flexiblePlan = planGroup.get('flexiblePlan').value === false;
        const category = planGroup.get('flexPlanCategoriesModel').value;
        const idDefined = category.idFlexPlanCategories !== null && category.idFlexPlanCategories !== undefined;
        return flexiblePlan && idDefined;
      });

    // Si no se encuentran planes con flexiblePlan === false, filtra los planes con flexiblePlan === true
    if (filteredPlans.length === 0) {
      filteredPlans = plansArray.controls
        .filter((planGroup: FormGroup) => {
          const flexiblePlan = planGroup.get('flexiblePlan').value === true;
          const category = planGroup.get('flexPlanCategoriesModel').value;
          const idDefined = category.idFlexPlanCategories !== null && category.idFlexPlanCategories !== undefined;
          return flexiblePlan && idDefined;
        });
    }

    // Mapea los planes filtrados para obtener el array con idFlexPlanCategories y categoryName
    return filteredPlans.map((planGroup: FormGroup) => {
      const category = planGroup.get('flexPlanCategoriesModel').value;
      return {
        idFlexPlanCategories: category.idFlexPlanCategories,
        categoryName: category.categoryName
      };
    });
  }


  hasInvalidPlans(): boolean {
    return (this.planFlexForm.get('planPlanesModels') as FormArray).controls.some((planGroup: FormGroup) => {
      const idFlexPlanPlanes = planGroup.get('idFlexPlanPlanes').value;
      const flexiblePlan = planGroup.get('flexiblePlan').value;
      return  (flexiblePlan === false && (idFlexPlanPlanes === null || idFlexPlanPlanes === undefined || idFlexPlanPlanes === ''));
    });
  }

  validateCategory(event, index: number) {
    const selectedCategoryId = +event.target.value; // Convertimos el valor a número
    const plansArray = this.planFlexForm.get('planPlanesModels') as FormArray;

    // Busca la categoría seleccionada en la lista de categorías disponibles
    const selectedCategory = this.categoriesForDropdown.find(category => category.idFlexPlanCategories === selectedCategoryId);

    if (selectedCategory) {
      // Obtén el FormGroup del plan correspondiente según el índice
      const planGroup = plansArray.at(index) as FormGroup;

      // Actualiza los valores en el FormGroup del plan correspondiente
      planGroup.get('flexPlanCategoriesModel').patchValue({
        idFlexPlanCategories: selectedCategory.idFlexPlanCategories,
        categoryName: selectedCategory.categoryName,
        planFlexId: this.idPlanFlex,
        flexibleProductId: 2
      });

      // Si necesitas también actualizar el ID de la categoría en el nivel superior
      planGroup.get('flexPlanCategoriesId').setValue(selectedCategory.idFlexPlanCategories);
    }
  }

  onOptionValueChange(event: any, coverage: AbstractControl, coverageIndex: number, templateCoverage: TemplateRef<any>) {
    const selectedValue = event.target.value;

    // Encontrar la opción seleccionada
    const selectedOption = coverage.get('optionsValues').value.find((option: any) => option.value === selectedValue);

    if (selectedOption) {
      this.selectedOption = selectedOption;
      coverage.get('unitMeasurementId').setValue(Number(selectedOption.unitMeasurementId));
    }

    // Si la opción tiene openValue en true, abrir el modal
    if (selectedOption.openValue === true) {
      // Asignar las variables de índice y cobertura actual
      this.currentCoverageIndex = coverageIndex;
      this.currentCoverage = coverage;

      // Abrir el modal
      this.openValueModal(templateCoverage);
    }
  }

  // Método para abrir el modal
  openValueModal(templateCoverage: TemplateRef<any>) {
    this.modalRef = this.modalService.show(templateCoverage, { initialState: { title: 'Ingrese un valor personalizado' }, class: 'modal-sm' });

  }

  // Método para agregar el valor personalizado al select
  addManualValueToSelect(coverageIndex: number, coverage: AbstractControl) {
    const currentOptions = coverage.get('optionsValues').value;
    const newOption = { value: `GUA + ${this.manualValue} + %`, openValue: false };
    currentOptions.push(newOption);
    this.optionsValuesMap[coverageIndex] = currentOptions;
    coverage.get('value').setValue(newOption.value);
  }

  // Método para cerrar el modal
  onCloseModal(): void {
    this.modalRef.hide();
  }

  // Método para guardar el valor y cerrar
  onSave(coverageIndex: number, coverage: AbstractControl): void {
    this.addManualValueToSelect(coverageIndex, coverage); // Agregar el valor ingresado
    this.modalRef.hide(); // Cerrar el modal
  }

  onContinueLoadPlanBase(): void {
      this.spinner.show('sp');
      this.api.getPlansBaseGMMForPlanFlexId(this.idPlanFlex)
        .then(
          (response: any) => {
            this.populateFormWithResponseData(response, true);

            this.spinner.hide('sp');
          }, error => {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            this.spinner.hide('sp');
          }
        );
    this.modalRef.hide(); // Cerrar el modal
  }

  hasEndorsementsInAnyPlan(): boolean {
    const planPlanesModels = this.planFlexForm.get('planPlanesModels') as FormArray;

    return planPlanesModels.controls.some((planGroup: FormGroup) => {
      const flexPlanCoveragesModelsArray = planGroup.get('flexPlanCoveragesModels') as FormArray;

      return flexPlanCoveragesModelsArray.controls.some((coverageGroup: FormGroup) => {
        const endorsementsArray = coverageGroup.get('endorsements') as FormArray;
        return endorsementsArray.length > 0;
      });
    });
  }

  hasEndorsementsThisPlan(planIndex: number): boolean {
    // Obtenemos el FormArray que contiene todos los planes
    const planPlanesModels = this.planFlexForm.get('planPlanesModels') as FormArray;

    // Obtenemos el plan en el índice especificado
    const planToCheck = planPlanesModels.at(planIndex) as FormGroup;

    // Obtenemos el FormArray de coberturas dentro del plan especificado
    const flexPlanCoveragesModelsArray = planToCheck.get('flexPlanCoveragesModels') as FormArray;

    // Verificamos si alguna de las coberturas dentro de este plan tiene endorsements
    return flexPlanCoveragesModelsArray.controls.some((coverageGroup: FormGroup) => {
      const endorsementsArray = coverageGroup.get('endorsements') as FormArray;
      return endorsementsArray.length > 0;
    });
  }

}
