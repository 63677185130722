import { BrowserModule } from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {HomeComponent} from './home/home.component';
import {LoginComponent} from './login/login.component';
import {RecoveryComponent} from './recovery/recovery.component';
import {MainPanelComponent} from './main-panel/main-panel.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NavbarComponent} from './navbar/navbar.component';
import {SidebarComponent} from './sidebar/sidebar.component';

import {ProfileComponent} from './profile/profile.component';
import {ChatComponent} from './chat/chat.component';
import {PushNotificationsModule} from 'ng-push';
import {ValidateCodeMdlComponent} from './validate-code-mdl/validate-code-mdl.component';
import {RecoveryPasswordMdlComponent} from './recovery-password-mdl/recovery-password-mdl.component';
import {NotificationMdlComponent} from './notification-mdl/notification-mdl.component';
import {ApiService} from './api.service';
import {QuoteService} from './quote.service';
import {WebsocketService} from './websocket.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {RequestOptionsInterceptor} from './request-options.interceptor';
import {ResponseOptionsInterceptor} from './response-options.interceptor';
import {QuoteDetailComponent} from './quote-detail/quote-detail.component';
import {QuoteFormComponent} from './quote-form/quote-form.component';
import {EmissionFormComponent} from './emission-form/emission-form.component';
import {QuoteHistoryComponent} from './quote-history/quote-history.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {ToastrModule} from 'ngx-toastr';
import {DatePipe, registerLocaleData} from '@angular/common';
import localeMx from '@angular/common/locales/es-MX';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CustomCurrencyPipe} from './custom.currencypipe';
import {EmissionDetailComponent} from './emission-detail/emission-detail.component';
import {PolicyComponent} from './policy/policy.component';
import {NgxUsefulSwiperModule} from 'ngx-useful-swiper';
import {NgxCurrencyModule} from 'ngx-currency';
import { InvoiceValidatorComponent } from './invoice-validator/invoice-validator.component';
import { MenuComponent } from './menu/menu.component';
import { AdminInsuredComponent } from './admin-insured/admin-insured.component';
import { InsuredComponent } from './insured/insured.component';
import { IncidentComponent } from './incident/incident.component';
import { RequestComponent } from './request/request.component';
import { HomeExecutiveComponent } from './home-executive/home-executive.component';
import { NewAddressComponent } from './new-address/new-address.component';
import { NewContactComponent } from './new-contact/new-contact.component';
import { InsuranceCompanyComponent } from './insurance-company/insurance-company.component';
import { EventComponent } from './event/event.component';
import {NgbModule, NgbRating, NgbRatingModule, NgbTimepickerModule} from '@ng-bootstrap/ng-bootstrap';


import {AdminReportsComponent} from './admin-reports/admin-reports.component';
import {AdminPoliciesComponent} from './admin-policies/admin-policies.component';
import { AdminCatalogComponent } from './admin-catalog/admin-catalog.component';
import { ModalIssueComponent } from './modal-issue/modal-issue.component';
import { AttachmentMdlComponent } from './attachment-mdl/attachment-mdl.component';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import { AdminReceiptsComponent } from './admin-receipts/admin-receipts.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import { AdminPoliciesReportsComponent } from './admin-policies-reports/admin-policies-reports.component';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { EventDetailMdlComponent } from './event-detail-mdl/event-detail-mdl.component';
import { AttachmentPoliciesMdlComponent } from './attachment-policies-mdl/attachment-policies-mdl.component';
import { AttachmetDamagesMdlComponent } from './attachmet-damages-mdl/attachmet-damages-mdl.component';
import { AttachmentRequestMdlComponent } from './attachment-request-mdl/attachment-request-mdl.component';
import { ViewPoliciesDamagesComponent } from './view-policies-damages/view-policies-damages.component';
import { AttachmetSinisterMdlComponent } from './attachmet-sinister-mdl/attachmet-sinister-mdl.component';
import { ConfigurationDocumentsComponent } from './configuration-documents/configuration-documents.component';
import { AdminClientComponent } from './admin-client/admin-client.component';
import { AdminRequestComponent } from './admin-request/admin-request.component';

import { RequestMdlComponent } from './request-mdl/request-mdl.component';
import { AdminNewClientComponent } from './admin-new-client/admin-new-client.component';
import { NgWizardModule, NgWizardConfig, THEME } from 'ng-wizard';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { TestComponent } from './test/test.component';
import { AdminInsuranceCompanyComponent } from './admin-insurance-company/admin-insurance-company.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ɵs } from '@ng-select/ng-select';
import { UploadFileComponent } from './upload-file/upload-file.component';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { ImageViewerModule } from 'ng2-image-viewer';
import { AttachmentNamePipe } from './insurance-company/attachment-name.pipe';
import {ValidatorComponent} from '../common/control-validator/control-validator.component';
import {CustomMaxDirective} from '../common/control-validator/custom-max-validator.directive';
import {CustomMinDirective} from '../common/control-validator/custom-min-validator.directive';
import {AuthGuard} from './login/services/auth-guard.service';
import {AuthService} from './login/services/auth.service';
import { AdminUsersComponent } from './admin-users/admin-users.component';
import { AdminUsersDetailComponent } from './admin-users-detail/admin-users-detail.component';
import { UploadCertificateComponent } from './upload-certificate/upload-certificate.component';
import { AdminRoleComponent } from './admin-role/admin-role.component';
import { AdminRoleDetailComponent } from './admin-role-detail/admin-role-detail.component';
import { AdminSpecialCoverageComponent } from './admin-special-coverage/admin-special-coverage.component';
import {AngularDualListBoxModule} from 'angular-dual-listbox';
import { DaysNamePipe } from './insurance-company/days-name.pipe';
import { AdminIncidentComponent } from './admin-incident/admin-incident.component';
import { IncidentMasiveLoadComponent } from './incident-masive-load/incident-masive-load.component';
import { IncidentMdlComponent } from './incident-mdl/incident-mdl.component';
import { UploadFileIncidentComponent } from './upload-file-incident/upload-file-incident.component';
import {AngularEditorModule} from '@kolkov/angular-editor';
import { AdminReportCoreComponent } from './admin-report-core/admin-report-core.component';
import { AdminReportProvisionComponent } from './admin-report-provision/admin-report-provision.component';
import { AdminReportProduccionComponent } from './admin-report-produccion/admin-report-produccion.component';

import { IncidentChartComponent } from './incident-chart/incident-chart.component';
import { AdminReportSiniestrosComponent } from './admin-report-siniestros/admin-report-siniestros.component';
import { RenovationsComponent } from './renovations/renovations.component';
import { CollectionChartComponent } from './collection-chart/collection-chart.component';
import { GraphAmountComponent } from './graph-amount/graph-amount.component';
import { GraphIngresoComponent } from './graph-ingreso/graph-ingreso.component';
import { GraphSubramoComponent } from './graph-subramo/graph-subramo.component';
import { GraphCobranzaComponent } from './graph-cobranza/graph-cobranza.component';
import { GraphUsepolicyComponent } from './graph-usepolicy/graph-usepolicy.component';
import { GraphIncidentsComponent } from './graph-incidents/graph-incidents.component';
import { CommonsReportProductionComponent } from './commons-report-production/commons-report-production.component';
import { CommonsReportIncidentComponent } from './commons-report-incident/commons-report-incident.component';
import { BasicCoverageComponent } from './basic-coverage/basic-coverage.component';
import { DisableControlDirective } from './disable-control.directive';
import { UpperCaseTextDirective } from './upper-case-text.directive';
import {ChartsModule, ThemeService} from 'ng2-charts';
import { PolicyLiteComponent } from './policy-lite/policy-lite.component';
import { GraphNetPremiumVsDevengComponent } from './graph-net-premium-vs-deveng/graph-net-premium-vs-deveng.component';
import { GraphUserStatusComponent } from './graph-user-status/graph-user-status.component';
import { AdminPaymentOrdersComponent } from './admin-payment-orders/admin-payment-orders.component';
import { CatalogUserClientComponent } from './catalog-user-client/catalog-user-client.component';
import { CoverageCarPolicyComponent } from './coverage-car-policy/coverage-car-policy.component';
import { CoverageCarPolicyFlotillaComponent } from './coverage-car-policy-flotilla/coverage-car-policy-flotilla.component';
import { AdminMessengerComponent } from './admin-messenger/admin-messenger.component';
import { DetailMessagingComponent } from './detail-messaging/detail-messaging.component';
import { AdminCarCoverageComponent } from './admin-car-coverage/admin-car-coverage.component';
import { AdminPackageCoverageComponent } from './admin-package-coverage/admin-package-coverage.component';
import {TreeDraggedElement, TreeModule} from '@circlon/angular-tree-component';
import { CobranzaCarPolicyComponent } from './cobranza-car-policy/cobranza-car-policy.component';
import {DamagePolicyComponent} from './damage-policy/damage-policy.component';
import {UploadFileDamageComponent} from './upload-file-damage/upload-file-damage.component';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {BsDatepickerModule, BsLocaleService} from 'ngx-bootstrap/datepicker';
import {defineLocale, esLocale} from 'ngx-bootstrap/chronos';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ModalModule} from 'ngx-bootstrap/modal';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { InputNumberDirective } from './input-number.directive';
import { InputAlphaNumericDirective } from './input-alphanumeric.directive';
import { MassiveLoadPolicyComponent} from './massive-load-policy/massive-load-policy.component';




import { CarEventDetailComponent } from './car-event-detail/car-event-detail.component';
import { ModalCarAttcComponent } from './modal-car-attc/modal-car-attc.component';
import {AccordionModule} from 'ngx-bootstrap/accordion';
import {PopoverModule} from 'ngx-bootstrap/popover';
import { AttachmentFlotillaMdlComponent } from './attachment-flotilla-mdl/attachment-flotilla-mdl.component';
import { AdminNewRequestComponent } from './admin-new-request/admin-new-request.component';
import { LocationDamageHousingComponent } from './location-damage-housing/location-damage-housing.component';
import { LocationDamageHousingEditComponent } from './location-damage-housing-edit/location-damage-housing-edit.component';
import { RequestEventDetailComponent } from './request-event-detail/request-event-detail.component';
import {RequestTempDetailComponent} from './request-temp-detail/request-temp-detail.component';
import { AdminEndorsementComponent } from './admin-endorsement/admin-endorsement.component';
import { AdminEndorsementNewComponent } from './admin-endorsement-new/admin-endorsement-new.component';
import { AdminEndorsementEditComponent } from './admin-endorsement-edit/admin-endorsement-edit.component';
import { AdminCatalogReportComponent } from './admin-catalog-report/admin-catalog-report.component';
import { InsuranceCompanyPaymentComponent } from './insurance-company-payment/insurance-company-payment.component';
import { InsuranceCompanyMedicalCareComponent } from './insurance-company-medical-care/insurance-company-medical-care.component';
import { PaymentNotificationsComponent } from './payment-notifications/payment-notifications.component';
import { CarIndivMassiveDetailComponent } from './car-indiv-massive-detail/car-indiv-massive-detail.component';
import { DinamicReportsComponent } from './dinamic-reports/dinamic-reports.component';
import { DinamicReportsNewComponent } from './dinamic-reports-new/dinamic-reports-new.component';
import { EnvioProgramadoComponent } from './dialogs/envio-programado/envio-programado.component';
import { VerReporteComponent } from './dialogs/ver/ver.component';
import { ConfirmComponent } from './dialogs/confirm/confirm.component';
import { SaveDynamicReportComponent } from './dinamic-reports-new/save/save.component';

import { NgxDateRangePickerModule } from 'ngx-daterangepicker';
import { AdminProyectionRenovationComponent } from './admin-proyection-renovation/admin-proyection-renovation.component';
import { AdminPolicySimplifyComponent } from './admin-policy-simplify/admin-policy-simplify.component';
import { AdminPolicyPrincipalComponent } from './admin-policy-principal/admin-policy-principal.component';
import { EndorsementComponent } from './endorsement/endorsement.component';
import { EndorsementModalComponent } from './endorsement-modal/endorsement-modal.component';
import { RecargaDirective } from './directives/recarga.directive';
import { CancellMassivePolicyEventComponent } from './cancell-massive-policy-event/cancell-massive-policy-event.component';
import { InsuredUpdatedEventComponent } from './insured-updated-event/insured-updated-event.component';
import { AdminPlacementQuotationDetailComponent } from './admin-placement-quotation-detail/admin-placement-quotation-detail.component';
import { AdminPlacementQuotationListComponent } from './admin-placement-quotation-list/admin-placement-quotation-list.component';
import { ModalNonRenewableComponent } from './modal-non-renewable/modal-non-renewable.component';
import { ViewDocumentComponent } from './view-document/view-document.component';

import {DndModule} from 'ngx-drag-drop';
import {AdminPoliciesPaymentlinkComponent} from './admin-policies/admin-policies-paymentlink.component';
import { LoadPolicyAutomationListComponent } from './load-policy-automation-list/load-policy-automation-list.component';
import { LoadPolicyAutomationDetailComponent } from './load-policy-automation-detail/load-policy-automation-detail.component';
import {GroupEndorsementsByCoverageIdPipe} from './load-policy-automation-detail/group-endorsements-by-coverage-id.pipe';
import {GroupReceiptBySubgroupIdPipe} from './load-policy-automation-detail/group-receipt-by-subgroup-id.pipe';
import { AdminPlansComponent } from './admin-plans/admin-plans.component';
import { PlansDetalleComponent } from './plans-detalle/plans-detalle.component';
import { PlansCrearComponent } from './plans-crear/plans-crear.component';
import { PlansCargaPoblacionComponent } from './plans-carga-poblacion/plans-carga-poblacion.component';
import { PlansPeriodoPruebaComponent } from './plans-periodo-prueba/plans-periodo-prueba.component';
import { GastosMedicosMenoresComponent } from './gastos-medicos-menores/gastos-medicos-menores.component';
import { FlexGmmViewComponent } from './flex-gmm-view/flex-gmm-view.component';
import { FlexDentalViewComponent } from './flex-dental-view/flex-dental-view.component';
import { FlexDentalP2ViewComponent } from './flex-dental-p2-view/flex-dental-p2-view.component';
import { FlexTypePoblationComponent } from './utils/flex-type-poblation/flex-type-poblation.component';
import { FlexLifeViewComponent } from './flex-life-view/flex-life-view.component';
import { FlexLifeQuoteViewComponent } from './flex-life-quote-view/flex-life-quote-view.component';
import { FlexGmmP2ViewComponent } from './flex-gmm-p2-view/flex-gmm-p2-view.component';
import { GastosMedicosMenoresP2Component } from './gastos-medicos-menores-p2/gastos-medicos-menores-p2.component';
import { MascotasComponent } from './mascotas/mascotas.component';
import { MascotasP2Component } from './mascotas-p2/mascotas-p2.component';
import { FlexVisionP1ViewComponent } from './flex-vision-p1-view/flex-vision-p1-view.component';
import { FlexVisionP2ViewComponent } from './flex-vision-p2-view/flex-vision-p2-view.component';
import { FlexSpousalFileViewComponent } from './flex-spousal-file-view/flex-spousal-file-view.component';
import { FlexSpousalLifeP2ViewComponent } from './flex-spousal-life-p2-view/flex-spousal-life-p2-view.component';
// import { AutosComponent } from './autos/autos.component';
import { FlexSoldPoliciesComponent } from './flex-sold-policies/flex-sold-policies.component';
import { FlexExcessViewComponent } from './flex-excess-view/flex-excess-view.component';
import { FlexExcessP2ViewComponent } from './flex-excess-p2-view/flex-excess-p2-view.component';
import { PlacementPorposalComponent } from './placement-porposal/placement-porposal.component';

const ngWizardConfig: NgWizardConfig = {
  theme: THEME.default
};
// prod
// const config: SocketIoConfig = { url: 'https://services.ammia.io', options: {path: '/socket.io' } };
// const config: SocketIoConfig = { url: 'https://services.ammia.io', options: {path: '/socket.io/gfhk' } };

// qa
const config: SocketIoConfig = { url: 'https://qa.services.ammia.io', options: {path: '/socket.io' } };
// const config: SocketIoConfig = { url: 'https://qa.services.ammia.io', options: {path: '/socket.io/gfhk' } };

registerLocaleData(localeMx, 'es-MX');
@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        RecoveryComponent,
        RecoveryPasswordMdlComponent,
        ValidateCodeMdlComponent,
        MainPanelComponent,
        NavbarComponent,
        SidebarComponent,
        HomeComponent,
        NotificationMdlComponent,
        ProfileComponent,
        ChatComponent,
        QuoteFormComponent,
        QuoteDetailComponent,
        EmissionDetailComponent,
        QuoteHistoryComponent,
        EmissionFormComponent,
        CustomCurrencyPipe,
        PolicyComponent,
        InvoiceValidatorComponent,
        MenuComponent,
        AdminInsuredComponent,
        InsuredComponent,
        IncidentComponent,
        RequestComponent,
        HomeExecutiveComponent,
        NewAddressComponent,
        NewContactComponent,
        InsuranceCompanyComponent,
        EventComponent,
        AdminReportsComponent,
        AdminPoliciesComponent,
        AdminCatalogComponent,
        ModalIssueComponent,
        AttachmentMdlComponent,
        AdminReceiptsComponent,
        AdminPoliciesReportsComponent,
        EventDetailMdlComponent,
        AttachmentPoliciesMdlComponent,
        AttachmetDamagesMdlComponent,
        AttachmentRequestMdlComponent,
        ViewPoliciesDamagesComponent,
        AttachmetSinisterMdlComponent,
        ConfigurationDocumentsComponent,
        AdminClientComponent,
        AdminRequestComponent,
        RequestMdlComponent,
        AdminNewClientComponent,
        TestComponent,
        AdminInsuranceCompanyComponent,
        UploadFileComponent,
        ValidatorComponent,
        CustomMaxDirective,
        CustomMinDirective,
        AttachmentNamePipe,
        AdminUsersComponent,
        AdminUsersDetailComponent,
        UploadCertificateComponent,
        AdminRoleComponent,
        AdminRoleDetailComponent,
        AdminSpecialCoverageComponent,
        DaysNamePipe,
        AdminIncidentComponent,
        IncidentMdlComponent,
        UploadFileIncidentComponent,
        UploadFileIncidentComponent,
        AdminReportCoreComponent,
        AdminReportProvisionComponent,
        AdminReportProduccionComponent,
        IncidentChartComponent,
        AdminReportSiniestrosComponent,
        RenovationsComponent,
        CollectionChartComponent,
        GraphAmountComponent,
        GraphIngresoComponent,
        GraphSubramoComponent,
        GraphCobranzaComponent,
        GraphUsepolicyComponent,
        GraphIncidentsComponent,
        CommonsReportProductionComponent,
        CommonsReportIncidentComponent,
        BasicCoverageComponent,
        DisableControlDirective,
        UpperCaseTextDirective,
        PolicyLiteComponent,
        GraphNetPremiumVsDevengComponent,
        GraphUserStatusComponent,
        AdminPaymentOrdersComponent,
        CatalogUserClientComponent,
        AdminMessengerComponent,
        DetailMessagingComponent,
        CoverageCarPolicyComponent,
        CoverageCarPolicyFlotillaComponent,
        AdminCarCoverageComponent,
        AdminPackageCoverageComponent,
        CobranzaCarPolicyComponent,
        DamagePolicyComponent,
        UploadFileDamageComponent,
        InputNumberDirective,
        InputAlphaNumericDirective,
        CarEventDetailComponent,
        ModalCarAttcComponent,
        AttachmentFlotillaMdlComponent,
        AdminNewRequestComponent,
        LocationDamageHousingComponent,
        LocationDamageHousingEditComponent,
        RequestEventDetailComponent,
        RequestTempDetailComponent,
        AdminEndorsementComponent,
        AdminEndorsementNewComponent,
        AdminEndorsementEditComponent,
        AdminCatalogReportComponent,
        InsuranceCompanyPaymentComponent,
        InsuranceCompanyMedicalCareComponent,
        PaymentNotificationsComponent,
        CarIndivMassiveDetailComponent,
        DinamicReportsComponent,
        DinamicReportsNewComponent,
        EnvioProgramadoComponent,
        VerReporteComponent,
        ConfirmComponent,
        SaveDynamicReportComponent,
        AdminProyectionRenovationComponent,
        AdminPolicySimplifyComponent,
        AdminPolicyPrincipalComponent,
        CancellMassivePolicyEventComponent,
        AdminPolicyPrincipalComponent,
        EndorsementComponent,
        EndorsementModalComponent,
        AdminPolicyPrincipalComponent,
        CarIndivMassiveDetailComponent,
        MassiveLoadPolicyComponent,
        RecargaDirective,
        InsuredUpdatedEventComponent,
        AdminPlacementQuotationListComponent,
        ModalNonRenewableComponent,
        ViewDocumentComponent,
        AdminPlacementQuotationDetailComponent,
        AdminPoliciesPaymentlinkComponent,
        AdminPlacementQuotationDetailComponent,
        LoadPolicyAutomationListComponent,
        LoadPolicyAutomationDetailComponent,
        GroupEndorsementsByCoverageIdPipe,
        GroupReceiptBySubgroupIdPipe,
        IncidentMasiveLoadComponent,
        AdminPlansComponent,
        PlansDetalleComponent,
        PlansCrearComponent,
        PlansCargaPoblacionComponent,
        PlansPeriodoPruebaComponent,
        GastosMedicosMenoresComponent,
        FlexGmmViewComponent,
        FlexDentalViewComponent,
        FlexDentalP2ViewComponent,
        FlexTypePoblationComponent,
        FlexTypePoblationComponent,
        FlexGmmP2ViewComponent,
        FlexLifeViewComponent,
        FlexLifeQuoteViewComponent,
        GastosMedicosMenoresP2Component,
        MascotasComponent,
        MascotasP2Component,
        FlexVisionP1ViewComponent,
        FlexVisionP2ViewComponent,
        FlexLifeQuoteViewComponent,
        FlexSpousalFileViewComponent,
        FlexSpousalLifeP2ViewComponent,
        // AutosComponent,
        FlexSoldPoliciesComponent,
        FlexSpousalLifeP2ViewComponent,
        FlexExcessViewComponent,
        FlexExcessP2ViewComponent,
        PlacementPorposalComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        BsDropdownModule,
        TypeaheadModule.forRoot(),
        ToastrModule.forRoot(),
        ModalModule.forRoot(),
        BsDropdownModule.forRoot(),
        BsDatepickerModule.forRoot(),
        PushNotificationsModule,
        InfiniteScrollModule,
        TabsModule.forRoot(),
        NgxUsefulSwiperModule,
        NgxCurrencyModule,
        PaginationModule,
        PaginationModule.forRoot(),
        NgbModule,
        NgMultiSelectDropDownModule.forRoot(),
        NgxSpinnerModule,
        SocketIoModule.forRoot(config),
        AutocompleteLibModule,
        NgWizardModule.forRoot(ngWizardConfig),
        UiSwitchModule,
        NgSelectModule,
        FileUploadModule,
        ImageViewerModule,
        AngularDualListBoxModule,
        AngularEditorModule,
        ChartsModule,
        TreeModule,
        PopoverModule.forRoot(),
        AccordionModule.forRoot(),
        NgbTimepickerModule,
        NgxDateRangePickerModule,
        DndModule
    ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [
    NotificationMdlComponent,
    RecoveryPasswordMdlComponent,
    ValidateCodeMdlComponent,
    InvoiceValidatorComponent,
    EnvioProgramadoComponent,
    VerReporteComponent,
    ConfirmComponent,
    SaveDynamicReportComponent
  ],
  providers: [ApiService,
    QuoteService,
    ThemeService,
    WebsocketService,
    DatePipe,
    ɵs,
    AuthGuard,
    AuthService,
    TreeDraggedElement,
    { provide: LOCALE_ID, useValue: 'es-MX' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestOptionsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseOptionsInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(localeService: BsLocaleService) {
    defineLocale('es', esLocale);
    localeService.use('es');
  }
}
