import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {DatePipe} from '@angular/common';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {NgxSpinnerService} from 'ngx-spinner';
import {ValidateAccessService} from "../validate-access.service";
import { NgxDateRangePickerOptions } from 'ngx-daterangepicker';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

import moment from 'moment';

@Component({
  selector: 'app-plans-crear',
  templateUrl: './plans-crear.component.html',
  styleUrls: ['./plans-crear.component.css']
})
export class PlansCrearComponent implements OnInit {

  planForm: FormGroup; // Formulario
  modalRefNewClient: BsModalRef;
  tipoCliente: any;

  payments: any;
  products: any;
  groups: any;
  clients: any;
  benefits: any;
  productsSelected: any;

  diasAguinaldo: any;
  primaVacacional: any;
  fondoAhorro: any;
  retencionMaxima: any;

  minDate: any;

  constructor(private api: ApiService,
              private router: Router,
              private toastr: ToastrService,
              private shared: SharedService,
              private datePipe: DatePipe,
              public fb: FormBuilder,
              private modalService: BsModalService,
              private spinner: NgxSpinnerService,
              private formBuilder: FormBuilder,
              private validateAccess: ValidateAccessService) {
  }
  ngOnInit() {
    this.productsSelected = [];
    this.tipoCliente = 1;
    this.minDate = new Date();

    this.createForm();
    this.getPayments();
    this.getProducts();
    this.getGroups();
    this.getBenefits();
  }
  /* -----------------------------------------------------------------------------------------------------
  ----------------------------- F U N C I O N E S G E N E R A L E S ---------------------------------------
  ----------------------------------------------------------------------------------------------------- */
  goToBack() {
    this.shared.gSearchId = 0;
    this.router.navigate(['/admin/executive']);
  }
  createForm(): void {
      this.planForm = this.formBuilder.group({
          namePlan: [null],
          tipoPrograma: [null],
          diasAguinaldo: [false],
          diasAguinaldoValor: [{ value: null, disabled: true }],
          diasAguinaldoMonetizar: [{ value: null, disabled: true }],
          primaVacacional: [false],
          primaVacacionalValor: [{ value: null, disabled: true }],
          primaVacacionalMonetizar: [{ value: null, disabled: true }],
          fondoAhorro: [false],
          fondoAhorroValor: [{ value: null, disabled: true }],
          fondoAhorroMonetizar: [{ value: null, disabled: true }],
          retencionMaxima: [false],
          retencionMaximaValor: [{ value: null, disabled: true }],
          retencionMaximaMonetizar: [{ value: null, disabled: true }],
          tipoPago: [null],
          tipoPagoValor: [null],
          nuevosIngresos: [false],
          tipoProducto: [null],
          grupo: [null],
          client: [null],
          campaignStartDate: [null],
          campaignEndDate: [null],
          startValidityCorporateInsuranceProgram: [null],
          endValidityCorporateInsuranceProgram: [null],
      });
  }
  getGroups() {
    this.api.getGroupsFlex().then((data: any) => {
      this.groups = data;
    }, error => {
    });
  }
  getBenefits(): void {
    this.api.getBenefits({}).subscribe(response => {
      this.benefits = response;

      this.diasAguinaldo = this.getBenefit(1);
      this.primaVacacional = this.getBenefit(2);
      this.fondoAhorro = this.getBenefit(3);
      this.retencionMaxima = this.getBenefit(4);
    },
    error => {
      console.log(error);
    });
  }
  getBenefit(id: number): any {
    let res;
    this.benefits.forEach(ben => {
      if (ben.id === id) {
        res = ben;
      }
    });

    return res;
  }
  getSubGroups() {
    const params = {
      id: +this.planForm.controls['grupo'].value
    };
    this.api.getSubGroupsFlex(params).then((data: any) => {
      this.clients = data;
    }, error => {
    });
  }
  getProducts(): void {
    this.api.getFlexibleProducts({}).subscribe(response => {
      this.products = response;
    },
    error => {
      console.log(error);
    });
  }

  getPayments(): void {
    this.api.getTypePaymentMethod({}).subscribe(response => {
      this.payments = response;
    },
    error => {
      console.log(error);
    });
  }

  isTradicional(): boolean {
    const value = this.planForm.controls['tipoPrograma'].value;
    return value === '1';
  }
  isContributorio(): boolean {
    const value = this.planForm.controls['tipoPrograma'].value;
    return value === '2';
  }

  openModalAddClient(template: TemplateRef<any>) {
    localStorage.removeItem('typePerson');
    localStorage.removeItem('client-id');
    localStorage.removeItem('selectTypeContact');
    localStorage.removeItem('isEditClient');
    localStorage.removeItem('isView');
    localStorage.setItem('selectTypeContact', '1');
    localStorage.setItem('esClienteFisico', 'NO');
    localStorage.setItem('isEditClient', 'false');
    localStorage.setItem('isView', 'false');
    localStorage.setItem('isViewFromPolicie', 'true');
    localStorage.setItem('clientNew', 'SI');
    this.modalRefNewClient = this.modalService.show(template, {class: 'modal-lg', keyboard: false, ignoreBackdropClick: true, backdrop: false});
  }

  closeModalNewClient() {
    this.modalRefNewClient.hide();
  }

  checkProducto(product) {
    const id = product.idFlexibleProduct;
    const index = this.productsSelected.indexOf(id);
    if (index <= -1) {
      this.productsSelected.push(id);
    } else {
      this.productsSelected.splice(index, 1);
    }
  }

  changeStartValidity(): void {
    const valueStartValidity = moment(this.planForm.controls['startValidityCorporateInsuranceProgram'].value);
    const valueStartCampaign = moment(this.planForm.controls['campaignEndDate'].value);
    if (valueStartValidity.isBefore(valueStartCampaign)) {
      this.planForm.controls['startValidityCorporateInsuranceProgram'].setErrors({ menor: true });
    } else {
      this.planForm.controls['startValidityCorporateInsuranceProgram'].setErrors(null);
    }
  }

  changeDiasAguinaldo(): void {
    const value = this.planForm.controls['diasAguinaldo'].value;
    if (value) {
      this.planForm.controls['diasAguinaldoValor'].enable();
      this.planForm.controls['diasAguinaldoMonetizar'].enable();
    } else {
      this.planForm.controls['diasAguinaldoValor'].disable();
      this.planForm.controls['diasAguinaldoMonetizar'].disable();
    }
  }

  changePrimaVacacional(): void {
    const value = this.planForm.controls['primaVacacional'].value;
    if (value) {
      this.planForm.controls['primaVacacionalValor'].enable();
      this.planForm.controls['primaVacacionalMonetizar'].enable();
    } else {
      this.planForm.controls['primaVacacionalValor'].disable();
      this.planForm.controls['primaVacacionalMonetizar'].disable();
    }
  }

  changeFondoAhorro(): void {
    const value = this.planForm.controls['fondoAhorro'].value;
    if (value) {
      this.planForm.controls['fondoAhorroValor'].enable();
      this.planForm.controls['fondoAhorroMonetizar'].enable();
    } else {
      this.planForm.controls['fondoAhorroValor'].disable();
      this.planForm.controls['fondoAhorroMonetizar'].disable();
    }
  }

  changeRetencionMaxima(): void {
    const value = this.planForm.controls['retencionMaxima'].value;
    if (value) {
      this.planForm.controls['retencionMaximaValor'].enable();
      this.planForm.controls['retencionMaximaMonetizar'].enable();
    } else {
      this.planForm.controls['retencionMaximaValor'].disable();
      this.planForm.controls['retencionMaximaMonetizar'].disable();
    }
  }

  configurar(): void {
    const data = this.planForm.value;

    const benefits = [];
    if (data.diasAguinaldo) {
      benefits.push({
        valueMonetize: +data.diasAguinaldoMonetizar,
        valueCompany: +data.diasAguinaldoValor,
        benefitId: 1
      });
    }
    if (data.primaVacacional) {
      benefits.push({
        valueMonetize: +data.primaVacacionalMonetizar,
        valueCompany: +data.primaVacacionalValor,
        benefitId: 2
      });
    }
    if (data.fondoAhorro) {
      benefits.push({
        valueMonetize: +data.fondoAhorroMonetizar,
        valueCompany: +data.fondoAhorroValor,
        benefitId: 3
      });
    }
    if (data.retencionMaxima) {
      benefits.push({
        valueMonetize: +data.retencionMaximaMonetizar,
        valueCompany: +data.retencionMaximaValor,
        benefitId: 4
      });
    }

    if (benefits.length <= 0) {
      this.toastr.error('Debes de seleccionar al menos una prestacion');
    }

    const params = {
      groupId: +data.grupo,
      clientId: +data.client,
      flexProgramTypeId: +data.tipoPrograma,
      methodPaymentId: +data.tipoPagoValor,
      namePlan: data.namePlan,
      campaignStartDate: moment(data.campaignStartDate).format('DD-MM-YYYY'),
      campaignEndDate: moment(data.campaignEndDate).format('DD-MM-YYYY'),
      startValidityCorporateInsuranceProgram: moment(data.startValidityCorporateInsuranceProgram).format('DD-MM-YYYY'),
      endValidityCorporateInsuranceProgram: moment(data.endValidityCorporateInsuranceProgram).format('DD-MM-YYYY'),
      newIncome: data.nuevosIngresos,
      benefits: benefits,
      products: this.productsSelected
    };

    this.api.createNewPlanFlex(params).subscribe(response => {
      this.toastr.success('Plan creado correctamente');
      this.router.navigate(['/admin/plans-flex']);
    },
    error => {
      console.log(error);
      this.toastr.error(error.error.message);
    });
  }

  cleanBeneficios(): void {
      this.planForm.controls['diasAguinaldo'].setValue(false);
      this.planForm.controls['diasAguinaldoValor'].setValue(null);
      this.planForm.controls['diasAguinaldoMonetizar'].setValue(null);
      this.planForm.controls['diasAguinaldoValor'].disable();
      this.planForm.controls['diasAguinaldoMonetizar'].disable();

      this.planForm.controls['primaVacacional'].setValue(false);
      this.planForm.controls['primaVacacionalValor'].setValue(null);
      this.planForm.controls['primaVacacionalMonetizar'].setValue(null);
      this.planForm.controls['primaVacacionalValor'].disable();
      this.planForm.controls['primaVacacionalMonetizar'].disable();

      this.planForm.controls['fondoAhorro'].setValue(false);
      this.planForm.controls['fondoAhorroValor'].setValue(null);
      this.planForm.controls['fondoAhorroMonetizar'].setValue(null);
      this.planForm.controls['fondoAhorroValor'].disable();
      this.planForm.controls['fondoAhorroMonetizar'].disable();

      this.planForm.controls['retencionMaxima'].setValue(false);
      this.planForm.controls['retencionMaximaValor'].setValue(null);
      this.planForm.controls['retencionMaximaMonetizar'].setValue(null);
      this.planForm.controls['retencionMaximaValor'].disable();
      this.planForm.controls['retencionMaximaMonetizar'].disable();
  }

  calculateDiasAguinaldo() {
    const valorAguinaldo = this.planForm.controls['diasAguinaldoValor'].value;
    const benefit = this.getBenefit(1);
    if (valorAguinaldo <= benefit.benefitByLaw) {
      this.planForm.controls['diasAguinaldoValor'].setErrors({ valorMenor: true });
      return;
    } else {
      this.planForm.controls['diasAguinaldoValor'].setErrors(null);
    }

    let value = valorAguinaldo - benefit.benefitByLaw;
    if (value < 0) {
      value = 0;
    }

    this.planForm.controls['diasAguinaldoMonetizar'].setValue(value);
  }

  calculatePrimaVacacional() {
    const valorAguinaldo = this.planForm.controls['primaVacacionalValor'].value;
    const benefit = this.getBenefit(2);
    if (valorAguinaldo > 100) {
      this.planForm.controls['primaVacacionalValor'].setErrors({ valorMayor: true });
      return;
    }
    if (valorAguinaldo <= benefit.benefitByLaw) {
      this.planForm.controls['primaVacacionalValor'].setErrors({ valorMenor: true });
      return;
    }

    this.planForm.controls['primaVacacionalValor'].setErrors(null);
    let value = valorAguinaldo - benefit.benefitByLaw;
    if (value < 0) {
      value = 0;
    }

    this.planForm.controls['primaVacacionalMonetizar'].setValue(value);
  }

  calculateFondoAhorro() {
    const valorAguinaldo = this.planForm.controls['fondoAhorroValor'].value;
    const benefit = this.getBenefit(3);
    if (valorAguinaldo > 100) {
      this.planForm.controls['fondoAhorroValor'].setErrors({ valorMayor: true });
      return;
    }
    if (valorAguinaldo <= benefit.benefitByLaw) {
      this.planForm.controls['fondoAhorroValor'].setErrors({ valorMenor: true });
      return;
    }

    this.planForm.controls['fondoAhorroValor'].setErrors(null);

    this.planForm.controls['fondoAhorroMonetizar'].setValue(100);
  }

  calculateRetencionMaxima() {
    const valorAguinaldo = this.planForm.controls['retencionMaximaValor'].value;
    const benefit = this.getBenefit(4);
    if (valorAguinaldo > benefit.benefitByLaw) {
      this.planForm.controls['retencionMaximaValor'].setErrors({ valorMenor: true });
      return;
    }

    this.planForm.controls['retencionMaximaValor'].setErrors(null);
    this.planForm.controls['retencionMaximaMonetizar'].setValue(valorAguinaldo);
  }

  calculateDiasAguinaldoMonetizar() {
    const valorAguinaldo = this.planForm.controls['diasAguinaldoMonetizar'].value;
    const valorAguinaldoValor = this.planForm.controls['diasAguinaldoValor'].value;
    const benefit = this.getBenefit(1);

    if (valorAguinaldo < 0) {
      this.planForm.controls['diasAguinaldoMonetizar'].setErrors({ valorMenor: true });
      return;
    }

    const total = valorAguinaldo + benefit.benefitByLaw;
    if (total > valorAguinaldoValor) {
      this.planForm.controls['diasAguinaldoMonetizar'].setErrors({ valorMayor: true });
      return;
    }

    this.planForm.controls['diasAguinaldoMonetizar'].setErrors(null);
  }

  calculateFondoAhorroMonetizar() {
    const valorAguinaldo = this.planForm.controls['fondoAhorroMonetizar'].value;
    const valorAguinaldoValor = this.planForm.controls['fondoAhorroValor'].value;
    const benefit = this.getBenefit(1);

    if (valorAguinaldo < 0) {
      this.planForm.controls['fondoAhorroMonetizar'].setErrors({ valorMenor: true });
      return;
    }

    if (valorAguinaldo > 100) {
      this.planForm.controls['fondoAhorroMonetizar'].setErrors({ valorMayorCien: true });
      return;
    }

    if (valorAguinaldo > valorAguinaldoValor) {
      this.planForm.controls['fondoAhorroMonetizar'].setErrors({ errorValor: true });
      return;
    }

    const total = valorAguinaldo + benefit.benefitByLaw;
    if (total > valorAguinaldoValor) {
      this.planForm.controls['fondoAhorroMonetizar'].setErrors({ valorMayor: true });
      return;
    }

    this.planForm.controls['fondoAhorroMonetizar'].setErrors(null);
  }

  calculatePrimaVacacionalMonetizar() {
    const valorAguinaldo = this.planForm.controls['primaVacacionalMonetizar'].value;
    const valorAguinaldoValor = this.planForm.controls['primaVacacionalValor'].value;
    const benefit = this.getBenefit(1);

    if (valorAguinaldo < 0) {
      this.planForm.controls['primaVacacionalMonetizar'].setErrors({ valorMenor: true });
      return;
    }

    if (valorAguinaldo > 100) {
      this.planForm.controls['primaVacacionalMonetizar'].setErrors({ valorMayorCien: true });
      return;
    }

    this.planForm.controls['primaVacacionalMonetizar'].setErrors(null);
  }

  calculateRetencionMaximaMonetizar() {
    const valorAguinaldo = this.planForm.controls['retencionMaximaMonetizar'].value;
    const valorAguinaldoValor = this.planForm.controls['retencionMaximaValor'].value;
    const benefit = this.getBenefit(1);

    if (valorAguinaldo < 0) {
      this.planForm.controls['retencionMaximaMonetizar'].setErrors({ valorMenor: true });
      return;
    }

    const total = valorAguinaldo + benefit.benefitByLaw;
    if (total > valorAguinaldoValor) {
      this.planForm.controls['retencionMaximaMonetizar'].setErrors({ valorMayor: true });
      return;
    }

    this.planForm.controls['retencionMaximaMonetizar'].setErrors(null);
  }

}
