import {Component, OnInit, TemplateRef, ViewChild, ChangeDetectorRef} from '@angular/core';
import {ApiService} from '../api.service';
import {Router, ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {DatePipe} from '@angular/common';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {NgxSpinnerService} from 'ngx-spinner';
import {ValidateAccessService} from "../validate-access.service";
import { NgxDateRangePickerOptions } from 'ngx-daterangepicker';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { ConfirmComponent } from '../dialogs/confirm/confirm.component';

import moment from 'moment';
import * as _ from 'lodash';
import { timeStamp } from 'console';

@Component({
  selector: 'app-autos',
  templateUrl: './autos.component.html',
  styleUrls: ['./autos.component.css']
})
export class AutosComponent implements OnInit {
// variables pantalla consulta siniestros
  planForm: FormGroup; // Formulario

  idPlan: number;
  idProduct: number;

  plan: any;

  insuranceList: any;
  aseguradorasSelected: any;

  constructor(private api: ApiService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private toastr: ToastrService,
              private shared: SharedService,
              private datePipe: DatePipe,
              public fb: FormBuilder,
              private modalService: BsModalService,
              private spinner: NgxSpinnerService,
              private formBuilder: FormBuilder,
              private cdr: ChangeDetectorRef,
              private validateAccess: ValidateAccessService) {
  }
  ngOnInit() {
    this.aseguradorasSelected = [];
    this.activatedRoute.params.subscribe(params => {
      this.idPlan = +params.idPlan;
      this.idProduct = +params.idProduct;

      this.getPlanInfo();
    });

    this.createForm();
  }
  /* -----------------------------------------------------------------------------------------------------
  ----------------------------- F U N C I O N E S G E N E R A L E S ---------------------------------------
  ----------------------------------------------------------------------------------------------------- */
  goToBack() {
    this.shared.gSearchId = 0;
    this.router.navigate(['/admin/executive']);
  }

  getPlanInfo(): void {
    const params = {
      idProduct: this.idProduct,
      flexPlanId: this.idPlan
    };

    this.api.getInfoConfigurationP1P2(params).subscribe(response => {
      this.plan = response;

      console.log(this.plan);
      this.planForm.get('typeConfigurationPolicyId').setValue(this.plan.typeConfigurationPolicyId ? this.plan.typeConfigurationPolicyId.toString() : null);
      this.planForm.get('paymentOnline').setValue(this.plan.paymentOnline ? this.plan.paymentOnline.toString() : false);
      this.planForm.get('payrollDeduction').setValue(this.plan.payrollDeduction ? this.plan.payrollDeduction.toString() : false);

      if (this.plan.listInsuranceKeys != null) {
        this.aseguradorasSelected = this.plan.listInsuranceKeys.map(ob => ob.insuranceCompanyId);
      }
      
      this.getInsurances();
    },
    error => {
      console.log(error);
    });
  }

  checkAseguradora(aseguradora): void {
    const id = aseguradora;
    const index = this.aseguradorasSelected.indexOf(id);
    if (index <= -1) {
      this.aseguradorasSelected.push(id);
    } else {
      this.aseguradorasSelected.splice(index, 1);
    }
  }

  checkAseguradoraSelected(aseguradora): boolean {
    // console.log(aseguradora);
    // console.log(this.aseguradorasSelected);
    const index = this.aseguradorasSelected ? this.aseguradorasSelected.indexOf(aseguradora) : -1;
    return index >= 0;
  }

  getInsurances(): void {
    this.api.getInsuranceByIdProduct({ idProduct: this.idProduct, payrollDeduction: false }).subscribe(response => {
      this.insuranceList = response;
    },
    error => {
      console.log(error);
    });
  }

  createForm(): void {
    this.planForm = this.formBuilder.group({
      typeConfigurationPolicyId: [null, Validators.required],
      paymentOnline: [false],
      payrollDeduction: [false],
      // aseguradoras: [null, Validators.required]
    });
  }

  saveConfiguration(): void {
    if (!this.planForm.valid) {
      this.toastr.error('Faltan datos por capturar');
      return;
    }

    const list = this.aseguradorasSelected.map(ob => ({ insuranceCompanyId: ob }));
    const params = {
      "planFlexId": this.idPlan,
      "flexPlanVehicleId": this.plan.flexPlanVehicleId,
      // "flexPlanVehicleId": null,
      "typeConfigurationPolicyId": +this.planForm.get('typeConfigurationPolicyId').value,
      "paymentOnline": this.planForm.get('paymentOnline').value,
      "payrollDeduction": this.planForm.get('payrollDeduction').value,
      "listInsuranceKeys": list
    };

    this.api.insertUpdateConfigurationP1Vehicle(params).subscribe(response => {
      this.toastr.success('Configuración correctamente guardada');
    },
    error => {
      this.toastr.error(error.error.message);
    });
  }

}
