import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ApiService} from '../api.service';
import {Router, ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {DatePipe} from '@angular/common';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {NgxSpinnerService} from 'ngx-spinner';
import {ValidateAccessService} from "../validate-access.service";
import { NgxDateRangePickerOptions } from 'ngx-daterangepicker';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

import moment from 'moment';

@Component({
  selector: 'app-plans-periodo-prueba',
  templateUrl: './plans-periodo-prueba.component.html',
  styleUrls: ['./plans-periodo-prueba.component.css']
})
export class PlansPeriodoPruebaComponent implements OnInit {
  usuarioForm: FormGroup; // Formulario
  testPlanForm: FormGroup; // Formulario
  idTestPlan: number;
  testPlan: any;
  isEditar: boolean;
  userSelected: any;

  groupId: number;
  clientId: number;

  diasAguinaldo: any = {};
  primaVacacional: any = {};
  fondoAhorro: any = {};
  retencionMaxima: any = {};

  benefits: any;
// variables pantalla consulta siniestros
  constructor(private api: ApiService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private toastr: ToastrService,
              private shared: SharedService,
              private datePipe: DatePipe,
              public fb: FormBuilder,
              private modalService: BsModalService,
              private spinner: NgxSpinnerService,
              private validateAccess: ValidateAccessService) {
  }
  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.idTestPlan = +params.id;
      this.groupId = +params.groupId;
      this.clientId = +params.clientId;

      this.getTestPlan();
    });

    this.getBenefits();
    this.createFormTestPlan();
    this.createForm();
  }
  /* -----------------------------------------------------------------------------------------------------
  ----------------------------- F U N C I O N E S G E N E R A L E S ---------------------------------------
  ----------------------------------------------------------------------------------------------------- */
  goToBack() {
    this.shared.gSearchId = 0;
    this.router.navigate(['/admin/executive']);
  }

  getBenefits(): void {
    this.api.getBenefits({}).subscribe(response => {
      this.benefits = response;
      console.log(this.benefits);

      this.diasAguinaldo = this.getBenefit(1);
      this.primaVacacional = this.getBenefit(2);
      this.fondoAhorro = this.getBenefit(3);
      this.retencionMaxima = this.getBenefit(4);
    },
    error => {
      console.log(error);
    });
  }
  
  getBenefit(id: number): any {
    let res;
    this.benefits.forEach(ben => {
      if (ben.id === id) {
        res = ben;
      }
    });

    return res;
  }

  createFormTestPlan(): void {
      this.testPlanForm = this.fb.group({
          testStartDate: [null],
          testEndDate: [null],
      });
  }
  createForm(): void {
      this.usuarioForm = this.fb.group({
          name: [null, [Validators.required]],
          fisrtName: [null, [Validators.required]],
          lastName: [null, [Validators.required]],
          email: [null, [Validators.required]],
          phone: [null, [Validators.required]],
          zipcode: [null, [Validators.required]],
          password: [null, [Validators.required]],
          birthDate: [null, [Validators.required]],
          grossSlary: [null, [Validators.required]],
          integratedDailyWage: [null, [Validators.required]],
          vacationDays: [null, [Validators.required]],
          christmasBonus: [null, [Validators.required]],
          vacationBonus: [null, [Validators.required]],
          savingFund: [null, [Validators.required]],
          groupId: [null],
          clientId: [null],
          planFlexId: [null],
      });
  }

  getTestPlan(): void {
    this.api.getCampaignTest({ idPlanFlex: this.idTestPlan }).subscribe(response => {
      this.testPlan = response;
      console.log(this.testPlan);
      this.testPlanForm.controls['testStartDate'].setValue(this.testPlan.testStartDate);
      this.testPlanForm.controls['testEndDate'].setValue(this.testPlan.testEndDate);
    },
    error => {
      console.log(error);
    });
  }

  agregarUsuario(): void {
    if (this.usuarioForm.invalid) {
      this.usuarioForm.markAllAsTouched();
      return;
    }

    const params = this.usuarioForm.value;
    if (this.isEditar) {
      params.birthDate = moment(params.birthDate).format('DD-MM-YYYY');
      params.groupId = this.groupId;
      params.clientId = this.clientId;
      params.planFlexId = this.testPlan.planFlexId;
      params.idFlexPlanInsured = this.userSelected.idFlexPlanInsured;
      if (params.password.indexOf('*') >= 0) {
        params.password = null;
      }
      this.api.updateFlexPlanTestInsured(params).subscribe(response => {
        this.toastr.success('Usuario actualizado correctamente');
        this.userSelected = {};
        this.isEditar = false;
        this.usuarioForm.reset();
        this.getTestPlan();
      },
      error => {
        console.log(error);
      });
    } else {
      params.birthDate = moment(params.birthDate).format('DD-MM-YYYY');
      params.groupId = this.groupId;
      params.clientId = this.clientId;
      params.planFlexId = this.testPlan.planFlexId;
      params.christmasBonus = +params.christmasBonus;
      params.grossSlary = +params.grossSlary;
      params.vacationBonus = +params.vacationBonus;
      params.savingFund = +params.savingFund;
      params.integratedDailyWage = +params.integratedDailyWage;
      params.vacationDays = +params.vacationDays;
      console.log(params);
      this.api.addNewFlexPlanTestInsured(params).subscribe(response => {
        this.toastr.success('Usuario creado correctamente');
        this.usuarioForm.reset();
        this.getTestPlan();
      },
      error => {
        console.log(error);
        this.toastr.success(error.error.message);
      });
    }
  }

  activarUsuario(usuario: any): void {
    const params = {
      idFlexPlanInsured: usuario.idFlexPlanInsured,
      status: true
    };
    this.api.activateTestCampaignUser(params).subscribe(response => {
      this.toastr.success('Usuario Activado correctamente');
      this.getTestPlan();
    },
    error => {
      console.log(error);
    });
  }

  inactivarUsuario(usuario: any): void {
    const params = {
      idFlexPlanInsured: usuario.idFlexPlanInsured,
      status: false
    };
    this.api.activateTestCampaignUser(params).subscribe(response => {
      this.toastr.success('Usuario Activado correctamente');
      this.getTestPlan();
    },
    error => {
      console.log(error);
    });
  }

  editarUsuario(usuario: any): void {
    this.userSelected = usuario;
    this.usuarioForm.patchValue(usuario);
    this.isEditar = true;
  }

  guardarPrueba(): void {
    const data = this.testPlanForm.value;
    const params = {
      testEndDate: moment(data.testEndDate).isValid() ? moment(data.testEndDate).format('DD-MM-YYYY') : data.testEndDate,
      idCampaignTest: this.testPlan.idCampaignTest,
      testStartDate: moment(data.testStartDate).isValid() ? moment(data.testStartDate).format('DD-MM-YYYY') : data.testStartDate,
      planFlexId: this.testPlan.planFlexId,
      status: true,
    };
    this.api.updateTestCampaign(params).subscribe(response => {
      this.toastr.success('Periodo de prueba actualizado correctamente');
      this.getTestPlan();
    },
    error => {
      console.log(error);
      this.toastr.error(error.error.message);
    });
  }

  changeStatusPlan(status: boolean): void {
    const data = this.testPlanForm.value;
    const params = {
      testEndDate: moment(data.testEndDate).isValid() ? moment(data.testEndDate).format('DD-MM-YYYY') : data.testEndDate,
      idCampaignTest: this.testPlan.idCampaignTest,
      testStartDate: moment(data.testStartDate).isValid() ? moment(data.testStartDate).format('DD-MM-YYYY') : data.testStartDate,
      planFlexId: this.testPlan.planFlexId,
      status: status,
    };
    console.log(params);
    this.api.updateTestCampaign(params).subscribe(response => {
      this.toastr.success('Periodo de prueba actualizado correctamente');
      this.getTestPlan();
    },
    error => {
      console.log(error);
      this.toastr.error(error.error.message);
    });
  }

  checkDiasAguinaldo() {
    const valorAguinaldo = this.usuarioForm.controls['christmasBonus'].value;
    const benefit = this.getBenefit(1);

    if (valorAguinaldo < 0) {
      this.usuarioForm.controls['christmasBonus'].setErrors({ valorMenor: true });
    } else {
      this.usuarioForm.controls['christmasBonus'].setErrors(null);
    }

    if (valorAguinaldo < benefit.benefitByLaw) {
      this.usuarioForm.controls['christmasBonus'].setErrors({ valorMayor: true });
    } else {
      this.usuarioForm.controls['christmasBonus'].setErrors(null);
    }
  }

  checkPrimaVacacional() {
    const valorAguinaldo = this.usuarioForm.controls['vacationBonus'].value;
    const benefit = this.getBenefit(2);

    if (valorAguinaldo < 0) {
      this.usuarioForm.controls['vacationBonus'].setErrors({ valorMenor: true });
    } else {
      this.usuarioForm.controls['vacationBonus'].setErrors(null);
    }

    if (valorAguinaldo < benefit.benefitByLaw) {
      this.usuarioForm.controls['christmasBonus'].setErrors({ valorMayor: true });
    } else {
      this.usuarioForm.controls['christmasBonus'].setErrors(null);
    }
  }

  checkFondoAhorro() {
    const valorAguinaldo = this.usuarioForm.controls['savingFund'].value;
    const benefit = this.getBenefit(3);

    if (valorAguinaldo < 0) {
      this.usuarioForm.controls['savingFund'].setErrors({ valorMenor: true });
    } else {
      this.usuarioForm.controls['savingFund'].setErrors(null);
    }

    if (valorAguinaldo < benefit.benefitByLaw) {
      this.usuarioForm.controls['savingFund'].setErrors({ valorMayor: true });
    } else {
      this.usuarioForm.controls['savingFund'].setErrors(null);
    }
  }
}
