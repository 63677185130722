import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-placement-porposal',
  templateUrl: './placement-porposal.component.html',
  styleUrls: ['./placement-porposal.component.css']
})
export class PlacementPorposalComponent implements OnInit {

  constructor() { }
  sections = [
    {
      title: 'Coberturas Solicitadas',
      items: [
        { name: 'Daños materiales', amount: '$1,000,000' },
        { name: 'Asesoría legal', amount: '$100,000' },
        { name: 'Gastos médicos', amount: '$10,000' },
        { name: 'Auto de reemplazo', amount: '$2,500' }
      ]
    },
    {
      title: 'Coberturas Actuales',
      items: [
        { name: 'Daños materiales', amount: '$1,000,000' },
        { name: 'Asesoría legal', amount: '$100,000' },
        { name: 'Gastos médicos', amount: '$10,000' },
        { name: 'Auto de reemplazo', amount: '$2,500' }
      ]
    },
    {
      title: 'Metlife',
      items: [
        { name: 'Daños materiales', amount: '$900,000' },
        { name: 'Asesoría legal', amount: '$80,000' },
        { name: 'Gastos médicos', amount: '$9,000' },
        { name: 'Auto de reemplazo', amount: '$2,300' }
      ]
    },
    {
      title: 'Monterrey',
      items: [
        { name: 'Daños materiales', amount: '$900,000' },
        { name: 'Asesoría legal', amount: '$80,000' },
        { name: 'Gastos médicos', amount: '$9,000' },
        { name: 'Auto de reemplazo', amount: '$2,300' }
      ]
    }
  ];
  ngOnInit() {
  }



}
