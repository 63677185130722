import { Component, OnInit } from '@angular/core';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {DatePipe} from '@angular/common';
import {ValidateAccessService} from '../validate-access.service';
import {PageChangedEvent} from "ngx-bootstrap/pagination";

@Component({
  selector: 'app-flex-sold-policies',
  templateUrl: './flex-sold-policies.component.html',
  styleUrls: ['./flex-sold-policies.component.css']
})
export class FlexSoldPoliciesComponent implements OnInit {

  groupList: any = [];
  groupsSelectedItems: any = [];
  dropdownSettingsGroups = {};
  dataFlexSoldPolicies: any = {};
  clientsSelectedItems: any = [];
  dropdownSettingsClients = {};
  clientList: any = [];
  groupId: number;
  flexPlanPlanesList: any = [];
  flexPlanPlanesSelectedItems: any = [];
  dropdownSettingsFlexPlanPlanes: any = {};
  flexProgramSelectedItems: any = [];
  dropdownSettingsFlexProgram: any = {};
  flexProductList: any = [];
  flexProductSelectedItems: any = [];
  dropdownSettingsFlexProduct: any = {};
  flexProgramList: any = [];
  categories: string;
  // -Varibales para paginado de recibos->
  pgBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgMaxSize = 3; // Número de links en paginado
  pgItemsPerPage = 10; // Número de registros por página
  pgTotalItems: number; // Total de registros
  pgCurrentPage = 1; // Página actual
  paginationResponse: any = {};
  soldPolicyList: any = [];
  constructor(private api: ApiService,
              private router: Router,
              private toastr: ToastrService,
              private shared: SharedService,
              private spinner: NgxSpinnerService,
              private datePipe: DatePipe,
              private validateAccess: ValidateAccessService) { }
  ngOnInit() {
    this.invokeFunctionInitFilters();
    this.dropdownSettingsGroups = {
      singleSelection: true,
      idField: 'id',
      textField: 'code',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON GRUPOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsClients = {
      singleSelection: false,
      idField: 'id',
      textField: 'code',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON SUB GRUPOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsFlexProgram = {
      singleSelection: false,
      idField: 'id',
      textField: 'code',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON PROGRAMAS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsFlexProduct = {
      singleSelection: false,
      idField: 'id',
      textField: 'code',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON PRODUCTOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsFlexPlanPlanes = {
      singleSelection: false,
      idField: 'id',
      textField: 'code',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON PLANES',
      searchPlaceholderText: 'BUSCAR'
    };
    this.invokeServiceGetGroups();
    this.invokeServiceGetFlexProgram();
    this.invokeServiceGetFlexProduct();
  }
  invokeFunctionInitFilters() {
    this.dataFlexSoldPolicies = {
      baseCategoryList: [],
      size: 10,
      productIdList: [],
      groupIdList: [],
      planFlexIdList: [],
      page: 1,
      clientIdList: [],
      flexPlanPlanesIdList: []
    };
    this.categories = '';
  }
  invokeFunctionGoToBack() {
    this.router.navigate(['/admin/report_core']);
  }
  invokeServiceGetGroups() {
    this.api.getGroupsList().then((data: any) => {
      this.groupList = data;
    }, error => {
    });
  }
  invokeServiceGetFlexProgram() {
    this.api.getFlexProgramCatalog().then((data: any) => {
      this.flexProgramList = data;
      console.log('programas');
      console.log(this.flexProgramList);
    }, error => {
    });
  }
  invokeServiceGetFlexProduct() {
    this.api.getFlexProductCatalog().then((data: any) => {
      this.flexProductList = data;
      console.log('productos');
      console.log(this.flexProductList);
    }, error => {
    });
  }
  invokeServiceGetFlexPlanPlanes(bodyRequest: any) {
    this.api.getFlexPlanPlanesCatalog(bodyRequest).then((data: any) => {
      this.flexPlanPlanesList = data;
      console.log('planes');
      console.log(this.flexPlanPlanesList);
    }, error => {
    });
  }

  changeStatusGroups() {
    const group = [];
    let multiGroup = {};
    setTimeout(() => {
      try {
        if (this.groupsSelectedItems.length === 0) {
          this.dataFlexSoldPolicies.groupIdList = [];
          this.clientsSelectedItems = [];
        } else {
          this.groupsSelectedItems.forEach(item => {
            group.push(item.id);
          });
          multiGroup = {
            id: group
          };
          this.invokeServiceGetSubGroups(multiGroup);
          this.dataFlexSoldPolicies.groupIdList = group;
          this.clientsSelectedItems = [];
        }
      } catch (e) {
      }
    }, 1000);
  }
  invokeFunctionSelectGroup(idGroup: number) {
    const obRequest = {
      id: idGroup
    };
    const group = [];
    group.push(idGroup);
    this.dataFlexSoldPolicies.groupIdList = group;
    console.log(this.dataFlexSoldPolicies.groupIdList);
    this.invokeServiceGetSubGroups(obRequest);
  }

  invokeServiceGetSubGroups(groups) {
    console.log(groups);
    this.api.getSubGroupsCatalogList(groups).then((data: any) => {
      this.clientList = data;
    }, error => {
    });
  }

  changeStatusSubGroups() {
    const subgroup = [];
    setTimeout(() => {
      try {
        if (this.clientsSelectedItems.length === 0) {
          this.dataFlexSoldPolicies.clientIdList = [];
        } else {
          this.clientsSelectedItems.forEach(item => {
            subgroup.push(item.id);
          });
          this.dataFlexSoldPolicies.clientIdList = subgroup;
        }

      } catch (e) {
      }
    }, 500);
  }

  changeStatusFlexProgram() {
    const program = [];
    setTimeout(() => {
      try {
        if (this.flexProgramSelectedItems.length === 0) {
          this.dataFlexSoldPolicies.planFlexIdList = [];
          this.flexProgramSelectedItems = [];
        } else {
          this.flexProgramSelectedItems.forEach(item => {
            program.push(item.id);
          });

          this.dataFlexSoldPolicies.planFlexIdList = program;
          // this.flexProgramSelectedItems = [];
        }
      } catch (e) {
      }
    }, 1000);
  }
  changeStatusFlexProduct() {
    const product = [];
    setTimeout(() => {
      try {
        if (this.flexProductSelectedItems.length === 0) {
          this.dataFlexSoldPolicies.productIdList = [];
          this.flexProductSelectedItems = [];
        } else {
          this.flexProductSelectedItems.forEach(item => {
            product.push(item.id);
          });

          this.dataFlexSoldPolicies.productIdList = product;
          // this.flexProductSelectedItems = [];
          const body = {
            idFlexPlanList: this.dataFlexSoldPolicies.planFlexIdList,
            idFlexProductList: this.dataFlexSoldPolicies.productIdList
          };
          this.invokeServiceGetFlexPlanPlanes(body);
        }
      } catch (e) {
      }
    }, 1000);
  }

  changeStatusFlexPlanPlanes() {}

  invokeFunctionClearFiltersTable() {
    this.invokeFunctionInitFilters();
    this.groupId = 0;
    this.clientsSelectedItems = [];
    this.flexProgramSelectedItems = [];
    this.flexProductSelectedItems = [];
    this.flexPlanPlanesSelectedItems = [];
    this.flexPlanPlanesSelectedItems = [];
    this.categories = '';
  }
  invokeFunctionGetSoldPolicies() {
    console.log(this.dataFlexSoldPolicies);
    if (this.dataFlexSoldPolicies.planFlexIdList.length === 0) {
      this.toastr.warning('ES NECESARIO AL MENOS INDICAR UN PROGRAMA FLEXIBLE.', 'NOTIFICACION');
      return;
    }
    const category = [];
    category.push(this.categories);
    if (this.categories === undefined || this.categories === '') {
    } else {
      this.dataFlexSoldPolicies.baseCategoryList = category;
    }
    this.invokeServiceGetSoldPolicies(1, 10);
  }
  invokeServiceForGetReportsSoldXls() {
    this.spinner.show('sp');
    if (this.dataFlexSoldPolicies.planFlexIdList.length === 0) {
      this.toastr.warning('ES NECESARIO AL MENOS INDICAR UN PROGRAMA FLEXIBLE.', 'NOTIFICACION');
      this.spinner.hide('sp');
      return;
    }
    const category = [];
    category.push(this.categories);
    if (this.categories === undefined || this.categories === '') {
    } else {
      this.dataFlexSoldPolicies.baseCategoryList = category;
    }
    this.api.downloadPoliciesSoldReport(this.dataFlexSoldPolicies)
      .then(
        (response: any) => {
          if (response.file === undefined) {
            this.toastr.error('OCURRIO UN PROBLEMA AL OBTENER EL REPORTE.', 'NOTIFIACION');
            this.spinner.hide('sp');
            return;
          }
          const base64Data = response.file;
          const binaryData = atob(base64Data);  // Decodificar Base64 a binario
          const arrayBuffer = new ArrayBuffer(binaryData.length);
          const uintArray = new Uint8Array(arrayBuffer);

          for (let i = 0; i < binaryData.length; i++) {
            uintArray[i] = binaryData.charCodeAt(i);
          }

          const blob = new Blob([uintArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(blob);
          downloadLink.setAttribute('download', 'reporte_polizas_flex.xls');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
          this.spinner.hide('sp');
        }, (error) => {
          this.spinner.hide('sp');
        });
  }

  invokeServiceGetSoldPolicies(page, size) {
    this.spinner.show('sp');
    console.log(this.dataFlexSoldPolicies);
    this.dataFlexSoldPolicies.page = page;
    this.dataFlexSoldPolicies.size = size;
    this.api.getFlexSoldPolicies(this.dataFlexSoldPolicies)
      .then(
        (response: any) => {
          console.log(response);
          if (response == null) {
            this.pgTotalItems = 0;
            this.soldPolicyList = [];
            this.toastr.info('NO SE HAN ENCONTRADO RESULTADOS', 'NOTIFICACION');
          } else {
            this.paginationResponse = response;
            this.pgTotalItems = this.paginationResponse.totalRows ;
            this.soldPolicyList = this.paginationResponse.tList ;
          }
          this.spinner.hide('sp');
        }, error => {
          this.pgTotalItems = 0;
          this.soldPolicyList = [];
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  changeColorPagination() {}
  pageChanged(event: PageChangedEvent): void {
    // // console.log(event);
    this.pgCurrentPage = event.page;
    this.invokeServiceGetSoldPolicies(this.pgCurrentPage, this.pgItemsPerPage);
    this.changeColorPagination();
  }
}
