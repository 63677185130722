import {Component, OnInit, TemplateRef} from '@angular/core';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {DatePipe} from '@angular/common';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {NgxSpinnerService} from 'ngx-spinner';
import {NgWizardConfig, NgWizardService, STEP_STATE, StepChangedArgs, StepValidationArgs, THEME} from 'ng-wizard';
import {number} from '@amcharts/amcharts4/core';
import {Documents} from '../upload-file/documents';
import {regex} from "../../common/regex";
import {FileUploadControl, FileUploadValidators} from "@iplab/ngx-file-upload";

@Component({
  selector: 'app-incident-mdl',
  templateUrl: './incident-mdl.component.html',
  styleUrls: ['./incident-mdl.component.css']
})
export class IncidentMdlComponent implements OnInit {
  modalRef: BsModalRef;
  // variables para conponte wizard
  stepStates = {
    normal: STEP_STATE.normal,
    disabled: STEP_STATE.disabled,
    error: STEP_STATE.error,
    hidden: STEP_STATE.hidden
  };
  config: NgWizardConfig = {
    selected: 0,
    theme: THEME.arrows,
    toolbarSettings: {
      showPreviousButton: false
      , showNextButton: false
    },
    anchorSettings: {
      anchorClickable: true,
      enableAllAnchors: true,
      markDoneStep: true,
      markAllPreviousStepsAsDone: true,
      removeDoneStepOnNavigateBack: true,
      enableAnchorOnDoneStep: true
    }
  };
  selectedStepIndex = 0;
  totalSteps = 5;
  disableBtnPrevious = true;
  disableBtnNext = false;
  typeStep: number;
  isValidTypeBoolean = true;
  dataIncident: any = {};
  incidentId: number;
  incidentTypeSelected: any = {};
  typeIncidentList: any = [];
  typeRequestSelected: any = {};
  typeRequestList: any = [];
  groupSelected: any = {};
  groupList: any = [];
  clientSelected: any = {};
  clientList: any = [];
  // variables documentos
  idConfigurationDocument: number;
  uploadedFilesToLoad: Array<Documents> = []; // Documentos
  typeDocumentList: any = []; // Documentos a consignar
  documentsLoads: any = []; // documentos cargados
  isView: boolean; // modo de ver detalle?
  disableFiles: boolean; // desacticvar archivos
  // variables para comunicación
  dataIncidentMessages: any = [];
  dataMessage: any = {};
  error = {active: false, msg: ''};
  public fileUploadAttachment: File;
  id: NodeJS.Timer;
  countMessageChat: number;
  // variables bitacoreo
  filters: any = {};
  dataRequestAuthor: any = [];
  dataRequestEvents: any = [];
  dataRequestMessageFilter: any = {};
  authorSelectedItems: any = [];
  dropdownSettingsAuthor: any = {};
  typesSelectedItems: any = [];
  dropdownSettingsTypes: any = {};
  requestMessagesList: any = [];
  // -Varibales para paginado de bitacora->
  pgBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgMaxSize = 3; // Número de links en paginado
  pgItemsPerPage = 5; // Número de registros por página
  pgTotalItems: number; // Total de registros
  pgCurrentPage = 1; // Página actual
  paginationResponse: any = {};
  dataMessageDetail: any = {};
  dataMessageLog: any = {};
  public fileUpload: File;
  dataTimeline: any = [];
  // variables para clasificacion
  flag: number;
  dataIncidentClassification: any = {};
  incidentSelected: any = {};
  incidentList: any = [];
  typeRequestList2: any = [];
  typedDocumentationSelected: any = {};
  incidentClassification: any = {};
  // variables para encuesta
  quizIncidentList: any = [];
  starRating: number;
  dataContactForm: FormGroup;
  form3: FormGroup;
  // tslint:disable-next-line:max-line-length
  public fileUploadControl3 = new FileUploadControl({listVisible: false, discardInvalid: true, accept: ['.pdf', '.jpeg', '.png', '.jpg', '.xml']},
    [FileUploadValidators.accept(['.pdf', '.jpeg', '.png', '.jpg', '.xml']), FileUploadValidators.fileSize(25428800)]);
  phaseId: number;
  dataFinishIncident: any = {};
  ext: string;

  isIncident: boolean;
  isIncidentAuto: boolean;
  estatusType: string;
  estatusSeleccionado: any;

  phases: any = [];
  phasesAnteriores: any = [];
  phasesSiguientes: any = [];
  phaseComentarios: string;

  estatusActual: any;
  showDocuments: boolean = false;

  typesIncidentAuto = [2,6,7,10,11,12];
  isLoadIncident: boolean = false;

  constructor(private api: ApiService,
              private router: Router,
              private toastr: ToastrService,
              private shared: SharedService,
              private datePipe: DatePipe,
              public fb: FormBuilder,
              private modalService: BsModalService,
              private spinner: NgxSpinnerService,
              private ngWizardService: NgWizardService) {
    this.dataContactForm = new FormGroup({
      phone: new FormControl(null, [
        Validators.pattern(regex.telefonoSinGuion),
        Validators.required
      ])
    });
  }
  catalogForm = this.fb.group({
    catalogs: ['groups', [Validators.required]]
  });
  ngOnInit() {
    this.starRating = 0;
    this.incidentId = Number(localStorage.getItem('incidentId'));
    this.getIncidentDetail(this.incidentId);
    this.loadFilters();
    this.dropdownSettingsAuthor = {
      singleSelection: false,
      idField: 'userId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON AUTHORES',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsTypes = {
      singleSelection: false,
      idField: 'typeRequestId',
      textField: 'description',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON EVENTOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.flag = 1;
    this.getTypesRequestType(this.flag);
  }

  changeTipoPersona(): void {
    this.showDocuments = false;
    setTimeout(() => {
      this.showDocuments = true;
    }, 500);
  }

  /* -----------------------------------------------------------------------------------------------------
   ----------------------------- F U N C I O N E S G E N E R A L E S ---------------------------------------
   ----------------------------------------------------------------------------------------------------- */
  goToBack() {
    this.shared.gSearchId = 0;
    this.ngOnDestroyInterval();
    this.router.navigate(['/admin/incidents']);
  }
  stepChanged(args: StepChangedArgs) {
    this.typeStep = args.step.index;
    console.log('entra a pesataña');
    console.log(this.typeStep);
    if (this.typeStep === 4 && this.dataIncident.classification != 0) {
      this.typeStep = 5;
    }
    if (this.typeStep === 4 && this.dataIncident.classification == 0) {
      this.typeStep = 4;
    }
    if (this.typeStep === this.selectedStepIndex) {
      console.log('No debe de entrar de nuevo a cargar la info');
    } else {
      console.log('debe de entrar y redireccionar el selectStepIndex para los botones');
      this.selectedStepIndex = this.typeStep;
      switch (this.typeStep) {
        case 0:
          console.log('datos generales');
          this.ngOnDestroyInterval();
          break;
        case 1:
          console.log('documentos');
          this.invokeServiceGetIdConfigurationByIdSubProcess();
          this.ngOnDestroyInterval();
          break;
        case 2:
          console.log('comunicación');
          this.countMessageChat = 0;
          this.messageClear();
          this.InvokeServiceIncidentMessagesAttention(1, this.incidentId, 2000);
          if (this.id !== undefined) {
            this.ngOnDestroyInterval();
          }
          this.id = setInterval(() => {
            this.InvokeServiceIncidentMessagesAttention(1, this.incidentId, 200);
          }, 5000);
          this.loadTableLog();
          break;
        case 3:
          console.log('bitacora');
          this.ngOnDestroyInterval();
          this.messageLogClear();
          this.loadDataRequestMessageFilter();
          this.invokeServiceGetAuthors();
          this.invokeServiceGetTypeEvents();
          this.getByPagination(1, 5);
          this.invokeServiceGetTimelineIncident();
          break;
        case 4:
          console.log('clasificacion');
          this.ngOnDestroyInterval();
          this.loadIncidentClassification();
          break;
        case 5:
          this.invokeServiceGetQuizByIncident();
          console.log('encuesta');
          break;
      }
      if (this.selectedStepIndex === 0) {
        this.disableBtnPrevious = true;
        this.disableBtnNext = false;
      } else if (this.selectedStepIndex === this.totalSteps) {
        this.disableBtnNext = true;
        this.disableBtnPrevious = false;
      }
    }
  }
  isValidFunctionReturnsBoolean(args: StepValidationArgs) {
    return true;
  }
  previousStep() {
    this.selectedStepIndex--;
    if (this.selectedStepIndex == 4 && this.dataIncident.classification != 0) {
      this.selectedStepIndex--;
      this.ngWizardService.show(  3);
    }
    if (this.selectedStepIndex > 0) {
      this.stepIndexSelected(this.selectedStepIndex);
      this.disableBtnPrevious = false;
      this.disableBtnNext = false;

    } else if (this.selectedStepIndex === 0) {
      this.stepIndexSelected(this.selectedStepIndex);
      this.disableBtnPrevious = true;
    }
  }
  nextStep() {
    this.selectedStepIndex++;
    if (this.selectedStepIndex == 4 && this.dataIncident.classification != 0) {
      this.selectedStepIndex++;
      this.ngWizardService.show(  4);
    }
    if (this.selectedStepIndex < this.totalSteps) {
      this.disableBtnNext = false;
      this.disableBtnPrevious = false;
      this.stepIndexSelected(this.selectedStepIndex);
    } else if (this.selectedStepIndex === this.totalSteps) {
      this.disableBtnNext = true;
      this.stepIndexSelected(this.selectedStepIndex);
    }
  }
  stepIndexSelected(selectedStepIndex) {
    this.ngWizardService.show(selectedStepIndex);
    this.typeStep = selectedStepIndex;
    switch (selectedStepIndex) {
      case 0:
        console.log('datos generales');
        this.getIncidentDetail(this.incidentId);
        this.ngOnDestroyInterval();
        break;
      case 1:
        console.log('documentos');
        this.invokeServiceGetIdConfigurationByIdSubProcess();
        this.ngOnDestroyInterval();
        break;
      case 2:
        console.log('comunicación');
        this.countMessageChat = 0;
        this.messageClear();
        this.InvokeServiceIncidentMessagesAttention(1, this.incidentId, 2000);
        if (this.id != undefined) {
          this.ngOnDestroyInterval();
        }
        this.id = setInterval(() => {
          this.InvokeServiceIncidentMessagesAttention(1, this.incidentId, 200);
        }, 5000);
        break;
      case 3:
        console.log('bitacora');
        this.ngOnDestroyInterval();
        this.messageLogClear();
        this.loadDataRequestMessageFilter();
        this.invokeServiceGetAuthors();
        this.invokeServiceGetTypeEvents();
        this.getByPagination(1, 5);
        break;
      case 4:
        console.log('clasificacion');
        this.ngOnDestroyInterval();
        this.loadIncidentClassification();
        break;
      case 5:
        this.invokeServiceGetQuizByIncident();
        console.log('encuesta');
        break;
    }
  }
  /* -----------------------------------------------------------------------------------------------------
    ------------------------D A T O S G E N E R A L E S S I N I E S T R O-----------------------------------------------
    ----------------------------------------------------------------------------------------------------- */
  getIncidentDetail(incidentId) {
    this.spinner.show('sp');
    this.api.getIncidentDetail(incidentId).then((data: any) => {
      this.dataIncident = data;
      console.log(this.dataIncident);
      this.spinner.hide('sp');
      this.isIncidentAuto = this.typesIncidentAuto.indexOf(this.dataIncident.typeIncidentId) >= 0;
      this.isIncident = this.typesIncidentAuto.indexOf(this.dataIncident.typeIncidentId) < 0;
      if (this.isIncidentAuto) {
        this.totalSteps = 2;
        this.getPhasesByCatType();
        this.api.getIncidentDetailCar({ incidentId: incidentId }).then((data: any) => {
          this.dataIncident.tipoSiniestro = this.dataIncident.typeIncidentId + '';
          if (data) {
            this.dataIncident = {...data, ...this.dataIncident};
            this.dataIncident.necesitaGrua = data.necesitaGrua + '';
            this.dataIncident.hayLesionados = data.hayLesionados + '';
            this.dataIncident.hayTercerAuto = data.hayTercerAuto + '';
            this.isLoadIncident = true;
          } else {
            if (this.dataIncident.phase_id === 3 || this.dataIncident.phase_id === 10) {
              this.dataIncident.phase_id = null;
              this.isIncidentAuto = false;
              this.isIncident = true;
              this.isLoadIncident = true;
            }
          }
          this.getPhasesById();
        });
      } else {
        this.isLoadIncident = true;
      }
      this.getIncidentTypes(this.dataIncident.policyId);
      this.getTypesRequest();
      this.getGroups();
      if (this.dataIncident.incidentIdOld > 0) {
        this.invokeServiceGetIncidentByUserId();
      }
    }, error => {
      this.spinner.hide('sp');
      this.dataIncident = {};
    });
  }
  afterIncidentDetail(dataIncident: any): void {

  }
  getPhasesByCatType(): void {
    this.api.getPhasesIncidentCarByCatType({ catPhaseIncidentId: this.dataIncident.typeIncidentId, ordenPhase: null }).then((response) => {
      console.log(response);
    });
  }
  getPhasesByOrden(): void {
    this.api.getPhasesIncidentCarByOrden({ catPhaseIncidentId: this.dataIncident.typeIncidentId, ordenPhase: null, phaseId: this.dataIncident.phase_id === 0 ? null : this.dataIncident.phase_id }).then((response: any) => {
      console.log(response);
      this.phasesAnteriores = response.anteriores;
      this.phasesSiguientes = response.siguientes;
      const data = response.anteriores.concat(response.siguientes);
      const hash = {};
      const noDuplicados = data.filter(o => hash[o.name] ? false : hash[o.name] = true);
      this.phases = noDuplicados;
    });
  }
  getPhasesById(): void {
    this.api.getPhasesById({ phaseId: this.dataIncident.phase_id, ordenPhase: 0, catPhaseIncidentId: 0 }).subscribe(response => {
      this.estatusActual = response;
      if (!this.estatusActual || this.estatusActual.name !== 'CERRADO') {
        this.getPhasesByOrden();
      }
    },
    error => {
      console.log(error);
    });
  }
  isNotCerrado(): boolean {
    return (this.estatusActual && this.estatusActual.name !== 'CERRADO') || this.estatusActual === null;
  }
  public changeTypeIncidentToConsult(event: any) {
    /*for (const item of this.typeIncidentList) {
      if (this.incidentTypeSelected == item.typeIncidentId) {
        this.dataIncident.typeIncidentId = item.typeIncidentId;
      }
    }*/
    if (event !== undefined){
      this.dataIncident.typeIncidentId = event.typeIncidentId;
    }
  }
  getIncidentTypes(policyId) {
    this.spinner.show('sp');
    this.api.getIncidentTypes(policyId).then((data: any) => {
      this.typeIncidentList = data;
      this.incidentTypeSelected = this.dataIncident.typeIncidentId;
      this.spinner.hide('sp');
    }, error => {
      this.spinner.hide('sp');
    });
  }
  public changeTypeRequestToConsult() {
    console.log('changetyperequest');
    for (const item of this.typeRequestList) {
      if (Number(this.typeRequestSelected) === item.requestTypeIncidentId) {
        this.dataIncident.typeRequestIncidentId = item.requestTypeIncidentId;
      }
    }
  }
  getTypesRequest() {
    /*this.spinner.show('sp');
    this.api.getTypesRequestIncident().then((data: any) => {
      this.typeRequestList = data;
      this.typeRequestSelected = this.dataIncident.typeRequestIncidentId;
      this.typedDocumentationSelected = this.dataIncident.typeRequestIncidentId;
      this.spinner.hide('sp');
    }, error => {
      this.spinner.hide('sp');
    });*/
    this.spinner.show('sp');
    this.api.getTypesRequestIncidentHigh(this.dataIncident.classification).then((data: any) => {
      this.typeRequestList = data;
      this.typeRequestSelected = this.dataIncident.typeRequestIncidentId;
      this.typedDocumentationSelected = this.dataIncident.typeRequestIncidentId;
      this.spinner.hide('sp');
    }, error => {
      this.spinner.hide('sp');
    });
  }
  changeGroupToConsult() {}
  changeClientToConsult() {}
  getGroups() {
    this.api.getGroups().then((data: any) => {
      this.groupList = data;
      this.groupSelected = this.dataIncident.groupId;
      const group = [];
      group.push(this.dataIncident.groupId);
      let multiGroup = {};
      multiGroup = {
        idsGroups: group
      };
      this.getSubGroups(multiGroup);
    }, error => {
    });
  }
  getSubGroups(groups) {
    this.api.getSubGroupsMultiselect(groups).then((data: any) => {
      this.clientList = data;
      this.clientSelected = this.dataIncident.clientId;
    }, error => {
    });
  }

  onShowClassification() {
    this.selectedStepIndex = 4;
    this.loadIncidentClassification();
    this.ngWizardService.show(4);
    if (this.selectedStepIndex < this.totalSteps) {
      this.disableBtnNext = false;
      this.disableBtnPrevious = false;
    } else if (this.selectedStepIndex === this.totalSteps) {
      this.disableBtnNext = true;
    }
  }
  invokeServiceUpdateIncident() {
    this.spinner.show('sp');
    if (!this.isIncidentAuto) {
      this.api.updateIncident(this.dataIncident)
        .then(
          (response: any) => {
            this.toastr.success('EL SINIESTRO SE ACTUALIZO CORRECTAMENTE', 'NOTIFICACIÓN');
            this.spinner.hide('sp');
          }, error => {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            this.spinner.hide('sp');
          }
        );
    } else {
      this.api.updateIncidentCar(this.dataIncident)
        .then(
          (response: any) => {
            this.toastr.success('EL SINIESTRO SE ACTUALIZO CORRECTAMENTE', 'NOTIFICACIÓN');
            this.spinner.hide('sp');
          }, error => {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            this.spinner.hide('sp');
          }
        );

    }
  }

  actualizarPhase(): void {
    const data = {
      phaseId: this.estatusSeleccionado.idPhase,
      incidentId: this.dataIncident.incidentId,
      comentarios: this.phaseComentarios,
      typeRequestIncidentId: this.idConfigurationDocument
    };
    this.api.updateIncidentPhaseCar(data).subscribe(response => {
      this.toastr.success('EL SINIESTRO SE ACTUALIZO CORRECTAMENTE', 'NOTIFICACIÓN');
      this.goToBack();
    },
    error => {
      console.log(error);
    });
  }
  /* -----------------------------------------------------------------------------------------------------
      --------------------------------------D O C U M E N T O S-----------------------------------------------
      ----------------------------------------------------------------------------------------------------- */
  invokeServiceGetIdConfigurationByIdSubProcess() {
    if (!this.isIncidentAuto)  {
      this.idConfigurationDocument = this.dataIncident.typeRequestIncidentId;
          // inicializan valores para documentos
      const configDocumentation = {
            configurationDocumentationId: this.idConfigurationDocument ,
            name: '',
            proccessId: 0,
            subproccessId: 0,
            process: '',
            subprocess: '',
            status: 0,
            createdAt: '',
            updated: '',
            number: 0,
            catTypeDocumentId: null,
            required: false,
            total: 0,
            statusName: ''
          };
      this.invokeServiceGetDocuments(configDocumentation);
    }
  }
  changeEstatus(event: any): void {
    this.api.getConfigurationIdByPhase({ phaseId: event.idPhase }).subscribe((response: any) => {
      this.idConfigurationDocument = response.configurationId;
          // inicializan valores para documentos
      const configDocumentation = {
            configurationDocumentationId: this.idConfigurationDocument ,
            name: '',
            proccessId: 0,
            subproccessId: 0,
            process: '',
            subprocess: '',
            status: 0,
            createdAt: '',
            updated: '',
            number: 0,
            catTypeDocumentId: null,
            required: false,
            total: 0,
            statusName: ''
          };
      //this.invokeServiceGetDocuments(configDocumentation);
    },
    error => {
      console.log(error);
    });
  }
  invokeServiceGetDocuments(configDocumentation: any) {
    const data = configDocumentation;
    this.api.getDocuments(data).then((result: any) => {
      if (result != null) {
        this.typeDocumentList = result;
        this.invokeServiceGetDocumentsLoad();
      }
    }, error => {
      if (error.status === 409) {
        this.toastr.error('Hubo un error al procesar la información de los documentos.', 'Notificación');
      } else if (error.status === 204) {
        this.toastr.warning('No se encontró información de los documentos.', 'Notificación');
      }
    });
  }

  onLoadDocuments(event) {
    this.uploadedFilesToLoad = event;
  }
  invokeServiceGetDocumentsLoad() {
    this.api.getDocumentsIncident(this.dataIncident.incidentId).then((result: any) => {
      if (result != null) {
        this.documentsLoads = result;
        this.uploadedFilesToLoad = [];
        this.documentsLoads.map((DOCCMODEL) => {
          const documents = new Documents();
          documents.base64 = DOCCMODEL.base;
          documents.number = DOCCMODEL.catTypeDocumentId;
          documents.name = DOCCMODEL.name;
          documents.idDocument = DOCCMODEL.incidentDocumentId;
          this.uploadedFilesToLoad.push(documents);
        });
      }
    }, error => {
      if (error.status === 409) {
        this.toastr.error('Hubo un error al procesar la información de los documentos.', 'Notificación');
      } else if (error.status === 204) {
        this.toastr.warning('No se encontró información de los documentos.', 'Notificación');
      }
    });
  }

  /* -----------------------------------------------------------------------------------------------------
      --------------------------------------C O M U N I C A C I O N-----------------------------------------------
      ----------------------------------------------------------------------------------------------------- */
  onUploadChange(evt: any) {
    const file = evt.target.files[0];
    this.fileUploadAttachment = evt.target.files[0];
    this.ext = this.fileUploadAttachment.name.slice((this.fileUploadAttachment.name.lastIndexOf('.') - 1 >>> 0) + 2);
    this.dataMessage.attachmentName = this.fileUploadAttachment.name;
    if (file) {
      const reader = new FileReader();
      reader.onload = this.handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }
  handleReaderLoaded(e) {
    this.dataMessage.path = btoa(e.target.result);
  }
  InvokeServiceSendMessage() {
    this.spinner.show('sp');
    this.dataMessage.incidentId = this.incidentId;
    this.dataMessage.userId = localStorage.getItem('user-id');
    this.dataMessage.typeEvent = 1;
    this.dataMessage.userType = 2;
    if (this.dataMessage.message == '') {
      this.toastr.warning('POR FAVOR INGRESE UN MENSAJE A ENVIAR', 'NOTIFICACIÓN');
      this.spinner.hide('sp');
      return;
    } // cambiar por el nuevo servicio de enviar mensaje ammia
    const ammia = {
      incidentId: this.incidentId,
      message: this.dataMessage.message,
      base64: this.dataMessage.path,
      extencion: this.ext,
      keyDocumentType: ''
    };
    this.invokeServiceSendMessageCore(ammia);
    setTimeout( () => {
    this.messageClear();
    this.spinner.hide('sp');
    (document.getElementById('fileChat') as HTMLInputElement).value = null;
    this.InvokeServiceIncidentMessagesAttention(1, this.incidentId, 500);
    }, 500);
    /*
    this.api.postSendMessageIncident(this.dataMessage)
      .then(
        (response: any) => {
          this.messageClear();
          this.spinner.hide('sp');
          (document.getElementById('fileChat') as HTMLInputElement).value = null;
          this.InvokeServiceIncidentMessagesAttention(1, this.incidentId, 500);
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );*/
  }
  invokeServiceSendMessageCore(ammia) {
    this.api.sendMessageFromCoreIncident(ammia).then((data: any) => {
      if (data == null) {
        console.log(data);
      }
      this.incidentList = data;
    }, error => {
    });
  }
  invokeServiceSendMessageCoreFinished(ammia) {
    this.api.sendMessageFromCoreIncidentLetter(ammia).then((data: any) => {
      if (data == null) {
        console.log(data);
      }
      this.incidentList = data;
    }, error => {
    });
  }
  messageClear() {
    this.dataMessage = {
      id: 0,
      incidentId: 0,
      message: '',
      attachmentName: '',
      path: '',
      userId: 0,
      createDate: '',
      typeEvent: 0,
      userType: 0
    };
    this.ext = '';
    this.fileUploadAttachment = undefined;
  }
  InvokeServiceIncidentMessagesAttention(type, incidentId, timeout) {
    setTimeout( () => {
      this.api.getIncidentMessagesAttention(type , incidentId).then((data: any) => {
        this.dataIncidentMessages = data;
        if (this.countMessageChat !== this.dataIncidentMessages.length) {
          this.spinner.show('sp');
          this.countMessageChat = this.dataIncidentMessages.length;
          setTimeout( () => {
            this.loadScroll();
            this.spinner.hide('sp');
          }, 300);
        }
      }, error => {
      });
    }, timeout);
  }
  loadScroll() {
    const objDiv = document.getElementById('chat');
    if (objDiv) {
      objDiv.scrollTop = objDiv.scrollHeight;
    }
  }
  ngOnDestroyInterval() {
    if (this.id) {
      clearInterval(this.id);
    }
  }
  downloadFile(m) {
    // console.log(m);
    this.api.getAttachmentIncidentById(m.id)
      .subscribe(
        (response: any) => {
          // console.log(response);
          if (response == null) {
            this.toastr.info('No existen archivos para descargar');
            return;
          } else {
            const dataType = response.type;
            const binaryData = [];
            binaryData.push(response);
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            downloadLink.setAttribute('download', m.attachmentName);
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
          }
        }, error => {
          // console.error(error);
        }
      );
  }
  invokeServiceDownloadExcelChat() {
    this.spinner.show('sp');

    this.api.getReportChatXls(this.incidentId)
      .then(
        (response: any) => {
          console.log(response);
          if (response == null) {
            this.toastr.info('No se encontraron registros para descargar');
            this.spinner.hide('sp');
            return;
          } else {
            const dataType = response.type;
            const binaryData = [];
            binaryData.push(response);
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            downloadLink.setAttribute('download', 'Historial de Chat.xls');
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
            this.spinner.hide('sp');
          }

        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición ', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  /* -----------------------------------------------------------------------------------------------------
       --------------------------------------B I T A C O R A-----------------------------------------------
       ----------------------------------------------------------------------------------------------------- */
  loadFilters() {
    this.filters = {
      description: '',
      authors: [],
      typeEvents: [],
      incidentId: this.incidentId,
      page: 1,
      size: 5
    };
  }
  changeStatusAuthor() {
    const authors = [];
    setTimeout(() => {
      try {
        this.authorSelectedItems.forEach(item => {
          authors.push(item.userId);
        });
        this.filters.authors = authors;
        this.getByPagination(1, 5);
      } catch (e) { }
    }, 500);
  }
  changeStatusTypes() {
    const events = [];
    setTimeout(() => {
      try {
        this.typesSelectedItems.forEach(item => {
          events.push(item.typeRequestId);
        });
        this.filters.typeEvents = events;
        this.getByPagination(1, 5);
      } catch (e) { }
    }, 500);
  }
  clearFiltersTable() {
    this.loadFilters();
    this.authorSelectedItems = [];
    this.typesSelectedItems = [];
    this.getByPagination(1, 5);
  }
  loadTableLog() {
    this.getByPagination(1, 5);
  }
  getByPagination(page, size) {
    this.spinner.show('sp');
    this.filters.page = page;
    this.filters.size = size;
    this.filters.incidentId = this.incidentId;
    this.api.getIncidentPaginationLog(this.filters)
      .then(
        (response: any) => {
          if (response == null) {
            this.toastr.info('NO SE ENCONTRARON RESULTADOS CON SU BUSQUEDA');
            this.requestMessagesList = [];
            this.pgTotalItems = 0;
            this.spinner.hide('sp');
            return;
          }
          this.paginationResponse = response;
          this.spinner.hide('sp');
          this.pgTotalItems = this.paginationResponse.total ;
          this.requestMessagesList = this.paginationResponse.incidentMessages ;
        }, error => {
          this.requestMessagesList = [];
          this.pgTotalItems = 0;
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  pageChanged(event: PageChangedEvent): void {
    this.pgCurrentPage = event.page;
    this.getByPagination(this.pgCurrentPage, this.pgItemsPerPage);
    this.changeColorPagination();
  }
  changeColorPagination() { }
  openModalDetail(template: TemplateRef<any>, id) {
    setTimeout( () => {
      this.invokeServiceGetIncidentMessageDetail(id);
    }, 1000);
    this.modalRef = this.modalService.show(template, {class: 'modal-md'});
  }
  invokeServiceGetIncidentMessageDetail(id) {
    this.api.getIncidentMessageDetail(id).then((data: any) => {
      this.dataMessageDetail = data;
    }, error => {
    });
  }
  closeRequestDetail() {
    this.ngOnDestroyInterval();
    localStorage.removeItem('request-id');
    this.modalRef.hide();
  }
  closeModal() {
    this.modalRef.hide();
  }
  onUploadChangeLog(evt: any) {
    const file = evt.target.files[0];
    this.fileUpload = evt.target.files[0];
    this.dataMessageLog.attachmentName = this.fileUpload.name;
    if (file) {
      const reader = new FileReader();
      reader.onload = this.handleReaderLoadedLog.bind(this);
      reader.readAsBinaryString(file);
    }
  }
  handleReaderLoadedLog(e) {
    this.dataMessageLog.path = btoa(e.target.result);
  }
  messageLogClear() {
    this.dataMessageLog = {
      id: 0,
      incidentId: 0,
      message: '',
      attachmentName: '',
      path: '',
      userId: 0,
      createDate: '',
      typeEvent: 0,
      userType: 0
    };
    this.fileUpload = undefined;
  }
  sendMessageLog() {
    this.spinner.show('sp');
    this.dataMessageLog.incidentId = this.incidentId;
    this.dataMessageLog.userId = localStorage.getItem('user-id');
    this.dataMessageLog.typeEvent = 2;
    this.dataMessageLog.userType = 2;
    if (this.dataMessageLog.message == '') {
      this.toastr.warning('POR FAVOR INGRESE UN EVENTO A ENVIAR', 'NOTIFICACIÓN');
      this.spinner.hide('sp');
      return;
    }
    if (!this.dataMessageLog.attachmentName) {
      this.dataMessageLog.attachmentName = '';
    }
    if (!this.dataMessageLog.path) {
      this.dataMessageLog.path = '';
    }
    console.log(this.dataMessageLog);
    this.api.postSendMessageIncident(this.dataMessageLog)
      .then(
        (response: any) => {
          this.messageLogClear();
          (document.getElementById('fileLog') as HTMLInputElement).value = null;
          this.getByPagination(1, 5);
          this.spinner.hide('sp');
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  loadDataRequestMessageFilter() {
    this.dataRequestMessageFilter = {
      description : '',
      authors: [],
      typeEvents: [],
      incidentId: this.incidentId,
      page: 0,
      size: 0
    };
  }
  invokeServiceGetAuthors() {
    this.api.getIncidentAuthors(this.incidentId).then((data: any) => {
      this.dataRequestAuthor = data;
    }, error => {
    });
  }
  invokeServiceGetTypeEvents() {
    this.api.getIncidentTypesEvents(this.incidentId).then((data: any) => {
      this.dataRequestEvents = data;
    }, error => {
    });
  }
  invokeServiceUpdateStatusIncident(template: TemplateRef<any>, status) {
    this.phaseId = status;
    this.fileUploadControl3.clear();
    this.dataFinishIncident = {
      b64: '',
      name: '',
      incidentId: this.incidentId,
      phaseId: this.phaseId,
      numberIncident: '',
      observations: ''
    };
    if (this.dataIncident.classification === 2) {
      this.dataFinishIncident.numberIncident = this.dataIncident.folioInsurance;
    }
    const myArray = this.dataIncident.folioSurexs.split('_', 1);
    console.log(myArray);
    if (myArray[0] === 'SNVEHI') {
      this.spinner.show('sp');
      this.api.updateStatusIncident(this.dataFinishIncident)
        .then(
          (response: any) => {
            this.getIncidentDetail(this.incidentId);
            this.toastr.success('LA OPERACIÓN SE REALIZO CON EXITO', 'NOTIFICACION');
            this.spinner.hide('sp');
            this.getByPagination(1, 5);
            this.invokeServiceSendMessageCoreFinished(response);
          }, error => {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            this.spinner.hide('sp');
          }
        );
    } else {
      // agregar que si es siniestro por pago directo no pida la carta
      if (this.dataIncident.typeRequestIncidentId === 1) {
        this.dataFinishIncident.name = 'FFTS-1';
      }
      this.modalRef = this.modalService.show(template, {class: 'modal-md'});
    }
  }

  invokeServiceCancelStatusIncident(template: TemplateRef<any>, status) {
    this.phaseId = status;
    this.dataFinishIncident = {
      b64: '',
      name: this.dataIncident.description,
      incidentId: this.incidentId,
      phaseId: 5,
      numberIncident: '',
      observations: ''
    };
    this.modalRef = this.modalService.show(template, {class: 'modal-md'});
  }

  invokeServiceDownloadExcel() {
    this.spinner.show('sp');

    this.api.getReportLogXls(this.filters)
      .then(
        (response: any) => {
          console.log(response);
          if (response == null) {
            this.toastr.info('No se encontraron registros para descargar');
            this.spinner.hide('sp');
            return;
          } else {
            const dataType = response.type;
            const binaryData = [];
            binaryData.push(response);
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            downloadLink.setAttribute('download', 'Historial de Bitacora.xls');
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
            this.spinner.hide('sp');
          }

        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición ', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }

  onLoadDocument3() {
    if (this.fileUploadControl3.invalid) {
      this.fileUploadControl3.getError().map(ERROR => {
        if (ERROR.fileSize !== undefined) {
          this.toastr.warning('Archivo inválido. El tamaño del archivo debe ser menor a 25 MB', 'Notificación');
        } else if (ERROR.fileTypes !== undefined) {
          this.toastr.warning('Archivo inválido. Formato no válido, solo PDF, JPEG, JPG y PNG', 'Notificación');
        }
      });
    } else {
      this.getBase64(this.fileUploadControl3.value[0]).then(
        res => {
          this.dataFinishIncident.b64 = res.split(',')[1];
          this.dataFinishIncident.name = this.fileUploadControl3.value[0].name;
          this.dataFinishIncident.incidentId = this.incidentId;
          this.dataFinishIncident.phaseId = this.phaseId;
          /*this.dataFinishIncident = {
            b64: res.split(',')[1],
            name: this.fileUploadControl3.value[0].name,
            incidentId: this.incidentId,
            phaseId: this.phaseId
          };*/
        }
      );
    }
  }
  getBase64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.toString());
      reader.onerror = error => reject(error);
    });
  }

  invokeServiceFinishIncident() {
    this.spinner.show('sp');
    this.api.updateStatusIncident(this.dataFinishIncident)
      .then(
        (response: any) => {
          this.getIncidentDetail(this.incidentId);
          this.toastr.success('LA OPERACIÓN SE REALIZO CON EXITO', 'NOTIFICACION');
          this.spinner.hide('sp');
          this.getByPagination(1, 5);
          this.invokeServiceGetTimelineIncident();
          this.closeModal();
          // this.invokeServiceSendMessageCore(response);
          this.invokeServiceSendMessageCoreFinished(response);
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }

  invokeServiceCancelIncident() {
    console.log(this.dataFinishIncident);
    this.spinner.show('sp');
    this.api.cancelStatusIncident(this.dataFinishIncident)
      .then(
        (response: any) => {
          this.getIncidentDetail(this.incidentId);
          this.toastr.success('LA OPERACIÓN SE REALIZO CON EXITO', 'NOTIFICACION');
          this.spinner.hide('sp');
          this.getByPagination(1, 5);
          this.invokeServiceGetTimelineIncident();
          this.closeModal();
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }

  invokeServiceGetTimelineIncident() {
    this.api.getTimelineByIncident(this.incidentId).then((data: any) => {
      this.dataTimeline = data;
    }, error => {
    });
  }
  /* -----------------------------------------------------------------------------------------------------
         -------------------------------------C L A S I F I C A C I O N-----------------------------------------------
         ----------------------------------------------------------------------------------------------------- */

  getTypesRequestType(type: number) {
    this.spinner.show('sp');
    this.api.getTypesRequestIncidentHigh(type).then((data: any) => {
      this.typeRequestList2 = data;
      this.spinner.hide('sp');
    }, error => {
      this.spinner.hide('sp');
    });
  }
  loadCatalog(value) {
    this.flag = value.catalogs;
    this.incidentClassification.classification = this.flag;
    switch (this.flag) {
      case 2:
        this.invokeServiceGetIncidentByUserId();
        break;
    }
    this.getTypesRequestType(this.flag);
  }
  changeTypeIncident() {
    for (const item of this.incidentList) {
      if (this.incidentSelected == item.incidentId) {
        this.dataIncidentClassification = item;
        this.incidentClassification.incidentIdOld = item.incidentId;
      }
    }
  }
  changeDocumentationToConsult(event: any) {
    /*for (const item of this.typeRequestList) {
      if (this.typedDocumentationSelected == item.requestTypeIncidentId) {
        this.incidentClassification.typeDocument = item.requestTypeIncidentId;
      }
    }*/
    if (event !== undefined) {
      this.incidentClassification.typeDocument = event.requestTypeIncidentId;
    }
  }
  invokeServiceGetIncidentByUserId() {
    this.api.getIncidentsByUserId(this.dataIncident.userId).then((data: any) => {
      if (data == null) {
        this.toastr.info('EL USUARIO NO CUENTA CON SINIESTROS PREVIOS');
        this.incidentList = [];
        return;
      }
      this.incidentList = data;
      if (this.dataIncident.incidentIdOld > 0) {
        this.incidentSelected = this.dataIncident.incidentIdOld;
        console.log('siniestro origen: ' + this.incidentSelected);
      }
    }, error => {
    });
  }
  invokeServiceClassification() {
    this.spinner.show('sp');
    if (this.incidentClassification.typeDocument === 0) {
      this.toastr.info('TIENE QUE SELECCIONAR UN TIPO DE DOCUMENTACIÓN VALIDO');
      this.spinner.hide('sp');
      return;
    }
    if (this.incidentClassification.classification === 2 && this.incidentClassification.incidentIdOld === 0) {
      this.toastr.info('TIENE QUE SELECCIONAR EL SINIESTRO ORIGEN PARA LA RECLAMACIÓN COMPLEMENTARIA');
      this.spinner.hide('sp');
      return;
    }
    this.api.updateClassificationIncident(this.incidentClassification)
      .then(
        (response: any) => {
          this.getIncidentDetail(this.incidentId);
          this.toastr.success('EL SINIESTRO SE CLASIFICO CORRECTAMENTE');
          this.spinner.hide('sp');
          this.ngWizardService.show(0);
          this.selectedStepIndex = 0;
          if (this.selectedStepIndex > 0) {
            this.disableBtnPrevious = false;
            this.disableBtnNext = false;

          } else if (this.selectedStepIndex === 0) {
            this.disableBtnPrevious = true;
          }
          this.invokeServiceSendMessageCore(response);
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  loadIncidentClassification() {
    this.incidentClassification = {
      incidentId: this.dataIncident.incidentId,
      classification: 1,
      incidentIdOld: 0,
      typeDocument: 0
    };
  }
  /* -----------------------------------------------------------------------------------------------------
         -------------------------------------E N C U E S T A-----------------------------------------------
         ----------------------------------------------------------------------------------------------------- */
  invokeServiceGetQuizByIncident() {
    this.api.getQuizByIncident(this.dataIncident.incidentId).then((data: any) => {
      this.quizIncidentList = data;
      if (this.quizIncidentList[0].answer) {
        this.starRating = 1;
      }
    }, error => {
    });
  }
  invokeServiceSaveQuiz() {
    this.spinner.show('sp');
    this.api.saveQuizByIncident(this.quizIncidentList)
      .then(
        (response: any) => {
          this.toastr.success('LA ENCUESTA SE GUARDO CORRECTAMENTE', 'NOTIFICACIÓN');
          this.spinner.hide('sp');
          this.invokeServiceGetQuizByIncident();
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
}
