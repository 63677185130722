import { Component, OnInit, EventEmitter } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Input, Output} from '@angular/core';
import {ApiService} from '../../api.service';
import {ToastrService} from 'ngx-toastr';
@Component({
  selector: 'app-flex-type-poblation',
  templateUrl: './flex-type-poblation.component.html',
  styleUrls: ['./flex-type-poblation.component.css']
})
export class FlexTypePoblationComponent implements OnInit {

 private _subranchId: number;

  @Input() hiddenTipoPlan: boolean = false;
  @Input() hiddenReduccion: boolean = false;

  typePopulationPolicyId: number;
  typePlanProductId: number;
  planReduction = false;
  planInit: any = {};
  @Output() planInitEmmiter: EventEmitter<object> = new EventEmitter<object>();

  addSpouse = false;
  addChildren = false;
  addMother = false;
  addFather = false;
  numberOfChildren = 0;
  selectedOption: string;
  typePopulationOptions: Array<{ key: number, value: string }> = [];
  planProductOptions: Array<{ key: number, value: string }> = [];

  form: FormGroup;

  constructor(private fb: FormBuilder,
              private api: ApiService,
              private toastr: ToastrService) {
    this.form = this.fb.group({
      typePopulationPolicyId: [null],
      typePlanProductId: [null],
      planReduction: [false]
    });
  }

  ngOnInit(): void {
    this.loadTypePopulationOptions();
    this.loadPlanProductOptions();
  }

  loadTypePopulationOptions(): void {
    this.api.getPopulationType()
      .then(
        (response: any) => {
          this.typePopulationOptions = response;
        }, error => {
          this.toastr.error('Ocurrió un problema al cargar el catálogo de Roles', 'Notificación');
        }
      );
  }

  loadPlanProductOptions(): void {
    this.api.getMobilityPlans()
      .then(
        (response: any) => {
          this.planProductOptions = response;
        }, error => {
          this.toastr.error('Ocurrió un problema al cargar el catálogo de Roles', 'Notificación');
        }
      );
  }

  onTypePlanProductIdChange(value: number): void {
    this.onSubmit();
  }
  onChildrenChange(value: number) {
    this.numberOfChildren = value;
  }

  onTypePopulationChange(value: number): void {
    this.typePopulationPolicyId = value;
    this.resetOptions();
    if (value == 2 || value == 3) {
      this.addChildren = true;
      this.numberOfChildren = 1;
    }
    switch (+value) {
      case 1:
        this.addSpouse = true;
        break;
      case 2:
        this.addMother = true;
        this.addFather = true;
        break;
      case 3:
        this.addSpouse = true;
        this.addMother = true;
        this.addFather = true;
        break;
      default:
        break;
    }

    this.onSubmit();
  }

  resetOptions(): void {
    this.addSpouse = false;
    this.addChildren = false;
    this.addMother = false;
    this.addFather = false;
  }

  onSubmit(): void {
    this.planInit = {
      subranchId: this._subranchId,
      typePopulationPolicyId: Number(this.typePopulationPolicyId),
      typePlanProductId: Number(this.typePlanProductId),
      planReduction: this.planReduction,
      addSpouse: this.addSpouse,
      addChildren: this.addChildren,
      addMother: this.addMother,
      addFather: this.addFather,
      numberOfChildren: Number(this.numberOfChildren),
    };
    this.planInitEmmiter.emit(this.planInit);
    console.log(this.planInit);
  }


  get subranchId(): number {
    return this._subranchId;
  }
  @Input()
  set subranchId(value: number) {
    this._subranchId = value;
  }

  setDependents(option: string) {
    // Reseteamos todas las propiedades a false antes de establecer las nuevas
    this.addSpouse = false;
    this.addChildren = false;
    this.addMother = false;
    this.addFather = false;

    switch (option) {
      case 'spouseAndChildren':
        this.addSpouse = true;
        this.addChildren = true;
        this.numberOfChildren = 1;
        break;
      case 'children':
        this.addChildren = true;
        this.numberOfChildren = 1;
        break;
      case 'spouseChildrenAndParents':
        this.addSpouse = true;
        this.addChildren = true;
        this.addMother = true;
        this.addFather = true;
        this.numberOfChildren = 1;
        break;
      case 'parents':
        this.addMother = true;
        this.addFather = true;
        break;
    }

    this.onSubmit();
  }
}
