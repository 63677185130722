import {Component, OnInit, TemplateRef, ViewChild, ChangeDetectorRef} from '@angular/core';
import {ApiService} from '../api.service';
import {Router, ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {DatePipe} from '@angular/common';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {NgxSpinnerService} from 'ngx-spinner';
import {ValidateAccessService} from "../validate-access.service";
import { NgxDateRangePickerOptions } from 'ngx-daterangepicker';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { ConfirmComponent } from '../dialogs/confirm/confirm.component';

import moment from 'moment';
import * as _ from 'lodash';
import { NullTemplateVisitor } from '@angular/compiler';

@Component({
  selector: 'app-mascotas-p2',
  templateUrl: './mascotas-p2.component.html',
  styleUrls: ['./mascotas-p2.component.css']
})
export class MascotasP2Component implements OnInit {

  planForm: FormGroup; // Formulario

  idPlan: number;
  idProduct: number;

  plan: any;
  tarifas: any = {};
  aseguradorasList: any = [];

  dropdownSettingsAseguradoras = {};

  constructor(private api: ApiService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private toastr: ToastrService,
              private shared: SharedService,
              private datePipe: DatePipe,
              public fb: FormBuilder,
              private modalService: BsModalService,
              private spinner: NgxSpinnerService,
              private formBuilder: FormBuilder,
              private cdr: ChangeDetectorRef,
              private validateAccess: ValidateAccessService) {
  }
  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.idPlan = +params.idPlan;
      this.idProduct = +params.idProduct;

      this.getConfigurationData();
    });

    this.getAseguradoras();
    this.dropdownSettingsAseguradoras = {
      singleSelection: true,
      idField: 'id',
      textField: 'code',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 4,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON ASEGURADORAS',
      searchPlaceholderText: 'BUSCAR'
    };

    this.createForm();
  }
  /* -----------------------------------------------------------------------------------------------------
  ----------------------------- F U N C I O N E S G E N E R A L E S ---------------------------------------
  ----------------------------------------------------------------------------------------------------- */
  goToBack() {
    this.shared.gSearchId = 0;
    this.router.navigate(['/admin/executive']);
  }

  createForm(): void {
    this.planForm = this.formBuilder.group({
      aseguradoras: [null],
      maximumMonths: [null],
      maximumYears: [null],
    });
  }

  getAseguradoras(): void {
    const params = {
      idProduct: this.idProduct
    };
    this.api.getInsuranceList(params).subscribe(response => {
      this.aseguradorasList = response;
      console.log(this.aseguradorasList);
    },
    error => {
      console.log(error);
    });
  }

  changeStatusAseguradoras(event: any): void {
  }

  getConfigurationData(): void {
    const params = {
      planFlexId: this.idPlan
    };

    this.api.getConfigurationData(params).subscribe(response => {
      this.plan = response;
      console.log(this.plan);
      this.plan.plans.forEach(planOb => {
        this.tarifas[planOb.planName] = {};
      });
    },
    error => {
      console.log(error);
    });
  }

  saveConfiguration(): void {
    if (!this.planForm.valid) {
      this.toastr.error('Faltan datos por capturar');
      return;
    }

    const aseguradoras = this.planForm.get('aseguradoras').value;
    let plans = [];
    aseguradoras.forEach(aseg => {
      const plansOb = this.plan.plans.map(plan => {
        const listCoverages = plan.coveragesPlan.map(cov => {
          const res = Object.assign({}, cov);
          res.value = +cov.value;

          return cov;
        });

        plan.coveragesPlan = listCoverages;
        plan.maximumMonths = +this.planForm.get('maximumMonths').value;
        plan.maximumYears = +this.planForm.get('maximumYears').value;
        plan.insuranceCompanyId = aseg.id;
        plan.netPremiumIndividual = +this.tarifas[plan.planName].netPremiumIndividual;
        plan.policyRights = +this.tarifas[plan.planName].policyRights;
        return plan;
      });

      plans = plans.concat(plansOb);
    });

    const params = {
      planFlexId: this.idPlan,
      typeConfigurationPolicyId: this.plan.typeConfigurationPolicyId,
      plans: plans
    };

    this.api.savePhaseTwoConfiguration(params).subscribe(response => {
      this.toastr.success('Configuración guardada correctamente');
    },
    error => {
      console.log(error);
    });
  }

}
