import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../api.service';
import {ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {BsModalService} from 'ngx-bootstrap/modal';
import {SharedService} from '../shared-service.service';
import {number} from '@amcharts/amcharts4/core';
import {regex} from '../../common/regex';

@Component({
  selector: 'app-flex-gmm-p2-view',
  templateUrl: './flex-gmm-p2-view.component.html',
  styleUrls: ['./flex-gmm-p2-view.component.css']
})
export class FlexGmmP2ViewComponent implements OnInit {

  planFlexForm: FormGroup;
  idPlanFlex;
  isEdition = false;
  planPlanesModels: FormArray;
  planProductOptions: any = [];
  insuranceListDetail: any = [];
  showSpecialCoverages = false;
  isLoadTarrifManual;
  selectedPlanIndex; // Para saber qué plan está seleccionando el usuario
  isTariffSectionVisible: boolean[] = [];
  allCoverages: FlexPlanCoveragesModel[] = [];
  error = {active: false, msg: ''};
  public fileUpload: File;
  public fileUploadFactor: File;
  fileTypes: string[] = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.oasis.opendocument.spreadsheet'];
  isPoblation: boolean = true;
  constructor(private fb: FormBuilder,
              private api: ApiService,
              private activatedRoute: ActivatedRoute,
              private toastr: ToastrService,
              private shared: SharedService,
              private spinner: NgxSpinnerService,
              private modalService: BsModalService,
              private cd: ChangeDetectorRef) {
    this.activatedRoute.params.subscribe(params => {
      this.idPlanFlex = params.idPlan;
      this.isEdition = params.isEdition === 'true';
    });
    this.isPoblation = true;
    this.planFlexForm = this.fb.group({
      idFlexPlanGmm: [null],  // Long
      planFlexId: [this.idPlanFlex],  // Long
      typePopulationPolicyId: [null],  // Long
      typeConfigurationPolicyId: [null],  // Long
      addSpouse: [false],  // Boolean
      addChildren: [0],  // Boolean
      addMother: [false],  // Boolean
      addFather: [false],  // Boolean
      typePlanProductId: [null],  // Long
      planReduction: [0.0],  // Double
      creationDate: [null],  // Date
      updateDate: [null],  // Date
      creationUserId: [null],  // Long
      updateUserId: [null],  // Long
      productPlanFlexConfiguration: [null],
      planPlanesModels: this.fb.array([])
    });

    this.planPlanesModels = this.planFlexForm.get('planPlanesModels') as FormArray;
  }

  ngOnInit() {
    this.loadUnitOptions();
    this.getPlanGMMByIdPlanFlex();

    this.getInsuranceCompanies();

  }

  loadUnitOptions() {
    this.api.getUnitMeasurement()
      .then(
        (response: any) => {
          this.planProductOptions = response;
        }, error => {
          this.toastr.error('Ocurrió un problema al cargar el catálogo de Medidas', 'Notificación');
        }
      );
  }

  getPlanGMMByIdPlanFlex(): void {
    this.spinner.show('sp');
    this.api.getPlanGMMById(this.planFlexForm.get('planFlexId').value)
      .then(
        (response: any) => {
          this.populateFormWithResponseData(response);

          this.spinner.hide('sp');
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }

  populateFormWithResponseData(response: any): void {
    this.planFlexForm.reset();
    this.allCoverages = [];
    // Asignar directamente los valores principales del formulario
    this.planFlexForm.patchValue({
      idFlexPlanGmm: response.idFlexPlanGmm,
      planFlexId: response.planFlexId,
      typePopulationPolicyId: response.typePopulationPolicyId,
      typeConfigurationPolicyId: response.typeConfigurationPolicyId,
      addSpouse: response.addSpouse,
      addChildren: response.addChildren,
      addMother: response.addMother,
      addFather: response.addFather,
      typePlanProductId: response.typePlanProductId,
      planReduction: response.planReduction,
      productPlanFlexConfiguration: response.productPlanFlexConfiguration,
      creationDate: response.creationDate,
      updateDate: response.updateDate,
      creationUserId: response.creationUserId,
      updateUserId: response.updateUserId
    });

    // Limpiar el FormArray antes de agregar nuevos planes
    const planPlanesModelsArray = this.planFlexForm.get('planPlanesModels') as FormArray;
    planPlanesModelsArray.clear();

    // Iterar sobre los planes y agregarlos al FormArray
    response.planPlanesModels.forEach((plan: any, index: number) => {

      const planFormGroup = this.createPlan(plan.flexiblePlan, index + 1);
      const planId = plan.idFlexPlanPlanes;

      // Asignar valores al plan
      planFormGroup.patchValue({
        idFlexPlanPlanes: plan.idFlexPlanPlanes,
        flexPlanCategoriesId: plan.flexPlanCategoriesId,
        planName: plan.planName,
        flexiblePlan: plan.flexiblePlan,
        quote: plan.quote,
        premiumIndividual: plan.premiumIndividual,
        averagePremium: plan.averagePremium,
        netPremiumIndividual: plan.netPremiumIndividual,
        netPremiumGroup: plan.netPremiumGroup,
        policyRights: plan.policyRights,
        surcharges: plan.surcharges,
        insuranceCompanyId: plan.insuranceCompanyId,
        creationDate: plan.creationDate,
        updateDate: plan.updateDate,
        creationUserId: plan.creationUserId,
        updateUserId: plan.updateUserId,
      });

      // Asignar valores a la categoría del plan
      planFormGroup.get('flexPlanCategoriesModel').patchValue({
        idFlexPlanCategories: plan.flexPlanCategoriesModel.idFlexPlanCategories,
        categoryName: plan.flexPlanCategoriesModel.categoryName,
        planFlexId: plan.flexPlanCategoriesModel.planFlexId,
        flexibleProductId: plan.flexPlanCategoriesModel.flexibleProductId,
        creationDate: plan.flexPlanCategoriesModel.creationDate,
        updateDate: plan.flexPlanCategoriesModel.updateDate,
        creationUserId: plan.flexPlanCategoriesModel.creationUserId,
        updateUserId: plan.flexPlanCategoriesModel.updateUserId
      });


      // Asignar coberturas al plan
      const flexPlanCoveragesModelsArray = planFormGroup.get('flexPlanCoveragesModels') as FormArray;

      plan.flexPlanCoveragesModels.forEach((coverage: any) => {
        const coverageGroup = this.fb.group({
          idFlexPlanCoverages: coverage.idFlexPlanCoverages,
          flexPlanPlanesId: coverage.flexPlanPlanesId,
          coverageSurexsId: coverage.coverageSurexsId,
          origen: coverage.origen,
          coverageName: coverage.coverageName,
          value: coverage.value,
          unitMeasurementId: coverage.unitMeasurementId,
          endoso: coverage.endoso,
          flexible: coverage.flexible,
          factorQuote: [coverage.factorQuote, [Validators.pattern(regex.onlydecimanes)]],
          endorsements: this.fb.array(coverage.endorsements.map((endorsement: any) => this.fb.group({
            idFlexPlanEndorsement: endorsement.idFlexPlanEndorsement,
            flexPlanCoverageId: endorsement.flexPlanCoverageId,
            coverageName: endorsement.coverageName,
            value: endorsement.value,
            unitMeasurementId: endorsement.unitMeasurementId,
          })))
        });

        // Usa `setValue`
        coverageGroup.setValue({
          idFlexPlanCoverages: coverage.idFlexPlanCoverages,
          flexPlanPlanesId: coverage.flexPlanPlanesId,
          coverageSurexsId: coverage.coverageSurexsId,
          origen: coverage.origen,
          coverageName: coverage.coverageName,
          value: coverage.value,
          unitMeasurementId: coverage.unitMeasurementId,
          endoso: coverage.endoso,
          flexible: coverage.flexible,
          factorQuote: coverage.factorQuote,
          endorsements: coverage.endorsements.map((endorsement: any) => ({
            idFlexPlanEndorsement: endorsement.idFlexPlanEndorsement,
            flexPlanCoverageId: endorsement.flexPlanCoverageId,
            coverageName: endorsement.coverageName,
            value: endorsement.value,
            unitMeasurementId: endorsement.unitMeasurementId,
          }))
        });

        // Agregar la cobertura al FormArray
        flexPlanCoveragesModelsArray.push(coverageGroup);

        // Si la cobertura es flexible, agregarla a `allCoverages`
        if (coverageGroup.get('flexible').value === true) {
          const coverageValue = coverageGroup.value as FlexPlanCoveragesModel;
          this.allCoverages.push(coverageValue);
        }
      });

      // Asignar tarifas a la categoría del plan
      const categoryRatesArray = planFormGroup.get('categoryRates') as FormArray;

      // Verificar si plan.categoryRates tiene datos antes de iterar
      if (plan.categoryRates && plan.categoryRates.length > 0) {
        plan.categoryRates.forEach((rate: any) => {
          const rateGroup = this.fb.group({
            idFlexPlanCategoryRates: [rate.idFlexPlanCategoryRates],  // Integer
            flexPlanPlanesId: [rate.flexPlanPlanesId],  // Integer
            minimumAge: [rate.minimumAge],  // Integer
            maximumAge: [rate.maximumAge],  // Integer
            manRate: [rate.manRate],  // Double
            womanRate: [rate.womanRate],  // Double
            thousandQuota: [rate.thousandQuota]  // Boolean
          });
          categoryRatesArray.push(rateGroup);
        });
      }

      // Agregar plan al FormArray de planes
      planPlanesModelsArray.push(planFormGroup);

    });

    // Inicializa el array de visibilidad con valores 'false' para cada plan
    this.isTariffSectionVisible = this.planFlexForm.get('planPlanesModels').value.reduce((acc, plan) => {
      acc[plan.idFlexPlanPlanes] = false;
      return acc;
    }, {});

    this.planPlanesModels = this.planFlexForm.get('planPlanesModels') as FormArray;
    this.cd.detectChanges();
  }

  createPlan(isPlanFexlible: boolean, count): FormGroup {
    return this.fb.group({
      idFlexPlanPlanes: [null],  // Integer
      flexPlanCategoriesId: [null],  // Integer
      planName: ['Plan ' + count],  // String
      flexiblePlan: [isPlanFexlible],  // Boolean
      quote: [0.0],  // Double
      premiumIndividual: [false],  // Double
      averagePremium: [0.0],  // Double
      netPremiumIndividual: [0.0],  // Double
      netPremiumGroup: [0.0],  // Double
      policyRights: [0.0],  // Double
      surcharges: [0.0],  // Double
      insuranceCompanyId: [null, Validators.required],
      creationDate: [null],  // Date
      updateDate: [null],  // Date
      creationUserId: [null],  // Integer
      updateUserId: [null],  // Integer
      status: [1],// Integer
      flexPlanCategoriesModel: this.fb.group({ // FormGroup para almacenar la categoria
        idFlexPlanCategories: [null],
        categoryName: [''],
        planFlexId: [this.idPlanFlex],
        flexibleProductId: [1],
        creationDate: [''],
        updateDate: [''],
        creationUserId: [''],
        updateUserId: ['']
      }),
      categoryRates: this.fb.array([]),  // FormArray para almacenar las tarifas manuales
      flexPlanCoveragesModels: this.fb.array([])  // FormArray para almacenar las coberturas
    });
  }

  getUnitOptions(): any[] {
    return this.planProductOptions;
  }

  toggleSpecialCoverages() {
    this.showSpecialCoverages = !this.showSpecialCoverages;
  }

  getEndorsementLabel(index: number): string {
    const labels = ['SUMA ASEGURADA', 'DEDUCIBLE', 'COASEGURO', 'PERIODO DE ESPERA'];
    return labels[index] || 'Valor';
  }

  getPlanAtIndex(planIndex: number): FormGroup {
    const planPlanesModels = this.planFlexForm.get('planPlanesModels') as FormArray;
    return planPlanesModels.at(planIndex) as FormGroup;
  }

  addCategoryRate(planIndex: number) {
    const plan = this.getPlanAtIndex(planIndex);
    if (planIndex !== null) {
      const rateForm = this.fb.group({
        idFlexPlanCategoryRates: [null],
        flexPlanPlanesId: [plan.get('idFlexPlanPlanes').value],
        minimumAge: [null, Validators.required],
        maximumAge: [null, Validators.required],
        manRate: [0.0, Validators.required],
        womanRate: [0.0, Validators.required],
        thousandQuota: [false],
        creationDate: [null],
        updateDate: [null],
        creationUserId: [null],
        updateUserId: [null]
      });

      const categoryRatesArray = (plan.get('categoryRates') as FormArray);
      categoryRatesArray.push(rateForm);
    }
  }

  // Método para mostrar la sección de tarifas
  showScreenCategoryRates(index: number) {
    console.log('Index plan: ', index);
    this.selectedPlanIndex = index;

  }

  getCategoryRatesFormArray(): FormArray {
    if (this.selectedPlanIndex !== null) {
      const plan = this.planPlanesModels.at(this.selectedPlanIndex);
      if (plan) {
        return plan.get('categoryRates') as FormArray;
      }
    }
    return this.fb.array([]);
  }


  // Método para mostrar/ocultar la sección de tarifas manuales de un plan específico
  toggleTariffSection(planIndex: number) {
    this.isTariffSectionVisible[planIndex] = !this.isTariffSectionVisible[planIndex];
  }

  onChangeInputFile(e) {
    this.error = {active: false, msg: ''};
    this.fileUpload = e.target.files[0];
    if (0 > this.fileTypes.indexOf(this.fileUpload.type)) {
      this.error = {active: true, msg: 'Debe seleccionar un archivo válido'};
    }
  }

  onChangeInputFileSlip(e) {
    this.error = {active: false, msg: ''};
    this.fileUploadFactor = e.target.files[0];
    if (0 > this.fileTypes.indexOf(this.fileUploadFactor.type)) {
      this.error = {active: true, msg: 'Debe seleccionar un archivo válido'};
    }
  }

  downloadLayout(id) {
    this.spinner.show('sp');
    this.api.getLayoutFile(id)
      .subscribe(
        (response: any) => {
          this.spinner.hide('sp');
          if (response == null) {
            this.toastr.info('NO EXISTE ARCHIVO A DESCARGAR');
            return;
          } else {
            const dataType = response.type;
            const binaryData = [];
            binaryData.push(response);
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            downloadLink.setAttribute('download',  'LAYOUT_TARIFAS.xlsx');
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
          }
        }, error => {
          this.spinner.hide('sp');
          // console.error(error);
        }
      );
  }

  removeCategoryRate(indexPlan: number, indexRate: number): void {
    const categoryRatesArray = this.getPlanAtIndex(indexPlan).get('categoryRates') as FormArray;
    categoryRatesArray.removeAt(indexRate);

  }

  saveRates(indexPlan: number): void {
    this.spinner.show('sp');

    if (this.isLoadTarrifManual === true || this.isLoadTarrifManual === 'true') {
      this.api.saveRatesConfiguration(this.planFlexForm.value)
        .then(
          (response: any) => {
            this.spinner.hide('sp');
            this.toastr.success('SU SOLICITUD SE HA REALIZADO DE FORMA EXITOSA', 'NOTIFICACIÓN');
          }, error => {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            this.spinner.hide('sp');
          }
        );
    } else {

      const idPlanPlanes = this.getPlanAtIndex(indexPlan).get('idFlexPlanPlanes').value;
      if (this.fileUpload === undefined) {
        this.toastr.info('DEBE DE INGRESAR EL LAYOUT', 'NOTIFICACION');
        this.spinner.hide('sp');
        return;
      }
      const file = this.fileUpload;
      const reader = new FileReader();

      reader.onloadend = () => {
        if (reader.result) {
          const base64String = (reader.result as string).split(',')[1];   // Elimina el prefijo de la URL de datos
          const model = {
            fileName: file.name,
            fileData: base64String,
            idFlexPlanPlanes: idPlanPlanes
          };
          this.api.getTariffFromLayout(model)
            .then(
              (response: any) => {
                this.spinner.hide('sp');
                this.toastr.success('SU SOLICITUD SE HA REALIZADO DE FORMA EXITOSA', 'NOTIFICACIÓN');
              }, error => {
                this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
                this.spinner.hide('sp');
              }
            );
        } else {
          this.toastr.error('Ocurrió un problema al leer el archivo', 'Notificación');
          this.spinner.hide('sp');
        }
      };

      reader.onerror = () => {
        this.toastr.error('Ocurrió un problema al leer el archivo', 'Notificación');
        this.spinner.hide('sp');
      };

      reader.readAsDataURL(file);  // Inicia la lectura del archivo
    }
  }

  saveFactors(): void {
    this.spinner.show('sp');
    if (this.fileUploadFactor === undefined) {
      this.toastr.info('DEBE DE INGRESAR EL LAYOUT', 'NOTIFICACION');
      this.spinner.hide('sp');
      return;
    }
    const file = this.fileUploadFactor;
    const reader = new FileReader();

    reader.onloadend = () => {
      if (reader.result) {
        const base64String = (reader.result as string).split(',')[1];   // Elimina el prefijo de la URL de datos
        const model = {
          fileName: file.name,
          fileData: base64String,
          planFlexGmm: this.planFlexForm.value
        };
        this.api.getFactorSlip(model)
          .then(
            (response: any) => {
              this.spinner.hide('sp');
              this.toastr.success('SU SOLICITUD SE HA REALIZADO DE FORMA EXITOSA', 'NOTIFICACIÓN');
              this.populateFormWithResponseData(response);
            }, error => {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
              this.spinner.hide('sp');
            }
          );
      } else {
        this.toastr.error('Ocurrió un problema al leer el archivo', 'Notificación');
        this.spinner.hide('sp');
      }
    };

    reader.onerror = () => {
      this.toastr.error('Ocurrió un problema al leer el archivo', 'Notificación');
      this.spinner.hide('sp');
    };
    reader.readAsDataURL(file);  // Inicia la lectura del archivo
  }

  savePlanGmm(): void {
    this.spinner.show('sp');

    if (!this.planFlexForm.valid) {
      this.planFlexForm.markAllAsTouched();
      this.spinner.hide('sp');
      return;
    }

    this.api.editPlanGMMById(this.planFlexForm.value)
      .then(
        (response: any) => {
          this.spinner.hide('sp');
          this.toastr.success('SU SOLICITUD SE HA REALIZADO DE FORMA EXITOSA', 'NOTIFICACIÓN');
          this.populateFormWithResponseData(response);

        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  layoutCargado(e) {
    console.log('respuesta población inicial');
    console.log(e);
    this.isPoblation = e;
  }

  getFactorQuoteControl(plan: FormGroup, coverage: any): FormControl | null {
    const coveragesArray = plan.get('flexPlanCoveragesModels') as FormArray;
    const coverageControl = coveragesArray.controls.find(cov => cov.get('idFlexPlanCoverages').value === coverage.idFlexPlanCoverages);
    return coverageControl ? coverageControl.get('factorQuote') as FormControl : null;
  }

  getUnitCode(unitMeasurementId: any): string {
    const unit = this.planProductOptions.find(option => option.id === Number(unitMeasurementId));
    return unit ? unit.code : 'N/A'; // Devuelve 'N/A' si no encuentra el id
  }

  getInsuranceCompanies() {
    this.api.getInsuranceCompaniesByProduct(1).then((data: any) => {
        this.insuranceListDetail = data;

    }, error => {
      this.toastr.error('Ocurrió un problema al cargar el catálogo de Roles', 'Notificación');
    });
  }

  saveOnlyPlan(indexPlan: number): void {

    const plan = this.getPlanAtIndex(indexPlan);
    this.spinner.show('sp');

    if (!plan.valid) {
      plan.markAllAsTouched();
      this.spinner.hide('sp');
      return;
    }
    this.api.saveAdditionalPlanConfigurationGMM(plan.value)
      .then(
        (response: any) => {
          this.spinner.hide('sp');
          this.toastr.success('SU SOLICITUD SE HA REALIZADO DE FORMA EXITOSA', 'NOTIFICACIÓN');
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }

  validarPlanes(): boolean {
    const plansArray = this.planFlexForm.get('planPlanesModels') as FormArray;

    return plansArray.controls.every(planControl => {
      const plan = planControl.value;
      return (
        (plan.quote === true && plan.categoryRates !== null && plan.categoryRates.length > 10) ||
        (plan.quote === false && plan.averagePremium !== null && plan.averagePremium > 0)
      );
    });
  }


  protected readonly number = number;
  protected readonly Number = Number;
}
