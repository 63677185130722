import {Component, OnInit, TemplateRef} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {ApiService} from '../api.service';
import {ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-flex-vision-p2-view',
  templateUrl: './flex-vision-p2-view.component.html',
  styleUrls: ['./flex-vision-p2-view.component.css']
})

export class FlexVisionP2ViewComponent implements OnInit {
  idPlanFlex;
  planFlexForm: FormGroup;
  planInformation: any = {};
  requestModel: any = {};
  selectedPlan: any = {};
  categories: any = [];
  insuranceList = [];
  modalRefAddCoveragePlan: BsModalRef;
  error = {active: false, msg: ''};
  public fileUpload: File;
  public fileUploadFactor: File;
  isPoblation = true;
  idInsurance = 0;
  fileTypes: string[] = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.oasis.opendocument.spreadsheet'];

  constructor(private fb: FormBuilder,
              private api: ApiService,
              private activatedRoute: ActivatedRoute,
              private toastr: ToastrService,
              private shared: SharedService,
              private spinner: NgxSpinnerService,
              private modalService: BsModalService) {
    this.activatedRoute.params.subscribe(params => {
      this.idPlanFlex = params.idPlan;
      // this.isEdition = params.isEdition === 'true';
    });
    this.planFlexForm = this.fb.group({
      netPremiumIndividual: [''],
      policyRights: [''],
      surcharges: ['']
    });
  }
  ngOnInit() {
    this.getPlanInformation();
    this.getInsurancesByIdProduct();
    // this.loadUnitOptions();
    // this.getPlanGMMByIdPlanFlex();
    // Inicializa el array de visibilidad con valores 'false' para cada plan
    // this.isTariffSectionVisible = new Array(this.planPlanesModels.length).fill(false);
  }
  openModalCoverage( templateCoverage: TemplateRef<any>, plan: any) {
    this.selectedPlan = plan; // Guardamos el plan
    this.planFlexForm.get('netPremiumIndividual').setValue(this.selectedPlan.netPremiumIndividual);
    this.planFlexForm.get('policyRights').setValue(this.selectedPlan.policyRights);
    this.planFlexForm.get('surcharges').setValue(this.selectedPlan.surcharges);
    this.modalRefAddCoveragePlan = this.modalService.show(templateCoverage,
      {class: 'modal-lg', keyboard: false, ignoreBackdropClick: true, backdrop: false});
  }
  closeModalCoverage() {
    console.log(this.planFlexForm);
    this.selectedPlan.netPremiumIndividual = this.planFlexForm.get('netPremiumIndividual').value ;
    this.selectedPlan.policyRights = this.planFlexForm.get('policyRights').value;
    this.selectedPlan.surcharges = this.planFlexForm.get('surcharges').value;
    this.modalRefAddCoveragePlan.hide();
    // this.planFlexForm.reset();
  }
  onSubmit(): void {
    const categories = [];
    const plans = [];
    this.planInformation.categories.forEach(category => {
      category.plans.forEach(plan => {
        plans.push({
          idPlan: plan.idPlan,
          premiumIndividual: plan.premiumIndividual,
          policyRights: plan.policyRights,
          quote: plan.quote,
          averagPremium: plan.averagPremium,
          namePlan: plan.namePlan,
          netPremiumIndividual: plan.netPremiumIndividual,
          netPremiumGroup: plan.netPremiumGroup,
          surcharges: plan.surcharges,
          flexiblePlan: plan.flexiblePlan,
          insuranceId: this.idInsurance
        });
      });
      categories.push({
        nameCategory: category.nameCategory,
        idCategory: category.idCategory,
        plans
      });
    });
    this.requestModel = {
      idPlanFlexId : this.planInformation.idPlanFlexId,
      categories
    };
    this.updateQuotesVision();
    // console.log(this.planPlanes.controls[0].get('planCoverages').controls[2].get('optionsValues').controls);
    // console.log(this.planPlanes.controls[0].get('categories').controls[0].get('plans').controls[0].get('coverages').value);
  }
  onChangeInputFile(e) {
    this.error = {active: false, msg: ''};
    this.fileUpload = e.target.files[0];
    if (0 > this.fileTypes.indexOf(this.fileUpload.type)) {
      this.error = {active: true, msg: 'Debe seleccionar un archivo válido'};
    }
  }
  saveFactors(): void {
    this.spinner.show('sp');
    if (this.fileUpload === undefined) {
      this.toastr.info('DEBE DE INGRESAR EL LAYOUT', 'NOTIFICACION');
      this.spinner.hide('sp');
      return;
    }
    const file = this.fileUpload;
    const reader = new FileReader();

    reader.onloadend = () => {
      if (reader.result) {
        const base64String = (reader.result as string).split(',')[1];   // Elimina el prefijo de la URL de datos
        const model = {
          fileName: file.name,
          fileData: base64String,
          planFlexGmm: this.planFlexForm.value
        };
        this.api.getFactorSlip(model)
          .then(
            (response: any) => {
              this.spinner.hide('sp');
              this.toastr.success('SU SOLICITUD SE HA REALIZADO DE FORMA EXITOSA', 'NOTIFICACIÓN');
            }, error => {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
              this.spinner.hide('sp');
            }
          );
      } else {
        this.toastr.error('Ocurrió un problema al leer el archivo', 'Notificación');
        this.spinner.hide('sp');
      }
    };

    reader.onerror = () => {
      this.toastr.error('Ocurrió un problema al leer el archivo', 'Notificación');
      this.spinner.hide('sp');
    };
    reader.readAsDataURL(file);  // Inicia la lectura del archivo
  }

  // SERVICES
  updateQuotesVision(): void {
    this.spinner.show('sp');
    this.api.updateQuotesVision(this.requestModel)
      .then(
        (response: any) => {
          console.log(response);
          this.spinner.hide('sp');
          this.toastr.success('Se cargaron las tarifas correctamente', 'Notificación');
        }, error => {
          this.toastr.error('Ocurrió un problema al cargar las tarifas', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  getPlanInformation(): void {
    const data = {
      idPlanFlex: Number(this.idPlanFlex)
    };
    this.spinner.show('sp');
    this.api.getPlanVisionInformation(data)
      .then(
        (response: any) => {
          this.planInformation = response;
          this.spinner.hide('sp');
        }, error => {
          this.toastr.error('No se encontro información', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  downloadLayout(id) {
    this.spinner.show('sp');
    this.api.getLayoutFile(id)
      .subscribe(
        (response: any) => {
          this.spinner.hide('sp');
          if (response == null) {
            this.toastr.info('NO EXISTE ARCHIVO A DESCARGAR');
            return;
          } else {
            const dataType = response.type;
            const binaryData = [];
            binaryData.push(response);
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            downloadLink.setAttribute('download',  'LAYOUT_TARIFAS.xlsx');
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
          }
        }, error => {
          this.spinner.hide('sp');
          // console.error(error);
        }
      );
  }
  layoutCargado(e) {
    console.log('respuesta población inicial');
    console.log(e);
    this.isPoblation = e;
  }
  getInsurancesByIdProduct(): void {
    const data = {
      idProduct: 6
    };
    this.spinner.show('sp');
    this.api.getInsurancesByIdProduct(data)
      .then(
        (response: any) => {
          this.insuranceList = response;
          console.log(this.insuranceList);
          this.spinner.hide('sp');
        }, error => {
          this.toastr.error('Ocurrió un problema al cargar las aseguradoras', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  onInsuranceChange(value: number): void {
    console.log(value);
    this.idInsurance = value;
  }

  protected readonly Number = Number;
}
