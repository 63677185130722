import {Component, OnInit, TemplateRef, ViewChild, ChangeDetectorRef} from '@angular/core';
import {ApiService} from '../api.service';
import {Router, ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {DatePipe} from '@angular/common';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {NgxSpinnerService} from 'ngx-spinner';
import {ValidateAccessService} from "../validate-access.service";
import { NgxDateRangePickerOptions } from 'ngx-daterangepicker';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { ConfirmComponent } from '../dialogs/confirm/confirm.component';

import moment from 'moment';
import * as _ from 'lodash';
import { timeStamp } from 'console';

@Component({
  selector: 'app-autos-p2',
  templateUrl: './autos-p2.component.html',
  styleUrls: ['./autos-p2.component.css']
})
export class AutosP2Component implements OnInit {
// variables pantalla consulta siniestros
  planForm: FormGroup; // Formulario

  idPlan: number;
  idProduct: number;

  plan: any;

  constructor(private api: ApiService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private toastr: ToastrService,
              private shared: SharedService,
              private datePipe: DatePipe,
              public fb: FormBuilder,
              private modalService: BsModalService,
              private spinner: NgxSpinnerService,
              private formBuilder: FormBuilder,
              private cdr: ChangeDetectorRef,
              private validateAccess: ValidateAccessService) {
  }
  ngOnInit() {
    this.createForm();

    this.activatedRoute.params.subscribe(params => {
      this.idPlan = +params.idPlan;
      this.idProduct = +params.idProduct;

      this.getPlanInfo();
    });
  }
  /* -----------------------------------------------------------------------------------------------------
  ----------------------------- F U N C I O N E S G E N E R A L E S ---------------------------------------
  ----------------------------------------------------------------------------------------------------- */
  goToBack() {
    this.shared.gSearchId = 0;
    this.router.navigate(['/admin/executive']);
  }

  getPlanInfo(): void {
    const params = {
      idProduct: this.idProduct,
      flexPlanId: this.idPlan
    };

    this.api.getInfoConfigurationP1P2(params).subscribe(response => {
      this.plan = response;
      console.log(this.plan);
      this.plan.listInsuranceKeys.forEach(insurance => {
        this.planForm.get(insurance.insuranceName).patchValue(insurance);
        this.planForm.get(insurance.insuranceName).setValidators(Validators.required);
      });
    },
    error => {
      console.log(error);
    });
  }

  createForm(): void {
    this.planForm = this.formBuilder.group({
      QUÁLITAS: this.formBuilder.group({
        clientAgent: [null],
        clientNoBusiness: [null],
        clientRightQualitas: [null],
      }),
      GNP: this.formBuilder.group({
        clientUser: [null],
        clientPromotionCode: [null],
        clientKey: [null],
        clientNoBusiness: [null],
      }),
      ANA: this.formBuilder.group({
        clientUser: [null],
        clientUserTest: [null],
        clientKey: [null],
        clientKeyTest: [null],
        clientNoBusiness: [null],
        clientNoBusinessTest: [null],
      }),
      CHUBB: this.formBuilder.group({
        clientUser: [null],
        clientUserTest: [null],
        clientKey: [null],
        clientKeyTest: [null],
        clientGroup: [null],
        clientGroupTest: [null],
        clientFee: [null],
        clientFeeTest: [null],
        clientNoBusiness: [null],
        clientNoBusinessTest: [null],
      })
    });
  }
  
  getListKeyFromPlan(name: string): any {
    const index = _.findIndex(this.plan.listInsuranceKeys, { insuranceName: name });
    return index >= 0 ? this.plan.listInsuranceKeys[index] : null;
  }

  saveConfiguration(aseguradora: any): void {
    if (!this.planForm.valid) {
      this.toastr.error('Faltan datos por capturar');
      return;
    }

    const listKeys = [];
    
    const obPlan = this.getListKeyFromPlan(aseguradora.insuranceName);
    if (obPlan) {
      const keys = this.planForm.get(aseguradora.insuranceName).value;
      const newOb = {...obPlan, ...keys};
      listKeys.push(newOb);
    }

    const params = {
      payrollDeduction: this.plan.payrollDeduction,
      typeConfigurationPolicyId: this.plan.typeConfigurationPolicyId,
      planFlexId: this.idPlan,
      paymentOnline: this.plan.paymentOnline,
      flexPlanVehicleId: this.plan.flexPlanVehicleId,
      listInsuranceKeys: listKeys
    };

    console.log(params);
    this.api.updateConfigurationP2(params).subscribe(response => {
      this.toastr.success('Configuracion guardada correctamente');
    },
    error => {
      this.toastr.error(error.error.message);
    });
  }

}
