import { Component, OnInit, TemplateRef } from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ApiService} from '../api.service';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-flex-spousal-life-p2-view',
  templateUrl: './flex-spousal-life-p2-view.component.html',
  styleUrls: ['./flex-spousal-life-p2-view.component.css']
})
export class FlexSpousalLifeP2ViewComponent implements OnInit {
  typeOptions: any = [];
  typeOptionsQuotes: any = [];
  typeOptionsGF: any = [];
  typeOptionsGFPremium: any = [];
  dataSendLife: any = {};
  // tslint:disable-next-line:variable-name
  _planFlexId: number;
  planProductOptions: Array<{ key: number, value: string }> = [];
  planUnitOptions: Array<{ key: number, value: string }> = [];
  typeSumInsured: number;
  catalogForm = this.fb.group({
    catalogs: ['groups', [Validators.required]]
  });
  catalogForm2 = this.fb.group({
    catalogs2: ['groups1', [Validators.required]]
  });
  numCategory: number;
  planPlanes: any = [];
  planPlanesFlex: any = [];
  planFlexCoverages: any = [];
  coverageList: any = [];
  modalCoverage: BsModalRef;
  modalRef: BsModalRef;
  key = 'idCoverage';
  display = 'coverageName';
  format = { add: 'Agregar', all: 'Todo', none: 'ninguno' };
  nextP1: number;
  messageProduct: string;
  planAddCar: number;
  quotes: any = {};
  funeralExpenses: number;

  catInsuranceCompany: any = [];
  isPoblation: boolean;
  constructor(private fb: FormBuilder,
              private api: ApiService,
              private toastr: ToastrService,
              private spinner: NgxSpinnerService,
              private modalService: BsModalService,
              private activatedRoute: ActivatedRoute,
              private router: Router) { }

  ngOnInit() {
    this.isPoblation = false;
    this.typeOptions = [
      { id: true, optionSelect: 'POR CUOTA'},
      { id: false, optionSelect: 'POR PRIMA'}
    ];
    this.typeOptionsQuotes = [
      { id: false, optionSelect: 'QUOTA POR PROMEDIO'},
      { id: true, optionSelect: 'QUOTA POR COBERTURA'}
    ];
    this.typeOptionsGF = [
      { id: true, optionSelect: 'POR QUOTA'},
      { id: false, optionSelect: 'POR PRIMA NETA'}
    ];
    this.typeOptionsGFPremium = [
      { id: true, optionSelect: 'PRIMA NETA POR PERSONA'},
      { id: false, optionSelect: 'POR PRIMA NETA TOTAL'}
    ];
    // this._planFlexId = 5;

    this.activatedRoute.params.subscribe(params => {
      this._planFlexId = +params.idPlan;
      console.log('planId');
      console.log(this._planFlexId);
      this.loadUnitOptions();
      this.invokeFunctionGetConfigurationProduct();
      this.invokeServiceGetInsuranceCompanies();
    });
  }
  invokeServiceGetInsuranceCompanies() {
    this.api.getInsuranceCompanies().then((data: any) => {
      this.catInsuranceCompany = data;
    }, error => {
    });
  }
  loadUnitOptions() {
    this.api.getUnitMeasurement()
      .then(
        (response: any) => {
          this.planUnitOptions = response;
        }, error => {
          this.toastr.error('Ocurrió un problema al cargar el catálogo de Medidas', 'Notificación');
        }
      );
  }
  getUnitOptions(): any[] {
    return this.planUnitOptions;
  }

  invokeFunctionGetConfigurationProduct() {
    this.spinner.show('sp');
    const objRequest = {
      idProduct: 4,
      idPlan: this._planFlexId
    };
    this.api.getConfigurationProductSpousalLifeP2(objRequest)
      .then(
        (response: any) => {
          this.dataSendLife = response;
          this.planPlanes = this.dataSendLife.planesCurrentSpousalLife;
          this.planPlanesFlex = this.dataSendLife.planesFlexSpousalLife;
          this.spinner.hide('sp');
        }, error => {
          this.toastr.error('Ocurrió un problema al cargar el catálogo de Medidas', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }

  invokeFunctionConfigurationQuotes(template: TemplateRef<any>, plan: any, position: number) {
    this.quotes = plan;
    console.log(this.quotes);
    this.funeralExpenses = 0;
    this.quotes.coverageLifeModelList.forEach(item => {
      if (item.coverageName === 'GASTOS FUNERARIOS') {
        this.funeralExpenses = 1;
      }
    });
    this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false, ignoreBackdropClick: true, backdrop: false});
  }


  onTypeChange(value: any) {
    if (value) {
      this.quotes.flexPlanPlanesQuotaModel.manRate = 0;
      this.quotes.flexPlanPlanesQuotaModel.womanRate = 0;
    }
  }
  onTypeChangeQuote(value: any) {
    if (value) {
      this.quotes.flexPlanPlanesLifeQuotaModel.averageQuota = 0;
    }
  }

  onTypeChangeQuoteGF(value: any) {
    if (!value) {
      this.quotes.flexPlanPlanesLifeQuotaModel.funeralExpensesQuotaPremiumTotal = 0;
    }
  }
  onTypeChangeQuoteGFPremium(value: any) {
    if (value) {
      this.quotes.flexPlanPlanesLifeQuotaModel.funeralExpensesPremiumTotal = 0;
    } else {
      this.quotes.flexPlanPlanesLifeQuotaModel.funeralExpensesPremiumIndividual = 0;
    }
  }

  invokeFunctionSaveQuotes() {
    console.log(this.quotes);
    this.quotes.flexPlanPlanesQuotaModel.thousandQuota = this.quotes.quote;
    this.spinner.show('sp');
    this.api.saveConfigurationSpousalLifeQuoteByPlan(this.quotes)
      .then(
        (response: any) => {
          this.spinner.hide('sp');
          if (response.flexPlanSpousalLifeId === 0) {
            this.toastr.error('Ocurrió un problema al guardar las quotas', 'Notificación');
          } else {
            this.toastr.success('La quotas fueron guardadas de manera correcta', 'Notificación');
            this.quotes = {};
          }
          this.invokeFunctionGetConfigurationProduct();
          this.modalRef.hide();
        }, error => {
          this.toastr.error('Ocurrió un problema al cargar el catálogo de Medidas', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  layoutCargado(e) {
    console.log('respuesta población inicial');
    console.log(e);
    this.isPoblation = e;
  }
  invokeFunctionBackDetailPlan() {
    this.router.navigate(['/admin/plans-detalle/', this._planFlexId]);
  }

}
