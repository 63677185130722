// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api_quote: 'http://100.26.10.11/api',
  api_rocket: 'ws://134.209.117.135:3000/websocket',
  /*enviroment prod*/
  /* api_rest: 'https://services.ammia.io/api/admin',
  api_rest_admin: 'https://services.ammia.io/api/core',
  api_user_external: 'http://157.245.246.200/api',
  api_rest_admin_surexs: 'https://services.ammia.io/api/core',
  api_rest_ammia_surexs: 'https://services.ammia.io/api/ammia/v1.0/protected/sso',
  api_rest_ammia_web_surexs: 'https://services.ammia.io/api/ammia/v1.0/protected'*/
  /*enviroment dev */
  api_rest: 'https://qa.services.ammia.io/api/admin',
  api_rest_admin: 'https://qa.services.ammia.io/api/core',
  api_user_external: 'http://157.245.246.200/api',
  api_rest_admin_surexs: 'https://qa.services.ammia.io/api/core',
  api_rest_ammia_surexs: 'https://qa.services.ammia.io/api/ammia/v1.0/protected/sso',
  api_rest_ammia_web_surexs: 'https://qa.services.ammia.io/api/ammia/v1.0/protected'

 /*enviroment local*/
  /*api_rest: 'https://qa.services.ammia.io/api/admin',
  api_rest_admin: 'http://localhost/api/core',
  api_user_external: 'http://157.245.246.200/api',
  api_rest_admin_surexs: 'http://localhost/api/core',
  api_rest_ammia_surexs: 'https://qa.services.ammia.io/api/ammia/v1.0/protected/sso',
  api_rest_ammia_web_surexs: 'https://qa.services.ammia.io/api/ammia/v1.0/public'*/

  , decryption : 'MIICWgIBAAKBgH0F2pqmSdK5QQsW8CqcFuR5KCo3gNq8VwIcKjkkSSTin4JKurws/MEF+qUS6hpb1bR0E9gBfi3y6PjaitQb95Rbf9C8DfIqOkfjmyxeme40H1uaqJoZSXKMvtYChBo++Dif7HDwBz8L6mWqyv72EhTvLpFP/zUnP1JkxlGv/9+5AgMBAAECgYAOD7l9iVRmQfXB1Iugjzu36LSOwv1YHLTAhQBuutkLL/32vobwITMwVobRSCZSXp/TkvakZc6WRmrTimjw4FBhFAbmou5m1b8Kkp1WyEExqzlhOhLxloLw08nS6bbJCwKQAeZJ4wqxzqqSc9Dq/Nt4XfJmo8vtYIf8p/WCvPOx/QJBALRWROuUotNb/SenKdEnQQYq0vHEU3HIiIW2MU8MuK0oEngxE3YDJLY0Jbg/RSK9wimc8hOixcW1zCM8jmgx8CcCQQCxemBNd+TidI+DT3oTMlueB4zzWTD3b27P9oY5OYjAv3pKqSORrqLHefznWYMZBJvLwktI6fTnlccihJBQgb0fAkBBK7wUds6xG1lNbzfJgioZlHhPLugJArNzvvfq3WoQCZ9DGFk0GMa3ofjPsO3HNFRqvXEub3CRlrWDNmswdHuZAkAZYUK8UVTt/XU4dfkjHaR0aOgDNNIH0GusWbjAHjYsOjWmz6FCiBLHK+66ttZEkaAl91cEylA3fffIO+TXXnZJAkBcQaOSCc9tNrx2hGzcWu2txXxBJKA0lzw33LtfbGzxK4lLFD7qEcFt5qAJduMFLwsOZKkHfmiG4YkhsNLpX9nv'
};

/*
* For easier debugging in development mode, you can import the following file
* to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
*
* This import should be commented out in production mode because it will have a negative impact
* on performance if an error is thrown.
*/
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
