import { Component, OnInit } from '@angular/core';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {DatePipe} from '@angular/common';
import {FormBuilder} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {ValidateAccessService} from "../validate-access.service";

@Component({
  selector: 'app-admin-policies-reports',
  templateUrl: './admin-policies-reports.component.html',
  styleUrls: ['./admin-policies-reports.component.css']
})
export class AdminPoliciesReportsComponent implements OnInit {
  receiptsList: any = [];
  filterRangeDate: Date[];
  filterRangeDateEnd: Date[];
  bsConfig: Partial<BsDatepickerConfig>;
  filterDate: any = {};
  clientList: any = [];
  groupList: any = [];
  insuranceList: any = [];
  statusList: any = [];
  policyList: any = [];
  executiveList: any = [];
  clientsSelectedItems: any = [];
  dropdownSettingsClients = {};
  groupsSelectedItems: any = [];
  dropdownSettingsGroups = {};
  insurancesSelectedItems: any = [];
  dropdownSettingsInsurances = {};
  statusSelectedItems: any = [];
  dropdownSettingsStatus = {};
  policiesSelectedItems: any = [];
  dropdownSettingsPolicies = {};
  executivesSelectedItems: any = [];
  dropdownSettingsExecutives = {};
  currencyList: any = [];
  currencySelectedItems: any = [];
  dropdownSettingsCurrency: any = {};
  salesmanList: any = [];
  salesmanSelectedItems: any = [];
  dropdownSettingsSalesman: any = {};
  coordinatorList: any = [];
  coordinatorSelectedItems: any = [];
  dropdownSettingsCoordinator: any = {};
  executiveSurexList: any = [];
  surexSelectedItems: any = [];
  dropdownSettingsSurex: any = {};
  executiveIncidentList: any = [];
  incidentSelectedItems: any = [];
  dropdownSettingsIncident: any = {};
  branchList: any = [];
  branchSelectedItems: any = [];
  dropdownSettingsBranch: any = {};
  subBranchList: any = [];
  subBranchSelectedItems: any = [];
  dropdownSettingsSubBranch: any = {};
  statusPolicyList: any = [];
  statusPolicySelectedItems: any = [];
  dropdownSettingsStatusPolicy: any = {};
  isCollapsed: boolean;
  rangeDate: string;
  rangeDateEnd: string;
  rangeGroups: string;
  rangeSubgroups: string;
  rangeInsurances: string;
  rangeExecutive: string;
  rangePolicies: string;
  rangeStatus: string;
  rangeGroupList: any = [];
  rangeSubgroupList: any = [];
  rangeInsuranceList: any = [];
  rangeExecutiveList: any = [];
  rangePolicyList: any = [];
  rangeStatusList: any = [];
  rangeCurrency: any = [];
  rangeSubBranch: any = [];
  rangeBranch: any = [];
  rangeSalesman: any = [];
  rangeIncident: any = [];
  rangeSurexs: any = [];
  rangeCoordinator: any = [];
  rangeStatusReceipt: any = [];
  editR: boolean;
  paymentList: any = [];
  paymentFormId: number;
  receiptStatusId: number;
  receiptData: any = {};
  paginationResponse: any = {};
  filters: any = {};
  // -Varibales para paginado de recibos->
  pgReceiptBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgReceiptMaxSize = 3; // Número de links en paginado
  pgReceiptItemsPerPage = 10; // Número de registros por página
  pgReceiptTotalItems: number; // Total de registros
  pgReceiptCurrentPage = 1; // Página actual
  isSimplifyPolice: boolean;
  namesBranch: any = [];
  namesSuBranches: any = [];
  constructor(private api: ApiService,
              private router: Router,
              private toastr: ToastrService,
              private shared: SharedService,
              private datePipe: DatePipe,
              public fb: FormBuilder,
              private spinner: NgxSpinnerService,
              private validateAccess: ValidateAccessService) {
    this.bsConfig = Object.assign({}, { containerClass: 'theme-default' });
    /*this.shared.gReceiptPolicyIdObservable().subscribe(
      gReceiptsPolicyId => {
        localStorage.setItem('policy-id', String(121));
        if (gReceiptsPolicyId > -1) {
          localStorage.setItem('policy-id', String(121));
        }
        this.loadFilters();
        this.getReceiptByPagination(1, 5);
      }
    );*/
  }

  ngOnInit() {
    this.editR = true;
    this.rangeDate = undefined;
    this.rangeDateEnd = undefined;
    this.rangeGroups = undefined;
    this.rangeSubgroups = undefined;
    this.rangeInsurances = undefined;
    this.rangeExecutive = undefined;
    this.rangePolicies = undefined;
    this.rangeStatus = undefined;
    this.rangeCurrency = undefined;
    this.rangeSubBranch = undefined;
    this.rangeBranch = undefined;
    this.rangeSalesman = undefined;
    this.rangeIncident = undefined;
    this.rangeSurexs = undefined;
    this.rangeCoordinator = undefined;
    this.rangeStatusReceipt = undefined;
    this.rangeGroupList = [];
    this.rangeSubgroupList = [];
    this.rangeInsuranceList = [];
    this.rangeExecutiveList = [];
    this.rangePolicyList = [];
    this.rangeStatusList = [];
    this.isCollapsed = true;
    this.shared.gSearchId = 2;
    this.getGroups();
    this.getInsuranceCompanies();
    // this.getStatusReceipts();
    // this.getPaymentForms();
    this.getCurrency();
    this.getSalesman();
    this.getExecutives(1);
    this.getExecutives(2);
    // this.getExecutives(3);
    this.getBranches();
    this.getStatusPolicy();
    this.dropdownSettingsClients = {
      singleSelection: false,
      idField: 'clientId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON SUB GRUPOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsGroups = {
      singleSelection: false,
      idField: 'groupId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON GRUPOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsInsurances = {
      singleSelection: false,
      idField: 'insuranceId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON ASEGURADORAS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsStatus = {
      singleSelection: false,
      idField: 'receiptStatusId',
      textField: 'receiptStatus',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON ESTATUS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsPolicies = {
      singleSelection: false,
      idField: 'policyId',
      textField: 'number',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON DOCUMENTOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsSalesman = {
      singleSelection: false,
      idField: 'userId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON VENDEDORES',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsCurrency = {
      singleSelection: false,
      idField: 'currencyId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON MONEDAS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsCoordinator = {
      singleSelection: false,
      idField: 'directoryId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON COORDINADORES',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsSurex = {
      singleSelection: false,
      idField: 'directoryId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON EJECUTIVOS SUREXS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsIncident = {
      singleSelection: false,
      idField: 'directoryId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON EJECUTIVO SINIESTRO',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsBranch = {
      singleSelection: false,
      idField: 'branchId',
      textField: 'branchType',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON RAMAS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsSubBranch = {
      singleSelection: false,
      idField: 'subBranchId',
      textField: 'subBranch',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON SUB RAMOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsStatusPolicy = {
      singleSelection: false,
      idField: 'statusPolicyId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON ESTATUS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.loadFilters();
    this.getPolicies();
    this.groupsSelectedItems = [];
    this.clientsSelectedItems = [];
    this.insurancesSelectedItems = [];
    this.policiesSelectedItems = [];
    this.statusPolicySelectedItems = [];
    this.coordinatorSelectedItems = [];
    this.surexSelectedItems = [];
    this.salesmanSelectedItems = [];
    this.branchSelectedItems = [];
    this.subBranchSelectedItems = [];
    this.currencySelectedItems = [];
    console.log('valida');
    if (localStorage.getItem('filter-policies') != undefined) {
      switch (localStorage.getItem('filter-policies')) {
        case 'renewals':
          this.statusPolicySelectedItems = [{statusPolicyId: 4, name: 'RENOVACION'}];
          // this.filters.statusPolicy = ['RENOVACION'];
          this.filters.statusPolicy = [6];
          break;
        case 'active':
          this.statusPolicySelectedItems = [{statusPolicyId: 1, name: 'ACTIVA'}];
          // this.filters.statusPolicy = ['ACTIVA'];
          this.filters.statusPolicy = [1];
          break;
        case 'cancellations':
          this.statusPolicySelectedItems = [{statusPolicyId: 0, name: 'CANCELADO'}];
          // this.filters.statusPolicy = ['CANCELADO'];
          this.filters.statusPolicy = [0];
          break;
      }
    }
    this.getReceiptByPagination(1, 10);
  }
  goToBack() {
    this.shared.gSearchId = 0;
    this.router.navigate(['/admin/executive']);
  }
  receiptModal(r) {
    localStorage.setItem('receipt-id', r.receiptId);
    // // console.log(localStorage.getItem('receipt-id'));
  }
  getGroups() {
    this.api.getGroups().then((data: any) => {
      this.groupList = data;
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }
  getSubGroups(groups) {
    this.api.getSubGroupsMultiselect(groups).then((data: any) => {
      this.clientList = data;
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }
  getInsuranceCompanies() {
    this.api.getInsuranceCompanies().then((data: any) => {
      this.insuranceList = data;
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }
  getStatusReceipts() {
    this.api.getStatusReceipts().then((data: any) => {
      this.statusList = data;
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }
  getPaymentForms() {
    this.api.getPaymentForms().then((data: any) => {
      this.paymentList = data;
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }
  getPolicies() {
    const obj = {
      branch: this.namesBranch,
      subBranch: this.namesSuBranches,
    };
    this.api.getPolicyFilterDocumentsEmmit(obj).then((data: any) => {
      this.policyList = data;
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }
  getCurrency() {
    this.currencyList = [
      { currencyId: 1, name: 'MX'} , {currencyId: 2, name: 'USD'}
    ];
  }
  getSalesman() {
    this.api.getSalesman().then((data: any) => {
      this.salesmanList = data;
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }
  getExecutives(id) {
    this.api.getExecutives(id).then((data: any) => {
      switch (id) {
        case 1:
          this.coordinatorList = data;
          break;
        case 2:
          this.executiveSurexList = data;
          break;
        case 3:
          this.executiveIncidentList = data;
          break;
      }
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }
  getBranches() {
    this.api.getBranches().then((data: any) => {
      this.branchList = data;
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }
  getSubBranches(id) {
    this.api.getSubBranchesMulti(id).then((data: any) => {
      this.subBranchList = data;
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }
  getStatusPolicy() {
    this.statusPolicyList = [
      {statusPolicyId: 1, name: 'ACTIVA'},
      {statusPolicyId: 6, name: 'RENOVACIÓN'},
      {statusPolicyId: 0, name: 'CANCELADO'},
      {statusPolicyId: 3, name: 'NO RENOVABLE'},
      {statusPolicyId: 7, name: 'NO RENOVADA'}
    ];
  }

  loadDatesData(filterRangeDate: Date[]) {
    if (filterRangeDate != null) {
      this.filters.dateStart = this.datePipe.transform(filterRangeDate[0], 'yyyy-MM-dd');
      this.filters.dateEnd = this.datePipe.transform(filterRangeDate[1], 'yyyy-MM-dd');
      this.rangeDate = this.filters.dateStart + '-' + this.filters.dateEnd + ' ';
    }
  }

  loadDatesDataEnd(filterRangeDate: Date[]) {
    if (filterRangeDate != null) {
      this.filters.dateStartEnd = this.datePipe.transform(filterRangeDate[0], 'yyyy-MM-dd');
      this.filters.dateEndEnd = this.datePipe.transform(filterRangeDate[1], 'yyyy-MM-dd');
      this.rangeDateEnd = this.filters.dateStartEnd + '-' + this.filters.dateEndEnd + ' ';
    }
  }
  changeStatusGroups() {
    const groups = [];
    let groupsName = '';
    const group = [];
    setTimeout(() => {
      try {
        this.groupsSelectedItems.forEach(item => {
          groups.push(item.name);
          group.push(item.groupId);
          groupsName = groupsName + item.name + ', ';
        });
        this.rangeGroups = groupsName + ' ';
        this.rangeGroupList = groups;
        let multiGroup = {};
        multiGroup = {
          idsGroups: group
        };
        this.getSubGroups(multiGroup);
        this.filters.groups = group;
      } catch (e) { }
    }, 1000);
  }
  changeStatusSubGroups() {
    const subGroups = [];
    let subGroupsName = '';
    const subGroups1 = [];
    setTimeout(() => {
      try {
        this.clientsSelectedItems.forEach(item => {
          subGroups.push(item.name);
          subGroupsName = subGroupsName + item.name + ', ';
          subGroups1.push(item.clientId);
        });
        this.rangeSubgroupList = subGroups;
        this.rangeSubgroups = subGroupsName + ' ';
        this.filters.subGroups = subGroups1;
      } catch (e) { }
    }, 500);
  }
  changeStatusInsurances() {
    const insurances = [];
    const idsInsurances = [];
    let insurancesName = '';
    setTimeout(() => {
      try {
        this.insurancesSelectedItems.forEach(item => {
          insurances.push(item.name);
          idsInsurances.push(item.insuranceId);
          insurancesName = insurancesName + item.name + ', ';
        });
        this.rangeInsuranceList =  insurances;
        this.rangeInsurances = insurancesName + ' ';
        this.filters.insurances = idsInsurances;
      } catch (e) { }
    }, 500);
  }
  changeStatusExecutives() {
    const executives = [];
    let executivesName = '';
    setTimeout(() => {
      try {
        this.executivesSelectedItems.forEach(item => {
          executives.push(item.executiveId);
          executivesName = executivesName + item.name + ', ';
        });
        this.rangeExecutiveList =  executives;
        this.rangeExecutive = executivesName + ' ';
      } catch (e) { }
    }, 500);
  }
  changeStatusPolicies() {
    const policies = [];
    let policiesName = '';
    setTimeout(() => {
      try {
        this.policiesSelectedItems.forEach(item => {
          policies.push(item.policyId);
          policiesName = policiesName + item.number + ', ';
        });
        this.rangePolicyList = policies;
        this.rangePolicies =  policiesName + ' ';
        this.filters.policies = policies;
        this.rangeStatusReceipt = policiesName;
      } catch (e) { }
    }, 500);
  }
  changeStatusReceipts() {
    const status = [];
    let statusName = '';
    setTimeout(() => {
      try {
        this.statusSelectedItems.forEach(item => {
          status.push(item.receiptStatus);
          statusName = statusName + item.receiptStatus + ', ';
        });
        this.rangeStatusList = status;
        this.rangeStatus = statusName + ' ';
        this.filters.statusPayment = status;
      } catch (e) { }
    }, 500);
  }
  changeStatusCurrency() {
    const currencies = [];
    let currencyName = '';
    const idsCurrency = [];
    setTimeout(() => {
      try {
        this.currencySelectedItems.forEach(item => {
          currencies.push(item.name);
          currencyName = currencyName + item.name + ', ';
          idsCurrency.push(item.currencyId);
        });
        this.filters.currencies = idsCurrency;
        this.rangeCurrency = currencyName;
      } catch (e) { }
    }, 500);
  }
  changeStatusSalesman() {
    const salesman = [];
    let salesmanName = '';
    const idsSalesman = [];
    setTimeout(() => {
      try {
        this.salesmanSelectedItems.forEach(item => {
          salesman.push(item.name);
          salesmanName = salesmanName + item.name + ', ';
          idsSalesman.push(item.userId);
        });
        this.filters.salesman = idsSalesman;
        this.rangeSalesman = salesmanName;
      } catch (e) { }
    }, 500);
  }
  changeStatusCoordinator() {
    const coordinator = [];
    let coordinatorName = '';
    const idsCoodinator = [];
    setTimeout(() => {
      try {
        this.coordinatorSelectedItems.forEach(item => {
          coordinator.push(item.name);
          coordinatorName = coordinatorName + item.name + ', ';
          idsCoodinator.push(item.directoryId);
        });
        this.filters.coordinators = idsCoodinator;
        this.rangeCoordinator = coordinatorName;
      } catch (e) { }
    }, 500);
  }
  changeStatusSurexs() {
    const surexs = [];
    let surexsName = '';
    const idsExecutives = [];
    setTimeout(() => {
      try {
        this.surexSelectedItems.forEach(item => {
          surexs.push(item.name);
          surexsName = surexsName + item.name + ', ';
          idsExecutives.push(item.directoryId);
        });
        this.filters.executives = idsExecutives;
        this.rangeSurexs = surexsName;
      } catch (e) { }
    }, 500);
  }
  changeStatusIncident() {
    const incident = [];
    let incidentName = '';
    setTimeout(() => {
      try {
        this.incidentSelectedItems.forEach(item => {
          incident.push(item.name);
          incidentName = incidentName + item.name + ', ';
        });
        this.filters.namesEjecutivesSiniestro = incident;
        this.rangeIncident = incidentName;
        // console.log(incident);
        // console.log('siniestros');
      } catch (e) { }
    }, 500);
  }
  changeStatusBranch() {
    const branch = [];
    let branchName = '';
    const branches = [];
    setTimeout(() => {
      try {
        this.branchSelectedItems.forEach(item => {
          branch.push(item.branchId);
          branches.push(item.branchType);
          branchName = branchName + item.branchType + ', ';
        });
        this.getSubBranches(branch);
        this.namesBranch = branches;
        this.filters.branch = branch;
        this.rangeBranch = branchName;
        this.getPolicies();
      } catch (e) { }
    }, 500);
  }
  changeStatusSubBranch() {
    const subBranch = [];
    let subBranchName = '';
    const idsSubBranches = [];
    setTimeout(() => {
      try {
        this.subBranchSelectedItems.forEach(item => {
          subBranch.push(item.subBranch);
          subBranchName = subBranchName + item.subBranch + ', ';
          idsSubBranches.push(item.subBranchId);
        });
        this.filters.subBranches = idsSubBranches;
        this.rangeSubBranch = subBranchName;
        this.namesSuBranches = subBranch;
        this.getPolicies();
      } catch (e) { }
    }, 500);
  }
  changeStatusPolicy() {
    const statusPolicy = [];
    let statusPolicyName = '';
    const idsPolicies = [];
    setTimeout(() => {
      try {
        this.statusPolicySelectedItems.forEach(item => {
          statusPolicy.push(item.name);
          statusPolicyName = statusPolicyName + item.name + ', ';
          idsPolicies.push(item.statusPolicyId);
        });
        this.filters.statusPolicy = idsPolicies;
      } catch (e) { }
    }, 500);
  }
  pageReceiptChanged(event: PageChangedEvent): void {
    // // console.log(event);
    this.pgReceiptCurrentPage = event.page;
    this.getReceiptByPagination(this.pgReceiptCurrentPage, this.pgReceiptItemsPerPage);
    this.changeColorPagination();
  }
  changeColorPagination() {
    /* setTimeout(() => {
       // console.log(this.paginationUser.elementRef.nativeElement.childNodes[0].childNodes);
       for (const obj of this.paginationUser.elementRef.nativeElement.childNodes[0].childNodes) {
         // console.log(obj);
         // console.log(obj.className);

         if (obj.className != null && obj.className.includes('pagination-page page-item active') ) {
           // console.log('entroo');
           obj.childNodes[0].style.background = this.sharedService.gSkin;
           obj.childNodes[0].style.borderColor = '#ffffff';
         }
         if (obj.className != null && obj.className === 'pagination-page page-item' ) {
           obj.childNodes[0].style.color = this.sharedService.gSkin;
         }
         if (obj.className != null && obj.className === 'pagination-page page-item disabled' ) {
           obj.childNodes[0].style.color = this.sharedService.gSkin;
         }
         if (obj.className != null && obj.className === 'pagination-next page-item' ) {
           obj.childNodes[0].style.color = this.sharedService.gSkin;
         }
       }
     }, 10);*/
  }
  getReceiptByPagination(page, size) {
     this.spinner.show('sp');
     this.filters.page = page;
     this.filters.size = size;
     console.log(this.filters);
     this.api.getDocumentsPagination(this.filters)
      .then(
        (response: any) => {
          if (response == null) {
            this.pgReceiptTotalItems = 0;
            this.receiptsList = [];
          } else {
            this.paginationResponse = response;
            this.pgReceiptTotalItems = this.paginationResponse.total ;
            this.receiptsList = this.paginationResponse.receipts ;
          }
          this.spinner.hide('sp');
        }, error => {
          this.pgReceiptTotalItems = 0;
          this.receiptsList = [];
          // console.error(error.status);
          // console.error(error.statusText);
          // console.error(error.message);
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  updateReceipt() {
    this.spinner.show('sp');
    // // console.log(this.receiptData);
    this.api.putReceiptData(this.receiptData)
      .then(
        (response: any) => {
          this.getReceiptByPagination(this.pgReceiptCurrentPage, this.pgReceiptItemsPerPage);
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
          this.spinner.hide('sp');
          this.editR = true;
        }, error => {
          // console.error(error.status);
          // console.error(error.statusText);
          // console.error(error.message);
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  calTotal() {
    // tslint:disable-next-line:max-line-length
    this.receiptData.totalPremium = this.receiptData.netPremium + this.receiptData.rights + this.receiptData.paymentCharges + this.receiptData.iva;
  }
  loadFilters() {
    this.filters = {
      dateStart: '',
      dateEnd: '',
      groups: [],
      subGroups: [],
      insurances: [],
      statusPolicy: [],
      coordinators: [],
      executives: [],
      salesman: [],
      branch: [],
      subBranches: [],
      policies: [],
      currencies: [],
      dateStartEnd: '',
      dateEndEnd: '',
      page: 0,
      size: 0
    };

  }
  loadFiltersTable(val) {
    this.isCollapsed = val;
    if (val) {
      // console.log(this.filters);
      this.getReceiptByPagination(1, 10);
    }
  }
  downloadExcel() {
    this.validateAccess.validateAccessCore(73).then((access: any) => {
      console.log('valor que regresa', access.accessCore);
      if (access.accessCore === 0) {
        this.toastr.warning(this.validateAccess.messageNotification(), this.validateAccess.messageTitle());
      } else {
        if (Number(localStorage.getItem('roleId')) === 2) {
          // this.filters.seeCommision = true;
        }
        this.api.getExcelFileDocuments(this.filters)
          .subscribe(
            (response: any) => {
              const dataType = response.type;
              const binaryData = [];
              binaryData.push(response);
              const downloadLink = document.createElement('a');
              downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
              downloadLink.setAttribute('download', 'Documentos.xls');
              document.body.appendChild(downloadLink);
              downloadLink.click();
              downloadLink.remove();
            }, error => {
              // console.error(error);
            });
      }
    });
  }

  loadPolicy(policy) {
    this.api.getPolicy(policy.policyId).then((data: any) => {
      this.isSimplifyPolice = data.policySimply;
      if (policy.branch === 'DAÑOS' && !this.isSimplifyPolice) {
        this.shared.fPolicyDataReadonly = true;
        this.shared.gPolicyIdDamage.next(policy.policyId);
        this.router.navigate(['/admin/damage-policy']);
      } else {
        this.shared.fPolicyDataReadonly = true;
        this.shared.gPolicyId.next(policy.policyId);
        this.router.navigate(['/admin/policies']);
      }
    }, error => {
    });

  }

}
