import {Component, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {Dates} from '../plans-carga-poblacion/dates.model';


@Component({
  selector: 'app-flex-vision-p2-view',
  templateUrl: './flex-vision-p2-view.component.html',
  styleUrls: ['./flex-vision-p2-view.component.css']
})

export class FlexVisionP2ViewComponent implements OnInit {
  idPlanFlex;
  planFlexForm: FormGroup;
  planPlanes: FormArray;
  planInformation: any = {};
  insuranceList = [];
  isTariffSectionVisible: boolean[] = [];
  error = {active: false, msg: ''};
  public fileUpload: File;
  poblationLoadReady = false;
  idInsurance = 0;
  dates: Dates;
  constructor(private fb: FormBuilder,
              private api: ApiService,
              private activatedRoute: ActivatedRoute,
              private toastr: ToastrService,
              private shared: SharedService,
              private spinner: NgxSpinnerService,
              private router: Router) {
    this.activatedRoute.params.subscribe(params => {
      this.idPlanFlex = params.idPlan;
      // this.isEdition = params.isEdition === 'true';
    });
    this.planFlexForm = this.fb.group({
      idPlanFlexId: [''],
      idInsurance: [''],
      categories: this.fb.array([]),
      categoriesFlex: this.fb.array([]),
    });
  }

  fileTypes: string[] = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.oasis.opendocument.spreadsheet'];
  ngOnInit() {
    this.getInsurancesByIdProduct();
    this.dates = {
      edadMaximaAnios: 70,
      edadMaximaMeses: 11,
      edadMinimaAnios: 0,
      edadMinimaMeses: 0,
      edadMaximaRenovacionAnios: 75,
      edadMaximaRenovacionMeses: 11,
      edadMinimaRenovacionAnios: 0,
      edadMinimaRenovacionMeses: 0,
    };
  }
  // Método para añadir una categoría
  addCategory(category: any) {
    const categoryFormGroup = this.fb.group({
      idCategory: [category.idCategory],
      nameCategory: [category.nameCategory],
      plans: this.fb.array([]),
    });
    if (category.nameCategory.includes('Flex') || category.nameCategory.includes('flex') || category.nameCategory.includes('FLEX')) {
      // Añadir el FormGroup de la categoría al FormArray 'categories'
      (this.planFlexForm.get('categoriesFlex') as FormArray).push(categoryFormGroup);
    } else {
      // Añadir el FormGroup de la categoría al FormArray 'categories'
      (this.planFlexForm.get('categories') as FormArray).push(categoryFormGroup);
    }
  }
  // Método para añadir un plan dentro de una categoría
  addPlanToCategory(categoryId: number, categoryName: string, plan: any) {
    console.log('PLAND', plan);
    this.idInsurance = plan.insuranceId;
    const planFormGroup = this.fb.group({
      idPlan: [plan.idPlan],
      premiumIndividual: [plan.premiumIndividual !== null ? plan.premiumIndividual : false],
      policyRights: [plan.policyRights],
      quote: [plan.quote],
      namePlan: [plan.namePlan],
      averagPremium: [plan.averagPremium],
      netPremiumIndividual: [plan.netPremiumIndividual],
      surcharges: [plan.surcharges],
      flexiblePlan: [plan.flexiblePlan],
      insuranceId: [plan.insuranceId],
      renewedPolicy: [false],
      maximumYearsPolicy: [plan.maximumYearsPolicy, Validators.required],
      maximumMonthsPolicy: [plan.maximumMonthsPolicy, Validators.required],
      minimumYearsPolicy: [0],
      minimumMonthsPolicy: [plan.minimumMonthsPolicy, Validators.required],
      maximumYearsRenewal: [plan.maximumYearsRenewal, Validators.required],
      maximumMonthsRenewal: [plan.maximumMonthsRenewal, Validators.required],
      minimumYearsRenewal: [0],
      minimumMonthsRenewal: [plan.minimumMonthsRenewal, Validators.required],
      coverages: this.fb.array([]),
    });

    // Obtener el FormArray de 'plans' de la categoría correspondiente
    const categoryPlansArray = this.findPlansByCategory(categoryId, categoryName);

    // Añadir el plan al FormArray de 'plans'
    categoryPlansArray.push(planFormGroup);
  }
  // Método para buscar el plan de una categoria en el form
  findPlansByCategory(categoryId: number, categoryName: string) {
    // Obtener el FormArray de 'plans' de la categoría correspondiente
    let categoriesArray: FormArray;
    if (categoryName.includes('Flex') || categoryName.includes('flex') || categoryName.includes('FLEX')) {
      categoriesArray = this.planFlexForm.get('categoriesFlex') as FormArray;
    } else {
      categoriesArray = this.planFlexForm.get('categories') as FormArray;
    }
    const category = categoriesArray.controls.find((control) => {
      return control.value.idCategory === categoryId;
    });
    return category.get('plans') as FormArray;
  }
  // Método para buscar las coverturas de un plan en el form
  findPlanById(categoryId: number, categoryName: string, planId: number) {
    const plan = this.findPlansByCategory(categoryId, categoryName).controls.find((control) => {
      return control.value.idPlan === planId;
    });
    return plan as FormArray;
  }
  // Método para añadir una categoría
  addCoverageToPlan(categoryId: number, categoryName: string, planId: number, coverages: any) {
    console.log('COV', coverages);
    coverages.forEach(coverage => {
      const coverageFormGroup = this.fb.group({
        idPlan: [coverage.idPlan],
        flexible: [coverage.flexible],
        measurementDescriptionEn: [coverage.measurementDescriptionEn],
        endoso: [coverage.endoso],
        nameCoverage: [coverage.nameCoverage],
        factorQuote: [coverage.factorQuote],
        idSurexs: [coverage.idSurexs],
        measurementDescription: [coverage.measurementDescription],
        origen: [coverage.origen],
        idMeasurementUnit: [coverage.idMeasurementUnit],
        value: [coverage.value],
        idCoverage: [coverage.idCoverage],
      });
      console.log('coverageFormGroup', coverageFormGroup.value);
      const formCoverages = this.findCoveragesByPlan(categoryId, categoryName, planId);
      console.log('COVFORM', formCoverages);

      // Añadir el FormGroup de la categoría al FormArray 'categories'
      formCoverages.push(coverageFormGroup);
      console.log('FORMD', formCoverages);
    });

  }
  // Método para buscar las coverturas de un plan en el form
  findCoveragesByPlan(categoryId: number, categoryName: string, planId: number) {
    const plan = this.findPlansByCategory(categoryId, categoryName).controls.find((control) => {
      return control.value.idPlan === planId;
    });
    return plan.get('coverages') as FormArray;
  }
  // Método para mostrar/ocultar la sección de tarifas manuales de un plan específico
  toggleTariffSection(planIndex: number) {
    this.isTariffSectionVisible[planIndex] = !this.isTariffSectionVisible[planIndex];
  }
  invokeFunctionBackDetailPlan() {
    this.router.navigate(['/admin/plans-detalle/', this.idPlanFlex]);
  }

  // SERVICES
  updateQuotesVision(quoteModel: any): void {
    this.spinner.show('sp');
    console.log('REQ-', quoteModel);
    this.api.updateQuotesVision(quoteModel)
      .then(
        (response: any) => {
          console.log(response);
          this.spinner.hide('sp');
          this.toastr.success('SE HA GUARDADO CON ÉXITO', 'NOTIFICACION');
        }, error => {
          this.toastr.error('Ocurrió un problema al cargar las tarifas', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }

  getPlanInformation(): void {
    const data = {
      idPlanFlex: Number(this.idPlanFlex)
    };
    this.spinner.show('sp');
    this.api.getPlanVisionInformation(data)
      .then(
        (response: any) => {
          this.planInformation = response;
          console.log('planinformation', response);
          if (this.planInformation.categories.length > 0) {
            if (this.planInformation.categories[0].plans.length > 0) {
              console.log('cov val-', this.planInformation.categories[0].plans[0].insuranceId);
              this.planFlexForm.patchValue({
                idPlanFlexId: this.planInformation.idPlanFlexId,
              });
              this.planInformation.categories.forEach(cat => {
                console.log('CAT', cat.idCategory);
                this.addCategory(cat);
                cat.plans.forEach(plan => {
                  console.log('PLAN', plan);
                  console.log('PLAN 1', cat.idCategory);
                  console.log('PLAN 2', plan.idPlan);
                  console.log('PLAN COVERAGES', plan.coverages);
                  this.addPlanToCategory(cat.idCategory, cat.nameCategory, plan);
                  this.addCoverageToPlan(cat.idCategory, cat.nameCategory, plan.idPlan, plan.coverages);
                });
              });
              console.log('GTO', this.planFlexForm.value);
            }
          }
          this.spinner.hide('sp');
          this.toastr.success('Se cargaron las tarifas correctamente', 'Notificación');
        }, error => {
          this.toastr.error('Ocurrió un problema al cargar el catálogo de Roles', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }

  validateCharge(event: any): void {
    const inputValue = event.target.value;
    if (inputValue > 15) {
      event.target.value = 15;  // Limita el valor a 15 si es mayor
      this.toastr.info('LOS RECARGOS NO PUEDEN SER MAYOR A 15', 'NOTIFICACIÓN');
    }
  }

  downloadLayout(id) {
    this.spinner.show('sp');
    this.api.getLayoutFile(id)
      .subscribe(
        (response: any) => {
          this.spinner.hide('sp');
          if (response == null) {
            this.toastr.info('NO EXISTE ARCHIVO A DESCARGAR');
            return;
          } else {
            const dataType = response.type;
            const binaryData = [];
            binaryData.push(response);
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            downloadLink.setAttribute('download',  'LAYOUT_TARIFAS.xlsx');
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
          }
        }, error => {
          this.spinner.hide('sp');
          // console.error(error);
        }
      );
  }

  getInsurancesByIdProduct(): void {
    const data = {
      idProduct: 6
    };
    this.spinner.show('sp');
    this.api.getInsurancesByIdProduct(data)
      .then(
        (response: any) => {
          this.insuranceList = response;
          console.log(this.insuranceList);
          this.spinner.hide('sp');
          this.getPlanInformation();
        }, error => {
          this.toastr.error('Ocurrió un problema al cargar las aseguradoras', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }

  onInsuranceChange(value: number): void {
    console.log(value);
    this.idInsurance = value;
    this.setInsurance('categories');
    this.setInsurance('categoriesFlex');
  }

  setInsurance(categoryName): void {
    const categories = this.planFlexForm.get(categoryName) as FormArray;
    categories.controls.forEach((categoryGroup, index) => {
      // Puedes acceder a cada FormGroup y actualizar el valor de `name`
      const plans = categoryGroup.get('plans') as FormArray;
      plans.controls.forEach((plan) => {
        plan.patchValue({
          insuranceId: Number(this.idInsurance)
        });
      });
    });
    this.planFlexForm.get('idInsurance').setValue(this.idInsurance);
    if (categories.length > 0) {
      this.validateQuotes();
    }
  }

  onMaximumYearsPolicyChange(value: string): void {
    this.setMaximumYearsPolicy('categories', value);
    this.setMaximumYearsPolicy('categoriesFlex', value);
  }
  setMaximumYearsPolicy(categoryName: string, value: string) {
    const categories = this.planFlexForm.get(categoryName) as FormArray;
    categories.controls.forEach((categoryGroup, index) => {
      // Puedes acceder a cada FormGroup y actualizar el valor de `name`
      const plans = categoryGroup.get('plans') as FormArray;
      plans.controls.forEach((plan) => {
        plan.patchValue({
          maximumYearsPolicy: value
        });
      });
      this.dates.edadMaximaAnios = Number(value);
    });
  }

  onMaximumMonthsPolicyChange(value: string): void {
    this.setMaximumMonthsPolicy('categories', value);
    this.setMaximumMonthsPolicy('categoriesFlex', value);
  }
  setMaximumMonthsPolicy(categoryName: string, value: string) {
    const categories = this.planFlexForm.get(categoryName) as FormArray;
    categories.controls.forEach((categoryGroup, index) => {
      // Puedes acceder a cada FormGroup y actualizar el valor de `name`
      const plans = categoryGroup.get('plans') as FormArray;
      plans.controls.forEach((plan) => {
        plan.patchValue({
          maximumMonthsPolicy: value
        });
      });
      this.dates.edadMaximaMeses = Number(value);
    });
  }

  /*onMinimumYearsPolicyChange(value: string): void {
    this.setMinimumYearsPolicy('categories', value);
    this.setMinimumYearsPolicy('categoriesFlex', value);
  }
  setMinimumYearsPolicy(categoryName: string, value: string) {
    const categories = this.planFlexForm.get(categoryName) as FormArray;
    categories.controls.forEach((categoryGroup, index) => {
      // Puedes acceder a cada FormGroup y actualizar el valor de `name`
      const plans = categoryGroup.get('plans') as FormArray;
      plans.controls.forEach((plan) => {
        plan.patchValue({
          minimumYearsPolicy: value
        });
      });
      this.dates.edadMinimaAnios = Number(value);
    });
  }*/

  onMinimumMonthsPolicyChange(value: string): void {
    this.setMinimumMonthsPolicy('categories', value);
    this.setMinimumMonthsPolicy('categoriesFlex', value);
  }
  setMinimumMonthsPolicy(categoryName: string, value: string) {
    const categories = this.planFlexForm.get(categoryName) as FormArray;
    categories.controls.forEach((categoryGroup, index) => {
      // Puedes acceder a cada FormGroup y actualizar el valor de `name`
      const plans = categoryGroup.get('plans') as FormArray;
      plans.controls.forEach((plan) => {
        plan.patchValue({
          minimumMonthsPolicy: value
        });
      });
      this.dates.edadMinimaMeses = Number(value);
    });
  }

  onMaximumYearsRenewalChange(value: string): void {
    this.setMaximumYearsRenewal('categories', value);
    this.setMaximumYearsRenewal('categoriesFlex', value);
  }
  setMaximumYearsRenewal(categoryName: string, value: string) {
    const categories = this.planFlexForm.get(categoryName) as FormArray;
    categories.controls.forEach((categoryGroup, index) => {
      // Puedes acceder a cada FormGroup y actualizar el valor de `name`
      const plans = categoryGroup.get('plans') as FormArray;
      plans.controls.forEach((plan) => {
        plan.patchValue({
          maximumYearsRenewal: value
        });
      });
      this.dates.edadMaximaRenovacionAnios = Number(value);
    });
  }

  onMaximumMonthsRenewalChange(value: string): void {
    this.setMaximumMonthsRenewal('categories', value);
    this.setMaximumMonthsRenewal('categoriesFlex', value);
  }
  setMaximumMonthsRenewal(categoryName: string, value: string) {
    const categories = this.planFlexForm.get(categoryName) as FormArray;
    categories.controls.forEach((categoryGroup, index) => {
      // Puedes acceder a cada FormGroup y actualizar el valor de `name`
      const plans = categoryGroup.get('plans') as FormArray;
      plans.controls.forEach((plan) => {
        plan.patchValue({
          maximumMonthsRenewal: value
        });
      });
      this.dates.edadMaximaRenovacionMeses = Number(value);
    });
  }

  /*onMinimumYearsRenewalChange(value: string): void {
    this.setMinimumYearsRenewal('categories', value);
    this.setMinimumYearsRenewal('categoriesFlex', value);
  }
  setMinimumYearsRenewal(categoryName: string, value: string) {
    const categories = this.planFlexForm.get(categoryName) as FormArray;
    categories.controls.forEach((categoryGroup, index) => {
      // Puedes acceder a cada FormGroup y actualizar el valor de `name`
      const plans = categoryGroup.get('plans') as FormArray;
      plans.controls.forEach((plan) => {
        plan.patchValue({
          minimumYearsRenewal: value
        });
      });
      this.dates.edadMinimaRenovacionAnios = Number(value);
    });
  }*/

  onMinimumMonthsRenewalChange(value: string): void {
    this.setMinimumMonthsRenewal('categories', value);
    this.setMinimumMonthsRenewal('categoriesFlex', value);
  }
  setMinimumMonthsRenewal(categoryName: string, value: string) {
    const categories = this.planFlexForm.get(categoryName) as FormArray;
    categories.controls.forEach((categoryGroup, index) => {
      // Puedes acceder a cada FormGroup y actualizar el valor de `name`
      const plans = categoryGroup.get('plans') as FormArray;
      plans.controls.forEach((plan) => {
        plan.patchValue({
          minimumMonthsRenewal: value
        });
      });
      this.dates.edadMinimaRenovacionMeses = Number(value);
    });
  }

  validateQuotes() {
    const dto = this.planFlexForm.value;
    dto.categories.forEach((cat) => {
      cat.plans.forEach((plan) => {
        if ( (plan.maximumYearsPolicy !== undefined && plan.maximumYearsPolicy !== '' && plan.maximumYearsPolicy !== null) &&
          (plan.maximumMonthsPolicy !== undefined && plan.maximumMonthsPolicy !== '' && plan.maximumMonthsPolicy !== null) &&
          // (plan.minimumYearsPolicy !== undefined && plan.minimumYearsPolicy !== '' && plan.minimumYearsPolicy !== null) &&
          (plan.minimumMonthsPolicy !== undefined && plan.minimumMonthsPolicy !== '' && plan.minimumMonthsPolicy !== null) &&
          (plan.maximumYearsRenewal !== undefined && plan.maximumYearsRenewal !== '' && plan.maximumYearsRenewal !== null) &&
          (plan.maximumMonthsRenewal !== undefined && plan.maximumMonthsRenewal !== '' && plan.maximumMonthsRenewal !== null) &&
          // (plan.minimumYearsRenewal !== undefined && plan.minimumYearsRenewal !== '' && plan.minimumYearsRenewal !== null) &&
          (plan.minimumMonthsRenewal !== undefined && plan.minimumMonthsRenewal !== '' && plan.minimumMonthsRenewal !== null) &&
          (plan.policyRights !== undefined && plan.policyRights !== null) &&
          (plan.averagPremium !== undefined && plan.averagPremium !== null) &&
          (plan.netPremiumIndividual !== undefined && plan.netPremiumIndividual !== null) &&
          (plan.surcharges !== undefined && plan.surcharges !== null)) {
          this.poblationLoadReady = true;
          this.setDates(plan);
        } else {
          this.poblationLoadReady = false;
        }
      });
    });
    if (!this.poblationLoadReady) {
      this.toastr.info('TIENE QUE CONFIGURAR TODAS LAS TARIFAS PARA PODER CARGAR LA POBLACIÓN', 'NOTIFICACIÓN');
    }
    console.log('flag', this.poblationLoadReady);
    console.log('Dates', this.dates);
    console.log('dto', dto);
  }

  setDates(plan: any) {
    this.dates = {
      edadMaximaAnios: Number(plan.maximumYearsPolicy),
      edadMaximaMeses: Number(plan.maximumMonthsPolicy),
      edadMinimaAnios: 0,
      edadMinimaMeses: Number(plan.minimumMonthsPolicy),
      edadMaximaRenovacionAnios: Number(plan.maximumYearsRenewal),
      edadMaximaRenovacionMeses: Number(plan.maximumMonthsRenewal),
      edadMinimaRenovacionAnios: 0,
      edadMinimaRenovacionMeses: Number(plan.minimumMonthsRenewal)
    };
  }

  saveOnlyPlan(categoryId: number, categoryName: string, indexPlan: number): void {
    console.log('--categoryIndex', categoryId);
    console.log('--indexPlan', indexPlan);
    console.log('--findPlansByCategory', this.findPlansByCategory(categoryId, categoryName));
    console.log('--findPlan', this.findPlanById(categoryId, categoryName, indexPlan));
    const plan = this.findPlanById(categoryId, categoryName, indexPlan) as FormArray;
    console.log('PLANF', plan);
    // Crear el objeto directamente
    const categoriesGroup = this.fb.group({
      idCategory: categoryId,
      plans: this.fb.array([]),
    });
    (categoriesGroup.get('plans') as FormArray).push(plan);
    const request = this.fb.group({
      idPlanFlexId: Number(this.idPlanFlex),
      categories: this.fb.array([]),
    });
    (request.get('categories') as FormArray).push(categoriesGroup);

    console.log('plan', request.value);
    this.updateQuotesVision(request.value);
  }

  protected readonly Number = Number;
}
