import {Component, OnInit, TemplateRef} from '@angular/core';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {NgxSpinnerService} from 'ngx-spinner';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-merge-documents-pdf',
  templateUrl: './merge-documents-pdf.component.html',
  styleUrls: ['./merge-documents-pdf.component.css']
})
export class MergeDocumentsPdfComponent implements OnInit {

  mergePDFCompletedList: any = [];
  mergePDFList: any = [];
  // -Varibles para paginado de usuarios->
  pgBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgMaxSize = 3; // Número de links en paginado
  pgItemsPerPage = 10; // Número de registros por página
  pgTotalItems: number; // Total de registros
  pgCurrentPage = 1; // Página actual
  paginationResponse: any = {};
  filters: any = {};
  bsConfig: Partial<BsDatepickerConfig>;
  startDateTmp: string;
  endDateTmp: any;
  selectedConceptTypeValidity: number;
  yearsValidity: number;
  modalLoadZip: BsModalRef;
  formCreateProcess: FormGroup;
  isFileDragDropAvailable: true;
  catClients: any = [];
  catClientsSelected: any = [];
  catGroups: any = [];
  catInsuranceCompany: any = [];
  // Labels for each file input
  public fileLabels: any[] = [];
  methodOfMerge: any;
  public fileUploadZip: File;
  errorZip = {active: false, msg: ''};
  fileTypesZip: string[] = ['application/zip', 'application/x-zip-compressed'];
  mergePDFSelected: any = {};

  multiple: true;
  constructor(private api: ApiService,
              private router: Router,
              private toastr: ToastrService,
              private shared: SharedService,
              private formBuilder: FormBuilder,
              private modalService: BsModalService,
              private spinner: NgxSpinnerService) {

  }

  ngOnInit() {

    this.clearFiltersTable();
  }
  /**
   * Función para limpiar los filtros
   */
  clearFiltersTable() {
    this.filters = {
      page: 1,
      size: 10
    };
    this.getByPagination(1, 10);
  }
  /**
   * Carga los datos según la página seleccionada
   */
  pageChangedSpecial(event: PageChangedEvent): void {
    console.log(event);
    this.pgCurrentPage = event.page;
    this.getByPagination(this.pgCurrentPage, this.pgItemsPerPage);
  }
  /**
   * Función para obtener las coberturas especiales del sistema
   */
  getByPagination(page, size) {
    this.spinner.show('sp');
    this.filters.page = page;
    this.filters.size = size;
    this.api.getAllMergePDFCompleted(this.filters)
      .then(
        (response: any) => {
          if (response == null) {
            this.toastr.info('NO SE ENCONTRARON RESULTADOS CON SU BUSQUEDA');
            this.mergePDFCompletedList = [];
            this.pgTotalItems = 0;
            this.spinner.hide('sp');
            return;
          }
          this.paginationResponse = response;
          this.spinner.hide('sp');
          this.pgTotalItems = this.paginationResponse.totalRows;
          this.mergePDFCompletedList = this.paginationResponse.mergePDFCompletedList;
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.mergePDFCompletedList = [];
          this.pgTotalItems = 0;
          this.spinner.hide('sp');
        }
      );
  }

  changeSelectProcesPFD() {
    console.log('Se seleccionó: ' + this.methodOfMerge);
    if (this.methodOfMerge === 0 || this.methodOfMerge === '0') {
      this.getAllMergePDFs();
    }
  }

  getAllMergePDFs() {
    this.mergePDFList = [];
    this.spinner.show('sp');
    this.api.getAllMergePDFs()
      .then(
        (response: any) => {
          if (response == null) {
            this.toastr.info('NO SE ENCONTRARON RESULTADOS CON SU BUSQUEDA');
            this.mergePDFList = [];
            this.spinner.hide('sp');
            return;
          }
          this.spinner.hide('sp');
          this.mergePDFList = response;
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.mergePDFList = [];
          this.pgTotalItems = 0;
          this.spinner.hide('sp');
        }
      );
  }


  openQuotationDetail(idPlacement) {
    this.router.navigate(['/admin/automation-detail/', idPlacement]);
  }


  public openModalAddNewProcess(template: TemplateRef<any>) {
    this.modalLoadZip = this.modalService.show(template, {class: 'modal-lg', keyboard: false,
      ignoreBackdropClick: true, backdrop: false});
  }

  closeModal() {
    this.modalLoadZip.hide();
  }

  onChangeInputFileZip(e) {
    this.errorZip = {active: false, msg: ''};
    this.fileUploadZip = e.target.files[0];
    if (0 > this.fileTypesZip.indexOf(this.fileUploadZip.type)) {
      this.errorZip = {active: true, msg: 'Debe seleccionar un archivo válido'};
      return;
    }
  }

  executeMergePDF() {
    if (this.fileUploadZip === undefined) {
      this.toastr.warning('DEBE DE CARGAR LOS CERTIFICADOS DE SU POBLACIÓN INICIAL', 'NOTIFICACIÓN');
      return;
    }

    this.spinner.show('sp');
    if (this.methodOfMerge !== 0 && this.methodOfMerge !== '0') {
      this.mergePDFSelected = {
        idInsurance: this.methodOfMerge //
      };
    }

    const formData = new FormData();
    formData.append('fileCompressed', this.fileUploadZip);
    formData.append('mergePdf', JSON.stringify(this.mergePDFSelected));

    this.api.mergePDF(formData)
      .then(
        (response: any) => {
          this.toastr.success('El proceso de unión de PDFs ha terminado', 'NOTIFICACIÓN');
          this.spinner.hide('sp');
          this.closeModal();
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.closeModal();
          this.spinner.hide('sp');
        }
      );
  }

  public invokeServiceForGetAttachmentFile(file) {
    const objAttachment = {
      typeAttachment: 2,
      id: file,
      path: file
    };
    this.api.getAttachmentFileGeneric(objAttachment)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download', this.extractFileName(file));
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();

        }, error => {
          // console.error(error);
        }
      );
  }

  extractFileName(fileURL: string): string {
    const segments: string[] = fileURL.split('/');

    // El último segmento contendrá el nombre del archivo con su extensión
    const fileNameWithExtension: string = segments[segments.length - 1];

    return fileNameWithExtension;
  }

  onMergePDFSelected(merge: any) {
    this.mergePDFSelected = merge;
    console.log("Merge seleccionado:", this.mergePDFSelected); // Opcional: para depuración
  }

}
