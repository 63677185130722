import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {DatePipe} from '@angular/common';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {NgxSpinnerService} from 'ngx-spinner';
import {ValidateAccessService} from "../validate-access.service";
import { NgxDateRangePickerOptions } from 'ngx-daterangepicker';

import moment from 'moment';

@Component({
  selector: 'app-admin-plans',
  templateUrl: './admin-plans.component.html',
  styleUrls: ['./admin-plans.component.css']
})
export class AdminPlansComponent implements OnInit {
// variables pantalla consulta siniestros
  planFilter: any = {};
  clientList: any = [];
  groupList: any = [];
  branchesList: any = [];
  statusList: any = [];
  clientsSelectedItems: any = [];
  dropdownSettingsClients = {};
  groupsSelectedItems: any = [];
  dropdownSettingsGroups = {};
  statusSelectedItems: any = [];
  dropdownSettingsStatus = {};
  plansList: any = {};
  pgBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgMaxSize = 3; // Número de links en paginado
  pgItemsPerPage = 10; // Número de registros por página
  pgTotalItems: number; // Total de registros
  pgCurrentPage = 1; // Página actual
  paginationResponse: any = {};
  fechaOptions: NgxDateRangePickerOptions;

  constructor(private api: ApiService,
              private router: Router,
              private toastr: ToastrService,
              private shared: SharedService,
              private datePipe: DatePipe,
              public fb: FormBuilder,
              private modalService: BsModalService,
              private spinner: NgxSpinnerService,
              private validateAccess: ValidateAccessService) {
  }
  ngOnInit() {
    this.plansList = [];
    // this.kinshipSelected = 0;
    this.getGroups();
    this.getSubGroups(null);
    this.getStatusIncidents();
    this.loadFilters();
    this.dropdownSettingsClients = {
      singleSelection: true,
      idField: 'id',
      textField: 'code',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON SUB GRUPOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsGroups = {
      singleSelection: true,
      idField: 'id',
      textField: 'code',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON GRUPOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsStatus = {
      singleSelection: false,
      idField: 'statusId',
      textField: 'statusName',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON ESTATUS',
      searchPlaceholderText: 'BUSCAR'
    };
    let fecha = {
      from: moment().toDate(),
      to: moment().toDate()
    };
    this.fechaOptions = {
        theme: 'default',
        labels: ['Start', 'End'],
        menu: [
            {alias: 'td', text: 'Hoy', operation: '0d'},
            {alias: 'tm', text: 'Este Mes', operation: '0m'},
            {alias: 'lm', text: 'Ultimo Mes', operation: '-1m'},
            {alias: 'tw', text: 'Esta Semana', operation: '0w'},
            {alias: 'lw', text: 'Ultima Semana', operation: '-1w'},
            {alias: 'ty', text: 'Este año', operation: '0y'},
            {alias: 'ly', text: 'Ultimo año', operation: '-1y'},
            {alias: 'lyt', text: 'Ultimo año apartir de hoy', operation: '-1yt'},
        ],
        dateFormat: 'DD-MM-YYYY',
        outputFormat: 'DD-MM-YYYY',
        startOfWeek: 0,
        outputType: 'object',
        locale: 'es',
        date: fecha
    };

    this.getByPagination(1, 10);
  }
  /* -----------------------------------------------------------------------------------------------------
  ----------------------------- F U N C I O N E S G E N E R A L E S ---------------------------------------
  ----------------------------------------------------------------------------------------------------- */
  goToBack() {
    this.shared.gSearchId = 0;
    this.router.navigate(['/admin/executive']);
  }
  choosedDate(event: any) {
    this.planFilter.campaignStartDate = event.from;
    this.planFilter.campaignEndDate = event.to;
    this.loadFiltersTable();
  }
  getSubGroups(groups) {
    const params = {
      id: groups !== null ? groups.idsGroups[0] : null
    };
    this.api.getSubGroupsFlex(params).then((data: any) => {
      this.clientList = data;
    }, error => {
    });
  }
  getStatusIncidents() {
    this.api.getStatusIncident().then((data: any) => {
      this.statusList = data;
      console.log(this.statusList);
    }, error => {
    });
  }
  changeStatusGroups() {
    const group = [];
    setTimeout(() => {
      try {
        this.groupsSelectedItems.forEach(item => {
          group.push(item.id);
        });
        let multiGroup = {};
        multiGroup = {
          idsGroups: group
        };
        this.getSubGroups(multiGroup);
        this.planFilter.groupId = group[0];
        this.clientsSelectedItems = [];
        this.loadFiltersTable();
      } catch (e) { }
    }, 1000);
  }
  changeStatusSubGroups() {
    const subGroups = [];
    setTimeout(() => {
      try {
        this.clientsSelectedItems.forEach(item => {
          subGroups.push(item.clientId);
        });
        this.planFilter.subGroups = subGroups;
        this.loadFiltersTable();
      } catch (e) { }
    }, 500);
  }
  getGroups() {
    this.api.getGroupsFlex().then((data: any) => {
      this.groupList = data;
    }, error => {
    });
  }
  loadFilters() {
    this.planFilter = {
      status: true,
      campaignStartDate: '',
      campaignEndDate: '',
      rfc: '',
      groupId: 0,
      clientId: 0,
      page: 0,
      size: 10
    };
  }
  clearFiltersTable() {
    this.loadFilters();
    this.statusSelectedItems = [];
    this.groupsSelectedItems = [];
    this.clientsSelectedItems = [];
    this.loadFiltersTable();
  }
  loadFiltersTable() {
    this.getByPagination(0, 10);
  }
  changeColorPagination() { }
  getByPagination(page, size) {
    this.spinner.show('sp');
    this.planFilter.page = page;
    this.planFilter.size = size;
    console.log(this.planFilter);
    this.api.getPlansPagination(this.planFilter)
      .then(
        (response: any) => {
          if (response == null) {
            this.toastr.info('NO SE ENCONTRARON RESULTADOS CON SU BUSQUEDA');
            this.plansList = [];
            this.pgTotalItems = 0;
            this.spinner.hide('sp');
            return;
          }
          this.paginationResponse = response;
          this.spinner.hide('sp');
          this.pgTotalItems = this.paginationResponse.totalRows;
          this.plansList = this.paginationResponse.tList;
        }, error => {
          // console.error(error.status);
          // console.error(error.statusText);
          // // console.logerror(error.message);
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.plansList = [];
          this.pgTotalItems = 0;
          this.spinner.hide('sp');
        }
      );
  }
  pageChanged(event: PageChangedEvent): void {
    this.pgCurrentPage = event.page;
    this.getByPagination(this.pgCurrentPage, this.pgItemsPerPage);
    this.changeColorPagination();
  }
}
