import {Component, OnInit, TemplateRef, ViewChild, ChangeDetectorRef} from '@angular/core';
import {ApiService} from '../api.service';
import {Router, ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {DatePipe} from '@angular/common';
import {BsModalService} from 'ngx-bootstrap/modal';
import {NgxSpinnerService} from 'ngx-spinner';
import {ValidateAccessService} from "../validate-access.service";
import {FormBuilder, FormGroup} from '@angular/forms';

import { Dates } from '../plans-carga-poblacion/dates.model';

import * as _ from 'lodash';

@Component({
  selector: 'app-mascotas-p2',
  templateUrl: './mascotas-p2.component.html',
  styleUrls: ['./mascotas-p2.component.css']
})
export class MascotasP2Component implements OnInit {

  planForm: FormGroup; // Formulario

  idPlan: number;
  idProduct: number;

  plan: any;
  tarifas: any = {};
  aseguradorasList: any = [];

  dropdownSettingsAseguradoras = {};
  dates: Dates;
  coverages: any;

  isCargaPoblacion: boolean;
  isShowCargaPoblacion: boolean;
  isTariffSectionVisible: boolean[] = [];

  constructor(private api: ApiService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private toastr: ToastrService,
              private shared: SharedService,
              private datePipe: DatePipe,
              public fb: FormBuilder,
              private modalService: BsModalService,
              private spinner: NgxSpinnerService,
              private formBuilder: FormBuilder,
              private cdr: ChangeDetectorRef,
              private validateAccess: ValidateAccessService) {
  }
  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.idPlan = +params.idPlan;
      this.idProduct = +params.idProduct;

    });

    this.getAseguradoras();
    this.dropdownSettingsAseguradoras = {
      singleSelection: true,
      idField: 'id',
      textField: 'code',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 4,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON ASEGURADORAS',
      searchPlaceholderText: 'BUSCAR'
    };

    this.getCoverages();
    this.createForm();
  }
  /* -----------------------------------------------------------------------------------------------------
  ----------------------------- F U N C I O N E S G E N E R A L E S ---------------------------------------
  ----------------------------------------------------------------------------------------------------- */
  goToBack() {
    this.shared.gSearchId = 0;
    this.router.navigate(['/admin/executive']);
  }

  toggleTariffSection(planIndex: string) {
    this.isTariffSectionVisible[planIndex] = !this.isTariffSectionVisible[planIndex];
  }

  createForm(): void {
    this.planForm = this.formBuilder.group({
      aseguradoras: [null],
      maximumMonths: [null],
      maximumYears: [null],
      minimumMonths: [null],
      minimumYears: [null],
      renewalMaximumMonths: [null],
      renewalMaximumYears: [null],
      renewalMinimumMonths: [null],
      renewalMinimumYears: [null],
      renewedPolicy: [false],
    });
  }

  getAseguradoras(): void {
    const params = {
      idProduct: this.idProduct
    };
    this.api.getInsuranceList(params).subscribe(response => {
      this.aseguradorasList = response;
      this.getConfigurationData();
    },
    error => {
      console.log(error);
    });
  }

  getCoverageByName(name: string): any {
    let res = null;
    this.coverages.forEach(cov => {
      if (cov.coverageName === name) {
        res = cov;
      }
    });

    return res;
  }

  changeStatusAseguradoras(event: any): void {
  }

  getCoverages(): void {
      const params = {
        idPlan: this.idPlan,
        idProduct: this.idProduct
      };
      this.api.getCoveragesByIdProductOb(params).subscribe(response => {
        this.coverages = response;
      },
      error => {
        console.log(error);
      });
  }

  validateQuotes(): void {
    if (this.isCargaPoblacion) {
      this.isShowCargaPoblacion = true;
    } else {
      this.toastr.info('TIENE QUE CONFIGURAR TODAS LAS TARIFAS PARA PODER CARGAR LA POBLACIÓN', 'NOTIFICACIÓN');
      this.isShowCargaPoblacion = false;
    }
  }

  getConfigurationData(): void {
    const params = {
      planFlexId: this.idPlan
    };

    this.api.getConfigurationData(params).subscribe(response => {
      this.plan = response;
      console.log(this.plan);
      const aseguradoras = [];
      this.plan.plans.forEach(planOb => {
        this.tarifas[planOb.planName] = {};
        this.isTariffSectionVisible[planOb.planName] = false;
        const indexAseguradora = _.findIndex(aseguradoras, { id: planOb.insuranceCompanyId });
        if (indexAseguradora === -1) {
          const index = _.findIndex(this.aseguradorasList, { id: planOb.insuranceCompanyId });
          aseguradoras.push(this.aseguradorasList[index]);
        }

        this.tarifas[planOb.planName].netPremiumIndividual = planOb.netPremiumIndividual;
        this.tarifas[planOb.planName].policyRights = planOb.policyRights;
        this.tarifas[planOb.planName].surcharges = planOb.surcharges;

        this.tarifas[planOb.planName].maximumYearsPolicy = planOb.maximumYears;
        this.tarifas[planOb.planName].maximumMonthsPolicy = planOb.maximumMonths;
        this.tarifas[planOb.planName].minimumYearsPolicy = planOb.minimumYears;
        this.tarifas[planOb.planName].minimumMonthsPolicy = planOb.minimumMonths;
        this.tarifas[planOb.planName].maximumYearsRenewal = planOb.renewalMaximumYears;
        this.tarifas[planOb.planName].maximumMonthsRenewal = planOb.renewalMaximumMonths;
        this.tarifas[planOb.planName].minimumYearsRenewal = planOb.renewalMinimumYears;
        this.tarifas[planOb.planName].minimumMonthsRenewal = planOb.renewalMinimumMonths;
        this.tarifas[planOb.planName].rewedPolicy = planOb.rewedPolicy;
      });

      this.planForm.get('aseguradoras').setValue(aseguradoras);

      const ob = this.plan.plans[0];
      if (ob.maximumMonths) {
        this.isCargaPoblacion = true;

        this.planForm.get('maximumMonths').setValue(ob.maximumMonths);
        this.planForm.get('maximumYears').setValue(ob.maximumYears);
        this.planForm.get('minimumYears').setValue(ob.minimumYears);
        this.planForm.get('minimumMonths').setValue(ob.minimumMonths);
        this.planForm.get('renewalMaximumMonths').setValue(ob.renewalMaximumMonths);
        this.planForm.get('renewalMaximumYears').setValue(ob.renewalMaximumYears);
        this.planForm.get('renewalMinimumYears').setValue(ob.renewalMinimumYears);
        this.planForm.get('renewalMinimumMonths').setValue(ob.renewalMinimumMonths);
        this.planForm.get('renewedPolicy').setValue(ob.renewedPolicy);

        this.dates = {
          edadMaximaAnios: ob.maximumYears,
          edadMaximaMeses: ob.maximumMonths,
          edadMinimaAnios: ob.minimumYears,
          edadMinimaMeses: ob.minimumMonths,
          edadMaximaRenovacionAnios: ob.renewalMaximumYears,
          edadMaximaRenovacionMeses: ob.renewalMaximumMonths,
          edadMinimaRenovacionAnios: ob.renewalMinimumYears,
          edadMinimaRenovacionMeses: ob.renewalMinimumMonths,
        };
        // this.dates = {
        //   edadMaximaAnios: 50,
        //   edadMaximaMeses: 11,
        //   edadMinimaAnios: 1,
        //   edadMinimaMeses: 1,
        //   edadMaximaRenovacionAnios: ob.renewalMaximumYears,
        //   edadMaximaRenovacionMeses: ob.renewalMaximumMonths,
        //   edadMinimaRenovacionAnios: ob.renewalMinimumYears,
        //   edadMinimaRenovacionMeses: ob.renewalMinimumMonths,
        // };
      }

    },
    error => {
      console.log(error);
    });
  }

  saveConfiguration(planOb: any): void {
    if (!this.planForm.valid) {
      this.toastr.error('Faltan datos por capturar');
      return;
    }

    const aseguradoras = this.planForm.get('aseguradoras').value;
    let plans = [];
    aseguradoras.forEach(aseg => {
      const plansOb = this.plan.plans.map(plan => {
        const listCoverages = plan.coveragesPlan.map(cov => {
          const res = Object.assign({}, cov);
          res.value = +cov.value;

          return cov;
        });

        plan.coveragesPlan = listCoverages;
        plan.maximumMonths = +this.tarifas[planOb.planName].maximumMonthsPolicy;
        plan.maximumYears = +this.tarifas[planOb.planName].maximumYearsPolicy;
        plan.minimumMonths = +this.tarifas[planOb.planName].minimumMonthsPolicy;
        plan.minimumYears = +this.tarifas[planOb.planName].minimumYearsPolicy;
        plan.renewalMaximumMonths = +this.tarifas[planOb.planName].maximumMonthsRenewal;
        plan.renewalMaximumYears = +this.tarifas[planOb.planName].maximumYearsRenewal;
        plan.renewalMinimumMonths = +this.tarifas[planOb.planName].minimumMonthsRenewal;
        plan.renewalMinimumYears = +this.tarifas[planOb.planName].minimumYearsRenewal;
        plan.renewedPolicy = this.tarifas[planOb.planName].renewedPolicy ? this.tarifas[plan.planName].renewedPolicy : false;
        plan.insuranceCompanyId = aseg.id;
        plan.netPremiumIndividual = +this.tarifas[planOb.planName].netPremiumIndividual;
        plan.policyRights = +this.tarifas[planOb.planName].policyRights;
        plan.surcharges = +this.tarifas[planOb.planName].surcharges;
        return plan;
      });

      plans = plans.concat(plansOb);
    });

    const params = {
      planFlexId: this.idPlan,
      typeConfigurationPolicyId: this.plan.typeConfigurationPolicyId,
      plans: plans
    };

    this.api.savePhaseTwoConfiguration(params).subscribe(response => {
      this.toastr.success('Configuración guardada correctamente');
      this.getConfigurationData();
    },
    error => {
      console.log(error);
    });
  }

}
