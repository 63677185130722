import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, NgZone, OnInit, TemplateRef} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {SharedService} from '../shared-service.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {ApiService} from '../api.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {DatePipe} from '@angular/common';
import {NgbActiveModal, NgbModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import {BehaviorSubject, Observable} from 'rxjs';
import {Placement} from '../admin-placement-quotation-detail/placement';
import {SpecialCoverageModel} from '../admin-policies/special-coverage-model';
import {regex} from '../../common/regex';
import {DualListComponent} from 'angular-dual-listbox';
import {CategoryCoverageModel} from '../admin-policies/coverage-model';
import {disable} from '@amcharts/amcharts4/.internal/core/utils/Debug';


@Component({
  selector: 'app-load-policy-automation-detail',
  templateUrl: './load-policy-automation-detail.component.html',
  styleUrls: ['./load-policy-automation-detail.component.css'],
  // add NgbModalConfig and NgbModal to the component providers
})
export class LoadPolicyAutomationDetailComponent implements OnInit {

  policyForm: FormGroup;
  bsStartDate: Partial<BsDatepickerConfig>  = new BsDatepickerConfig();
  bsExpirationDate: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  categoryList: any[];
  selectedConceptTypeValidity: number;
  yearsValidity: number;
  startDateTmp: string;
  endDateTmp: any;
  catClients: any = [];
  catClientsSelected: any = [];
  catGroups: any = [];
  catInsuranceCompany: any = [];
  catAgentKeys: any = [];
  catBranch: any = [];
  catSubBranch: any = [];
  clientsSelectedItems: any = [];
  salesmanList: any = [];
  executiveSurexs: any = [];
  executiveIncidents: any = [];
  catCoordinatorList: any = [];
  executiveSurexList: any = [];
  coordinatorsSelectedItems: any = [];
  surexsSelectedItems: any = [];
  catManagerIncidentList: any = [];
  executiveIncidentList: any = [];
  incidentsSelectedItems: any = [];
  currencyList: any = [];
  catAgeMaxLimit: any = [];
  officeList: any = [];
  catTypeAdmin: any = [];
  dropdownSettingsClients = {};
  dropdownSettingsCoordinators = {};
  dropdownSettingsSurexs = {};
  dropdownSettingsIncidents = {};
  attachmentCatalog: any = [];
  catPaymentTypes: any = [];
  catTypePayments: any = [];
  isRenewPolicy: boolean;
  policyIdExpired: number;
  dataExpired: any = [];
  medicalCare: number;
  searchTerm = '';
  editMode: boolean;
  endorsementsEdit: FormArray;
  formEdit: FormGroup;
  formUserEdit: FormGroup;
  chargesPercentage = 0;
  catTaxes: any = [];
  payment: any;
  collapsedStates: { [id: string]: boolean } = {};
  /*MODAL*/
  modalRef: BsModalRef;
  modalRefEditUser: BsModalRef;
  modalRefRenew: BsModalRef;
  modalRefEditEndorsement: BsModalRef;
  idAutoamtion: any;
  rateInfo: any = [];
  endorsementsSelected: FormArray;
  ratesToModified: FormArray;
  specialCoverageList: Array<any>;
  key: any;
  display: any;
  specialCoverageListSelected: Array<any>;
  format = {
    add: 'ASIGNAR COBERTURA',
    remove: 'QUITAR COBERTURA',
    all: 'SELECCIONAR TODOS',
    none: 'DESELECCIONAR',
    direction: DualListComponent.LTR, draggable: true };
  editingIndex: number | null = null;
  error = {active: false, msg: ''};
  public fileUpload: File;
  fileTypes: string[] = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.oasis.opendocument.spreadsheet'];
  private endorsementsSelectedSubject = new BehaviorSubject<FormArray>(new FormArray([]));
  categorySelected: any = {};
  public fileUploadXlsx: File;
  errorXlsx = {active: false, msg: ''};
  fileTypesXlsx: string[] = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

  constructor( public shared: SharedService,
               private router: Router,
               private toastr: ToastrService,
               private api: ApiService,
               private spinner: NgxSpinnerService,
               private datePipe: DatePipe,
               private formBuilder: FormBuilder,
               private activatedRoute: ActivatedRoute,
               private modalService: BsModalService,
               private zone: NgZone,
               private cdr: ChangeDetectorRef) {

    this.cdr.markForCheck();
    this.activatedRoute.params.subscribe(params => {
        this.idAutoamtion = params.id;
    });


    this.getSalesman();
    this.getCurrency();
    this.getGroups();
    this.getInsuranceCompanies();
    this.getBranches();
    this.getTypePayments();
    this.getTypePaymentsPolicy();
    this.getExecutives(1);
    this.getExecutives(2);
    this.getExecutives(3);
    this.getManagerIncident();
    this.getCatMaxAgeLimit();
    this.getOffices();
    this.getTypesAdminPolicy();
    this. loadDataTaxes();
  }

  ngOnInit() {

    this.key = 'id';
    this.display = 'name';

    this.bsStartDate = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      showWeekNumbers: false,
      dateInputFormat: 'YYYY-MM-DD'
    });
    this.bsExpirationDate = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      showWeekNumbers: false,
      dateInputFormat: 'YYYY-MM-DD'
    });

    this.editMode = false;
    this.policyForm = this.formBuilder.group({
      policyId: [null],
      number: ['', Validators.required],
      startDate: ['', Validators.required],
      expirationDate: ['', Validators.required],
      groupId: [null, Validators.required],
      branchId: [null, Validators.required],
      subBranchId: [null, Validators.required],
      renew: [false],
      insuranceId: [null, Validators.required],
      currencyId: [null, Validators.required],
      userId: [null, Validators.required],
      policyIdOld: [null],
      policyNumberOld: [''],
      paymentTypeId: [null, Validators.required],
      clients: [[], Validators.required],
      coordinators: [[], Validators.required],
      executiveSurexs: [[], Validators.required],
      executiveIncidents: [[], Validators.required],
      agentKeyId: [null, Validators.required],
      typePaymentPolicy: [null, Validators.required],
      managerIncident: [null, Validators.required],
      maxAgeLimit: [null, Validators.required],
      officeId: [null, Validators.required],
      medicalCare: [false],
      typeAdministrationPolicy: [null, Validators.required],
      policyOld: [[]],
      commission: [null],
      taxId: [null],
      commissionAmount: [null],
      pathCertificates: [null],
      categoryList: new FormArray([]),
      idLoadingAutomationProcess: [null],
      processVersion: [null],
      emailNotification: [''],
      keyProcess: [''],
      documents: new FormArray([]),
      typeTax: [null],
      averagePremium: [false],
      numberFormat: [null],
      coinsuranceCap: [null],
      insuredMassiveModels: [[]]
    });

    this.dropdownSettingsClients = {
      singleSelection: false,
      idField: 'clientId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON SUB GRUPOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsSurexs = {
      singleSelection: false,
      idField: 'directoryId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON EJECUTIVOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsIncidents = {
      singleSelection: false,
      idField: 'directoryId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON EJECUTIVOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsCoordinators = {
      singleSelection: false,
      idField: 'directoryId',
      textField: 'name',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON COORDINADORES',
      searchPlaceholderText: 'BUSCAR'
    };

    this.getProcessById();
  }

  createCategoryFormArray(categories: any[]): FormArray {
    return this.formBuilder.array(
      categories.map(category => this.createCategoryFormGroup(category))
    );
  }

  createCategoryFormGroup(category: any): FormGroup {
    return this.formBuilder.group({
      categoryId: [category.categoryId],
      name: [category.name, Validators.required],
      numberCategory: [category.numberCategory, Validators.required],
      typeTax: [1, Validators.required],
      clientId: [category.clientId, Validators.required],
      averagePremium: [category.averagePremium, Validators.required],
      rateInfo: this.formBuilder.array(this.createRateInfoFormArray(category.rateInfo)),
      coverages: this.formBuilder.array(this.createCoveragesFormArray(category.coverages)),
      endorsements: this.formBuilder.array(this.createEndorsementsFormArray(category.endorsements)),
      endorsementsStr: [category.endorsementsStr],
      receipts: this.formBuilder.array(this.createReceiptsFormArray(category.receipts))
    });
  }

  createRateInfoFormArray(rateInfo: any[]): FormGroup[] {
    // Asegúrate de que rateInfo sea un array, aunque sea vacío
    const validRateInfo = Array.isArray(rateInfo) ? rateInfo : [];

    return validRateInfo.map(rate => {
      return this.formBuilder.group({
        rateId: [rate.rateId || null],
        startAge: [rate.startAge || null],
        endAge: [rate.endAge || null],
        maleAmount: [rate.maleAmount || null],
        femaleAmount: [rate.femaleAmount || null]
      });
    });
  }


  createCoveragesFormArray(coverages: any[]): FormGroup[] {
    return coverages.map(coverage => {
      return this.formBuilder.group({
        coverageId: [coverage.coverageId],
        description: [coverage.description, Validators.required],
        value: [coverage.value, Validators.required],
        medition: [coverage.medition],
        visible: [coverage.visible],
        typeCoverage: [coverage.typeCoverage]
      });
    });
  }

  createEndorsementsFormArray(endorsements: any[]): FormGroup[] {
    return endorsements.map(coverage => {
      return this.formBuilder.group({
        coverageId: [coverage.coverageId],
        description: [coverage.description, Validators.required],
        value: [coverage.value, Validators.required],
        medition: [coverage.medition],
        visible: [coverage.visible],
        typeCoverage: [coverage.typeCoverage]
      });
    });
  }

  createReceiptsFormArray(receipts: any[]): FormGroup[] {
    return receipts.map(receipt => {
      return this.formBuilder.group({
        receiptId: [receipt.receiptId],
        numberCategory: [receipt.numberCategory],
        commission: [receipt.commission],
        commissionAmount: [receipt.commissionAmount],
        netPremium: [receipt.netPremium],
        rights: [receipt.rights],
        paymentCharges: [receipt.paymentCharges],
        iva: [receipt.iva],
        totalPremium: [receipt.totalPremium],
        categoryId: [receipt.categoryId],
        taxId: [receipt.taxId],
        clientId: [receipt.clientId]
      });
    });
  }

  getProcessById() {
    this.spinner.show('sp');
    this.api.getDataFromPolicyAutomationById(this.idAutoamtion).then (
      (response: any) => {
        this.spinner.hide('sp');
        this.mapResponseToForm(response);
        this.toastr.success('Se ha generado la base correctamente', 'Notificación');
      }, error => {
        if (error.status === 500) {
          if (error.error.message !== undefined) {
            this.toastr.warning(error.error.message, 'Notificación');
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        } else {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
        console.error(error);
        this.spinner.hide('sp');
      });
  }

  createDocumentsFormArray(documents: any[]): FormArray {
    const formArray = this.formBuilder.array([]);
    documents.forEach(document => {
      formArray.push(this.formBuilder.group({
        idInsurer: [document.idInsurer],
        insurerName: [document.insurerName],
        files: this.formBuilder.array(document.files.map(file => this.formBuilder.control(file)))
      }));
    });
    return formArray;
  }

  createInsuredMassiveFormArray(insuredMassiveModels: any[]): FormArray {
    const formArray = this.formBuilder.array([]);
    insuredMassiveModels.forEach(model => {
      formArray.push(this.formBuilder.group({
        name: [model.name, Validators.required],
        firstName: [model.firstName, Validators.required],
        lastName: [model.lastName, Validators.required],
        email: [model.email, Validators.required],
        gender: [model.gender, Validators.required],
        birthDate: [model.birthDate, Validators.required],
        startDate: [model.startDate, Validators.required],
        kinshipId: [model.kinshipId, Validators.required],
        certificateNumber: [model.certificateNumber],
        clientId: [model.clientId],
        subGroup: [model.subGroup],
        category: [model.category],
        salary: [model.salary],
        policyId: [model.policyId],
        filePath: [model.filePath],
        pathCertificate: [model.pathCertificate],
        pathFile: [model.pathFile],
        employeeNumber: [model.employeeNumber],
        endorsement: [model.endorsement]
      }));
    });
    return formArray;
  }
  mapResponseToForm(response: any) {

    this.policyForm.patchValue({
      policyId: response.policyId,
      number: response.number,
      startDate: this.convertDateToISO(response.startDate),
      expirationDate: this.convertDateToISO(response.expirationDate),
      groupId: response.groupId,
      branchId: response.branchId,
      subBranchId: response.subBranchId,
      renew: response.renew !== null,
      insuranceId: response.insuranceId,
      currencyId: response.currencyId,
      userId: response.userId,
      policyIdOld: response.policyIdOld,
      policyNumberOld: response.policyNumberOld,
      paymentTypeId: response.paymentTypeId,
      clients: response.clients,
      coordinators: response.coordinators,
      executiveSurexs: response.executiveSurexs,
      executiveIncidents: response.executiveIncidents,
      agentKeyId: response.agentKeyId,
      typePaymentPolicy: response.typePaymentPolicy,
      managerIncident: response.managerIncident,
      maxAgeLimit: response.maxAgeLimit,
      officeId: response.officeId,
      medicalCare: response.medicalCare,
      typeAdministrationPolicy: response.typeAdministrationPolicy,
      policyOld: response.policyOld,
      commission: response.commission,
      taxId: response.taxId,
      commissionAmount: response.commissionAmount,
      pathCertificates: response.pathCertificates,
      idLoadingAutomationProcess: response.idLoadingAutomationProcess,
      processVersion: response.processVersion,
      emailNotification: response.emailNotification,
      keyProcess: response.keyProcess,
      typeTax: response.typeTax,
      averagePremium: response.averagePremium,
      numberFormat: response.numberFormat,
      coinsuranceCap: response.coinsuranceCap
    });
    this.policyForm.setControl('categoryList', this.createCategoryFormArray(response.categoryList));
    this.policyForm.setControl('insuredMassiveModels', this.createInsuredMassiveFormArray(response.insuredMassiveModels));
    this.policyForm.setControl('documents', this.createDocumentsFormArray(response.documents));

    this.getSubGroups(response.groupId);
    this.getSubBranches(response.branchId);
    this.getAgentKeys(response.insuranceId);

    this.payment = this.policyForm.get('paymentTypeId').value;

    // this.getExecutivesByPolicy(1);
    // this.getExecutivesByPolicy(2);
    // this.getExecutivesByPolicy(3);

  }

  getExecutivesByPolicy(id) {
    this.api.getExecutivesByPolicy(this.policyForm.get('policyId').value, id).then((data: any) => {
      switch (id) {
        case 1:
          this.coordinatorsSelectedItems = data;
          this.chanceStatusCoordinators();
          break;
        case 2:
          this.surexsSelectedItems = data;
          this.changeStatusSurexs();
          break;
        case 3:
          this.incidentsSelectedItems = data;
          this.changeStatusIncidents();
          break;
      }
    }, error => {
      // console.error(error.status);
      // console.error(error.statusText);
      // console.error(error.message);
    });
  }

  public openModalAddExpiration(startDate: Date[], template: TemplateRef<any>) {
    this.selectedConceptTypeValidity = 1;
    this.yearsValidity = 2;
    if (!this.shared.fPolicyDataReadonly && this.policyForm.get('policyId').value === 0) {
      if (startDate !== null) {
        this.startDateTmp = this.datePipe.transform(startDate, 'yyyy-MM-dd', 'es-ES');
        this.modalRef = this.modalService.show(template, {class: 'modal-sm', keyboard: false});
      }
    }
  }

  validateNumberYears() {
    if (this.yearsValidity < 0) {
      this.yearsValidity = 2;
      this.toastr.warning('EL NUMERO DE AÑOS NO PUEDE SER NEGATIVO, POR FAVOR INGRESAR UN NUMERO VALIDO', 'NOTIFICACIÓN');
      return;
    }
    if (this.yearsValidity === 1) {
      this.toastr.warning('EL NÚMERO MÍNIMO PERMITIDO PARA LA OPCIÓN MULTI-ANUAL, ES 2', 'NOTIFICACIÓN');
      this.yearsValidity = 2;
      return;
    }
    if (this.yearsValidity === 0) {
      this.yearsValidity = 2;
      this.toastr.warning('EL NUMERO DE AÑOS NO PUEDE SER 0, POR FAVOR INGRESAR UN NUMERO VALIDO', 'NOTIFICACIÓN');
      return;
    }
  }

  public selectedTypeValidity() {
    switch (this.selectedConceptTypeValidity) {
      case 1:
        this.endDateTmp = new Date(this.startDateTmp);
        // tslint:disable-next-line:max-line-length
        this.endDateTmp = this.endDateTmp.setFullYear(this.endDateTmp.getFullYear() + 1, this.endDateTmp.getMonth(), this.endDateTmp.getDate() + 1);
        this.policyForm.get('expirationDate').patchValue(this.datePipe.transform(this.endDateTmp, 'yyyy-MM-dd', 'es-ES'));
        break;
      case 2:
        if (this.yearsValidity === 0 || this.yearsValidity === undefined) {
          this.toastr.warning('DEBE DE INGRESAR EL NUMERO DE AÑOS DE VIGENCIA DE LA PÓLIZA', 'NOTIFICACIÓN');
          return;
        }
        if (this.yearsValidity < 0) {
          this.toastr.warning('EL NUMERO DE AÑOS NO PUEDE SER NEGATIVO, POR FAVOR INGRESAR UN NUMERO VALIDO', 'NOTIFICACIÓN');
          return;
        }
        if (this.yearsValidity < 1) {
          this.toastr.warning('EL MÍNIMO PERMITIDO PARA MULTI-ANUAL ES 2', 'NOTIFICACIÓN');
          return;
        }
        this.endDateTmp = new Date(this.startDateTmp);
        // tslint:disable-next-line:max-line-length
        this.endDateTmp = this.endDateTmp.setFullYear(this.endDateTmp.getFullYear() + this.yearsValidity, this.endDateTmp.getMonth(), this.endDateTmp.getDate() + 1);
        this.policyForm.get('expirationDate').patchValue(this.datePipe.transform(this.endDateTmp, 'yyyy-MM-dd', 'es-ES'));
        break;
      case 3:
        this.policyForm.get('expirationDate').patchValue('');
        break;
    }
    this.modalRef.hide();
  }


  changeSubGroups(event?) {
    const subGroup = [];
    setTimeout(() => {
      try {
        this.clientsSelectedItems.forEach(item => {
          subGroup.push(item.clientId);
        });
        this.policyForm.get('clients').patchValue(subGroup);
        // console.log(this.dataToSendGeneral.clients);
      } catch (e) {
      }
    }, 1000);
  }

  getAgentKeys(insuranceId) {
    this.api.getAgentKeysByInsuranceCompany(insuranceId).then((data: any) => {
      this.catAgentKeys = data;
    }, error => {
    });
  }

  getSubBranches(id) {
    this.attachmentCatalog = [
      {attachmentId: 1, description: 'ARCHIVO'},
      {attachmentId: 8, description: 'DIRECTORIO MEDICO'}];


    this.api.getSubBranches(id).then((data: any) => {
      this.catSubBranch = data;
    }, error => {
    });
  }

  onChangeRenewPolicy(event, template: TemplateRef<any>) {
    this.isRenewPolicy = event;
    this.policyForm.get('renew').patchValue(event);
    this.policyIdExpired = 0;
    if (this.isRenewPolicy) {
      if (this.policyForm.get('startDate').value === null || this.policyForm.get('groupId').value === null
        || this.policyForm.get('clients').value.length === 0 || this.policyForm.get('subBranchId').value === null) {
        this.toastr.warning('DEBE DE CAPTURAR LOS DATOS PREVIOS PARA PODER SELECCIONAR LA PÓLIZA A RENOVAR', 'NOTIFICACIÓN');
        this.policyForm.get('renew').patchValue(false);
        this.isRenewPolicy = false;
        this.policyForm.controls.renewPolicy.setValue(false);
        return;
      }
      const dateStart = Date.parse(this.policyForm.get('startDate').value);

      const data = {
        groupId: this.policyForm.get('groupId').value,
        clients: this.policyForm.get('clients').value,
        subBranchId: this.policyForm.get('subBranchId').value,
        branchId: this.policyForm.get('branchId').value,
        startDate: this.datePipe.transform(dateStart, 'yyyy-MM-dd', 'es-ES')
      };
      this.getPoliciesExpired(data);
      this.modalRefRenew = this.modalService.show(template, {class: 'modal-lg', keyboard: false});
    }
  }

  getPoliciesExpired(dataRenew) {
    this.api.postExpiredPolicies(dataRenew).then((data: any) => {
      if (data == null) {
        this.dataExpired = [];
      } else {
        this.dataExpired = data;
      }
    }, error => {
    });
  }

  closeModalRenew() {
    this.modalRefRenew.hide();
    this.policyForm.get('renew').patchValue(false);
  }

  selectPolicy(selectedIds: any) {
    const policyOldArray = selectedIds.map(selectedItem => ({
      policyIdOld: selectedItem.policyId,
      policyNumberOld: selectedItem.policyNumber
    }));

    this.policyForm.get('policyOld').patchValue(policyOldArray);  // Reemplaza el valor anterior

    console.log('Políticas antiguas:', this.policyForm.get('policyOld').value);
  }


  saveRelationRenew() {
    const policyOldControl = this.policyForm.get('policyOld');
    const policyOldValue = policyOldControl ? policyOldControl.value : null;

    if (!policyOldValue || policyOldValue.length === 0) {
      this.toastr.warning('DEBE DE SELECCIONAR UNA PÓLIZA', 'NOTIFICACIÓN');
      return;
    }

    this.modalRefRenew.hide();
  }



  public invokeServiceForDownloadPolicyFile() {
    this.api.getPolicyFile(this.policyForm.get('policyId').value)
      .subscribe(
        (response: any) => {
          if (response == null) {
            this.toastr.info('No existen archivos para descargar');
            return;
          } else {
            const dataType = response.type;
            const binaryData = [];
            binaryData.push(response);
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            downloadLink.setAttribute('download', this.policyForm.get('number').value + '.pdf');
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
          }
        }, error => {
        }
      );
  }

  chanceStatusCoordinators() {
    setTimeout(() => {
      try {
        const coordinators = [];
        this.coordinatorsSelectedItems.forEach(item => {
          coordinators.push(item.directoryId);
        });
        this.policyForm.get('coordinators').patchValue(coordinators);
      } catch (e) {
      }
    }, 500);
  }

  changeStatusSurexs() {
    setTimeout(() => {
      try {
        const executive = [];
        this.surexsSelectedItems.forEach(item => {
          executive.push(item.directoryId);
        });
        // console.log(executive);
        this.policyForm.get('executiveSurexs').patchValue(executive);
      } catch (e) {
      }
    }, 500);
  }

  changeStatusIncidents() {
    setTimeout(() => {
      try {
        const executive1 = [];
        this.incidentsSelectedItems.forEach(item => {
          executive1.push(item.directoryId);
        });
        // console.log(executive1);
        this.policyForm.get('executiveIncidents').patchValue(executive1);
      } catch (e) {
      }
    }, 500);
  }

  // region GET CATALOGOS
  getOffices() {
    this.api.getOffices().then((data: any) => {
      this.officeList = data;
    }, error => {
    });
  }
  getGroups() {
    this.api.getGroups().then((data: any) => {
      this.catGroups = data;
    }, error => {
    });
  }
  getSubGroups(id) {
    this.api.getSubGroups(id).then((data: any) => {
      this.catClients = data;
      if (this.policyForm.get('clients').value.length !== 0 && this.policyForm.get('clients').value.length !== null && this.policyForm.get('clients').value.length !== undefined) {
        this.onChangeSubgroup();
      }
    }, error => {
    });
  }

  onChangeSubgroup() {
    this.catClientsSelected = [];
    this.policyForm.get('clients').value.forEach(clientSelected => {
      const foundClient = this.catClients.find(client => client.clientId === clientSelected);
      if (foundClient) {
        this.catClientsSelected.push(foundClient);
      }
    });
  }

  getInsuranceCompanies() {
    this.api.getInsuranceCompanies().then((data: any) => {
      this.catInsuranceCompany = data;
    }, error => {
    });
  }

  getSalesman() {
    this.api.getSalesman().then((data: any) => {
      this.salesmanList = data;
    }, error => {
    });
  }

  getCurrency() {
    this.currencyList = [
      {currencyId: 1, name: 'MX'}, {currencyId: 2, name: 'USD'}
    ];
  }

  getBranches() {
    this.api.getBranchesGeneral().then((data: any) => {
      this.catBranch = data;
    }, error => {
    });
  }

  getTypesAdminPolicy() {
    this.catTypeAdmin = [
      {
        typeAdminId: 1,
        name: 'AUTO ADMINISTRADA'
      },
      {
        typeAdminId: 2,
        name: 'A DETALLE'
      }

    ];
  }

  getTypePaymentsPolicy() {
    this.api.getTypePaymentsPolicy().then((data: any) => {
      this.catTypePayments = data;
    }, error => {
    });
  }

  getTypePayments() {
    this.api.getTypePayments().then((data: any) => {
      this.catPaymentTypes = data;
    }, error => {
    });
  }

  getExecutives(id) {
    this.api.getExecutives(id).then((data: any) => {
      switch (id) {
        case 1:
          this.catCoordinatorList = data;
          // this.dataToSendGeneral.directoryId = this.catCoordinatorList[0].directoryId;
          break;
        case 2:
          this.executiveSurexList = data;
          break;
        case 3:
          this.executiveIncidentList = data;
          break;
      }
    }, error => {
    });
  }
  getManagerIncident() {
    this.api.getManagerIncident().then((data: any) => {
      if (data == null) {
        this.catManagerIncidentList = [];
      } else {
        this.catManagerIncidentList = data;
        // this.dataToSendGeneral.managerIncident = this.catManagerIncidentList[0].userId;
      }
    }, error => {
    });
  }

  getCatMaxAgeLimit() {
    this.api.getCatAgeMaxLimit(1).then((data: any) => {
      this.catAgeMaxLimit = data;
    }, error => {
    });
  }

  loadDataTaxes() {
    this.api.getTaxes().then((data: any) => {
      this.catTaxes = data;
    }, error => {
    });
  }
  // endregion

  get categories(): FormArray {
    return this.policyForm.controls.categoryList as FormArray;
  }

  coverages(categoryForm): FormArray {
    return categoryForm === undefined ? new FormArray([]) : categoryForm.controls.coverages as FormArray;
  }

  specialCoverages(categoryForm): FormArray {
    return categoryForm === undefined ? new FormArray([]) : categoryForm.controls.endorsements as FormArray;
  }

  get endorsementsGroups(): FormArray {
    return this.formEdit.controls.endorsementsGroup as FormArray;
  }

  receipts(categoryForm): FormArray {
    return categoryForm === undefined ? new FormArray([]) : categoryForm.controls.receipts as FormArray;
  }

  get insuredMassive(): FormArray {
    return this.policyForm.controls.insuredMassiveModels as FormArray;
  }

  files(documentForm): FormArray {
    return documentForm === undefined ? new FormArray([]) : documentForm.controls.files as FormArray;
  }

  get documents(): FormArray {
    return this.policyForm.controls.documents as FormArray;
  }

  openEditModal(modal, group) {
    console.log(group);

    // Crear un nuevo FormArray
    this.endorsementsEdit = this.formBuilder.array([]);

    // Agregar los controles de endosos correspondientes al FormArray
    group.forEach((endorsement) => {
      this.endorsementsEdit.push(endorsement);
    });

    this.formEdit = this.formBuilder.group({
      endorsementsGroup: this.endorsementsEdit
    });

    // Abrir el modal
    this.modalRefEditEndorsement = this.modalService.show(modal, {class: 'modal-sm', keyboard: false});
  }

  openEditBasicCoverageModal(coverage) {
    console.log(coverage);

  }

  closeEndorsementEdit() {
    this.modalRefEditEndorsement.hide();
  }

  openEditUserModal(modal, user) {
    // Inicializar el formulario dentro del método
    this.formUserEdit = this.formBuilder.group({
      name: [''],
      firstName: [''],
      lastName: [''],
      email: [''],
      gender: [''],
      birthDate: [''],
      startDate: [''],
      kinshipId: [''],
      certificateNumber: [''],
      clientId: [''],
      subGroup: [''],
      category: [''],
      salary: [''],
      policyId: [''],
      filePath: [''],
      pathCertificate: [''],
      pathFile: [''],
      employeeNumber: [''],
      endorsement: ['']
    });

    this.formUserEdit = user;

    // Abrir el modal
    this.modalRefEditUser = this.modalService.show(modal, {class: 'modal-lg', keyboard: false});
  }

  closeModalRefEditUser() {
    this.modalRefEditUser.hide();
  }

  deleteEndorsement(groupIndex: number, endorsementIndex: number, category) {
    const endorsementsArray = this.specialCoverages(category).controls[groupIndex].get('endorsements') as FormArray;
    endorsementsArray.removeAt(endorsementIndex);
  }

  sumTotalPremium(receipt) {
    if (receipt.get('taxId').value > 0) {
      this.reCalTax(receipt);
    }
    this.calCommissionAmount(receipt);

    if (this.chargesPercentage === 0 && receipt.get('paymentCharges').value > 0) {
      this.calChargesPercentage(receipt);
    }
    // tslint:disable-next-line:max-line-length
    receipt.get('totalPremium').setValue(receipt.get('netPremium').value + receipt.get('rights').value + receipt.get('paymentCharges').value + receipt.get('iva').value);
  }

  calChargesPercentage(receipt) {
    const chargesPercentageTmp = (receipt.get('paymentCharges').value * 100) / receipt.get('netPremium').value;
    this.chargesPercentage = chargesPercentageTmp;
  }

  reCalTax(receipt) {
    for (const tax of this.catTaxes) {
      if (tax.taxId === Number(receipt.get('taxId').value)) {
        // ('entra a recalculo');
        // tslint:disable-next-line:max-line-length
        receipt.get('iva').setValue(((receipt.get('netPremium').value + receipt.get('rights').value + receipt.get('paymentCharges').value) * tax.taxValue) / 100);
      }
    }
  }

  calCommissionAmount(receipt) {
    const commissionAmount = (receipt.get('netPremium').value * receipt.get('commission').value) / 100;
    receipt.get('commissionAmount').setValue(commissionAmount.toFixed(4));
  }

  calChargesAmount(receipt) {
    const chargesAmount =  (receipt.get('netPremium').value * this.chargesPercentage) / 100;
    receipt.get('paymentCharges').setValue(chargesAmount);
  }

  calCommissionPercentage(receipt) {
    const commssion = (receipt.get('commissionAmount').value * 100) / receipt.get('netPremium').value;
    receipt.get('commission').setValue(commssion.toFixed(2));
  }
  calTax(i, receipt) {
    for (const tax of this.catTaxes) {
      if (tax.taxId === Number(i)) {
        // tslint:disable-next-line:max-line-length
        receipt.get('iva').setValue(((receipt.get('netPremium').value + receipt.get('rights').value + receipt.get('paymentCharges').value) * tax.taxValue) / 100);
        this.sumTotalPremium(receipt);
      }
    }
  }

  nameOfClientByIdClient(clientId: any): string {
    let name = '';
    for (const client of this.catClients) {
      if (client.clientId === clientId) {
        name = client.name;
        break; // Detener la búsqueda una vez que se encuentre el cliente
      }
    }
    return name;
  }
  getPathWithoutPrefix(): string {
    const fullPath = this.policyForm.get('pathCertificates').value;
    return this.removePrefix(fullPath);
  }
  removePrefix(path: string): string {
    const prefix = 'var/surexs/';
    if (path.startsWith(prefix)) {
      return path.substring(prefix.length);
    }
    return path;  // Si no tiene el prefijo, retorna la cadena original
  }
  public invokeServiceForGetAttachmentFile(file) {
    const objAttachment = {
      typeAttachment: 3,
      id: file,
      path: file
    };
    this.api.getAttachmentFileGeneric(objAttachment)
      .subscribe(
        (response: any) => {
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download', this.extractFileName(file));
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();

        }, error => {
          // console.error(error);
        }
      );
  }

  extractFileName(fileURL: string): string {
    const segments: string[] = fileURL.split('/');

    // El último segmento contendrá el nombre del archivo con su extensión
    const fileNameWithExtension: string = segments[segments.length - 1];

    return fileNameWithExtension;
  }

  convertDateToISO(inputDate: string): string {
    // Eliminar espacios en blanco al principio y al final
    inputDate = inputDate.trim();

    // Patrón para formato YYYY-MM-DD (con o sin espacios adicionales)
    const datePatternISO = /^\d{4}\s*-\s*\d{2}\s*-\s*\d{2}$/;
    // Patrón para formato DD/MM/YYYY (con o sin espacios adicionales)
    const datePattern1 = /^\d{2}\s*\/\s*\d{2}\s*\/\s*\d{4}$/;
    // Patrón para formato DD MMM YYYY
    const datePattern2 = /^\d{2} [A-Z]{3} \d{4}$/;
    // Patrón para formato DD MM YYYY
    const datePattern3 = /^\d{2} \d{2} \d{4}$/;

    if (datePatternISO.test(inputDate)) {
      // Formato YYYY-MM-DD con posibles espacios adicionales
      const [year, month, day] = inputDate.split(/\s*-\s*/);
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    } else if (datePattern1.test(inputDate)) {
      // Formato DD/MM/YYYY con posibles espacios adicionales
      const [day, month, year] = inputDate.split(/\s*\/\s*/);
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    } else if (datePattern2.test(inputDate)) {
      // Diccionario para convertir nombres de meses a números
      const months: { [key: string]: string } = {
        ENE: '01', FEB: '02', MAR: '03', ABR: '04',
        MAY: '05', JUN: '06', JUL: '07', AGO: '08',
        SEP: '09', OCT: '10', NOV: '11', DIC: '12'
      };

      // Formato DD MMM YYYY
      const [day, month, year] = inputDate.split(' ');
      const monthNumber = months[month.toUpperCase()];
      return `${year}-${monthNumber}-${day.padStart(2, '0')}`;
    } else if (datePattern3.test(inputDate)) {
      // Formato DD MM YYYY
      const [day, month, year] = inputDate.split(' ');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    } else {
      throw new Error('Invalid date format. Expected formats: YYYY-MM-DD, DD/MM/YYYY, DD MMM YYYY or DD MM YYYY');
    }
  }


  isGeneralDataInvalid(): boolean {
    return this.policyForm.get('number').invalid ||
      this.policyForm.get('startDate').invalid ||
      this.policyForm.get('expirationDate').invalid ||
      this.policyForm.get('groupId').invalid ||
      this.policyForm.get('branchId').invalid ||
      this.policyForm.get('subBranchId').invalid ||
      this.policyForm.get('renew').invalid ||
      this.policyForm.get('insuranceId').invalid ||
      this.policyForm.get('currencyId').invalid ||
      this.policyForm.get('paymentTypeId').invalid ||
      this.policyForm.get('clients').invalid ||
      this.policyForm.get('coordinators').invalid ||
      this.policyForm.get('executiveSurexs').invalid ||
      this.policyForm.get('executiveIncidents').invalid ||
      this.policyForm.get('agentKeyId').invalid ||
      this.policyForm.get('typePaymentPolicy').invalid ||
      this.policyForm.get('managerIncident').invalid ||
      this.policyForm.get('officeId').invalid ||
      this.policyForm.get('typeAdministrationPolicy').invalid;
  }

  isCategoriesInvalid(): boolean {
    return this.policyForm.get('categoryList').invalid;
  }

  isInsuredInvalid(): boolean {
    return this.policyForm.get('insuredMassiveModels').invalid;
  }

  showTariff(rateInfo: AbstractControl, template: TemplateRef<any>) {
    this.ratesToModified = (rateInfo as FormArray);
    this.rateInfo = rateInfo.value;
    this.modalRef = this.modalService.show(template, { class: 'modal-lg', keyboard: false, backdrop: 'static' });
  }


  invokeSaveLastPhase() {
    this.spinner.show('sp');
    this.api.savePhase(this.policyForm.getRawValue())
      .then(
        (response: any) => {
          this.spinner.hide('sp');
          this.toastr.success('SU SOLICITUD SE HA REALIZADO DE FORMA EXITOSA', 'NOTIFICACIÓN');
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  invokeDownloadInsured() {
    this.spinner.show('sp');
    this.api.downloadInsuredFile(this.policyForm.get('idLoadingAutomationProcess').value).then(
      (response: any) => {
        const dataType = response.type;
        const binaryData = [];
        binaryData.push(response);
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
        downloadLink.setAttribute('download',  'usuarios.xlsx');
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.remove();
        this.spinner.hide('sp');
      }, (error) => {
        this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        this.spinner.hide('sp');
      });
  }
  invokeGeneratePolicy() {
    this.spinner.show('sp');

    this.api.savePhase(this.policyForm.value)
      .then(
        (response: any) => {
          this.api.generatePolicy(this.policyForm.get('idLoadingAutomationProcess').value)
            .then(
              (response: any) => {
                this.spinner.hide('sp');
                this.toastr.success('SU SOLICITUD SE HA REALIZADO DE FORMA EXITOSA', 'NOTIFICACIÓN');
              }, error => {
                this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
                this.spinner.hide('sp');
              }
            );
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }

  onDestinationChange(event) {
    let exist = false;

    if (this.endorsementsSelected !== null && this.endorsementsSelected.length > 0) {

      event.map(ITEM => { // primero verificamos que en la lista de destino all exista en el listado
        this.endorsementsSelected.controls.some(COVERAGE => {
          if (Number(ITEM.id) === Number(COVERAGE.get('coverageId').value)) {
            exist = true;
            return;
          }
        });

        if (!exist) {
          this.addCoverageToListConfiguration(ITEM.id, ITEM.name);
        }
        exist = false;
      });

      // Primero, crea la lista de IDs que deseas eliminar
      const id = [];

      this.endorsementsSelected.controls.filter((COVERAGE) => {
        exist = event.some(ITEM => {
          return Number(COVERAGE.get('coverageId').value) === Number(ITEM.id);
        });

        if (!exist) {
          id.push(COVERAGE.get('coverageId').value);
        }
      });

      const filteredEndorsements = this.endorsementsSelected.controls.filter((COVERAGE) => {
        return !id.includes(COVERAGE.get('coverageId').value);
      });

      // Limpiar el `FormArray` existente y agregar los controles que se deben mantener
      while (this.endorsementsSelected.length !== 0) {
        this.endorsementsSelected.removeAt(0);
      }

      filteredEndorsements.forEach(control => this.endorsementsSelected.push(control));


    } else {

      event.map(ITEM => {
        this.addCoverageToListConfiguration(ITEM.id, ITEM.name);
      });
    }

    this.assignEndorsementFormToSelected(this.endorsementsSelected);

  }

  addCoverageToListConfiguration(idCoverage: number, nameCoverage: string) {

    // Los objetos con las características que necesitas
    const endorsements = [
      {
        coverageId: idCoverage,
        description: nameCoverage,
        value: 'AMPARADA',
        medition: '',
        visible: true,
        typeCoverage: 2
      },
      {
        coverageId: idCoverage,
        description: 'SUMA ASEGURADA',
        value: '',
        medition: '',
        visible: true,
        typeCoverage: 2
      },
      {
        coverageId: idCoverage,
        description: 'COASEGURO',
        value: '',
        medition: '',
        visible: true,
        typeCoverage: 2
      },
      {
        coverageId: idCoverage,
        description: 'DEDUCIBLE',
        value: '',
        medition: '',
        visible: true,
        typeCoverage: 2
      },
      {
        coverageId: idCoverage,
        description: 'PERIODO DE ESPERA',
        value: '',
        medition: '',
        visible: true,
        typeCoverage: 2
      }
    ];

    endorsements.forEach(coverage => {
      const control = this.formBuilder.group({
        coverageId: [coverage.coverageId],
        description: [coverage.description, Validators.required],
        value: [coverage.value, Validators.required],
        medition: [coverage.medition],
        visible: [coverage.visible],
        typeCoverage: [coverage.typeCoverage]
      });
      this.endorsementsSelected.push(control);
    });

  }

  assignEndorsementFormToSelected(endorsements: FormArray) {
    this.specialCoverageListSelected = new Array<any>();
    // Convertir el FormArray a un array de objetos de endorsements
    const endorsementsArray = endorsements.controls.map(control => control.value);

    // Usar un Set para rastrear los coverageId ya vistos
    const seenCoverageIds = new Set();
    const filteredEndorsements = endorsementsArray.filter(coverage => {
      if (seenCoverageIds.has(coverage.coverageId)) {
        return false; // Ya hemos visto este coverageId, excluirlo
      } else {
        seenCoverageIds.add(coverage.coverageId); // No hemos visto este coverageId, incluirlo
        return true; // Incluir el primer elemento con este coverageId
      }
    });

    // Crear el array de specialCoveragesArrays sin los endorsements filtrados
    const specialCoveragesArrays = filteredEndorsements.map(coverage => ({
      id: coverage.coverageId,
      name: coverage.description
    }));

    // Asigna los valores filtrados a specialCoverageListSelected
    this.specialCoverageListSelected = JSON.parse(JSON.stringify(specialCoveragesArrays));
  }

  openDetailCoverage(template: TemplateRef<any>, endorsements: FormArray) {

    this.assignEndorsementFormToSelected(endorsements);

    this.endorsementsSelected = endorsements;

    if (this.policyForm.get('subBranchId').value !== null && this.policyForm.get('subBranchId').value !== undefined ) {
      const subranch = {
        idSubranch: Number(this.policyForm.get('subBranchId').value)
      };
      this.api.getSpecialCoveragesNoPagination(subranch).then(
        (coverages: any) => {
          this.specialCoverageList = JSON.parse(JSON.stringify(coverages));
          this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false});
        }, error => {
          this.toastr.error('Ocurrió un error al obtener las coberturas', 'Notificación');
        });
    } else {
      this.toastr.warning('Es necsario seleccióna el subramo', 'Notificación');
    }
  }

  transform(endorsements: any[]): { [coverageId: string]: any[] } {
    const groupedEndorsements: { [coverageId: string]: any[] } = {};

    endorsements.forEach(endorsement => {
      const coverageId = endorsement.get('coverageId').value;
      if (!groupedEndorsements[coverageId]) {
        groupedEndorsements[coverageId] = [];
      }
      groupedEndorsements[coverageId].push(endorsement);
    });

    return groupedEndorsements;
  }

  closeModalAddEndorsementValue() {
    this.modalRefEditEndorsement.hide();
  }

  backToList() {
    this.router.navigate(['/admin/automation-list']);
  }

  onChangeInputFileXlsx(e) {
    this.errorXlsx = {active: false, msg: ''};
    this.fileUploadXlsx = e.target.files[0];
    console.log(this.fileUploadXlsx);
    if (0 > this.fileTypesXlsx.indexOf(this.fileUploadXlsx.type)) {
      this.errorXlsx = {active: true, msg: 'Debe seleccionar un archivo válido'};
      this.fileUploadXlsx = null;
    }
  }

  updateInsured() {
    if (this.fileUploadXlsx === undefined ) {
      this.toastr.warning('DEBE DE INGRESAR LOS ARCHIVOS SOLICITADOS', 'NOTIFICACION');
    } else {
      this.spinner.show('sp');

      const formDataXlsx = new FormData();
      formDataXlsx.append('fileXlsx', this.fileUploadXlsx);
      formDataXlsx.append('idProcess', this.policyForm.get('idLoadingAutomationProcess').value);
      this.api.uploadInsurer(formDataXlsx).then(
        (response: any) => {
              this.toastr.success('El proceso de importación ha iniciado, en cuanto termine se le notificará.', 'Notificación');
              this.spinner.hide('sp');
              this.modalRef.hide();
            }, error => {
              console.log(error);
              this.toastr.warning(error.error);
              console.error();
              this.spinner.hide('sp');
            }
          );
    }
  }

  openModal(template: TemplateRef<any>) {
    this.fileUploadXlsx = null;
    this.modalRef = this.modalService.show(template, {class: 'modal-lg', keyboard: false});

  }

  closeModal() {
    this.modalRef.hide();
    this.cleanFiles();
  }
  cleanFiles() {
    this.fileUploadXlsx = undefined;
  }

  editCoverage(index: number) {
    this.editingIndex = index;
  }

  back() {
    this.editingIndex = null;
  }

  downloadLayout() {
    this.spinner.show('sp');
    this.api.getLayoutFile(3)
      .subscribe(
        (response: any) => {
          this.spinner.hide('sp');
          if (response == null) {
            this.toastr.info('NO EXISTE ARCHIVO A DESCARGAR');
            return;
          } else {
            const dataType = response.type;
            const binaryData = [];
            binaryData.push(response);
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            downloadLink.setAttribute('download',  'LAYOUT_TARIFAS.xlsx');
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
          }
        }, error => {
          this.spinner.hide('sp');
          // console.error(error);
        }
      );
  }

  openModalSaveEndorsement() {
    if (this.error.active === true || this.fileUpload === undefined) {
      this.error = {active: true, msg: 'Debe seleccionar un archivo válido'};
      this.spinner.hide('sp');
      return;
    }
    this.saveRates();

  }

  saveRates() {
    this.spinner.show('sp');
    const formData = new FormData();
    formData.append('file', this.fileUpload);
    this.api.getTariffFromLayout(formData)
      .then(
        (res) => {
          this.spinner.hide('sp');
          let rates: any = [];
          rates = res;
          this.fileUpload = undefined;
          (document.getElementById('fileCategory') as HTMLInputElement).value = null;
          this.updateFormWithRates(rates); // Actualizamos el formulario con los datos recibidos
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
        }, error => {
          this.toastr.warning(error.error);
          this.spinner.hide('sp');
        }
      );
  }

  updateFormWithRates(rates: any[]) {
    this.ratesToModified.clear();
    // Agregar nuevos datos al formulario
    rates.forEach(rate => {
      this.ratesToModified.push(this.formBuilder.group({
        rateId: [rate.rateId],
        startAge: [rate.startAge],
        endAge: [rate.endAge],
        maleAmount: [rate.maleAmount],
        femaleAmount: [rate.femaleAmount]
      }));
    });

    this.rateInfo = this.ratesToModified.value;

    console.log(this.policyForm.value);
  }

  onChangeInputFile(e) {
    // console.log(e);
    this.error = {active: false, msg: ''};
    this.fileUpload = e.target.files[0];
    if (0 > this.fileTypes.indexOf(this.fileUpload.type)) {
      this.error = {active: true, msg: 'Debe seleccionar un archivo válido'};
    }
  }

  protected readonly FormArray = FormArray;
}
