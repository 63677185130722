import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ApiService} from '../api.service';
import {Router, ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {DatePipe} from '@angular/common';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {NgxSpinnerService} from 'ngx-spinner';
import {ValidateAccessService} from "../validate-access.service";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { Dates } from '../plans-carga-poblacion/dates.model';
import { forkJoin } from 'rxjs';

import moment from 'moment';
import * as _ from 'lodash';

@Component({
  selector: 'app-gastos-medicos-menores-p2',
  templateUrl: './gastos-medicos-menores-p2.component.html',
  styleUrls: ['./gastos-medicos-menores-p2.component.css']
})
export class GastosMedicosMenoresP2Component implements OnInit {

  planForm: FormGroup; // Formulario

  idPlan: number;
  idProduct: number;

  planes: any;
  aseguradoras: any;
  aseguradorasSeleccionadas: any = [];
  aseguradorasSeleccionadasName: any = [];
  aseguradorasSeleccionadasBase: any = [];
  aseguradorasSeleccionadasNameBase: any = [];
  coverages: any = {};
  coveragesSeleccionados: any = {};
  coveragesSeleccionadosBase: any = {};
  coveragesOtrosSeleccionados: any = {};
  coveragesOtrosSeleccionadosBase: any = {};
  poblation: any = {};
  planesSeleccionados: any = {};
  planesSeleccionadosBase: any = {};
  loadPlanes: any = {};
  loadPlanesBase: any = {};
  plan: any;
  dates: Dates;
  tarifas: any = {};

  isCargaPoblacion: boolean;
  isShowCargaPoblacion: boolean;
  isTariffSectionVisible: boolean[] = [];

  constructor(private api: ApiService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private toastr: ToastrService,
              private shared: SharedService,
              private datePipe: DatePipe,
              public fb: FormBuilder,
              private modalService: BsModalService,
              private spinner: NgxSpinnerService,
              private formBuilder: FormBuilder,
              private validateAccess: ValidateAccessService) {
  }
  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.idPlan = params.idPlan;
      this.idProduct = +params.idProduct;

      this.getPlanInfo();
    });
  }
  /* -----------------------------------------------------------------------------------------------------
  ----------------------------- F U N C I O N E S G E N E R A L E S ---------------------------------------
  ----------------------------------------------------------------------------------------------------- */
  goToBack() {
    this.shared.gSearchId = 0;
    this.router.navigate(['/admin/executive']);
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  getPlanInfo(): void {
    const params = {
      idProduct: +this.idProduct,
      flexPlanId: +this.idPlan
    };

    this.api.getInfoConfigurationP2(params).subscribe(response => {
      this.plan = response;
      console.log(this.plan);

      this.getInsurancePlanByIdProduct();
    },
    error => {
      console.log(error);
    });
  }

  getInsurancePlanByIdProduct(): void {
    this.api.getInsurancePlanByIdProduct({ idProduct: this.idProduct }).subscribe(response => {
      this.planes = response;
      console.log(this.planes);
      this.aseguradoras = _.chain(this.planes).groupBy('insuranceName').value();
      console.log(this.aseguradoras);

      Object.keys(this.aseguradoras).forEach(key => {
        this.aseguradorasSeleccionadas[key] = false;
        this.loadPlanes[key] = false;
        this.aseguradorasSeleccionadasBase[key] = false;
        this.loadPlanesBase[key] = false;
      });

      //Hablar con gaby para que regrese el insuranceName desde el mismo servicio
      this.plan.listCategoriesPlan.forEach(cat => {
        const plan = this.getPlanById(cat.insurancePlanId);
        cat.insuranceName = plan.insuranceName;
      });

      const aseguradorasFromPlan = _.chain(this.plan.listCategoriesPlan).groupBy('insuranceName').value();
      Object.keys(aseguradorasFromPlan).forEach(async (key, index) => {
        const as = aseguradorasFromPlan[key].filter((ob: any) => ob.flexiblePlan);
        const asBase = aseguradorasFromPlan[key].filter((ob: any) => !ob.flexiblePlan);
        if (as.length > 0) {
          this.aseguradorasSeleccionadas[key] = true
          await this.delay(1000);
          this.getCoveragesByIdProduct(key, index, as);
        }
        if (asBase.length > 0) {
          this.aseguradorasSeleccionadasBase[key] = true
          await this.delay(1000);
          this.getCoveragesByIdProductBase(key, index, asBase);
        }
      });
    },
    error => {
      console.log(error);
    });
  }

  getPlanById(id: number): any {
    let res = null;

    this.planes.forEach(plan => {
      if (plan.idInsurancePlan === id) {
        res = plan;
      }
    });

    return res;
  }

  getPlanRates(plan: any): void {
    const planList = this.getPlanFormPlanById(plan.idInsurancePlan, true);
    const params = {
      flexPlanId : +this.idPlan,
      flexPlanPlanesId: planList.idFlexPlanPlanes,
      insuranceId: plan.idInsurance
    };

    this.api.getPlanRatesConfigurationP2(params).subscribe((response: any) => {
      this.tarifas[plan.planName + '_FLEX'] = response;

      const ob = response;
      if (ob.maximumMonthsPolicy !== null) {
        this.isCargaPoblacion = true;
        this.dates = {
          edadMaximaAnios: ob.maximumYearsPolicy,
          edadMaximaMeses: ob.maximumMonthsPolicy,
          edadMinimaAnios: ob.minimumYearsPolicy,
          edadMinimaMeses: ob.minimumMonthsPOlicy,
          edadMaximaRenovacionAnios: ob.maximumYearsRenewal,
          edadMaximaRenovacionMeses: ob.maximumMonthsRenewal,
          edadMinimaRenovacionAnios: ob.minimumYearsRenewal,
          edadMinimaRenovacionMeses: ob.minimumMonthsRenewal,
        };

        console.log(this.dates);
      }

    },
    error => {
      console.log(error);
    });
  }

  toggleTariffSectionBase(planIndex: string) {
    this.isTariffSectionVisible[planIndex + '_BASE'] = !this.isTariffSectionVisible[planIndex + '_BASE'];
  }

  toggleTariffSection(planIndex: string) {
    this.isTariffSectionVisible[planIndex + '_FLEX'] = !this.isTariffSectionVisible[planIndex + '_FLEX'];
  }

  getPlanRatesBase(plan: any): void {
    const planList = this.getPlanFormPlanById(plan.idInsurancePlan, false);
    const params = {
      flexPlanId : +this.idPlan,
      flexPlanPlanesId: planList.idFlexPlanPlanes,
      insuranceId: plan.idInsurance
    };

    this.api.getPlanRatesConfigurationP2(params).subscribe((response: any) => {
      console.log(response);
      this.tarifas[plan.planName + '_BASE'] = response;

      const ob = response;
      if (ob.maximumMonthsPolicy !== null) {
        this.isCargaPoblacion = true;
        this.dates = {
          edadMaximaAnios: ob.maximumYearsPolicy,
          edadMaximaMeses: ob.maximumMonthsPolicy,
          edadMinimaAnios: ob.minimumYearsPolicy,
          edadMinimaMeses: ob.minimumMonthsPOlicy,
          edadMaximaRenovacionAnios: ob.maximumYearsRenewal,
          edadMaximaRenovacionMeses: ob.maximumMonthsRenewal,
          edadMinimaRenovacionAnios: ob.minimumYearsRenewal,
          edadMinimaRenovacionMeses: ob.minimumMonthsRenewal,
        };

        console.log(this.dates);
      }
    },
    error => {
      console.log(error);
    });
  }

  getCoveragesByIdProduct(name: string, index: number, as: any = null): void {
    this.loadPlanes[name] = false;
    this.aseguradorasSeleccionadasName[index] = name;
    console.log(this.aseguradorasSeleccionadas[name]);
    if (this.aseguradorasSeleccionadas[name]) {
      const plan = this.aseguradoras[name];
      const params = {
        idPlan: plan[0].idInsurancePlan,
        idProduct: this.idProduct
      };
      this.api.getCoveragesByIdProductOb(params).subscribe(response => {
        this.coverages[name] = response;

        this.aseguradoras[name].forEach(plan => {
          const ob = { [plan.planName] : [] };
          this.coveragesSeleccionados[name] = {...this.coveragesSeleccionados[name], ...ob};
          const obCov = { [plan.planName] : [] };
          this.coveragesOtrosSeleccionados[name] = {...this.coveragesOtrosSeleccionados[name], ...obCov};
          let ob2;
          if (as) {
            const ind = _.findIndex(as, { insurancePlanId: plan.idInsurancePlan });
            if (ind >= 0) {
              ob2 = { [plan.planName] : true };
              this.planesSeleccionados[name] = {...this.planesSeleccionados[name], ...ob2};

              this.coverages[name].forEach((value, ind) => {
                if (as) {
                  const indPlan = _.findIndex(as, { insurancePlanId : plan.idInsurancePlan });
                  if (indPlan >= 0) {
                    const ind2 = _.findIndex(as[indPlan].listCoverages, { coverageName : value.coverageName });
                    if (ind2 >= 0) {
                      const indCovOptions = _.findIndex(value.optionsValues, { value: as[indPlan].listCoverages[ind2].value })
                      this.coveragesSeleccionados[name][plan.planName][ind] = indCovOptions < 0 ? 'OTRO' : as[indPlan].listCoverages[ind2].value;
                      this.coveragesOtrosSeleccionados[name][plan.planName][ind] = as[indPlan].listCoverages[ind2].value;
                    } else {
                      value.optionsValues.forEach(val => {
                        if (val.defaultValue) {
                          this.coveragesSeleccionados[name][plan.planName][ind] = val.value;
                          this.coveragesOtrosSeleccionados[name][plan.planName][ind] = val.value;
                        }
                      });
                    }
                  } else {
                    value.optionsValues.forEach(val => {
                      if (val.defaultValue) {
                        this.coveragesSeleccionados[name][plan.planName][ind] = val.value;
                        this.coveragesOtrosSeleccionados[name][plan.planName][ind] = val.value;
                      }
                    });
                  }
                } else {
                  value.optionsValues.forEach(val => {
                    if (val.defaultValue) {
                      this.coveragesSeleccionados[name][plan.planName][ind] = val.value;
                      this.coveragesOtrosSeleccionados[name][plan.planName][ind] = val.value;
                    }
                  });
                }
              });

              if (this.planesSeleccionados[name][plan.planName]) {
                this.getPlanRates(plan);
              }

            }
          }

        });

        this.loadPlanes[name] = true;
        console.log(this.planesSeleccionados);
        console.log(this.loadPlanes);
      },
      error => {
        console.log(error);
      });
    } else {
    }
  }

  getCoveragesByIdProductBase(name: string, index: number, as: any = null): void {
    this.loadPlanesBase[name] = false;
    this.aseguradorasSeleccionadasNameBase[index] = name;
    if (this.aseguradorasSeleccionadasBase[name]) {
      const plan = this.aseguradoras[name];
      const params = {
        idPlan: plan[0].idInsurancePlan,
        idProduct: this.idProduct
      };
      this.api.getCoveragesByIdProductOb(params).subscribe(response => {
        this.coverages[name] = response;

        this.aseguradoras[name].forEach(plan => {
          const ob = { [plan.planName] : [] };
          this.coveragesSeleccionadosBase[name] = {...this.coveragesSeleccionadosBase[name], ...ob};
          const obCov = { [plan.planName] : [] };
          this.coveragesOtrosSeleccionadosBase[name] = {...this.coveragesOtrosSeleccionadosBase[name], ...obCov};
          let ob2;
          if (as) {
            const ind = _.findIndex(as, { insurancePlanId: plan.idInsurancePlan });
            if (ind >= 0) {
              ob2 = { [plan.planName] : true };
              this.planesSeleccionadosBase[name] = {...this.planesSeleccionadosBase[name], ...ob2};

              this.coverages[name].forEach((value, ind) => {
                if (as) {
                  const indPlan = _.findIndex(as, { insurancePlanId : plan.idInsurancePlan });
                  if (indPlan >= 0) {
                    const ind2 = _.findIndex(as[indPlan].listCoverages, { coverageName : value.coverageName });
                    if (ind2 >= 0) {
                      const indCovOptions = _.findIndex(value.optionsValues, { value: as[indPlan].listCoverages[ind2].value })
                      this.coveragesSeleccionadosBase[name][plan.planName][ind] = indCovOptions < 0 ? 'OTRO' : as[indPlan].listCoverages[ind2].value;
                      this.coveragesOtrosSeleccionadosBase[name][plan.planName][ind] = as[indPlan].listCoverages[ind2].value;
                    } else {
                      value.optionsValues.forEach(val => {
                        if (val.defaultValue) {
                          this.coveragesSeleccionadosBase[name][plan.planName][ind] = val.value;
                          this.coveragesOtrosSeleccionadosBase[name][plan.planName][ind] = val.value;
                        }
                      });
                    }
                  } else {
                    value.optionsValues.forEach(val => {
                      if (val.defaultValue) {
                        this.coveragesSeleccionadosBase[name][plan.planName][ind] = val.value;
                        this.coveragesOtrosSeleccionadosBase[name][plan.planName][ind] = val.value;
                      }
                    });
                  }
                } else {
                  value.optionsValues.forEach(val => {
                    if (val.defaultValue) {
                      this.coveragesSeleccionadosBase[name][plan.planName][ind] = val.value;
                      this.coveragesOtrosSeleccionadosBase[name][plan.planName][ind] = val.value;
                    }
                  });
                }
              });

              if (this.planesSeleccionadosBase[name][plan.planName]) {
                this.getPlanRatesBase(plan);
              }

            }
          }

        });

        this.loadPlanesBase[name] = true;
      },
      error => {
        console.log(error);
      });
    } else {
    }
  }

  getPlan(name: string): any {
    let res = {};

    this.planes.forEach(plan => {
      if (plan.planName === name) {
        res = plan;
      }
    });

    return res;
  }

  getPlanFormPlan(name: string): any {
    let res = {};

    this.plan.listCategoriesPlan.forEach(plan => {
      if (plan.planName === name) {
        res = plan;
      }
    });

    return res;
  }

  getPlanFormPlanById(id: string, flexiblePlan: boolean): any {
    let res = null;

    if (this.plan.listCategoriesPlan) {
      this.plan.listCategoriesPlan.forEach(plan => {
        if (plan.insurancePlanId === id && plan.flexiblePlan === flexiblePlan) {
          res = plan;
        }
      });
    }

    return res;
  }

  configurar(params: any): void {
    console.log(params);
    const obs = [];
    Object.keys(this.tarifas).forEach(key => {
      const ob = this.tarifas[key];
      ob.maximumMonthsPolicy = params.maximumMonthsPolicy;
      ob.maximumMonthsRenewal = params.maximumMonthsRenewal;
      ob.maximumYearsPolicy = params.maximumYearsPolicy;
      ob.maximumYearsRenewal = params.maximumYearsRenewal;
      ob.minimumMonthsPolicy = params.minimumMonthsPolicy;
      ob.minimumMonthsRenewal = params.minimumMonthsRenewal;
      ob.minimumYearsPolicy = params.minimumYearsPolicy;
      ob.minimumYearsRenewal = params.minimumYearsRenewal;
      // ob.netPremiumGroup = params.netPremiumGroup;
      // ob.netPremiumIndividual = params.netPremiumIndividual;
      // ob.policyRights = params.policyRights;
      // ob.policyRightsGroup = params.policyRightsGroup;
      // ob.renewedPolicy = params.renewedPolicy;
      // ob.surcharges = params.surcharges;
      // ob.surchargesGroup = +params.surchargesGroup;
      obs.push(this.api.updateRatesConfigurationP2(ob));
    });

    forkJoin(obs).subscribe(response => {
      this.toastr.success('Tarifa actualizada correctamente');
    }, 
    error => {
        console.log(error);
        this.toastr.error(error.error.mensaje);
    });
  }

  validateQuotes(): void {
    if (this.isCargaPoblacion) {
      this.isShowCargaPoblacion = true;
    } else {
      this.toastr.info('TIENE QUE CONFIGURAR TODAS LAS TARIFAS PARA PODER CARGAR LA POBLACIÓN', 'NOTIFICACIÓN');
      this.isShowCargaPoblacion = false;
    }
  }

}
