import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {BsModalService} from 'ngx-bootstrap/modal';
import {SharedService} from '../shared-service.service';
import {number} from '@amcharts/amcharts4/core';
import {regex} from '../../common/regex';
import {Dates} from '../plans-carga-poblacion/dates.model';

@Component({
  selector: 'app-flex-gmm-p2-view',
  templateUrl: './flex-gmm-p2-view.component.html',
  styleUrls: ['./flex-gmm-p2-view.component.css']
})
export class FlexGmmP2ViewComponent implements OnInit {

  planFlexForm: FormGroup;
  idPlanFlex;
  isEdition = false;
  planPlanesModels: FormArray;
  planProductOptions: any = [];
  insuranceListDetail: any = [];
  showSpecialCoverages = false;
  isLoadTarrifManual;
  specialCoverageList = [];
  selectedPlanIndex; // Para saber qué plan está seleccionando el usuario
  isTariffSectionVisible: boolean[] = [];
  allCoverages: FlexPlanCoveragesModel[] = [];
  error = {active: false, msg: ''};
  public fileUpload: File;
  public fileUploadFactor: File;
  fileTypes: string[] = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.oasis.opendocument.spreadsheet'];
  isPoblation: boolean;
  dates: Dates;
  groupedCoverages: { [key: string]: any[] } = {};
  columns: string[] = [];
  tableRows: any[] = [];
  constructor(private fb: FormBuilder,
              private api: ApiService,
              private activatedRoute: ActivatedRoute,
              private toastr: ToastrService,
              protected shared: SharedService,
              private router: Router,
              private spinner: NgxSpinnerService,
              private cdRef: ChangeDetectorRef,
              private modalService: BsModalService,
              private cd: ChangeDetectorRef) {
    this.activatedRoute.params.subscribe(params => {
      this.idPlanFlex = params.idPlan;
      this.isEdition = params.isEdition === 'true';
    });
    this.planFlexForm = this.fb.group({
      idFlexPlanGmm: [null],  // Long
      planFlexId: [this.idPlanFlex],  // Long
      typePopulationPolicyId: [null],  // Long
      typeConfigurationPolicyId: [null],  // Long
      addSpouse: [false],  // Boolean
      addChildren: [0],  // Boolean
      addMother: [false],  // Boolean
      addFather: [false],  // Boolean
      typePlanProductId: [null],  // Long
      planReduction: [0.0],  // Double
      creationDate: [null],  // Date
      updateDate: [null],  // Date
      creationUserId: [null],  // Long
      updateUserId: [null],  // Long
      productPlanFlexConfiguration: [null],
      planPlanesModels: this.fb.array([])
    });

    this.planPlanesModels = this.planFlexForm.get('planPlanesModels') as FormArray;
  }

  ngOnInit() {
    this.loadUnitOptions();
    this.getPlanGMMByIdPlanFlex();
    this.getInsuranceCompanies();

    this.dates = {
      edadMaximaAnios: 0,
      edadMaximaMeses: 0,
      edadMinimaAnios: 0,
      edadMinimaMeses: 0,
      edadMaximaRenovacionAnios: 0,
      edadMaximaRenovacionMeses: 0,
      edadMinimaRenovacionAnios: 0,
      edadMinimaRenovacionMeses: 0
    };

  }

  loadUnitOptions() {
    this.api.getUnitMeasurement()
      .then(
        (response: any) => {
          this.planProductOptions = response;
        }, error => {
          this.toastr.error('Ocurrió un problema al cargar el catálogo de Medidas', 'Notificación');
        }
      );
  }

  getPlanGMMByIdPlanFlex(): void {
    this.spinner.show('sp');
    this.api.getPlanGMMById(this.planFlexForm.get('planFlexId').value)
      .then(
        (response: any) => {
          this.loadCoveragesByIdProduct().then(() => {
            this.populateFormWithResponseData(response);
            this.spinner.hide('sp');
          });

          this.spinner.hide('sp');
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  loadCoveragesByIdProduct(): Promise<void> {
    return new Promise((resolve, reject) => {
      const data = {
        idProduct: 1,
        idPlan: null
      };
      this.api.getCoveragesByIdProduct(data)
        .then(
          (response: any) => {
            this.specialCoverageList = response;
            resolve();
          }, error => {
            this.toastr.error('Ocurrió un problema al cargar el catálogo de Roles', 'Notificación');
            reject(error);
          }
        );
    });
  }


  populateFormWithResponseData(response: any): void {
    this.planFlexForm.reset();
    this.allCoverages = [];
    // Asignar directamente los valores principales del formulario
    this.planFlexForm.patchValue({
      idFlexPlanGmm: response.idFlexPlanGmm,
      planFlexId: response.planFlexId,
      typePopulationPolicyId: response.typePopulationPolicyId,
      typeConfigurationPolicyId: response.typeConfigurationPolicyId,
      addSpouse: response.addSpouse,
      addChildren: response.addChildren,
      addMother: response.addMother,
      addFather: response.addFather,
      typePlanProductId: response.typePlanProductId,
      planReduction: response.planReduction,
      productPlanFlexConfiguration: response.productPlanFlexConfiguration,
      creationDate: response.creationDate,
      updateDate: response.updateDate,
      creationUserId: response.creationUserId,
      updateUserId: response.updateUserId
    });

    // Limpiar el FormArray antes de agregar nuevos planes
    const planPlanesModelsArray = this.planFlexForm.get('planPlanesModels') as FormArray;
    planPlanesModelsArray.clear();

    // Iterar sobre los planes y agregarlos al FormArray
    response.planPlanesModels.forEach((plan: any, index: number) => {

      const planFormGroup = this.createPlan(plan.flexiblePlan, index + 1);


      // Asignar valores al plan
      planFormGroup.patchValue({
        idFlexPlanPlanes: plan.idFlexPlanPlanes,
        flexPlanCategoriesId: plan.flexPlanCategoriesId,
        planName: plan.planName,
        flexiblePlan: plan.flexiblePlan,
        quote: plan.quote,
        premiumIndividual: plan.premiumIndividual,
        averagePremium: plan.averagePremium,
        netPremiumIndividual: plan.netPremiumIndividual,
        netPremiumGroup: plan.netPremiumGroup,
        policyRights: plan.policyRights,
        surcharges: plan.surcharges,
        insuranceCompanyId: plan.insuranceCompanyId,
        creationDate: plan.creationDate,
        updateDate: plan.updateDate,
        creationUserId: plan.creationUserId,
        updateUserId: plan.updateUserId,
        renewedPolicy: plan.renewedPolicy,
        maximumYearsPolicy: plan.maximumYearsPolicy,
        maximumMonthsPolicy: plan.maximumMonthsPolicy,
        minimumYearsPolicy: plan.minimumYearsPolicy,
        minimumMonthsPolicy: plan.minimumMonthsPolicy,
        maximumYearsRenewal: plan.maximumYearsRenewal,
        maximumMonthsRenewal: plan.maximumMonthsRenewal,
        minimumYearsRenewal: plan.minimumYearsRenewal,
        minimumMonthsRenewal: plan.minimumMonthsRenewal
      });

      // Asignar los valores a this.dates
      this.dates = {
        edadMaximaAnios: plan.maximumYearsPolicy,
        edadMaximaMeses: plan.maximumMonthsPolicy,
        edadMinimaAnios: plan.minimumYearsPolicy,
        edadMinimaMeses: plan.minimumMonthsPolicy,
        edadMaximaRenovacionAnios: plan.maximumYearsRenewal,
        edadMaximaRenovacionMeses: plan.maximumMonthsRenewal,
        edadMinimaRenovacionAnios: plan.minimumYearsRenewal,
        edadMinimaRenovacionMeses: plan.minimumMonthsRenewal
      };

      // Asignar valores a la categoría del plan
      planFormGroup.get('flexPlanCategoriesModel').patchValue({
        idFlexPlanCategories: plan.flexPlanCategoriesModel.idFlexPlanCategories,
        categoryName: plan.flexPlanCategoriesModel.categoryName,
        planFlexId: plan.flexPlanCategoriesModel.planFlexId,
        flexibleProductId: plan.flexPlanCategoriesModel.flexibleProductId,
        creationDate: plan.flexPlanCategoriesModel.creationDate,
        updateDate: plan.flexPlanCategoriesModel.updateDate,
        creationUserId: plan.flexPlanCategoriesModel.creationUserId,
        updateUserId: plan.flexPlanCategoriesModel.updateUserId
      });


      // Asignar coberturas al plan
      const flexPlanCoveragesModelsArray = planFormGroup.get('flexPlanCoveragesModels') as FormArray;
      plan.flexPlanCoveragesModels.forEach((coverage: any) => {
        const coverageGroup = this.fb.group({
          idFlexPlanCoverages: coverage.idFlexPlanCoverages,
          flexPlanPlanesId: coverage.flexPlanPlanesId,
          coverageSurexsId: coverage.coverageSurexsId,
          origen: coverage.origen,
          coverageName: coverage.coverageName,
          value: coverage.value,
          unitMeasurementId: coverage.unitMeasurementId,
          endoso: coverage.endoso,
          flexible: coverage.flexible,
          factorQuote: [coverage.factorQuote, Validators.pattern(regex.onlydecimanes)],
          endorsements: this.fb.array(coverage.endorsements.map((endorsement: any) => this.fb.group({
            idFlexPlanEndorsement: endorsement.idFlexPlanEndorsement,
            flexPlanCoverageId: endorsement.flexPlanCoverageId,
            coverageName: endorsement.coverageName,
            value: endorsement.value,
            unitMeasurementId: endorsement.unitMeasurementId,
          })))
        });

        // Usa `setValue`
        coverageGroup.setValue({
          idFlexPlanCoverages: coverage.idFlexPlanCoverages,
          flexPlanPlanesId: coverage.flexPlanPlanesId,
          coverageSurexsId: coverage.coverageSurexsId,
          origen: coverage.origen,
          coverageName: coverage.coverageName,
          value: coverage.value,
          unitMeasurementId: coverage.unitMeasurementId,
          endoso: coverage.endoso,
          flexible: coverage.flexible,
          factorQuote: coverage.factorQuote,
          endorsements: coverage.endorsements.map((endorsement: any) => ({
            idFlexPlanEndorsement: endorsement.idFlexPlanEndorsement,
            flexPlanCoverageId: endorsement.flexPlanCoverageId,
            coverageName: endorsement.coverageName,
            value: endorsement.value,
            unitMeasurementId: endorsement.unitMeasurementId,
          }))
        });


        const specialCoverage = this.specialCoverageList.find(sc => sc.coverageName === coverageGroup.get('coverageName').value);

        // Si existe una coincidencia, agregar optionsValues si están definidos
        if (specialCoverage && specialCoverage.optionsValues && specialCoverage.optionsValues.length > 0) {
          const optionsValuesArray = this.fb.array(specialCoverage.optionsValues.map(option => this.fb.group({
            value: [option.value],
            unitMeasurementId: [option.unitMeasurementId],
            defaultValue: [option.defaultValue],
            openValue: [option.openValue],
            order: [option.order],
            unitMeasurement: [option.unitMeasurement]
          })));
          coverageGroup.addControl('optionsValues', optionsValuesArray);
        }

        // Agregar la cobertura al FormArray
        flexPlanCoveragesModelsArray.push(coverageGroup);
        coverageGroup.get('flexible').value === false ? coverageGroup.disable() : coverageGroup.enable();

        // Si la cobertura es flexible, agregarla a `allCoverages`
        if (coverageGroup.get('flexible').value === true) {
          const coverageValue = coverageGroup.value as FlexPlanCoveragesModel;
          this.allCoverages.push(coverageValue);
        }
      });

      // Asignar tarifas a la categoría del plan
      const categoryRatesArray = planFormGroup.get('categoryRates') as FormArray;

      // Verificar si plan.categoryRates tiene datos antes de iterar
      if (plan.categoryRates && plan.categoryRates.length > 0) {
        plan.categoryRates.forEach((rate: any) => {
          const rateGroup = this.fb.group({
            idFlexPlanCategoryRates: [rate.idFlexPlanCategoryRates],  // Integer
            flexPlanPlanesId: [rate.flexPlanPlanesId],  // Integer
            minimumAge: [rate.minimumAge],  // Integer
            maximumAge: [rate.maximumAge],  // Integer
            manRate: [rate.manRate],  // Double
            womanRate: [rate.womanRate],  // Double
            thousandQuota: [rate.thousandQuota]  // Boolean
          });
          categoryRatesArray.push(rateGroup);
        });
      }

      // Agregar plan al FormArray de planes
      planPlanesModelsArray.push(planFormGroup);

    });

    // Inicializa el array de visibilidad con valores 'false' para cada plan
    this.isTariffSectionVisible = this.planFlexForm.get('planPlanesModels').value.reduce((acc, plan) => {
      acc[plan.idFlexPlanPlanes] = false;
      return acc;
    }, {});

    this.planPlanesModels = this.planFlexForm.get('planPlanesModels') as FormArray;
    this.getCoverages();
    this.cd.detectChanges();
  }

  createPlan(isPlanFexlible: boolean, count): FormGroup {
    return this.fb.group({
      idFlexPlanPlanes: [null],  // Integer
      flexPlanCategoriesId: [null],  // Integer
      planName: ['Plan ' + count],  // String
      flexiblePlan: [isPlanFexlible],  // Boolean
      quote: [0.0],  // Double
      premiumIndividual: [false],  // Double
      averagePremium: [0.0],  // Double
      netPremiumIndividual: [0.0],  // Double
      netPremiumGroup: [0.0],  // Double
      policyRights: [null , Validators.required],  // Double
      surcharges: [null, Validators.required],  // Double
      insuranceCompanyId: [null, Validators.required],
      creationDate: [null],  // Date
      updateDate: [null],  // Date
      creationUserId: [null],  // Integer
      updateUserId: [null],  // Integer
      status: [1],  // Integer
      flexPlanCategoriesModel: this.fb.group({  // FormGroup para almacenar la categoria
        idFlexPlanCategories: [null],
        categoryName: [''],
        planFlexId: [this.idPlanFlex],
        flexibleProductId: [1],
        creationDate: [''],
        updateDate: [''],
        creationUserId: [''],
        updateUserId: ['']
      }),
      categoryRates: this.fb.array([]),  // FormArray para almacenar las tarifas manuales
      flexPlanCoveragesModels: this.fb.array([]),  // FormArray para almacenar las coberturas
      maximumYearsPolicy: [null, Validators.required],  // Integer
      maximumMonthsPolicy: [null, Validators.required],  // Integer
      minimumYearsPolicy: [null, Validators.required],  // Integer
      minimumMonthsPolicy: [null, Validators.required],  // Integer
      maximumYearsRenewal: [null, Validators.required],  // Integer
      maximumMonthsRenewal: [null, Validators.required],  // Integer
      minimumYearsRenewal: [null, Validators.required],  // Integer
      minimumMonthsRenewal: [null, Validators.required],
      renewedPolicy: []// Integer
    });

  }

  getUnitOptions(): any[] {
    return this.planProductOptions;
  }

  toggleSpecialCoverages() {
    this.showSpecialCoverages = !this.showSpecialCoverages;
  }

  getEndorsementLabel(index: number): string {
    const labels = ['SUMA ASEGURADA', 'DEDUCIBLE', 'COASEGURO', 'PERIODO DE ESPERA'];
    return labels[index] || 'Valor';
  }

  getPlanAtIndex(planIndex: number): FormGroup {
    const planPlanesModels = this.planFlexForm.get('planPlanesModels') as FormArray;
    return planPlanesModels.at(planIndex) as FormGroup;
  }

  addCategoryRate(planIndex: number) {
    const plan = this.getPlanAtIndex(planIndex);
    if (planIndex !== null) {
      const rateForm = this.fb.group({
        idFlexPlanCategoryRates: [null],
        flexPlanPlanesId: [plan.get('idFlexPlanPlanes').value],
        minimumAge: [null, Validators.required],
        maximumAge: [null, Validators.required],
        manRate: [0.0, Validators.required],
        womanRate: [0.0, Validators.required],
        thousandQuota: [false],
        creationDate: [null],
        updateDate: [null],
        creationUserId: [null],
        updateUserId: [null]
      });

      const categoryRatesArray = (plan.get('categoryRates') as FormArray);
      categoryRatesArray.push(rateForm);
    }
  }

  // Método para mostrar la sección de tarifas
  showScreenCategoryRates(index: number) {
    console.log('Index plan: ', index);
    this.selectedPlanIndex = index;

  }

  getCategoryRatesFormArray(): FormArray {
    if (this.selectedPlanIndex !== null) {
      const plan = this.planPlanesModels.at(this.selectedPlanIndex);
      if (plan) {
        return plan.get('categoryRates') as FormArray;
      }
    }
    return this.fb.array([]);
  }


  // Método para mostrar/ocultar la sección de tarifas manuales de un plan específico
  toggleTariffSection(planIndex: number) {
    this.isTariffSectionVisible[planIndex] = !this.isTariffSectionVisible[planIndex];
  }

  onChangeInputFile(e) {
    this.error = {active: false, msg: ''};
    this.fileUpload = e.target.files[0];
    if (0 > this.fileTypes.indexOf(this.fileUpload.type)) {
      this.error = {active: true, msg: 'Debe seleccionar un archivo válido'};
    }
  }

  onChangeInputFileSlip(e) {
    this.error = {active: false, msg: ''};
    this.fileUploadFactor = e.target.files[0];
    if (0 > this.fileTypes.indexOf(this.fileUploadFactor.type)) {
      this.error = {active: true, msg: 'Debe seleccionar un archivo válido'};
    }
  }

  downloadLayout(id) {
    this.spinner.show('sp');
    this.api.getLayoutFile(id)
      .subscribe(
        (response: any) => {
          this.spinner.hide('sp');
          if (response == null) {
            this.toastr.info('NO EXISTE ARCHIVO A DESCARGAR');
            return;
          } else {
            const dataType = response.type;
            const binaryData = [];
            binaryData.push(response);
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            downloadLink.setAttribute('download',  'LAYOUT_TARIFAS.xlsx');
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
          }
        }, error => {
          this.spinner.hide('sp');
          // console.error(error);
        }
      );
  }

  removeCategoryRate(indexPlan: number, indexRate: number): void {
    const categoryRatesArray = this.getPlanAtIndex(indexPlan).get('categoryRates') as FormArray;
    categoryRatesArray.removeAt(indexRate);

  }

  saveRates(indexPlan: number): void {
    this.spinner.show('sp');

    if (this.isLoadTarrifManual === true || this.isLoadTarrifManual === 'true') {
      this.api.saveRatesConfiguration(this.planFlexForm.value)
        .then(
          (response: any) => {

            const plansArray = this.planFlexForm.get('planPlanesModels') as FormArray;
            const plansArrayRates = response.planPlanesModels;

            plansArray.controls.forEach(planControl => {
              plansArrayRates.forEach(plan => {
                const categoryRatesArray = planControl.get('categoryRates') as FormArray;
                categoryRatesArray.clear();

                // Verificar si plan.categoryRates tiene datos antes de iterar
                if (plan.categoryRates && plan.categoryRates.length > 0 && plan.idFlexPlanPlanes === planControl.get('idFlexPlanPlanes').value) {
                  plan.categoryRates.forEach((rate: any) => {
                    const rateGroup = this.fb.group({
                      idFlexPlanCategoryRates: [rate.idFlexPlanCategoryRates],  // Integer
                      flexPlanPlanesId: [rate.flexPlanPlanesId],  // Integer
                      minimumAge: [rate.minimumAge],  // Integer
                      maximumAge: [rate.maximumAge],  // Integer
                      manRate: [rate.manRate],  // Double
                      womanRate: [rate.womanRate],  // Double
                      thousandQuota: [rate.thousandQuota]  // Boolean
                    });
                    categoryRatesArray.push(rateGroup);
                  });
                }
              });
            });
            this.spinner.hide('sp');
            this.toastr.success('SU SOLICITUD SE HA REALIZADO DE FORMA EXITOSA', 'NOTIFICACIÓN');
          }, error => {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            this.spinner.hide('sp');
          }
        );
    } else {

      const idPlanPlanes = this.getPlanAtIndex(indexPlan).get('idFlexPlanPlanes').value;
      const categoryRatesArray = this.getPlanAtIndex(indexPlan).get('categoryRates') as FormArray;
      categoryRatesArray.clear();

      if (this.fileUpload === undefined) {
        this.toastr.info('DEBE DE INGRESAR EL LAYOUT', 'NOTIFICACION');
        this.spinner.hide('sp');
        return;
      }
      const file = this.fileUpload;
      const reader = new FileReader();

      reader.onloadend = () => {
        if (reader.result) {
          const base64String = (reader.result as string).split(',')[1];   // Elimina el prefijo de la URL de datos
          const model = {
            fileName: file.name,
            fileData: base64String,
            idFlexPlanPlanes: idPlanPlanes
          };
          this.api.getTariffFromLayoutGMM(model)
            .then(
              (response: any) => {
                this.fileUpload = undefined;
                this.cdRef.detectChanges();

                // Verificar si plan.categoryRates tiene datos antes de iterar
                if (response && response.length > 0) {
                  response.forEach((rate: any) => {
                    const rateGroup = this.fb.group({
                      idFlexPlanCategoryRates: [rate.idFlexPlanCategoryRates],  // Integer
                      flexPlanPlanesId: [rate.flexPlanPlanesId],  // Integer
                      minimumAge: [rate.minimumAge],  // Integer
                      maximumAge: [rate.maximumAge],  // Integer
                      manRate: [rate.manRate],  // Double
                      womanRate: [rate.womanRate],  // Double
                      thousandQuota: [rate.thousandQuota]  // Boolean
                    });
                    categoryRatesArray.push(rateGroup);
                  });
                }

                this.spinner.hide('sp');
                this.toastr.success('SU SOLICITUD SE HA REALIZADO DE FORMA EXITOSA', 'NOTIFICACIÓN');
              }, error => {
                this.fileUpload = undefined;
                this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
                this.spinner.hide('sp');
              }
            );
        } else {
          this.toastr.error('Ocurrió un problema al leer el archivo', 'Notificación');
          this.spinner.hide('sp');
        }
      };

      reader.onerror = () => {
        this.toastr.error('Ocurrió un problema al leer el archivo', 'Notificación');
        this.spinner.hide('sp');
      };

      reader.readAsDataURL(file);  // Inicia la lectura del archivo
    }
  }

  saveFactors(): void {
    this.spinner.show('sp');
    if (this.fileUploadFactor === undefined) {
      this.toastr.info('DEBE DE INGRESAR EL LAYOUT', 'NOTIFICACION');
      this.spinner.hide('sp');
      return;
    }
    const file = this.fileUploadFactor;
    const reader = new FileReader();

    reader.onloadend = () => {
      if (reader.result) {
        const base64String = (reader.result as string).split(',')[1];   // Elimina el prefijo de la URL de datos
        const model = {
          fileName: file.name,
          fileData: base64String,
          planFlexGmm: this.planFlexForm.value
        };
        this.api.getFactorSlip(model)
          .then(
            (response: any) => {
              this.spinner.hide('sp');
              this.toastr.success('SU SOLICITUD SE HA REALIZADO DE FORMA EXITOSA', 'NOTIFICACIÓN');
              this.populateFormWithResponseData(response);
            }, error => {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
              this.spinner.hide('sp');
            }
          );
      } else {
        this.toastr.error('Ocurrió un problema al leer el archivo', 'Notificación');
        this.spinner.hide('sp');
      }
    };

    reader.onerror = () => {
      this.toastr.error('Ocurrió un problema al leer el archivo', 'Notificación');
      this.spinner.hide('sp');
    };
    reader.readAsDataURL(file);  // Inicia la lectura del archivo
  }

  savePlanGmm(): void {
    this.spinner.show('sp');

    if (!this.planFlexForm.valid) {
      this.planFlexForm.markAllAsTouched();
      this.spinner.hide('sp');
      return;
    }

    const formCopy = {
      ...this.planFlexForm.getRawValue(), // Usamos getRawValue para incluir valores deshabilitados
      planPlanesModels: this.planFlexForm.getRawValue().planPlanesModels.map(plan => ({
        ...plan,
        flexPlanCoveragesModels: plan.flexPlanCoveragesModels.map(coverage => {
          // Eliminamos el campo optionsValues
          const { optionsValues, ...coverageWithoutOptions } = coverage;
          return coverageWithoutOptions;
        })
      }))
    };

    this.api.editPlanGMMById(formCopy)
      .then(
        (response: any) => {
          this.spinner.hide('sp');
          this.toastr.success('SU SOLICITUD SE HA REALIZADO DE FORMA EXITOSA', 'NOTIFICACIÓN');
          this.populateFormWithResponseData(response);

        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }
  layoutCargado(e) {
    console.log('respuesta población inicial');
    console.log(e);
    this.isPoblation = e;
  }

  /*getFactorQuoteControl(plan: FormGroup, coverage: any): FormControl | null {
    if (!plan || !coverage) { return null; }  // Validamos que los parámetros existan

    const coveragesArray = plan.get('flexPlanCoveragesModels') as FormArray;
    if (!coveragesArray || !coveragesArray.controls) { return null; }  // Evita errores si el FormArray es null

    const coverageControl = coveragesArray.controls.find(cov => {
      const control = cov.get('idFlexPlanCoverages');
      return control && control.value === coverage.idFlexPlanCoverages;
    });

    return coverageControl ? (coverageControl.get('factorQuote') as FormControl) : null;
  }*/


  getUnitCode(unitMeasurementId: any): string {
    const unit = this.planProductOptions.find(option => option.id === Number(unitMeasurementId));
    return unit ? unit.code : 'N/A'; // Devuelve 'N/A' si no encuentra el id
  }

  getInsuranceCompanies() {
    this.api.getInsuranceCompaniesByProduct(1).then((data: any) => {
        this.insuranceListDetail = data;

    }, error => {
      this.toastr.error('Ocurrió un problema al cargar el catálogo de Roles', 'Notificación');
    });
  }

  saveOnlyPlan(indexPlan: number): void {

    const plan = this.getPlanAtIndex(indexPlan);
    this.copyAge(plan);
    this.spinner.show('sp');

    if (!plan.valid) {
      plan.markAllAsTouched();
      this.spinner.hide('sp');
      this.toastr.warning('Por favor, llena todos los datos', 'Notificación');
      return;
    }

    const planValue = plan.getRawValue();

    // formCopy usando planValue, que sí es serializable.
    const formCopy = {
      ...planValue,
      flexPlanCoveragesModels: planValue.flexPlanCoveragesModels.map(coverage => {
        const { optionsValues, ...coverageWithoutOptions } = coverage;
        return coverageWithoutOptions;
      })
    };

    // Asignar los valores a this.dates
    this.dates = {
      edadMaximaAnios: plan.get('maximumYearsPolicy').value,
      edadMaximaMeses: plan.get('maximumMonthsPolicy').value,
      edadMinimaAnios: plan.get('minimumYearsPolicy').value,
      edadMinimaMeses: plan.get('minimumMonthsPolicy').value,
      edadMaximaRenovacionAnios: plan.get('maximumYearsRenewal').value,
      edadMaximaRenovacionMeses: plan.get('maximumMonthsRenewal').value,
      edadMinimaRenovacionAnios: plan.get('minimumYearsRenewal').value,
      edadMinimaRenovacionMeses: plan.get('minimumMonthsRenewal').value
    };

    console.log(this.dates);

    this.api.saveAdditionalPlanConfigurationGMM(formCopy)
      .then(
        (response: any) => {
          this.spinner.hide('sp');
          this.toastr.success('SU SOLICITUD SE HA REALIZADO DE FORMA EXITOSA', 'NOTIFICACIÓN');
        }, error => {
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          this.spinner.hide('sp');
        }
      );
  }

  copyAge(plan) {
    const plansArray = this.planFlexForm.get('planPlanesModels') as FormArray;
    plansArray.controls.forEach(planControl => {

      function setDefaultValue(controlName: string, defaultValue: any) {
        const control = planControl.get(controlName);
        if (control && (control.value === null || control.value === '' || control.value === undefined)) {
          control.setValue(defaultValue);
        }
      }
      setDefaultValue('maximumMonthsPolicy', plan.get('maximumMonthsPolicy').value);
      setDefaultValue('maximumMonthsRenewal', plan.get('maximumMonthsRenewal').value);
      setDefaultValue('maximumYearsPolicy', plan.get('maximumYearsPolicy').value);
      setDefaultValue('maximumYearsRenewal', plan.get('maximumYearsRenewal').value);
      setDefaultValue('minimumMonthsPolicy', plan.get('minimumMonthsPolicy').value);
      setDefaultValue('minimumMonthsRenewal', plan.get('minimumMonthsRenewal').value);
      setDefaultValue('minimumYearsPolicy', plan.get('minimumYearsPolicy').value);
      setDefaultValue('minimumYearsRenewal', plan.get('minimumYearsRenewal').value);

    });
  }


  validarPlanes(): boolean {
    const plansArray = this.planFlexForm.get('planPlanesModels') as FormArray;

    return plansArray.controls.every(planControl => {
      const plan = planControl.value;
      return (
        ((plan.quote === true || plan.quote === 'true') && plan.categoryRates !== null && plan.categoryRates.length > 0) ||
        ((plan.quote === false || plan.quote === 'false') && plan.averagePremium !== null && plan.averagePremium > 0)
      );
    });
  }

  invokeFunctionLoadPoblation() {
    console.log(this.isPoblation);
    const objRequest = {
      idPlan: Number(this.idPlanFlex),
      idProduct: 1
    };

    this.api.getLoadTariffQuotesByPlanProduct(objRequest)
      .then(
        (response: any) => {
          if (response.LoadTariffQuotes === 0) {
            this.isPoblation = true;
          } else {
            this.toastr.info('TIENE QUE CONFIGURAR TODAS LAS TARIFAS PARA PODER CARGAR LA POBLACIÓN', 'NOTIFICACIÓN');
          }
        }, error => {
          this.toastr.error('Ocurrió un problema al cargar el catálogo de Medidas', 'Notificación');
        }
      );

  }

  invokeFunctionBackDetailPlan() {
    this.router.navigate(['/admin/plans-detalle/', this.idPlanFlex]);
  }

  asignInsurance(insurance: any) {
    console.log('Esta es la aseguradora', insurance);

    const plansArray = this.planFlexForm.get('planPlanesModels');
    if (!(plansArray instanceof FormArray)) {
      console.error('planPlanesModels no es un FormArray');
      return;
    }

    plansArray.controls.forEach(planControl => {
      const insuranceCompanyId = planControl.get('insuranceCompanyId');
      insuranceCompanyId.setValue(insurance);
    });
  }

  getCoverages() {
    this.groupedCoverages = {};
    // Agrupar coberturas por nombre
    this.allCoverages.forEach(coverage => {
      if (!this.groupedCoverages[coverage.coverageName]) {
        this.groupedCoverages[coverage.coverageName] = [];
      }
      this.groupedCoverages[coverage.coverageName].push(coverage);
    });
  }

  getFactorQuoteControl(plan: FormGroup, coverage: any): FormControl | null {
    // Obtenemos el FormArray de coberturas
    const coveragesArray = plan.get('flexPlanCoveragesModels') as FormArray;

    if (!coveragesArray || !coveragesArray.controls) {
      return null; // Si no existe el FormArray, retornamos null
    }

    // Buscamos la cobertura específica
    for (const control of coveragesArray.controls) {
      const idControl = control.get('idFlexPlanCoverages');
      if (idControl && idControl.value === coverage.idFlexPlanCoverages) {
        const factorQuoteControl = control.get('factorQuote');
        return factorQuoteControl instanceof FormControl ? factorQuoteControl : null;
      }
    }

    return null; // Si no encontramos la cobertura, retornamos null
  }



  getUniqueCoverages() {
    const uniqueCoverageMap = new Map();

    this.allCoverages.forEach(coverage => {
      // Si la cobertura no está en el mapa, la agregamos
      if (!uniqueCoverageMap.has(coverage.coverageSurexsId)) {
        uniqueCoverageMap.set(coverage.coverageSurexsId, coverage);
      }
    });

    return Array.from(uniqueCoverageMap.values());
  }

// Función para verificar si la cobertura pertenece al plan
  isCoverageInPlan(coverageSurexsId: number, planId: number): boolean {
    return this.allCoverages.some(c => c.coverageSurexsId === coverageSurexsId && c.flexPlanPlanesId === planId);
  }




  protected readonly Number = Number;
}
