export const regex = {
    alfanumerico: '^[0-9A-Za-záéíóúñÁÉÍÓÚÑÜü ]+$',
    alfanumericosinespacios: /^[0-9A-Za-záéíóúñÁÉÍÓÚÑÜü]+$/,
    alfanumericoConGuiones: '^[0-9A-Za-záéíóúñÁÉÍÓÚÑÜü \\-_%.,¿%$]+$',
    alfanumericoPesos: '^[0-9A \\-_%.,¿%$]+$',
    alfanumericoConGuionMedio: '^(?!\\s)[0-9A-Za-záéíóúñÁÉÍÓÚÑÜü \\-]+$',
    alfanumerico2: '^(([A-Za-zñÑÁáÉéÍíÓóÚúÜü0-9-.]+)\\s{0,1})+$',
    alfabeticoConAcentuaciones: '^(([A-Za-zñÑÁáÉéÍíÓóÚúÜü]+\\s{0,1}))+$',
    alfanumericoBase: '^[0-9A-Za-z]+$',
    numeros: '^[0-9]+$',
    numerosSinCero: '^[1-9]+$',
    numeroEnteroPositivo: '^[0-9]+$',
    Porcentaje: '^[1-9][0-9]?$|^100$',
    PorcentajeConCero: '^([0-9]|([1-9][0-9])|100)$',
    numeroEnteroNegativo: '^[-][0-9]+$',
    numeroEnteroGuioMedio: '^[0-9 -]+$',
    numeroEnteroPositivoNegativo: '^[-]?[0-9]+$',
    numeroPedimentoAduaneroSAT: '^[0-9]{2}  [0-9]{2}  [0-9]{4} [0-9]{7}$',
    numerosYletras: '^[A-Za-z0-9áéíóúñÁÉÍÓÚÑÜü]+$',
    numeroDelAl: '^[0-9]/[0-9]+$',
    alfanumericoConPuntuacion: '^[0-9A-Za-záéíóúñÁÉÍÓÚÑÜü .,;]+$',
    alfanumericoConPuntuacionConGuiones: '^[0-9A-Za-záéíóúñÁÉÍÓÚÑÜü .,-_$#%:]+$',
    alfanumericoConPuntuacionConGuionesConArroba: '^[0-9A-Za-záéíóúñÁÉÍÓÚÑÜü .,-_@]+$',
    alfanumericoConPuntuacionConGuiones2: '^[0-9A-Za-záéíóúñÁÉÍÓÚÑÜü .-_]+$',
    _alfanumericoConPuntuacionConGuiones: /^[0-9A-Za-záéíóúñÁÉÍÓÚÑÜü .,-_]+$/,
    alfanumericoConPuntuacionConGuionesConNumeral: /^[0-9A-Za-záéíóúñÁÉÍÓÚÑÜü .,-_#]+$/,
    alfanumericoSinPuntuacion: '^(([A-Za-z0-9]+)\\s{0,1})+$',
    alfanumericoSinPuntuacionConGuionMedio: '^(([A-Za-z0-9 -]+)\\s{0,1})+$',
    alfanumericoConPuntuacionConArrobaConGuiones: '^[0-9A-Za-záéíóúñÁÉÍÓÚÑÜü .,@-_]+$',
    alfanumericoConPuntuacionConGuionesConSaltoLinea: '^[0-9A-Za-záéíóúñÁÉÍÓÚÑÜü .,-_\n]+$',
    alfanumericoConPuntuacionConGuionesConSaltoLineaComillaDoble: '^[0-9A-Za-záéíóúñÁÉÍÓÚÑÜü \\.,-_\n"\$]+$',
    RFC: '^[A-Za-z0-9]+$',
    RFC13Digitos: '^[A-Z]{4}([0-9]{2})(1[0-2]|0[1-9])([0-3][0-9])([A-Z0-9]{3})$',
    RFCMoral: '[A-Z&Ñ]{3}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]',
    RFCFisicaSAT: '[A-Z&Ñ]{4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]',
    letras: '^(([A-Za-zñÑÁáÉéÍíÓóÚúÜü]+)\\s{0,1})+$',
    _soloLetras: /^[A-Za-záéíóúñÁÉÍÓÚÑÜü ]+$/,
    razonSocial: '^(([A-Za-zñÑÁáÉéÍíÓóÚúÜü0-9-.,_@]+)\\s{0,1})+$',
    nombrePersona: '^(([A-Za-zñÑÁáÉéÍíÓóÚúÜü]+\\s{0,1}))+$',
    folioCFDI: '[^|]{1,40}',
    correoElectronico: '^(([A-Za-z0-9\\_\\.\\-\\,]+)@([a-z0-9]+)\\.{1}([a-z]+))$',
    correoElectronicolarge: '^[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$',
    correoElectronicoSurex: '^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\\.)?[a-zA-Z]+\\.)?(surexs)\\.com$',
    correoElectronicoSurex2: '^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\\.)?[a-zA-Z]+\\.)?(surexs|bxp)\\.com$',
    caracteresRFC5322email: '[a-zA-Z0-9áéíóúñÁÉÍÓÚÑÜü.!#@$%&\'\*\+\/=?^_`{|}~-]+',
    decimales: '\d+(\.\d{1,2})?',
    telefono: '^[0-9]{3}-[0-9]{3}-[0-9]{2}-[0-9]{2}$',
    telefonoSinGuion: '^[0-9]{10}$',
    rfc_SAT: '^[A-ZÑ]{3,4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]$',
    idMedidor: '^[A-Za-z]{1}[0-9]{5}$',
    decimales2: '^(\\d{1,12})(\\.\\d{1,2})?$',
    decimales3: '(\d{1,3})(?:,\d{3}){0,3}(\.\d{1,3})??',
    decimales12punto2: '^[0-9]{1,12}.[0-9]{1,2}$',
    alfanumericoConDiagonalConComillaDoble: '^[0-9A-Za-záéíóúñÁÉÍÓÚÑÜü  \\ /"]+$',
    numericoSinCeroInicial: '^[1-9][0-9]*$',
    alfanumericoConDiagonal: '^[0-9A-Za-záéíóúñÁÉÍÓÚÑÜü  \\ /]+$',
    numerosYPuntoDecimal: '^[0-9]+([.][0-9]{1,2})?$',
    vin: '^[0-9A-HJ-NPR-Za-hj-npr-z\\d]{11}[0-9A-Z]{6}$',
    onlydecimanes: /^(0|[1-9][0-9]*)\.[0-9]{2}$|^[0-9]{2}$/
};


/**
 * Método que permite redondear decimales
 * @param valor Valor a redondear
 * @param decimales Cantidad de decimales
 */
export function onDecimalRound(valor, decimales): number {
    const multiplicacion = Math.pow(10, decimales || 0);
    let redondeo = Math.round(valor * multiplicacion) / multiplicacion;
    redondeo = isNaN(redondeo) ? 0 : redondeo;
    return redondeo;
}

export function onPropiedadFloat(valor: any): number {
    const parse = (valor == null ||
        valor === undefined) ? 0 : parseFloat(valor.toString());
    const cantidad = isNaN(parse) ? 0 : parse;
    return cantidad;
}
