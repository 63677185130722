import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ApiService} from '../api.service';
import {Router, ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {DatePipe} from '@angular/common';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {NgxSpinnerService} from 'ngx-spinner';
import {ValidateAccessService} from "../validate-access.service";
import { NgxDateRangePickerOptions } from 'ngx-daterangepicker';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

import moment from 'moment';
import {invoke} from "lodash";

@Component({
  selector: 'app-plans-detalle',
  templateUrl: './plans-detalle.component.html',
  styleUrls: ['./plans-detalle.component.css']
})
export class PlansDetalleComponent implements OnInit {
// variables pantalla consulta siniestros
  planFilter: any = {};
  clientList: any = [];
  groupList: any = [];
  branchesList: any = [];
  statusList: any = [];
  clientsSelectedItems: any = [];
  dropdownSettingsClients = {};
  groupsSelectedItems: any = [];
  dropdownSettingsGroups = {};
  statusSelectedItems: any = [];
  dropdownSettingsStatus = {};
  plansList: any = {};
  pgBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgMaxSize = 3; // Número de links en paginado
  pgItemsPerPage = 10; // Número de registros por página
  pgTotalItems: number; // Total de registros
  pgCurrentPage = 1; // Página actual
  paginationResponse: any = {};

  idPlanFlex: number;

  payments: any;
  benefits: any;

  planDetail: any;
  planForm: FormGroup; // Formulario

  constructor(private api: ApiService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private toastr: ToastrService,
              private shared: SharedService,
              private datePipe: DatePipe,
              public fb: FormBuilder,
              private modalService: BsModalService,
              private spinner: NgxSpinnerService,
              private formBuilder: FormBuilder,
              private validateAccess: ValidateAccessService) {
  }
  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.idPlanFlex = +params.id;

      this.getPlanFlex();
      this.getPayments();
      this.getBenefits();
    });

    this.createForm();
    this.plansList = [];
    // this.kinshipSelected = 0;
    this.getGroups();
    this.dropdownSettingsClients = {
      singleSelection: true,
      idField: 'id',
      textField: 'code',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON SUB GRUPOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsGroups = {
      singleSelection: true,
      idField: 'id',
      textField: 'code',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON GRUPOS',
      searchPlaceholderText: 'BUSCAR'
    };
    this.dropdownSettingsStatus = {
      singleSelection: false,
      idField: 'statusId',
      textField: 'statusName',
      selectAllText: 'TODOS',
      unSelectAllText: 'NINGUNO',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'NO SE ENCONTRARON ESTATUS',
      searchPlaceholderText: 'BUSCAR'
    };
  }
  /* -----------------------------------------------------------------------------------------------------
  ----------------------------- F U N C I O N E S G E N E R A L E S ---------------------------------------
  ----------------------------------------------------------------------------------------------------- */
  goToBack() {
    this.shared.gSearchId = 0;
    this.router.navigate(['/admin/executive']);
  }

  createForm(): void {
    this.planForm = this.formBuilder.group({
        namePlan: [null],
        newIncome: [null],
        idMethodPayment: [null],
        idPlanFlexCampaign: [null],
        campaignStartDate: [null],
        campaignEndDate: [null],
        startValidityCorporateInsuranceProgram: [null],
        endValidityCorporateInsuranceProgram: [null],
        benefitsFlexPlan: [null],
        diasAguinaldo: [null],
        primaVacacional: [null],
        fondoAhorro: [null],
        retencionMaxima: [null],
    });
  }

  getPayments(): void {
    this.api.getTypePaymentMethod({}).subscribe(response => {
      this.payments = response;
    },
    error => {
      console.log(error);
    });
  }

  getBenefits(): void {
    this.api.getBenefits({}).subscribe(response => {
      this.benefits = response;
    },
    error => {
      console.log(error);
    });
  }

  getBenefit(id: number): any {
    let res;
    this.benefits.forEach(ben => {
      if (ben.id === id) {
        res = ben;
      }
    });

    return res;
  }

  getBenefitFromPlan(name: string): any {
    let res;
    this.planDetail.benefitsFlexPlan.forEach(ben => {
      if (ben.benefit === name) {
        res = ben;
      }
    });

    return res;
  }

  getPlanFlex(): void {
    this.api.getFlexPlanDetail({ idPlanFlex: this.idPlanFlex }).then(response => {
      this.planDetail = response;
      console.log(this.planDetail);

      this.planForm.controls['namePlan'].setValue(this.planDetail.namePlan);
      this.planForm.controls['campaignStartDate'].setValue(this.planDetail.campaignStartDate);
      this.planForm.controls['campaignEndDate'].setValue(this.planDetail.campaignEndDate);
      this.planForm.controls['startValidityCorporateInsuranceProgram'].setValue(this.planDetail.startValidityCorporateInsuranceProgram);
      this.planForm.controls['endValidityCorporateInsuranceProgram'].setValue(this.planDetail.endValidityCorporateInsuranceProgram);
      this.planForm.controls['newIncome'].setValue(this.planDetail.newIncome);
      this.planForm.controls['idMethodPayment'].setValue(this.planDetail.idMethodPayment);

      const diasAguinaldo = this.getBenefitFromPlan('DÍAS DE AGUINALDO');
      if (diasAguinaldo) {
        this.planForm.controls['diasAguinaldo'].setValue(diasAguinaldo.valueCompany);
      }

      const primaVacacional = this.getBenefitFromPlan('PRIMA VACACIONAL');
      if (primaVacacional) {
        this.planForm.controls['primaVacacional'].setValue(primaVacacional.valueCompany);
      }

      const fondoAhorro = this.getBenefitFromPlan('FONDO DE AHORRO');
      if (primaVacacional) {
        this.planForm.controls['fondoAhorro'].setValue(fondoAhorro.valueCompany);
      }

      const retencionMaxima = this.getBenefitFromPlan('RETENCIÓN MÁXIMA DE NÓMINA ');
      if (retencionMaxima) {
        this.planForm.controls['retencionMaxima'].setValue(retencionMaxima.valueCompany);
      }
    });
  }
  savePlanFlex(): void {
    const data = this.planForm.value;
    const benefits = [];
    const diasAguinaldo = this.planForm.controls['diasAguinaldo'].value;
    const diasAguinaldoOb = this.getBenefitFromPlan('DÍAS DE AGUINALDO');
    const newDiasAguinaldo = {
      valueMonetize: diasAguinaldo,
      valueCompany: diasAguinaldoOb.valueCompany,
      idBenefitPlanFlex: diasAguinaldoOb.idBenefitPlanFlex
    };
    benefits.push(newDiasAguinaldo);

    const primaVacacional = this.planForm.controls['primaVacacional'].value;
    const primaVacacionalOb = this.getBenefitFromPlan('PRIMA VACACIONAL');
    const newPrimaVacacional = {
      valueMonetize: primaVacacional,
      valueCompany: primaVacacionalOb.valueCompany,
      idBenefitPlanFlex: primaVacacionalOb.idBenefitPlanFlex
    };
    benefits.push(newPrimaVacacional);

    const fondoAhorro = this.planForm.controls['fondoAhorro'].value;
    const fondoAhorroOb = this.getBenefitFromPlan('FONDO DE AHORRO');
    const newFondoAhorro = {
      valueMonetize: fondoAhorro,
      valueCompany: fondoAhorroOb.valueCompany,
      idBenefitPlanFlex: fondoAhorroOb.idBenefitPlanFlex
    };
    benefits.push(newFondoAhorro);

    const retencionMaxima = this.planForm.controls['retencionMaxima'].value;
    const retencionMaximaOb = this.getBenefitFromPlan('RETENCIÓN MÁXIMA DE NÓMINA ');
    const newRetencionMaxima = {
      valueMonetize: retencionMaxima,
      valueCompany: retencionMaximaOb.valueCompany,
      idBenefitPlanFlex: retencionMaximaOb.idBenefitPlanFlex
    };
    benefits.push(newRetencionMaxima);

    const params = {
      idPlanFlex: this.idPlanFlex,
      namePlan: data.namePlan,
      newIncome: data.newIncome,
      idMethodPayment: data.idMethodPayment,
      idPlanFlexCampaign: this.planDetail.idPlanFlexCampaign,
      campaignStartDate: moment(data.campaignStartDate).isValid() ? moment(data.campaignStartDate).format('DD-MM-YYYY') : data.campaignStartDate,
      campaignEndDate: moment(data.campaignEndDate).isValid() ? moment(data.campaignEndDate).format('DD-MM-YYYY') : data.campaignEndDate,
      startValidityCorporateInsuranceProgram: moment(data.startValidityCorporateInsuranceProgram).isValid() ? moment(data.startValidityCorporateInsuranceProgram).format('DD-MM-YYYY') : data.startValidityCorporateInsuranceProgram,
      endValidityCorporateInsuranceProgram: moment(data.endValidityCorporateInsuranceProgram).isValid() ? moment(data.endValidityCorporateInsuranceProgram).format('DD-MM-YYYY') : data.endValidityCorporateInsuranceProgram,
      benefitsFlexPlan: benefits
    };

    this.api.updateFlexPlanDetail(params).subscribe(response => {
      this.toastr.success('Plan actualizado correctamente');
      this.getPlanFlex();
    },
    error => {
      this.toastr.error(error.error.message);
    });
  }
  eliminarProducto(product: any): void {
    const params = {
      idPlanFlex: this.idPlanFlex,
      idProduct: product.idFlexlibleProduct
    };

    this.api.deleteProductoInFlexPlan(params).subscribe(response => {
      this.getPlanFlex();
    },
    error => {
      console.log(error);
    });
  }
  getSubGroups(groups) {
    const params = {
      id: groups.idsGroups[0]
    };
    this.api.getSubGroupsFlex(params).then((data: any) => {
      this.clientList = data;
    }, error => {
    });
  }
  changeStatusGroups() {
    const group = [];
    setTimeout(() => {
      try {
        this.groupsSelectedItems.forEach(item => {
          group.push(item.id);
        });
        let multiGroup = {};
        multiGroup = {
          idsGroups: group
        };
        this.getSubGroups(multiGroup);
        this.planFilter.groups = group;
        this.clientsSelectedItems = [];
      } catch (e) { }
    }, 1000);
  }
  getGroups() {
    this.api.getGroupsFlex().then((data: any) => {
      this.groupList = data;
    }, error => {
    });
  }

  protected readonly invoke = invoke;

  invokeFunctionGetSlipLife(idPlanFlex: number) {
    this.spinner.show('sp');
    this.api.getSlipLife(idPlanFlex)
      .then(
        (response: any) => {
          if (response.slip === undefined) {
            this.toastr.error('OCURRIO UN PROBLEMA AL OBTENER EL SLIP.', 'NOTIFIACION');
            this.spinner.hide('sp');
            return;
          }
          const base64Data = response.slip;
          const binaryData = atob(base64Data);  // Decodificar Base64 a binario
          const arrayBuffer = new ArrayBuffer(binaryData.length);
          const uintArray = new Uint8Array(arrayBuffer);

          for (let i = 0; i < binaryData.length; i++) {
            uintArray[i] = binaryData.charCodeAt(i);
          }

          const blob = new Blob([uintArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(blob);
          downloadLink.setAttribute('download', 'SurexsSlip.xlsx');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
          this.spinner.hide('sp');
        }, (error) => {
          this.spinner.hide('sp');
        });
  }
  invokeFunctionGetSlipSpousalLife(idPlanFlex: number) {
    this.spinner.show('sp');
    this.api.getSlipSpousalLife(idPlanFlex)
      .then(
        (response: any) => {
          if (response.slip === undefined) {
            this.toastr.error('OCURRIO UN PROBLEMA AL OBTENER EL SLIP.', 'NOTIFIACION');
            this.spinner.hide('sp');
            return;
          }
          const base64Data = response.slip;
          const binaryData = atob(base64Data);  // Decodificar Base64 a binario
          const arrayBuffer = new ArrayBuffer(binaryData.length);
          const uintArray = new Uint8Array(arrayBuffer);

          for (let i = 0; i < binaryData.length; i++) {
            uintArray[i] = binaryData.charCodeAt(i);
          }

          const blob = new Blob([uintArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(blob);
          downloadLink.setAttribute('download', 'SurexsSlip.xlsx');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
          this.spinner.hide('sp');
        }, (error) => {
          this.spinner.hide('sp');
        });
  }

  invokeFunctionGetSlipGmmExcess(idPlanFlex: number): void {
    this.spinner.show('sp');
    this.api.getSlipExcess(idPlanFlex)
      .then(
        (response: any) => {
          const base64Data = response.slip;
          const binaryData = atob(base64Data);  // Decodificar Base64 a binario
          const arrayBuffer = new ArrayBuffer(binaryData.length);
          const uintArray = new Uint8Array(arrayBuffer);

          for (let i = 0; i < binaryData.length; i++) {
            uintArray[i] = binaryData.charCodeAt(i);
          }

          const blob = new Blob([uintArray], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(blob);
          downloadLink.setAttribute('download', 'SurexsSlip.xlsx');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
          this.spinner.hide('sp');
        }, (error) => {
          this.spinner.hide('sp');
        });

  }

  invokeFunctionRedirectionProductP1(idPlanFlex: number, product: any) {
    switch (product.idFlexibleProduct) {
      case 1:
        this.router.navigate(['/admin/flex-gmm/', idPlanFlex]);
        break;
      case 2:
        this.router.navigate(['/admin/flex-excess/', idPlanFlex]);
        break;
      case 3:
        this.router.navigate(['/admin/flex-life/', idPlanFlex]);
        break;
      case 7:
        this.router.navigate(['/admin/gastos-medicos-menores/', product.idFlexibleProduct, idPlanFlex]);
        break;
      case 4:
        this.router.navigate(['/admin/flex-spousal-life', idPlanFlex]);
        break;
      case 5:
        this.router.navigate(['/admin/flex-dental/', idPlanFlex]);
        break;
      case 6:
        this.router.navigate(['/admin/flex-vision/', idPlanFlex]);
        break;
      case 8:
        this.router.navigate(['/admin/mascotas/', product.idFlexibleProduct, idPlanFlex]);
        break;
      case 10:
        this.router.navigate(['/admin/autos/', product.idFlexibleProduct, idPlanFlex]);
        break;
    }
  }

  invokeFunctionRedirectionProductP2(idPlanFlex: number, product: any) {
    console.log(product);
    switch (product.idFlexibleProduct) {
      case 1:
        this.router.navigate(['/admin/flex-gmm-p2/', idPlanFlex]);
        break;
      case 2:
        this.router.navigate(['/admin/flex-excess-p2/', idPlanFlex]);
        break;
      case 3:
        this.router.navigate(['/admin/flex-life-p2/', idPlanFlex]);
        break;
      case 7:
        this.router.navigate(['/admin/gastos-medicos-menores-p2/', product.idFlexibleProduct, idPlanFlex]);
        break;
      case 4:
        this.router.navigate(['/admin/flex-spousal-life-p2/', idPlanFlex]);
        break;
      case 5:
        this.router.navigate(['/admin/flex-dental-p2/', idPlanFlex]);
        break;
      case 6:
        this.router.navigate(['/admin/flex-vision-p2/', idPlanFlex]);
        break;
      case 8:
        this.router.navigate(['/admin/mascotas-p2/', product.idFlexibleProduct, idPlanFlex]);
        break;
      case 10:
        this.router.navigate(['/admin/autos-p2/', product.idFlexibleProduct, idPlanFlex]);
        break;
    }
  }
  invokeFunctionGetSlipByProduct(idPlanFlex: number, product: any) {
    switch (product.idFlexibleProduct) {
      case 1:
        this.invokeFunctionGetSlipGmmExcess(idPlanFlex);
        break;
      case 2:
        this.invokeFunctionGetSlipGmmExcess(idPlanFlex);
        break;
      case 3:
        this.invokeFunctionGetSlipLife(idPlanFlex);
        break;
      case 4:
        this.invokeFunctionGetSlipSpousalLife(idPlanFlex);
        break;
    }
  }
}
