import {Component, OnInit, TemplateRef, ViewChild, Input, Output, EventEmitter} from '@angular/core';
import {ApiService} from '../api.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../shared-service.service';
import {DatePipe} from '@angular/common';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {NgxSpinnerService} from 'ngx-spinner';
import {ValidateAccessService} from "../validate-access.service";
import { NgxDateRangePickerOptions } from 'ngx-daterangepicker';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import * as XLSX from 'xlsx';

import moment from 'moment';
import _ from 'lodash';

@Component({
  selector: 'app-plans-carga-poblacion',
  templateUrl: './plans-carga-poblacion.component.html',
  styleUrls: ['./plans-carga-poblacion.component.css']
})
export class PlansCargaPoblacionComponent implements OnInit {

  @Input() productoId: number;
  @Input() planFlexibleId: number;
  @Input() flexibleProductId: number;

  @Output() isLayoutCargado = new EventEmitter<any>();

  public fileUploadXlsx: File;
  errorXlsx = {active: false, msg: ''};
  fileTypesXlsx: string[] = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

  layoutCargado: boolean;
  acceso: string;
  infoPoblacion: any = [];
  infoPoblacionPre: any = [];

  usuarioSelected: any;
  indexSelected: number;
  isEdit: boolean;

  categorias: any;

  name: string = '';
  rfc: string = '';
  employeNumber: string = '';
  email: string = '';

  pgBoundaryLinks = false; // Bandera que indica si se muestra Inicio/Fin del paginado
  pgMaxSize = 3; // Número de links en paginado
  pgItemsPerPage = 500; // Número de registros por página
  pgTotalItems: number; // Total de registros
  pgCurrentPage = 1; // Página actual
  paginationResponse: any = {};

  relaciones = [
	'TITULAR',
	'HIJO(A)',
	'CONYUGE',
	'HERMANO',
	'PADRE',
	'MADRE'
  ];

  generos = [
	'FEMENINO',
	'MASCULINO'
  ];

  acciones = [
	'AGREGAR',
	'EDITAR',
    'ELIMINAR'
  ];

  insureds: any;
  tab1: boolean = true;
  tab2: boolean;

  constructor(private api: ApiService,
              private router: Router,
              private toastr: ToastrService,
              private shared: SharedService,
              private datePipe: DatePipe,
              public fb: FormBuilder,
              private modalService: BsModalService,
              private spinner: NgxSpinnerService,
              private formBuilder: FormBuilder,
              private validateAccess: ValidateAccessService) {
  }
  ngOnInit() {
    this.layoutCargado = false;
    this.getCategorias();
	  this.getInsureds();
  }
  /* -----------------------------------------------------------------------------------------------------
  ----------------------------- F U N C I O N E S G E N E R A L E S ---------------------------------------
  ----------------------------------------------------------------------------------------------------- */
  goToBack() {
    this.shared.gSearchId = 0;
    this.router.navigate(['/admin/executive']);
  }

  buscar(): void {
	this.getInsureds();
  }

  pageChanged(event: PageChangedEvent): void {
    this.pgCurrentPage = event.page;
	this.getInsureds();
  }

  getInsureds(): any {
	const params = {
		planFlexId: this.planFlexibleId,
		flexibleProductId: this.flexibleProductId,
		name: this.name,
		rfc: this.rfc,
		employeNumer: this.employeNumber,
		email: this.email,
		page: this.pgCurrentPage,
		size: this.pgItemsPerPage
	};
  console.log('params-', params);
	this.api.getInsuredListByProduct(params).subscribe((response: any) => {
    console.log('RES', response);
		const tmp = response.insureds.map(ob => ({
			'PARENTESCO': ob.relationship,
			'NUMERO DE EMPLEADO': ob.numberEmploye,
			'NOMBRE EMPLEADO': ob.name,
			'APELLIDO PATERNO EMPLEADO': ob.firstName,
			'APELLIDO MATERNO EMPLEADO': ob.lastName,
			'RFC': ob.rfc,
			'GENERO': ob.gender,
			'FECHA NACIMIENTO': ob.birthDate,
			'TELEFONO': ob.phone,
			'SALARIO BRUTO': parseFloat(ob.grossSalary).toFixed(2),
			'SALARIO DIARIO INTEGRADO': parseFloat(ob.integratedDailyWage).toFixed(2),
			'DIAS DE VACACIONES': ob.vacationDays,
			'DIAS DE AGUINALDO': ob.christmasBonus,
			'PRIMA VACACIONAL': ob.vacationBonus,
			'FONDO DE AHORRO': ob.savingFund,
			'CORREO': ob.email,
			'CODIGO POSTAL': ob.zipcode,
			'CATEGORIA': ob.categoryExcesses,
			'TRANSACCION': null
		}));
		if (tmp.length > 0) {
			this.infoPoblacionPre = tmp;
			this.layoutCargado = true;
		}
    this.checkIsLayoutCargado();
	},
	error => {
		console.log(error);
	});
  }

  getCategorias(): void {
    this.api.getFlexPlanCategoriesByIdProduct({ idPlanFlex: +this.planFlexibleId, idProduct: this.flexibleProductId }).subscribe(response => {
        this.categorias = response;
    },
    error => {
        console.log(error);
    });
  }
  checkIsLayoutCargado(): void {
    if (this.layoutCargado) {
      this.isLayoutCargado.emit(true);
    } else {
      this.isLayoutCargado.emit(false);
    }
  }
    fileUploadXls(event: any): void {
        // Obtener la fecha actual
        const today = new Date();
        const e: any = event;
        // Convertir a cadena ISO y extraer la fecha en formato aaaa-mm-dd
        const formattedDate = today.toISOString().slice(0, 10);
        // Obtener la fecha y hora actual
        const now = new Date();
        // Extraer la hora en formato hh:mm:ss
        const formattedTime = now.toLocaleTimeString();

        let selectedFile = event.target.files[0];
        // this.onFileSelected(event);
        const fileReader = new FileReader();
        fileReader.readAsBinaryString(selectedFile);

        if (selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            // tslint:disable-next-line:no-shadowed-variable
            fileReader.onload = (event: any) => {
                const binaryData = event.target.result;
                const workBook = XLSX.read(binaryData, {type: 'binary', cellDates: true, dateNF: 'dd/mm/yyyy'});

                // Obtener los datos de la hoja de cálculo en forma de matriz JSON
                const sheetName = workBook.SheetNames[1];
                const worksheet = workBook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet);
                this.infoPoblacion = jsonData;

                this.layoutCargado = true;
				this.tab1 = false;
				this.tab2 = true;
            }
        }
    }

	editarUsuario(index: number, user: any): void {
		this.indexSelected = index;
		this.usuarioSelected = user;
		this.isEdit = true;
	}

	cancelarUsuario(index: number, user: any): void {
		this.indexSelected = null;
		this.usuarioSelected = {};
		this.isEdit = false;
	}

	guardarUsuario(index: number, user: any): void {
		this.indexSelected = null;
		this.usuarioSelected = {};
		this.isEdit = false;
	}

	convertInfoPoblacion(arr: any): any {
        return arr.filter(ob => ob['TRANSACCION'] !== null).map(ob => {
			const index = _.findIndex(this.categorias, { categoryName: ob['CATEGORIA'] });
			let categoryId = null;
			let categoryName = null;
			if (index >= 0) {
				const ob = this.categorias[index];
				categoryId = ob.idFlexPlanCategories;
				categoryName = ob.categoryName;
			}

			const birthDateOb = moment(ob['FECHA NACIMIENTO']);
			let birthDate = ob['FECHA NACIMIENTO'];
			if (birthDateOb.isValid()) {
				birthDate = birthDateOb.format('DD-MM-YYYY');
			}

			return {
				relationship: ob['PARENTESCO'],
				numberEmploye: ob['NUMERO DE EMPLEADO'].toString(),
				name: ob['NOMBRE EMPLEADO'],
				firstName: ob['APELLIDO PATERNO EMPLEADO'],
				lastName: ob['APELLIDO MATERNO EMPLEADO'],
				rfc: ob['RFC'],
				email: ob['CORREO'],
				gender: ob['GENERO'],
				birthDate: birthDate,
				phone: ob['TELEFONO'],
				zipcode: ob['CODIGO POSTAL'],
				grossSalary: ob['SALARIO BRUTO'],
				integratedDailyWage: ob['SALARIO DIARIO INTEGRADO'],
				christmasBonus: ob['DIAS DE AGUINALDO'],
				vacationBonus: ob['PRIMA VACACIONAL'],
				vacationDays: ob['DIAS DE VACACIONES'],
				savingFund: ob['FONDO DE AHORRO'],
				categoryExcesses: categoryName,
				categoryId: categoryId,
				transaction: ob['TRANSACCION'],
			};
        });
	}

	descargarLayout(): void {
		this.api.downloadlayoutLoadPopulation({}).subscribe((response: any) => {
            const dataType = response.type;
            const binaryData = [];
            binaryData.push(response);
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
			downloadLink.setAttribute('download',  'LAYOUT_CARGA_POBLACION.xlsx');

            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
		},
		error => {
			this.toastr.error(error.error.message);
		});
	}

    procesar(): void {
		const insuredPre = this.convertInfoPoblacion(this.infoPoblacionPre);
		const insureds = this.convertInfoPoblacion(this.infoPoblacion);
        const params = {
            planFlexId: this.planFlexibleId,
            origen: this.productoId,
            flexibleProductId: this.flexibleProductId,
            insureds: insureds.concat(insuredPre)
        };
        console.log(params);
        this.api.flexPlanLoadPopulation(params).subscribe(response => {
			this.toastr.success('Información procesada correctamente');
        },
        error => {
            console.log(error);
        });
    }

    isErrorRelacion(val: any): boolean {
        return val === null || val.trim() === '' || this.relaciones.indexOf(val) === -1;
    }

    isErrorGenero(val: any): boolean {
        return val === null || val.trim() === '' || this.generos.indexOf(val) === -1;
    }

    isErrorFechaNacimiento(val: any): boolean {
        const date = moment(val);
        //const fechaNacimiento = date.format('DD-MM-YYYY');
        //const fechaNacimientoPattern = /^[0-3]?[0-9].[0-3]?[0-9].(?:[0-9]{2})?[0-9]{2}$/gm;
		//const fechaNacimientoPattern = /^([0-2][0-9]|3[0-1])(-)(0[1-9]|1[0-2])\\2(\\d{4})$/g;
		return val === null || !date.isValid();
    }

    isErrorCategoria(val: any): boolean {
        const index = _.findIndex(this.categorias, { categoryName: val.toLowerCase() });
        return index === -1 && val !== '';
    }

    isErrorNumeroEmpleado(val: any): boolean {
        return val === null;
    }

    isErrorNombreEmpleado(val: any): boolean {
        return val === null || val.trim() === '';
    }

    isErrorApEmpleado(val: any): boolean {
        return val === null || val.trim() === '';
    }

    isErrorAmEmpleado(val: any): boolean {
        return val === null || val.trim() === '';
    }

    isErrorRfc(val: any): boolean {
        const rfcPattern = /[A-ZÑ&]{3,4}\d{6}[A-V1-9][A-Z1-9][0-9A]/gm;
		//const rfcPatternWithOutHomoClave = /^[A-Za-zñÑ&]{3,4}\\d{6}$/;
		//const rfcPattern = /^(((?!(([CcKk][Aa][CcKkGg][AaOo])|([Bb][Uu][Ee][YyIi])|([Kk][Oo](([Gg][Ee])|([Jj][Oo])))|([Cc][Oo](([Gg][Ee])|([Jj][AaEeIiOo])))|([QqCcKk][Uu][Ll][Oo])|((([Ff][Ee])|([Jj][Oo])|([Pp][Uu]))[Tt][Oo])|([Rr][Uu][Ii][Nn])|([Gg][Uu][Ee][Yy])|((([Pp][Uu])|([Rr][Aa]))[Tt][Aa])|([Pp][Ee](([Dd][Oo])|([Dd][Aa])|([Nn][Ee])))|([Mm](([Aa][Mm][OoEe])|([Ee][Aa][SsRr])|([Ii][Oo][Nn])|([Uu][Ll][Aa])|([Ee][Oo][Nn])|([Oo][Cc][Oo])))))[A-Za-zñÑ&][aeiouAEIOUxX]?[A-Za-zñÑ&]{2}(((([02468][048])|([13579][26]))0229)|(\\d{2})((02((0[1-9])|1\\d|2[0-8]))|((((0[13456789])|1[012]))((0[1-9])|((1|2)\\d)|30))|(((0[13578])|(1[02]))31)))[a-zA-Z1-9]{2}[\\dAa])|([Xx][AaEe][Xx]{2}010101000))$/g;
		return val === null || val.trim() === '' || !rfcPattern.test(val);
    }

    isErrorSalarioBruto(val: any): boolean {
        const salarioBrutoPattern = /^([0-9]{1,10}(\.[0-9]{1,2})?)$/gm;
		//const salarioBrutoPattern = /^[1-9][0-9]{1,}(?:,?[0-9]{3}){0,3}(?:.?[0-9]){1,2}$/g;
		return val === null || !salarioBrutoPattern.test(val);
    }

    isErrorSalarioDiarioIntegrado(val: any): boolean {
        const salarioDiarioIntegradoPattern = /^([0-9]{1,10}(\.[0-9]{1,2})?)$/gm;
		//const salarioBrutoPattern = /^[1-9][0-9]{1,}(?:,?[0-9]{3}){0,3}(?:.?[0-9]){1,2}$/g;
		return val === null || !salarioDiarioIntegradoPattern.test(val);
    }

    isErrorAguinaldo(val: any): boolean {
        const aguinaldoPattern = /^[0-9]*$/gm;
		//const aguinaldoPattern = /\\d{1,100}$/g;
		return val === null || !aguinaldoPattern.test(val);
    }

    isErrorPrimaVacacional(val: any): boolean {
        const primaVacacionalPattern = /^[0-9]*$/gm;
		//const primaVacacionalPattern = /\\d{1,100}$/g;
		return val === null || !primaVacacionalPattern.test(val);
    }

    isErrorFondoAhorro(val: any): boolean {
        const fondoAhorroPattern = /^[0-9]*$/gm;
		//const fondoAhorroPattern = /\\d{1,100}$/g;
		return val === null || !fondoAhorroPattern.test(val);
    }

    isErrorDiasVacaciones(val: any): boolean {
        const fondoAhorroPattern = /^[0-9]*$/gm;
		//const fondoAhorroPattern = /\\d{1,100}$/g;
		return val === null || !fondoAhorroPattern.test(val);
    }

    isErrorCorreo(val: any): boolean {
		//const correoPattern = /^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0–9]{1,3}\\.[0–9]{1,3}\\.[0–9]{1,3}\\.[0–9]{1,3}])|(([a-zA-Z\\-0–9]+\\.)+[a-zA-Z]{2,}))$/g;
		const correoPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/gm;
		return val === null || val.trim() === '' || !correoPattern.test(val);
    }

    isErrorTelefono(val: any): boolean {
		//const telefonoPattern = /^\\(?(\\d{3})\\)?(\\d{3})(\\d{4})$/g;
		const telefonoPattern = /^([0-9]{2,3})([\s.-]?)([0-9]{3,4})([\s.-]?)([0-9]{4})$/gm;
  	    return val === null || !telefonoPattern.test(val);
    }

    isErrorCodigoPostal(val: any): boolean {
        const codigoPostalPattern = /^[0-9]{4,5}$/gm;
		//const codigoPostalPattern = /^\\d{4,5}$/g;
		return val === null || !codigoPostalPattern.test(val);
    }

}
